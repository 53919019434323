import svgFile from "../../.sprite-stuff/sprite.svg" assert { type: "svg" };

class Sprite extends HTMLElement {
  static get observedAttributes() {
    return [];
  }
  async connectedCallback() {
    this.innerHTML = svgFile;
  }
}
window.customElements.define("sprite-sheet", Sprite);
