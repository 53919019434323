(function (scope) {
    "use strict";
    function F(arity, fun, wrapper) {
        wrapper.a = arity;
        wrapper.f = fun;
        return wrapper;
    }
    function F2(fun) {
        var curried = function (a) {
            return function (b) {
                return fun(a, b);
            };
        };
        curried.a2 = fun;
        return curried;
    }
    function F3(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return fun(a, b, c);
                };
            };
        };
        curried.a3 =
            fun;
        return curried;
    }
    function F4(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return fun(a, b, c, d);
                    };
                };
            };
        };
        curried.a4 = fun;
        return curried;
    }
    function F5(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return function (e) {
                            return fun(a, b, c, d, e);
                        };
                    };
                };
            };
        };
        curried.a5 = fun;
        return curried;
    }
    function F6(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return function (e) {
                            return function (f) {
                                return fun(a, b, c, d, e, f);
                            };
                        };
                    };
                };
            };
        };
        curried.a6 = fun;
        return curried;
    }
    function F7(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return function (e) {
                            return function (f) {
                                return function (g) { return fun(a, b, c, d, e, f, g); };
                            };
                        };
                    };
                };
            };
        };
        curried.
            a7 = fun;
        return curried;
    }
    function F8(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return function (e) {
                            return function (f) {
                                return function (g) {
                                    return function (h) {
                                        return fun(a, b, c, d, e, f, g, h);
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
        curried.a8 = fun;
        return curried;
    }
    function F9(fun) {
        var curried = function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return function (e) {
                            return function (f) {
                                return function (g) {
                                    return function (h) {
                                        return function (i) {
                                            return fun(a, b, c, d, e, f, g, h, i);
                                        };
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
        curried
            .a9 = fun;
        return curried;
    }
    function A2(fun, a, b) {
        return fun.a2 ? fun.a2(a, b) : fun(a)(b);
    }
    function A3(fun, a, b, c) {
        return fun.a3 ? fun.a3(a, b, c) : fun(a)(b)(c);
    }
    function A4(fun, a, b, c, d) {
        return fun.a4 ? fun.a4(a, b, c, d) : fun(a)(b)(c)(d);
    }
    function A5(fun, a, b, c, d, e) {
        return fun.a5 ? fun.a5(a, b, c, d, e)
            : fun(a)(b)(c)(d)(e);
    }
    function A6(fun, a, b, c, d, e, f) {
        return fun.a6 ? fun.a6(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
    }
    function A7(fun, a, b, c, d, e, f, g) {
        return fun.a7 ? fun.a7(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
    }
    function A8(fun, a, b, c, d, e, f, g, h) {
        return fun.a8 ? fun.a8(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
    }
    function A9(fun, a, b, c, d, e, f, g, h, i) {
        return fun.a9 ? fun.a9(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
    }
    var _JsArray_empty = [];
    function _JsArray_singleton(value) {
        return [value];
    }
    function _JsArray_length(array) {
        return array.length;
    }
    var _JsArray_initialize_fn = function (size, offset, func) {
        var result = new Array(size);
        for (var i = 0; i < size; i++) {
            result[i] = func(offset + i);
        }
        return result;
    }, _JsArray_initialize = F3(_JsArray_initialize_fn);
    var _JsArray_initializeFromList_fn = function (max, ls) {
        var result = new Array(max);
        for (var i = 0; i < max && ls.b; i++) {
            result[i] = ls.a;
            ls = ls.b;
        }
        result.length = i;
        return _Utils_Tuple2(result, ls);
    }, _JsArray_initializeFromList = F2(_JsArray_initializeFromList_fn);
    var _JsArray_unsafeGet_fn = function (index, array) {
        return array[index];
    }, _JsArray_unsafeGet = F2(_JsArray_unsafeGet_fn);
    var _JsArray_unsafeSet_fn = function (index, value, array) {
        var length = array.length;
        var result = new Array(length);
        for (var i = 0; i < length; i++) {
            result[i] = array[i];
        }
        result[index] = value;
        return result;
    }, _JsArray_unsafeSet = F3(_JsArray_unsafeSet_fn);
    var _JsArray_push_fn = function (value, array) {
        var length = array.length;
        var result = new Array(length + 1);
        for (var i = 0; i < length; i++) {
            result[i] = array[i];
        }
        result[length] = value;
        return result;
    }, _JsArray_push = F2(_JsArray_push_fn);
    var _JsArray_foldl_fn = function (func, acc, array) {
        var length = array.length;
        for (var i = 0; i < length; i++) {
            acc = A2(func, array[i], acc);
        }
        return acc;
    }, _JsArray_foldl_fn_unwrapped = function (func, acc, array) {
        var length = array.length;
        for (var i = 0; i < length; i++) {
            acc = func(array[i], acc);
        }
        return acc;
    }, _JsArray_foldl = F3(_JsArray_foldl_fn);
    var _JsArray_foldr_fn = function (func, acc, array) {
        for (var i = array.length - 1; i >= 0; i--) {
            acc = A2(func, array[i], acc);
        }
        return acc;
    }, _JsArray_foldr_fn_unwrapped = function (func, acc, array) {
        for (var i = array.length - 1; i >= 0; i--) {
            acc = func(array[i], acc);
        }
        return acc;
    }, _JsArray_foldr = F3(_JsArray_foldr_fn);
    var _JsArray_map_fn = function (func, array) {
        var length = array.length;
        var result = new Array(length);
        for (var i = 0; i < length; i++) {
            result[i] = func(array[i]);
        }
        return result;
    }, _JsArray_map = F2(_JsArray_map_fn);
    var _JsArray_indexedMap_fn = function (func, offset, array) {
        var length = array.length;
        var result = new Array(length);
        for (var i = 0; i < length; i++) {
            result[i] = A2(func, offset + i, array[i]);
        }
        return result;
    }, _JsArray_indexedMap_fn_unwrapped = function (func, offset, array) {
        var length = array.length;
        var result = new Array(length);
        for (var i = 0; i < length; i++) {
            result[i] = func(offset + i, array[i]);
        }
        return result;
    }, _JsArray_indexedMap = F3(_JsArray_indexedMap_fn);
    var _JsArray_slice_fn = function (from, to, array) {
        return array.slice(from, to);
    }, _JsArray_slice = F3(_JsArray_slice_fn);
    var _JsArray_appendN_fn = function (n, dest, source) {
        var destLen = dest.length;
        var itemsToCopy = n - destLen;
        if (itemsToCopy > source.length) {
            itemsToCopy = source.length;
        }
        var size = destLen + itemsToCopy;
        var result = new Array(size);
        for (var i = 0; i < destLen; i++) {
            result[i] = dest[i];
        }
        for (var i = 0; i < itemsToCopy; i++) {
            result[i + destLen] = source[i];
        }
        return result;
    }, _JsArray_appendN = F3(_JsArray_appendN_fn);
    var _Debug_log_fn = function (tag, value) {
        return value;
    }, _Debug_log = F2(_Debug_log_fn);
    var _Debug_log_UNUSED_fn = function (tag, value) {
        console.log(tag + ": " + _Debug_toString(value));
        return value;
    }, _Debug_log_UNUSED = F2(_Debug_log_UNUSED_fn);
    function _Debug_todo(moduleName, region) {
        return function (message) {
            _Debug_crash(8, moduleName, region, message);
        };
    }
    function _Debug_todoCase(moduleName, region, value) {
        return function (message) {
            _Debug_crash(9, moduleName, region, value, message);
        };
    }
    function _Debug_toString(value) {
        return "<internals>";
    }
    function _Debug_toString_UNUSED(value) {
        return _Debug_toAnsiString(false, value);
    }
    function _Debug_toAnsiString(ansi, value) {
        if (typeof value === "function") {
            return _Debug_internalColor(ansi, "<function>");
        }
        if (typeof value === "boolean") {
            return _Debug_ctorColor(ansi, value ? "True" : "False");
        }
        if (typeof value === "number") {
            return _Debug_numberColor(ansi, value + "");
        }
        if (value instanceof String) {
            return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
        }
        if (typeof value === "string") {
            return _Debug_stringColor(ansi, "\"" + _Debug_addSlashes(value, false) + "\"");
        }
        if (typeof value === "object" && "$" in value) {
            var tag = value.$;
            if (typeof tag === "number") {
                return _Debug_internalColor(ansi, "<internals>");
            }
            if (tag[0] === "#") {
                var output = [];
                for (var k in value) {
                    if (k === "$")
                        continue;
                    output.push(_Debug_toAnsiString(ansi, value[k]));
                }
                return "(" + output.join(",") + ")";
            }
            if (tag === "Set_elm_builtin") {
                return _Debug_ctorColor(ansi, "Set")
                    + _Debug_fadeColor(ansi, ".fromList") + " "
                    + _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
            }
            if (tag === "RBNode_elm_builtin" || tag === "RBEmpty_elm_builtin") {
                return _Debug_ctorColor(ansi, "Dict")
                    + _Debug_fadeColor(ansi, ".fromList") + " "
                    + _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
            }
            if (tag === "Array_elm_builtin") {
                return _Debug_ctorColor(ansi, "Array")
                    + _Debug_fadeColor(ansi, ".fromList") + " "
                    + _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
            }
            if (tag === "::" || tag === "[]") {
                var output = "[";
                value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b);
                for (; value.b; value = value.b) {
                    output += "," + _Debug_toAnsiString(ansi, value.a);
                }
                return output + "]";
            }
            var output = "";
            for (var i in value) {
                if (i === "$")
                    continue;
                var str = _Debug_toAnsiString(ansi, value[i]);
                var c0 = str[0];
                var parenless = c0 === "{" || c0 === "(" || c0 === "[" || c0 === "<" || c0 === "\"" || str.indexOf(" ") < 0;
                output += " " + (parenless ? str : "(" + str + ")");
            }
            return _Debug_ctorColor(ansi, tag) + output;
        }
        if (typeof DataView === "function" && value instanceof DataView) {
            return _Debug_stringColor(ansi, "<" + value.byteLength + " bytes>");
        }
        if (typeof File !== "undefined" && value instanceof File) {
            return _Debug_internalColor(ansi, "<" + value.name + ">");
        }
        if (typeof value === "object") {
            var output = [];
            for (var key in value) {
                var field = key[0] === "_" ? key.slice(1) : key;
                output.push(_Debug_fadeColor(ansi, field) + " = " + _Debug_toAnsiString(ansi, value[key]));
            }
            if (output.length === 0) {
                return "{}";
            }
            return "{ " + output.join(", ") + " }";
        }
        return _Debug_internalColor(ansi, "<internals>");
    }
    function _Debug_addSlashes(str, isChar) {
        var s = str
            .replace(/\\/g, "\\\\")
            .replace(/\n/g, "\\n")
            .replace(/\t/g, "\\t")
            .replace(/\r/g, "\\r")
            .replace(/\v/g, "\\v")
            .replace(/\0/g, "\\0");
        if (isChar) {
            return s.replace(/\'/g, "\\'");
        }
        else {
            return s.replace(/\"/g, "\\\"");
        }
    }
    function _Debug_ctorColor(ansi, string) {
        return ansi ? "\u001B[96m" + string + "\u001B[0m" : string;
    }
    function _Debug_numberColor(ansi, string) {
        return ansi ? "\u001B[95m" + string + "\u001B[0m" : string;
    }
    function _Debug_stringColor(ansi, string) {
        return ansi ? "\u001B[93m" + string + "\u001B[0m" : string;
    }
    function _Debug_charColor(ansi, string) {
        return ansi ? "\u001B[92m" + string + "\u001B[0m" : string;
    }
    function _Debug_fadeColor(ansi, string) {
        return ansi ? "\u001B[37m" + string + "\u001B[0m" : string;
    }
    function _Debug_internalColor(ansi, string) {
        return ansi ? "\u001B[36m" + string + "\u001B[0m" : string;
    }
    function _Debug_toHexDigit(n) {
        return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
    }
    function _Debug_crash(identifier) {
        throw new Error("https://github.com/elm/core/blob/1.0.0/hints/" + identifier + ".md");
    }
    function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4) {
        switch (identifier) {
            case 0:
                throw new Error("What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById(\"elm-node\")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.");
            case 1:
                throw new Error("Browser.application programs cannot handle URLs like this:\n\n    " + document.location.href + "\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.");
            case 2:
                var jsonErrorString = fact1;
                throw new Error("Problem with the flags given to your Elm program on initialization.\n\n" + jsonErrorString);
            case 3:
                var portName = fact1;
                throw new Error("There can only be one port named `" + portName + "`, but your program has multiple.");
            case 4:
                var portName = fact1;
                var problem = fact2;
                throw new Error("Trying to send an unexpected type of value through port `" + portName + "`:\n" + problem);
            case 5:
                throw new Error("Trying to use `(==)` on functions.\nThere is no way to know if functions are \"the same\" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.");
            case 6:
                var moduleName = fact1;
                throw new Error("Your page is loading multiple Elm scripts with a module named " + moduleName + ". Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!");
            case 8:
                var moduleName = fact1;
                var region = fact2;
                var message = fact3;
                throw new Error("TODO in module `" + moduleName + "` " + _Debug_regionToString(region) + "\n\n" + message);
            case 9:
                var moduleName = fact1;
                var region = fact2;
                var value = fact3;
                var message = fact4;
                throw new Error("TODO in module `" + moduleName + "` from the `case` expression "
                    + _Debug_regionToString(region) + "\n\nIt received the following value:\n\n    "
                    + _Debug_toString(value).replace("\n", "\n    ")
                    + "\n\nBut the branch that handles it says:\n\n    " + message.replace("\n", "\n    "));
            case 10:
                throw new Error("Bug in https://github.com/elm/virtual-dom/issues");
            case 11:
                throw new Error("Cannot perform mod 0. Division by zero error.");
        }
    }
    function _Debug_regionToString(region) {
        if (region.bo.dC === region.bI.dC) {
            return "on line " + region.bo.dC;
        }
        return "on lines " + region.bo.dC + " through " + region.bI.dC;
    }
    function _Utils_eq(x, y) {
        for (var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack); isEqual && (pair = stack.pop()); isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)) { }
        return isEqual;
    }
    function _Utils_eqHelp(x, y, depth, stack) {
        if (x === y) {
            return true;
        }
        if (typeof x !== "object" || x === null || y === null) {
            typeof x === "function" && _Debug_crash(5);
            return false;
        }
        if (depth > 100) {
            stack.push(_Utils_Tuple2(x, y));
            return true;
        }
        if (x.$ < 0) {
            x = $elm$core$Dict$toList(x);
            y = $elm$core$Dict$toList(y);
        }
        for (var key in x) {
            if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack)) {
                return false;
            }
        }
        return true;
    }
    var _Utils_equal = F2(_Utils_eq);
    var _Utils_notEqual_fn = function (a, b) { return !_Utils_eq(a, b); }, _Utils_notEqual = F2(_Utils_notEqual_fn);
    function _Utils_cmp(x, y, ord) {
        if (typeof x !== "object") {
            return x === y ? 0 : x < y ? -1 : 1;
        }
        if (typeof x.$ === "undefined") {
            return (ord = _Utils_cmp(x.a, y.a))
                ? ord
                : (ord = _Utils_cmp(x.b, y.b))
                    ? ord
                    : _Utils_cmp(x.c, y.c);
        }
        for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) { }
        return ord || (x.b ? 1 : y.b ? -1 : 0);
    }
    var _Utils_lt_fn = function (a, b) { return _Utils_cmp(a, b) < 0; }, _Utils_lt = F2(_Utils_lt_fn);
    var _Utils_le_fn = function (a, b) { return _Utils_cmp(a, b) < 1; }, _Utils_le = F2(_Utils_le_fn);
    var _Utils_gt_fn = function (a, b) { return _Utils_cmp(a, b) > 0; }, _Utils_gt = F2(_Utils_gt_fn);
    var _Utils_ge_fn = function (a, b) { return _Utils_cmp(a, b) >= 0; }, _Utils_ge = F2(_Utils_ge_fn);
    var _Utils_compare_fn = function (x, y) {
        var n = _Utils_cmp(x, y);
        return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
    }, _Utils_compare = F2(_Utils_compare_fn);
    var _Utils_Tuple0 = 0;
    var _Utils_Tuple0_UNUSED = { $: "#0" };
    function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
    function _Utils_Tuple2_UNUSED(a, b) { return { $: "#2", a: a, b: b }; }
    function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
    function _Utils_Tuple3_UNUSED(a, b, c) { return { $: "#3", a: a, b: b, c: c }; }
    function _Utils_chr(c) { return c; }
    function _Utils_chr_UNUSED(c) { return new String(c); }
    function _Utils_update(oldRecord, updatedFields) {
        var newRecord = {};
        for (var key in oldRecord) {
            newRecord[key] = oldRecord[key];
        }
        for (var key in updatedFields) {
            newRecord[key] = updatedFields[key];
        }
        return newRecord;
    }
    var _Utils_append = F2(_Utils_ap);
    function _Utils_ap(xs, ys) {
        if (typeof xs === "string") {
            return xs + ys;
        }
        if (!xs.b) {
            return ys;
        }
        var root = _List_Cons(xs.a, ys);
        xs = xs.b;
        for (var curr = root; xs.b; xs = xs.b) {
            curr = curr.b = _List_Cons(xs.a, ys);
        }
        return root;
    }
    var _List_Nil = { $: 0, a: null, b: null };
    var _List_Nil_UNUSED = { $: "[]" };
    function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
    function _List_Cons_UNUSED(hd, tl) { return { $: "::", a: hd, b: tl }; }
    var _List_cons = F2(_List_Cons);
    function _List_fromArray(arr) {
        var out = _List_Nil;
        for (var i = arr.length; i--;) {
            out = _List_Cons(arr[i], out);
        }
        return out;
    }
    function _List_toArray(xs) {
        for (var out = []; xs.b; xs = xs.b) {
            out.push(xs.a);
        }
        return out;
    }
    var _List_map2_fn = function (f, xs, ys) {
        for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) {
            arr.push(A2(f, xs.a, ys.a));
        }
        return _List_fromArray(arr);
    }, _List_map2_fn_unwrapped = function (f, xs, ys) {
        for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) {
            arr.push(f(xs.a, ys.a));
        }
        return _List_fromArray(arr);
    }, _List_map2 = F3(_List_map2_fn);
    var _List_map3_fn = function (f, xs, ys, zs) {
        for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) {
            arr.push(A3(f, xs.a, ys.a, zs.a));
        }
        return _List_fromArray(arr);
    }, _List_map3_fn_unwrapped = function (f, xs, ys, zs) {
        for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) {
            arr.push(f(xs.a, ys.a, zs.a));
        }
        return _List_fromArray(arr);
    }, _List_map3 = F4(_List_map3_fn);
    var _List_map4_fn = function (f, ws, xs, ys, zs) {
        for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) {
            arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
        }
        return _List_fromArray(arr);
    }, _List_map4_fn_unwrapped = function (f, ws, xs, ys, zs) {
        for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) {
            arr.push(f(ws.a, xs.a, ys.a, zs.a));
        }
        return _List_fromArray(arr);
    }, _List_map4 = F5(_List_map4_fn);
    var _List_map5_fn = function (f, vs, ws, xs, ys, zs) {
        for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) {
            arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
        }
        return _List_fromArray(arr);
    }, _List_map5_fn_unwrapped = function (f, vs, ws, xs, ys, zs) {
        for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) {
            arr.push(f(vs.a, ws.a, xs.a, ys.a, zs.a));
        }
        return _List_fromArray(arr);
    }, _List_map5 = F6(_List_map5_fn);
    var _List_sortBy_fn = function (f, xs) {
        return _List_fromArray(_List_toArray(xs).sort(function (a, b) {
            return _Utils_cmp(f(a), f(b));
        }));
    }, _List_sortBy = F2(_List_sortBy_fn);
    var _List_sortWith_fn = function (f, xs) {
        return _List_fromArray(_List_toArray(xs).sort(function (a, b) {
            var ord = A2(f, a, b);
            return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
        }));
    }, _List_sortWith_fn_unwrapped = function (f, xs) {
        return _List_fromArray(_List_toArray(xs).sort(function (a, b) {
            var ord = f(a, b);
            return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
        }));
    }, _List_sortWith = F2(_List_sortWith_fn);
    var _Basics_add_fn = function (a, b) { return a + b; }, _Basics_add = F2(_Basics_add_fn);
    var _Basics_sub_fn = function (a, b) { return a - b; }, _Basics_sub = F2(_Basics_sub_fn);
    var _Basics_mul_fn = function (a, b) { return a * b; }, _Basics_mul = F2(_Basics_mul_fn);
    var _Basics_fdiv_fn = function (a, b) { return a / b; }, _Basics_fdiv = F2(_Basics_fdiv_fn);
    var _Basics_idiv_fn = function (a, b) { return (a / b) | 0; }, _Basics_idiv = F2(_Basics_idiv_fn);
    var _Basics_pow_fn = Math.pow, _Basics_pow = F2(_Basics_pow_fn);
    var _Basics_remainderBy_fn = function (b, a) { return a % b; }, _Basics_remainderBy = F2(_Basics_remainderBy_fn);
    var _Basics_modBy_fn = function (modulus, x) {
        var answer = x % modulus;
        return modulus === 0
            ? _Debug_crash(11)
            :
                ((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
                    ? answer + modulus
                    : answer;
    }, _Basics_modBy = F2(_Basics_modBy_fn);
    var _Basics_pi = Math.PI;
    var _Basics_e = Math.E;
    var _Basics_cos = Math.cos;
    var _Basics_sin = Math.sin;
    var _Basics_tan = Math.tan;
    var _Basics_acos = Math.acos;
    var _Basics_asin = Math.asin;
    var _Basics_atan = Math.atan;
    var _Basics_atan2_fn = Math.atan2, _Basics_atan2 = F2(_Basics_atan2_fn);
    function _Basics_toFloat(x) { return x; }
    function _Basics_truncate(n) { return n | 0; }
    function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }
    var _Basics_ceiling = Math.ceil;
    var _Basics_floor = Math.floor;
    var _Basics_round = Math.round;
    var _Basics_sqrt = Math.sqrt;
    var _Basics_log = Math.log;
    var _Basics_isNaN = isNaN;
    function _Basics_not(bool) { return !bool; }
    var _Basics_and_fn = function (a, b) { return a && b; }, _Basics_and = F2(_Basics_and_fn);
    var _Basics_or_fn = function (a, b) { return a || b; }, _Basics_or = F2(_Basics_or_fn);
    var _Basics_xor_fn = function (a, b) { return a !== b; }, _Basics_xor = F2(_Basics_xor_fn);
    var _String_cons_fn = function (chr, str) {
        return chr + str;
    }, _String_cons = F2(_String_cons_fn);
    function _String_uncons(string) {
        var word = string.charCodeAt(0);
        return !isNaN(word)
            ? $elm$core$Maybe$Just(55296 <= word && word <= 56319
                ? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
                : _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1)))
            : $elm$core$Maybe$Nothing;
    }
    var _String_append_fn = function (a, b) {
        return a + b;
    }, _String_append = F2(_String_append_fn);
    function _String_length(str) {
        return str.length;
    }
    var _String_map_fn = function (func, string) {
        var len = string.length;
        var array = new Array(len);
        var i = 0;
        while (i < len) {
            var word = string.charCodeAt(i);
            if (55296 <= word && word <= 56319) {
                array[i] = func(_Utils_chr(string[i] + string[i + 1]));
                i += 2;
                continue;
            }
            array[i] = func(_Utils_chr(string[i]));
            i++;
        }
        return array.join("");
    }, _String_map = F2(_String_map_fn);
    var _String_filter_fn = function (isGood, str) {
        var arr = [];
        var len = str.length;
        var i = 0;
        while (i < len) {
            var char = str[i];
            var word = str.charCodeAt(i);
            i++;
            if (55296 <= word && word <= 56319) {
                char += str[i];
                i++;
            }
            if (isGood(_Utils_chr(char))) {
                arr.push(char);
            }
        }
        return arr.join("");
    }, _String_filter = F2(_String_filter_fn);
    function _String_reverse(str) {
        var len = str.length;
        var arr = new Array(len);
        var i = 0;
        while (i < len) {
            var word = str.charCodeAt(i);
            if (55296 <= word && word <= 56319) {
                arr[len - i] = str[i + 1];
                i++;
                arr[len - i] = str[i - 1];
                i++;
            }
            else {
                arr[len - i] = str[i];
                i++;
            }
        }
        return arr.join("");
    }
    var _String_foldl_fn = function (func, state, string) {
        var len = string.length;
        var i = 0;
        while (i < len) {
            var char = string[i];
            var word = string.charCodeAt(i);
            i++;
            if (55296 <= word && word <= 56319) {
                char += string[i];
                i++;
            }
            state = A2(func, _Utils_chr(char), state);
        }
        return state;
    }, _String_foldl_fn_unwrapped = function (func, state, string) {
        var len = string.length;
        var i = 0;
        while (i < len) {
            var char = string[i];
            var word = string.charCodeAt(i);
            i++;
            if (55296 <= word && word <= 56319) {
                char += string[i];
                i++;
            }
            state = func(_Utils_chr(char), state);
        }
        return state;
    }, _String_foldl = F3(_String_foldl_fn);
    var _String_foldr_fn = function (func, state, string) {
        var i = string.length;
        while (i--) {
            var char = string[i];
            var word = string.charCodeAt(i);
            if (56320 <= word && word <= 57343) {
                i--;
                char = string[i] + char;
            }
            state = A2(func, _Utils_chr(char), state);
        }
        return state;
    }, _String_foldr_fn_unwrapped = function (func, state, string) {
        var i = string.length;
        while (i--) {
            var char = string[i];
            var word = string.charCodeAt(i);
            if (56320 <= word && word <= 57343) {
                i--;
                char = string[i] + char;
            }
            state = func(_Utils_chr(char), state);
        }
        return state;
    }, _String_foldr = F3(_String_foldr_fn);
    var _String_split_fn = function (sep, str) {
        return str.split(sep);
    }, _String_split = F2(_String_split_fn);
    var _String_join_fn = function (sep, strs) {
        return strs.join(sep);
    }, _String_join = F2(_String_join_fn);
    var _String_slice_fn = function (start, end, str) {
        return str.slice(start, end);
    }, _String_slice = F3(_String_slice_fn);
    function _String_trim(str) {
        return str.trim();
    }
    function _String_trimLeft(str) {
        return str.replace(/^\s+/, "");
    }
    function _String_trimRight(str) {
        return str.replace(/\s+$/, "");
    }
    function _String_words(str) {
        return _List_fromArray(str.trim().split(/\s+/g));
    }
    function _String_lines(str) {
        return _List_fromArray(str.split(/\r\n|\r|\n/g));
    }
    function _String_toUpper(str) {
        return str.toUpperCase();
    }
    function _String_toLower(str) {
        return str.toLowerCase();
    }
    var _String_any_fn = function (isGood, string) {
        var i = string.length;
        while (i--) {
            var char = string[i];
            var word = string.charCodeAt(i);
            if (56320 <= word && word <= 57343) {
                i--;
                char = string[i] + char;
            }
            if (isGood(_Utils_chr(char))) {
                return true;
            }
        }
        return false;
    }, _String_any = F2(_String_any_fn);
    var _String_all_fn = function (isGood, string) {
        var i = string.length;
        while (i--) {
            var char = string[i];
            var word = string.charCodeAt(i);
            if (56320 <= word && word <= 57343) {
                i--;
                char = string[i] + char;
            }
            if (!isGood(_Utils_chr(char))) {
                return false;
            }
        }
        return true;
    }, _String_all = F2(_String_all_fn);
    var _String_contains_fn = function (sub, str) {
        return str.indexOf(sub) > -1;
    }, _String_contains = F2(_String_contains_fn);
    var _String_startsWith_fn = function (sub, str) {
        return str.indexOf(sub) === 0;
    }, _String_startsWith = F2(_String_startsWith_fn);
    var _String_endsWith_fn = function (sub, str) {
        return str.length >= sub.length &&
            str.lastIndexOf(sub) === str.length - sub.length;
    }, _String_endsWith = F2(_String_endsWith_fn);
    var _String_indexes_fn = function (sub, str) {
        var subLen = sub.length;
        if (subLen < 1) {
            return _List_Nil;
        }
        var i = 0;
        var is = [];
        while ((i = str.indexOf(sub, i)) > -1) {
            is.push(i);
            i = i + subLen;
        }
        return _List_fromArray(is);
    }, _String_indexes = F2(_String_indexes_fn);
    function _String_fromNumber(number) {
        return number + "";
    }
    function _String_toInt(str) {
        var total = 0;
        var code0 = str.charCodeAt(0);
        var start = code0 == 43 || code0 == 45 ? 1 : 0;
        for (var i = start; i < str.length; ++i) {
            var code = str.charCodeAt(i);
            if (code < 48 || 57 < code) {
                return $elm$core$Maybe$Nothing;
            }
            total = 10 * total + code - 48;
        }
        return i == start
            ? $elm$core$Maybe$Nothing
            : $elm$core$Maybe$Just(code0 == 45 ? -total : total);
    }
    function _String_toFloat(s) {
        if (s.length === 0 || /[\sxbo]/.test(s)) {
            return $elm$core$Maybe$Nothing;
        }
        var n = +s;
        return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
    }
    function _String_fromList(chars) {
        return _List_toArray(chars).join("");
    }
    function _Char_toCode(char) {
        var code = char.charCodeAt(0);
        if (55296 <= code && code <= 56319) {
            return (code - 55296) * 1024 + char.charCodeAt(1) - 56320 + 65536;
        }
        return code;
    }
    function _Char_fromCode(code) {
        return _Utils_chr((code < 0 || 1114111 < code)
            ? "\uFFFD"
            :
                (code <= 65535)
                    ? String.fromCharCode(code)
                    :
                        (code -= 65536,
                            String.fromCharCode(Math.floor(code / 1024) + 55296, code % 1024 + 56320)));
    }
    function _Char_toUpper(char) {
        return _Utils_chr(char.toUpperCase());
    }
    function _Char_toLower(char) {
        return _Utils_chr(char.toLowerCase());
    }
    function _Char_toLocaleUpper(char) {
        return _Utils_chr(char.toLocaleUpperCase());
    }
    function _Char_toLocaleLower(char) {
        return _Utils_chr(char.toLocaleLowerCase());
    }
    function _Json_succeed(msg) {
        return {
            $: 0,
            a: msg
        };
    }
    function _Json_fail(msg) {
        return {
            $: 1,
            a: msg
        };
    }
    function _Json_decodePrim(decoder) {
        return { $: 2, b: decoder };
    }
    var _Json_decodeInt = _Json_decodePrim(function (value) {
        return (typeof value !== "number")
            ? _Json_expecting("an INT", value)
            :
                (-2147483647 < value && value < 2147483647 && (value | 0) === value)
                    ? $elm$core$Result$Ok(value)
                    :
                        (isFinite(value) && !(value % 1))
                            ? $elm$core$Result$Ok(value)
                            : _Json_expecting("an INT", value);
    });
    var _Json_decodeBool = _Json_decodePrim(function (value) {
        return (typeof value === "boolean")
            ? $elm$core$Result$Ok(value)
            : _Json_expecting("a BOOL", value);
    });
    var _Json_decodeFloat = _Json_decodePrim(function (value) {
        return (typeof value === "number")
            ? $elm$core$Result$Ok(value)
            : _Json_expecting("a FLOAT", value);
    });
    var _Json_decodeValue = _Json_decodePrim(function (value) {
        return $elm$core$Result$Ok(_Json_wrap(value));
    });
    var _Json_decodeString = _Json_decodePrim(function (value) {
        return (typeof value === "string")
            ? $elm$core$Result$Ok(value)
            : (value instanceof String)
                ? $elm$core$Result$Ok(value + "")
                : _Json_expecting("a STRING", value);
    });
    function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
    function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }
    function _Json_decodeNull(value) { return { $: 5, c: value }; }
    var _Json_decodeField_fn = function (field, decoder) {
        return {
            $: 6,
            d: field,
            b: decoder
        };
    }, _Json_decodeField = F2(_Json_decodeField_fn);
    var _Json_decodeIndex_fn = function (index, decoder) {
        return {
            $: 7,
            e: index,
            b: decoder
        };
    }, _Json_decodeIndex = F2(_Json_decodeIndex_fn);
    function _Json_decodeKeyValuePairs(decoder) {
        return {
            $: 8,
            b: decoder
        };
    }
    function _Json_mapMany(f, decoders) {
        return {
            $: 9,
            f: f,
            g: decoders
        };
    }
    var _Json_andThen_fn = function (callback, decoder) {
        return {
            $: 10,
            b: decoder,
            h: callback
        };
    }, _Json_andThen = F2(_Json_andThen_fn);
    function _Json_oneOf(decoders) {
        return {
            $: 11,
            g: decoders
        };
    }
    var _Json_map1_fn = function (f, d1) {
        return _Json_mapMany(f, [d1]);
    }, _Json_map1 = F2(_Json_map1_fn);
    var _Json_map2_fn = function (f, d1, d2) {
        return _Json_mapMany(f, [d1, d2]);
    }, _Json_map2 = F3(_Json_map2_fn);
    var _Json_map3_fn = function (f, d1, d2, d3) {
        return _Json_mapMany(f, [d1, d2, d3]);
    }, _Json_map3 = F4(_Json_map3_fn);
    var _Json_map4_fn = function (f, d1, d2, d3, d4) {
        return _Json_mapMany(f, [d1, d2, d3, d4]);
    }, _Json_map4 = F5(_Json_map4_fn);
    var _Json_map5_fn = function (f, d1, d2, d3, d4, d5) {
        return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
    }, _Json_map5 = F6(_Json_map5_fn);
    var _Json_map6_fn = function (f, d1, d2, d3, d4, d5, d6) {
        return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
    }, _Json_map6 = F7(_Json_map6_fn);
    var _Json_map7_fn = function (f, d1, d2, d3, d4, d5, d6, d7) {
        return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
    }, _Json_map7 = F8(_Json_map7_fn);
    var _Json_map8_fn = function (f, d1, d2, d3, d4, d5, d6, d7, d8) {
        return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
    }, _Json_map8 = F9(_Json_map8_fn);
    var _Json_runOnString_fn = function (decoder, string) {
        try {
            var value = JSON.parse(string);
            return _Json_runHelp(decoder, value);
        }
        catch (e) {
            return $elm$core$Result$Err($elm$json$Json$Decode$Failure_fn("This is not valid JSON! " + e.message, _Json_wrap(string)));
        }
    }, _Json_runOnString = F2(_Json_runOnString_fn);
    var _Json_run_fn = function (decoder, value) {
        return _Json_runHelp(decoder, _Json_unwrap(value));
    }, _Json_run = F2(_Json_run_fn);
    function _Json_runHelp(decoder, value) {
        switch (decoder.$) {
            case 2:
                return decoder.b(value);
            case 5:
                return (value === null)
                    ? $elm$core$Result$Ok(decoder.c)
                    : _Json_expecting("null", value);
            case 3:
                if (!_Json_isArray(value)) {
                    return _Json_expecting("a LIST", value);
                }
                return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);
            case 4:
                if (!_Json_isArray(value)) {
                    return _Json_expecting("an ARRAY", value);
                }
                return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);
            case 6:
                var field = decoder.d;
                if (typeof value !== "object" || value === null || !(field in value)) {
                    return _Json_expecting("an OBJECT with a field named `" + field + "`", value);
                }
                var result = _Json_runHelp(decoder.b, value[field]);
                return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err($elm$json$Json$Decode$Field_fn(field, result.a));
            case 7:
                var index = decoder.e;
                if (!_Json_isArray(value)) {
                    return _Json_expecting("an ARRAY", value);
                }
                if (index >= value.length) {
                    return _Json_expecting("a LONGER array. Need index " + index + " but only see " + value.length + " entries", value);
                }
                var result = _Json_runHelp(decoder.b, value[index]);
                return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err($elm$json$Json$Decode$Index_fn(index, result.a));
            case 8:
                if (typeof value !== "object" || value === null || _Json_isArray(value)) {
                    return _Json_expecting("an OBJECT", value);
                }
                var keyValuePairs = _List_Nil;
                for (var key in value) {
                    if (value.hasOwnProperty(key)) {
                        var result = _Json_runHelp(decoder.b, value[key]);
                        if (!$elm$core$Result$isOk(result)) {
                            return $elm$core$Result$Err($elm$json$Json$Decode$Field_fn(key, result.a));
                        }
                        keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
                    }
                }
                return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));
            case 9:
                var answer = decoder.f;
                var decoders = decoder.g;
                for (var i = 0; i < decoders.length; i++) {
                    var result = _Json_runHelp(decoders[i], value);
                    if (!$elm$core$Result$isOk(result)) {
                        return result;
                    }
                    answer = answer(result.a);
                }
                return $elm$core$Result$Ok(answer);
            case 10:
                var result = _Json_runHelp(decoder.b, value);
                return (!$elm$core$Result$isOk(result))
                    ? result
                    : _Json_runHelp(decoder.h(result.a), value);
            case 11:
                var errors = _List_Nil;
                for (var temp = decoder.g; temp.b; temp = temp.b) {
                    var result = _Json_runHelp(temp.a, value);
                    if ($elm$core$Result$isOk(result)) {
                        return result;
                    }
                    errors = _List_Cons(result.a, errors);
                }
                return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));
            case 1:
                return $elm$core$Result$Err($elm$json$Json$Decode$Failure_fn(decoder.a, _Json_wrap(value)));
            case 0:
                return $elm$core$Result$Ok(decoder.a);
        }
    }
    function _Json_runArrayDecoder(decoder, value, toElmValue) {
        var len = value.length;
        var array = new Array(len);
        for (var i = 0; i < len; i++) {
            var result = _Json_runHelp(decoder, value[i]);
            if (!$elm$core$Result$isOk(result)) {
                return $elm$core$Result$Err($elm$json$Json$Decode$Index_fn(i, result.a));
            }
            array[i] = result.a;
        }
        return $elm$core$Result$Ok(toElmValue(array));
    }
    function _Json_isArray(value) {
        return Array.isArray(value) || (typeof FileList !== "undefined" && value instanceof FileList);
    }
    function _Json_toElmArray(array) {
        return $elm$core$Array$initialize_fn(array.length, function (i) { return array[i]; });
    }
    function _Json_expecting(type, value) {
        return $elm$core$Result$Err($elm$json$Json$Decode$Failure_fn("Expecting " + type, _Json_wrap(value)));
    }
    function _Json_equality(x, y) {
        if (x === y) {
            return true;
        }
        if (x.$ !== y.$) {
            return false;
        }
        switch (x.$) {
            case 0:
            case 1:
                return x.a === y.a;
            case 2:
                return x.b === y.b;
            case 5:
                return x.c === y.c;
            case 3:
            case 4:
            case 8:
                return _Json_equality(x.b, y.b);
            case 6:
                return x.d === y.d && _Json_equality(x.b, y.b);
            case 7:
                return x.e === y.e && _Json_equality(x.b, y.b);
            case 9:
                return x.f === y.f && _Json_listEquality(x.g, y.g);
            case 10:
                return x.h === y.h && _Json_equality(x.b, y.b);
            case 11:
                return _Json_listEquality(x.g, y.g);
        }
    }
    function _Json_listEquality(aDecoders, bDecoders) {
        var len = aDecoders.length;
        if (len !== bDecoders.length) {
            return false;
        }
        for (var i = 0; i < len; i++) {
            if (!_Json_equality(aDecoders[i], bDecoders[i])) {
                return false;
            }
        }
        return true;
    }
    var _Json_encode_fn = function (indentLevel, value) {
        return JSON.stringify(_Json_unwrap(value), null, indentLevel) + "";
    }, _Json_encode = F2(_Json_encode_fn);
    function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
    function _Json_unwrap_UNUSED(value) { return value.a; }
    function _Json_wrap(value) { return value; }
    function _Json_unwrap(value) { return value; }
    function _Json_emptyArray() { return []; }
    function _Json_emptyObject() { return {}; }
    var _Json_addField_fn = function (key, value, object) {
        object[key] = _Json_unwrap(value);
        return object;
    }, _Json_addField = F3(_Json_addField_fn);
    function _Json_addEntry(func) {
        return F2(function (entry, array) {
            array.push(_Json_unwrap(func(entry)));
            return array;
        });
    }
    var _Json_encodeNull = _Json_wrap(null);
    function _Scheduler_succeed(value) {
        return {
            $: 0,
            a: value
        };
    }
    function _Scheduler_fail(error) {
        return {
            $: 1,
            a: error
        };
    }
    function _Scheduler_binding(callback) {
        return {
            $: 2,
            b: callback,
            c: null
        };
    }
    var _Scheduler_andThen_fn = function (callback, task) {
        return {
            $: 3,
            b: callback,
            d: task
        };
    }, _Scheduler_andThen = F2(_Scheduler_andThen_fn);
    var _Scheduler_onError_fn = function (callback, task) {
        return {
            $: 4,
            b: callback,
            d: task
        };
    }, _Scheduler_onError = F2(_Scheduler_onError_fn);
    function _Scheduler_receive(callback) {
        return {
            $: 5,
            b: callback
        };
    }
    var _Scheduler_guid = 0;
    function _Scheduler_rawSpawn(task) {
        var proc = {
            $: 0,
            e: _Scheduler_guid++,
            f: task,
            g: null,
            h: []
        };
        _Scheduler_enqueue(proc);
        return proc;
    }
    function _Scheduler_spawn(task) {
        return _Scheduler_binding(function (callback) {
            callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
        });
    }
    function _Scheduler_rawSend(proc, msg) {
        proc.h.push(msg);
        _Scheduler_enqueue(proc);
    }
    var _Scheduler_send_fn = function (proc, msg) {
        return _Scheduler_binding(function (callback) {
            _Scheduler_rawSend(proc, msg);
            callback(_Scheduler_succeed(_Utils_Tuple0));
        });
    }, _Scheduler_send = F2(_Scheduler_send_fn);
    function _Scheduler_kill(proc) {
        return _Scheduler_binding(function (callback) {
            var task = proc.f;
            if (task.$ === 2 && task.c) {
                task.c();
            }
            proc.f = null;
            callback(_Scheduler_succeed(_Utils_Tuple0));
        });
    }
    var _Scheduler_working = false;
    var _Scheduler_queue = [];
    function _Scheduler_enqueue(proc) {
        _Scheduler_queue.push(proc);
        if (_Scheduler_working) {
            return;
        }
        _Scheduler_working = true;
        while (proc = _Scheduler_queue.shift()) {
            _Scheduler_step(proc);
        }
        _Scheduler_working = false;
    }
    function _Scheduler_step(proc) {
        while (proc.f) {
            var rootTag = proc.f.$;
            if (rootTag === 0 || rootTag === 1) {
                while (proc.g && proc.g.$ !== rootTag) {
                    proc.g = proc.g.i;
                }
                if (!proc.g) {
                    return;
                }
                proc.f = proc.g.b(proc.f.a);
                proc.g = proc.g.i;
            }
            else if (rootTag === 2) {
                proc.f.c = proc.f.b(function (newRoot) {
                    proc.f = newRoot;
                    _Scheduler_enqueue(proc);
                });
                return;
            }
            else if (rootTag === 5) {
                if (proc.h.length === 0) {
                    return;
                }
                proc.f = proc.f.b(proc.h.shift());
            }
            else {
                proc.g = {
                    $: rootTag === 3 ? 0 : 1,
                    b: proc.f.b,
                    i: proc.g
                };
                proc.f = proc.f.d;
            }
        }
    }
    function _Process_sleep(time) {
        return _Scheduler_binding(function (callback) {
            var id = setTimeout(function () {
                callback(_Scheduler_succeed(_Utils_Tuple0));
            }, time);
            return function () { clearTimeout(id); };
        });
    }
    var _Platform_worker_fn = function (impl, flagDecoder, debugMetadata, args) {
        return _Platform_initialize(flagDecoder, args, impl.du, impl.ey, impl.eo, function () { return function () { }; });
    }, _Platform_worker = F4(_Platform_worker_fn);
    function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder) {
        var result = _Json_run_fn(flagDecoder, _Json_wrap(args ? args["flags"] : undefined));
        $elm$core$Result$isOk(result) || _Debug_crash(2);
        var managers = {};
        var initPair = init(result.a);
        var model = initPair.a;
        var stepper = stepperBuilder(sendToApp, model);
        var ports = _Platform_setupEffects(managers, sendToApp);
        function sendToApp(msg, viewMetadata) {
            var pair = A2(update, msg, model);
            stepper(model = pair.a, viewMetadata);
            _Platform_enqueueEffects(managers, pair.b, subscriptions(model));
        }
        _Platform_enqueueEffects(managers, initPair.b, subscriptions(model));
        return ports ? { ports: ports } : {};
    }
    var _Platform_preload;
    function _Platform_registerPreload(url) {
        _Platform_preload.add(url);
    }
    var _Platform_effectManagers = {};
    function _Platform_setupEffects(managers, sendToApp) {
        var ports;
        for (var key in _Platform_effectManagers) {
            var manager = _Platform_effectManagers[key];
            if (manager.a) {
                ports = ports || {};
                ports[key] = manager.a(key, sendToApp);
            }
            managers[key] = _Platform_instantiateManager(manager, sendToApp);
        }
        return ports;
    }
    function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap) {
        return {
            b: init,
            c: onEffects,
            d: onSelfMsg,
            e: cmdMap,
            f: subMap
        };
    }
    function _Platform_instantiateManager(info, sendToApp) {
        var router = {
            g: sendToApp,
            h: undefined
        };
        var onEffects = info.c;
        var onSelfMsg = info.d;
        var cmdMap = info.e;
        var subMap = info.f;
        function loop(state) {
            return _Scheduler_andThen_fn(loop, _Scheduler_receive(function (msg) {
                var value = msg.a;
                if (msg.$ === 0) {
                    return A3(onSelfMsg, router, value, state);
                }
                return cmdMap && subMap
                    ? A4(onEffects, router, value.i, value.j, state)
                    : A3(onEffects, router, cmdMap ? value.i : value.j, state);
            }));
        }
        return router.h = _Scheduler_rawSpawn(_Scheduler_andThen_fn(loop, info.b));
    }
    var _Platform_sendToApp_fn = function (router, msg) {
        return _Scheduler_binding(function (callback) {
            router.g(msg);
            callback(_Scheduler_succeed(_Utils_Tuple0));
        });
    }, _Platform_sendToApp = F2(_Platform_sendToApp_fn);
    var _Platform_sendToSelf_fn = function (router, msg) {
        return _Scheduler_send_fn(router.h, {
            $: 0,
            a: msg
        });
    }, _Platform_sendToSelf = F2(_Platform_sendToSelf_fn);
    function _Platform_leaf(home) {
        return function (value) {
            return {
                $: 1,
                k: home,
                l: value
            };
        };
    }
    function _Platform_batch(list) {
        return {
            $: 2,
            m: list
        };
    }
    var _Platform_map_fn = function (tagger, bag) {
        return {
            $: 3,
            n: tagger,
            o: bag
        };
    }, _Platform_map = F2(_Platform_map_fn);
    var _Platform_effectsQueue = [];
    var _Platform_effectsActive = false;
    function _Platform_enqueueEffects(managers, cmdBag, subBag) {
        _Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });
        if (_Platform_effectsActive)
            return;
        _Platform_effectsActive = true;
        for (var fx; fx = _Platform_effectsQueue.shift();) {
            _Platform_dispatchEffects(fx.p, fx.q, fx.r);
        }
        _Platform_effectsActive = false;
    }
    function _Platform_dispatchEffects(managers, cmdBag, subBag) {
        var effectsDict = {};
        _Platform_gatherEffects(true, cmdBag, effectsDict, null);
        _Platform_gatherEffects(false, subBag, effectsDict, null);
        for (var home in managers) {
            _Scheduler_rawSend(managers[home], {
                $: "fx",
                a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
            });
        }
    }
    function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers) {
        switch (bag.$) {
            case 1:
                var home = bag.k;
                var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
                effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
                return;
            case 2:
                for (var list = bag.m; list.b; list = list.b) {
                    _Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
                }
                return;
            case 3:
                _Platform_gatherEffects(isCmd, bag.o, effectsDict, {
                    s: bag.n,
                    t: taggers
                });
                return;
        }
    }
    function _Platform_toEffect(isCmd, home, taggers, value) {
        function applyTaggers(x) {
            for (var temp = taggers; temp; temp = temp.t) {
                x = temp.s(x);
            }
            return x;
        }
        var map = isCmd
            ? _Platform_effectManagers[home].e
            : _Platform_effectManagers[home].f;
        return A2(map, applyTaggers, value);
    }
    function _Platform_insert(isCmd, newEffect, effects) {
        effects = effects || { i: _List_Nil, j: _List_Nil };
        isCmd
            ? (effects.i = _List_Cons(newEffect, effects.i))
            : (effects.j = _List_Cons(newEffect, effects.j));
        return effects;
    }
    function _Platform_checkPortName(name) {
        if (_Platform_effectManagers[name]) {
            _Debug_crash(3, name);
        }
    }
    function _Platform_outgoingPort(name, converter) {
        _Platform_checkPortName(name);
        _Platform_effectManagers[name] = {
            e: _Platform_outgoingPortMap,
            u: converter,
            a: _Platform_setupOutgoingPort
        };
        return _Platform_leaf(name);
    }
    var _Platform_outgoingPortMap_fn = function (tagger, value) { return value; }, _Platform_outgoingPortMap = F2(_Platform_outgoingPortMap_fn);
    function _Platform_setupOutgoingPort(name) {
        var subs = [];
        var converter = _Platform_effectManagers[name].u;
        var init = _Process_sleep(0);
        _Platform_effectManagers[name].b = init;
        _Platform_effectManagers[name].c = F3(function (router, cmdList, state) {
            for (; cmdList.b; cmdList = cmdList.b) {
                var currentSubs = subs;
                var value = _Json_unwrap(converter(cmdList.a));
                for (var i = 0; i < currentSubs.length; i++) {
                    currentSubs[i](value);
                }
            }
            return init;
        });
        function subscribe(callback) {
            subs.push(callback);
        }
        function unsubscribe(callback) {
            subs = subs.slice();
            var index = subs.indexOf(callback);
            if (index >= 0) {
                subs.splice(index, 1);
            }
        }
        return {
            subscribe: subscribe,
            unsubscribe: unsubscribe
        };
    }
    function _Platform_incomingPort(name, converter) {
        _Platform_checkPortName(name);
        _Platform_effectManagers[name] = {
            f: _Platform_incomingPortMap,
            u: converter,
            a: _Platform_setupIncomingPort
        };
        return _Platform_leaf(name);
    }
    var _Platform_incomingPortMap_fn = function (tagger, finalTagger) {
        return function (value) {
            return tagger(finalTagger(value));
        };
    }, _Platform_incomingPortMap = F2(_Platform_incomingPortMap_fn);
    function _Platform_setupIncomingPort(name, sendToApp) {
        var subs = _List_Nil;
        var converter = _Platform_effectManagers[name].u;
        var init = _Scheduler_succeed(null);
        _Platform_effectManagers[name].b = init;
        _Platform_effectManagers[name].c = F3(function (router, subList, state) {
            subs = subList;
            return init;
        });
        function send(incomingValue) {
            var result = _Json_run_fn(converter, _Json_wrap(incomingValue));
            $elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);
            var value = result.a;
            for (var temp = subs; temp.b; temp = temp.b) {
                sendToApp(temp.a(value));
            }
        }
        return { send: send };
    }
    function _Platform_export(exports) {
        scope["Elm"]
            ? _Platform_mergeExportsProd(scope["Elm"], exports)
            : scope["Elm"] = exports;
    }
    function _Platform_mergeExportsProd(obj, exports) {
        for (var name in exports) {
            (name in obj)
                ? (name == "init")
                    ? _Debug_crash(6)
                    : _Platform_mergeExportsProd(obj[name], exports[name])
                : (obj[name] = exports[name]);
        }
    }
    function _Platform_export_UNUSED(exports) {
        scope["Elm"]
            ? _Platform_mergeExportsDebug("Elm", scope["Elm"], exports)
            : scope["Elm"] = exports;
    }
    function _Platform_mergeExportsDebug(moduleName, obj, exports) {
        for (var name in exports) {
            (name in obj)
                ? (name == "init")
                    ? _Debug_crash(6, moduleName)
                    : _Platform_mergeExportsDebug(moduleName + "." + name, obj[name], exports[name])
                : (obj[name] = exports[name]);
        }
    }
    var _VirtualDom_divertHrefToApp;
    var _VirtualDom_doc = typeof document !== "undefined" ? document : {};
    function _VirtualDom_appendChild(parent, child) {
        parent.appendChild(child);
    }
    var _VirtualDom_init_fn = function (virtualNode, flagDecoder, debugMetadata, args) {
        var node = args["node"];
        node.parentNode.replaceChild(_VirtualDom_render(virtualNode, function () { }), node);
        return {};
    }, _VirtualDom_init = F4(_VirtualDom_init_fn);
    function _VirtualDom_text(string) {
        return {
            $: 0,
            a: string
        };
    }
    var _VirtualDom_nodeNS_fn = function (namespace, tag) {
        return F2(function (factList, kidList) {
            for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) {
                var kid = kidList.a;
                descendantsCount += (kid.b || 0);
                kids.push(kid);
            }
            descendantsCount += kids.length;
            return {
                $: 1,
                c: tag,
                d: _VirtualDom_organizeFacts(factList),
                e: kids,
                f: namespace,
                b: descendantsCount
            };
        });
    }, _VirtualDom_nodeNS = F2(_VirtualDom_nodeNS_fn);
    var _VirtualDom_node_a0 = undefined, _VirtualDom_node = _VirtualDom_nodeNS(_VirtualDom_node_a0);
    var _VirtualDom_keyedNodeNS_fn = function (namespace, tag) {
        return F2(function (factList, kidList) {
            for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) {
                var kid = kidList.a;
                descendantsCount += (kid.b.b || 0);
                kids.push(kid);
            }
            descendantsCount += kids.length;
            return {
                $: 2,
                c: tag,
                d: _VirtualDom_organizeFacts(factList),
                e: kids,
                f: namespace,
                b: descendantsCount
            };
        });
    }, _VirtualDom_keyedNodeNS = F2(_VirtualDom_keyedNodeNS_fn);
    var _VirtualDom_keyedNode_a0 = undefined, _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(_VirtualDom_keyedNode_a0);
    function _VirtualDom_custom(factList, model, render, diff) {
        return {
            $: 3,
            d: _VirtualDom_organizeFacts(factList),
            g: model,
            h: render,
            i: diff
        };
    }
    var _VirtualDom_map_fn = function (tagger, node) {
        return {
            $: 4,
            j: tagger,
            k: node,
            b: 1 + (node.b || 0)
        };
    }, _VirtualDom_map = F2(_VirtualDom_map_fn);
    function _VirtualDom_thunk(refs, thunk) {
        return {
            $: 5,
            l: refs,
            m: thunk,
            k: undefined
        };
    }
    var _VirtualDom_lazy_fn = function (func, a) {
        return _VirtualDom_thunk([func, a], function () {
            return func(a);
        });
    }, _VirtualDom_lazy = F2(_VirtualDom_lazy_fn);
    var _VirtualDom_lazy2_fn = function (func, a, b) {
        return _VirtualDom_thunk([func, a, b], function () {
            return A2(func, a, b);
        });
    }, _VirtualDom_lazy2_fn_unwrapped = function (func, a, b) {
        return _VirtualDom_thunk([func, a, b], function () {
            return func(a, b);
        });
    }, _VirtualDom_lazy2 = F3(_VirtualDom_lazy2_fn);
    var _VirtualDom_lazy3_fn = function (func, a, b, c) {
        return _VirtualDom_thunk([func, a, b, c], function () {
            return A3(func, a, b, c);
        });
    }, _VirtualDom_lazy3_fn_unwrapped = function (func, a, b, c) {
        return _VirtualDom_thunk([func, a, b, c], function () {
            return func(a, b, c);
        });
    }, _VirtualDom_lazy3 = F4(_VirtualDom_lazy3_fn);
    var _VirtualDom_lazy4_fn = function (func, a, b, c, d) {
        return _VirtualDom_thunk([func, a, b, c, d], function () {
            return A4(func, a, b, c, d);
        });
    }, _VirtualDom_lazy4_fn_unwrapped = function (func, a, b, c, d) {
        return _VirtualDom_thunk([func, a, b, c, d], function () {
            return func(a, b, c, d);
        });
    }, _VirtualDom_lazy4 = F5(_VirtualDom_lazy4_fn);
    var _VirtualDom_lazy5_fn = function (func, a, b, c, d, e) {
        return _VirtualDom_thunk([func, a, b, c, d, e], function () {
            return A5(func, a, b, c, d, e);
        });
    }, _VirtualDom_lazy5_fn_unwrapped = function (func, a, b, c, d, e) {
        return _VirtualDom_thunk([func, a, b, c, d, e], function () {
            return func(a, b, c, d, e);
        });
    }, _VirtualDom_lazy5 = F6(_VirtualDom_lazy5_fn);
    var _VirtualDom_lazy6_fn = function (func, a, b, c, d, e, f) {
        return _VirtualDom_thunk([func, a, b, c, d, e, f], function () {
            return A6(func, a, b, c, d, e, f);
        });
    }, _VirtualDom_lazy6_fn_unwrapped = function (func, a, b, c, d, e, f) {
        return _VirtualDom_thunk([func, a, b, c, d, e, f], function () {
            return func(a, b, c, d, e, f);
        });
    }, _VirtualDom_lazy6 = F7(_VirtualDom_lazy6_fn);
    var _VirtualDom_lazy7_fn = function (func, a, b, c, d, e, f, g) {
        return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function () {
            return A7(func, a, b, c, d, e, f, g);
        });
    }, _VirtualDom_lazy7_fn_unwrapped = function (func, a, b, c, d, e, f, g) {
        return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function () {
            return func(a, b, c, d, e, f, g);
        });
    }, _VirtualDom_lazy7 = F8(_VirtualDom_lazy7_fn);
    var _VirtualDom_lazy8_fn = function (func, a, b, c, d, e, f, g, h) {
        return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function () {
            return A8(func, a, b, c, d, e, f, g, h);
        });
    }, _VirtualDom_lazy8_fn_unwrapped = function (func, a, b, c, d, e, f, g, h) {
        return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function () {
            return func(a, b, c, d, e, f, g, h);
        });
    }, _VirtualDom_lazy8 = F9(_VirtualDom_lazy8_fn);
    var _VirtualDom_on_fn = function (key, handler) {
        return {
            $: "a0",
            n: key,
            o: handler
        };
    }, _VirtualDom_on = F2(_VirtualDom_on_fn);
    var _VirtualDom_style_fn = function (key, value) {
        return {
            $: "a1",
            n: key,
            o: value
        };
    }, _VirtualDom_style = F2(_VirtualDom_style_fn);
    var _VirtualDom_property_fn = function (key, value) {
        return {
            $: "a2",
            n: key,
            o: value
        };
    }, _VirtualDom_property = F2(_VirtualDom_property_fn);
    var _VirtualDom_attribute_fn = function (key, value) {
        return {
            $: "a3",
            n: key,
            o: value
        };
    }, _VirtualDom_attribute = F2(_VirtualDom_attribute_fn);
    var _VirtualDom_attributeNS_fn = function (namespace, key, value) {
        return {
            $: "a4",
            n: key,
            o: { f: namespace, o: value }
        };
    }, _VirtualDom_attributeNS = F3(_VirtualDom_attributeNS_fn);
    var _VirtualDom_RE_script = /^script$/i;
    var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
    var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
    var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;
    function _VirtualDom_noScript(tag) {
        return _VirtualDom_RE_script.test(tag) ? "p" : tag;
    }
    function _VirtualDom_noOnOrFormAction(key) {
        return _VirtualDom_RE_on_formAction.test(key) ? "data-" + key : key;
    }
    function _VirtualDom_noInnerHtmlOrFormAction(key) {
        return key == "innerHTML" || key == "formAction" ? "data-" + key : key;
    }
    function _VirtualDom_noJavaScriptUri(value) {
        return _VirtualDom_RE_js.test(value)
            ? ""
            : value;
    }
    function _VirtualDom_noJavaScriptOrHtmlUri(value) {
        return _VirtualDom_RE_js_html.test(value)
            ? ""
            : value;
    }
    function _VirtualDom_noJavaScriptOrHtmlJson(value) {
        return (typeof _Json_unwrap(value) === "string" && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
            ? _Json_wrap("") : value;
    }
    var _VirtualDom_mapAttribute_fn = function (func, attr) {
        return (attr.$ === "a0")
            ? _VirtualDom_on_fn(attr.n, _VirtualDom_mapHandler(func, attr.o)) : attr;
    }, _VirtualDom_mapAttribute = F2(_VirtualDom_mapAttribute_fn);
    function _VirtualDom_mapHandler(func, handler) {
        var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);
        return {
            $: handler.$,
            a: !tag
                ? _Json_map1_fn(func, handler.a) : _Json_map2_fn(tag < 3
                ? _VirtualDom_mapEventTuple
                : _VirtualDom_mapEventRecord, $elm$json$Json$Decode$succeed(func), handler.a)
        };
    }
    var _VirtualDom_mapEventTuple_fn = function (func, tuple) {
        return _Utils_Tuple2(func(tuple.a), tuple.b);
    }, _VirtualDom_mapEventTuple = F2(_VirtualDom_mapEventTuple_fn);
    var _VirtualDom_mapEventRecord_fn = function (func, record) {
        return {
            dN: func(record.dN),
            bp: record.bp,
            bk: record.bk
        };
    }, _VirtualDom_mapEventRecord = F2(_VirtualDom_mapEventRecord_fn);
    function _VirtualDom_organizeFacts(factList) {
        for (var facts = {}; factList.b; factList = factList.b) {
            var entry = factList.a;
            var tag = entry.$;
            var key = entry.n;
            var value = entry.o;
            if (tag === "a2") {
                (key === "className")
                    ? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
                    : facts[key] = _Json_unwrap(value);
                continue;
            }
            var subFacts = facts[tag] || (facts[tag] = {});
            (tag === "a3" && key === "class")
                ? _VirtualDom_addClass(subFacts, key, value)
                : subFacts[key] = value;
        }
        return facts;
    }
    function _VirtualDom_addClass(object, key, newClass) {
        var classes = object[key];
        object[key] = classes ? classes + " " + newClass : newClass;
    }
    function _VirtualDom_render(vNode, eventNode) {
        var tag = vNode.$;
        if (tag === 5) {
            return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
        }
        if (tag === 0) {
            return _VirtualDom_doc.createTextNode(vNode.a);
        }
        if (tag === 4) {
            var subNode = vNode.k;
            var tagger = vNode.j;
            while (subNode.$ === 4) {
                typeof tagger !== "object"
                    ? tagger = [tagger, subNode.j]
                    : tagger.push(subNode.j);
                subNode = subNode.k;
            }
            var subEventRoot = { j: tagger, p: eventNode };
            var domNode = _VirtualDom_render(subNode, subEventRoot);
            domNode.elm_event_node_ref = subEventRoot;
            return domNode;
        }
        if (tag === 3) {
            var domNode = vNode.h(vNode.g);
            _VirtualDom_applyFacts(domNode, eventNode, vNode.d);
            return domNode;
        }
        var domNode = vNode.f
            ? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
            : _VirtualDom_doc.createElement(vNode.c);
        if (_VirtualDom_divertHrefToApp && vNode.c == "a") {
            domNode.addEventListener("click", _VirtualDom_divertHrefToApp(domNode));
        }
        _VirtualDom_applyFacts(domNode, eventNode, vNode.d);
        for (var kids = vNode.e, i = 0; i < kids.length; i++) {
            _VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
        }
        return domNode;
    }
    function _VirtualDom_applyFacts(domNode, eventNode, facts) {
        for (var key in facts) {
            var value = facts[key];
            key === "a1"
                ? _VirtualDom_applyStyles(domNode, value)
                :
                    key === "a0"
                        ? _VirtualDom_applyEvents(domNode, eventNode, value)
                        :
                            key === "a3"
                                ? _VirtualDom_applyAttrs(domNode, value)
                                :
                                    key === "a4"
                                        ? _VirtualDom_applyAttrsNS(domNode, value)
                                        :
                                            ((key !== "value" && key !== "checked") || domNode[key] !== value) && (domNode[key] = value);
        }
    }
    function _VirtualDom_applyStyles(domNode, styles) {
        var domNodeStyle = domNode.style;
        for (var key in styles) {
            domNodeStyle[key] = styles[key];
        }
    }
    function _VirtualDom_applyAttrs(domNode, attrs) {
        for (var key in attrs) {
            var value = attrs[key];
            typeof value !== "undefined"
                ? domNode.setAttribute(key, value)
                : domNode.removeAttribute(key);
        }
    }
    function _VirtualDom_applyAttrsNS(domNode, nsAttrs) {
        for (var key in nsAttrs) {
            var pair = nsAttrs[key];
            var namespace = pair.f;
            var value = pair.o;
            typeof value !== "undefined"
                ? domNode.setAttributeNS(namespace, key, value)
                : domNode.removeAttributeNS(namespace, key);
        }
    }
    function _VirtualDom_applyEvents(domNode, eventNode, events) {
        var allCallbacks = domNode.elmFs || (domNode.elmFs = {});
        for (var key in events) {
            var newHandler = events[key];
            var oldCallback = allCallbacks[key];
            if (!newHandler) {
                domNode.removeEventListener(key, oldCallback);
                allCallbacks[key] = undefined;
                continue;
            }
            if (oldCallback) {
                var oldHandler = oldCallback.q;
                if (oldHandler.$ === newHandler.$) {
                    oldCallback.q = newHandler;
                    continue;
                }
                domNode.removeEventListener(key, oldCallback);
            }
            oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
            domNode.addEventListener(key, oldCallback, _VirtualDom_passiveSupported
                && { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 });
            allCallbacks[key] = oldCallback;
        }
    }
    var _VirtualDom_passiveSupported;
    try {
        window.addEventListener("t", null, Object.defineProperty({}, "passive", {
            get: function () { _VirtualDom_passiveSupported = true; }
        }));
    }
    catch (e) { }
    function _VirtualDom_makeCallback(eventNode, initialHandler) {
        function callback(event) {
            var handler = callback.q;
            var result = _Json_runHelp(handler.a, event);
            if (!$elm$core$Result$isOk(result)) {
                return;
            }
            var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);
            var value = result.a;
            var message = !tag ? value : tag < 3 ? value.a : value.dN;
            var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bp;
            var currentEventNode = (stopPropagation && event.stopPropagation(),
                (tag == 2 ? value.b : tag == 3 && value.bk) && event.preventDefault(),
                eventNode);
            var tagger;
            var i;
            while (tagger = currentEventNode.j) {
                if (typeof tagger == "function") {
                    message = tagger(message);
                }
                else {
                    for (var i = tagger.length; i--;) {
                        message = tagger[i](message);
                    }
                }
                currentEventNode = currentEventNode.p;
            }
            currentEventNode(message, stopPropagation);
        }
        callback.q = initialHandler;
        return callback;
    }
    function _VirtualDom_equalEvents(x, y) {
        return x.$ == y.$ && _Json_equality(x.a, y.a);
    }
    function _VirtualDom_diff(x, y) {
        var patches = [];
        _VirtualDom_diffHelp(x, y, patches, 0);
        return patches;
    }
    function _VirtualDom_pushPatch(patches, type, index, data) {
        var patch = {
            $: type,
            r: index,
            s: data,
            t: undefined,
            u: undefined
        };
        patches.push(patch);
        return patch;
    }
    function _VirtualDom_diffHelp(x, y, patches, index) {
        if (x === y) {
            return;
        }
        var xType = x.$;
        var yType = y.$;
        if (xType !== yType) {
            if (xType === 1 && yType === 2) {
                y = _VirtualDom_dekey(y);
                yType = 1;
            }
            else {
                _VirtualDom_pushPatch(patches, 0, index, y);
                return;
            }
        }
        switch (yType) {
            case 5:
                var xRefs = x.l;
                var yRefs = y.l;
                var i = xRefs.length;
                var same = i === yRefs.length;
                while (same && i--) {
                    same = xRefs[i] === yRefs[i];
                }
                if (same) {
                    y.k = x.k;
                    return;
                }
                y.k = y.m();
                var subPatches = [];
                _VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
                subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
                return;
            case 4:
                var xTaggers = x.j;
                var yTaggers = y.j;
                var nesting = false;
                var xSubNode = x.k;
                while (xSubNode.$ === 4) {
                    nesting = true;
                    typeof xTaggers !== "object"
                        ? xTaggers = [xTaggers, xSubNode.j]
                        : xTaggers.push(xSubNode.j);
                    xSubNode = xSubNode.k;
                }
                var ySubNode = y.k;
                while (ySubNode.$ === 4) {
                    nesting = true;
                    typeof yTaggers !== "object"
                        ? yTaggers = [yTaggers, ySubNode.j]
                        : yTaggers.push(ySubNode.j);
                    ySubNode = ySubNode.k;
                }
                if (nesting && xTaggers.length !== yTaggers.length) {
                    _VirtualDom_pushPatch(patches, 0, index, y);
                    return;
                }
                if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers) {
                    _VirtualDom_pushPatch(patches, 2, index, yTaggers);
                }
                _VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
                return;
            case 0:
                if (x.a !== y.a) {
                    _VirtualDom_pushPatch(patches, 3, index, y.a);
                }
                return;
            case 1:
                _VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
                return;
            case 2:
                _VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
                return;
            case 3:
                if (x.h !== y.h) {
                    _VirtualDom_pushPatch(patches, 0, index, y);
                    return;
                }
                var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
                factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);
                var patch = y.i(x.g, y.g);
                patch && _VirtualDom_pushPatch(patches, 5, index, patch);
                return;
        }
    }
    function _VirtualDom_pairwiseRefEqual(as, bs) {
        for (var i = 0; i < as.length; i++) {
            if (as[i] !== bs[i]) {
                return false;
            }
        }
        return true;
    }
    function _VirtualDom_diffNodes(x, y, patches, index, diffKids) {
        if (x.c !== y.c || x.f !== y.f) {
            _VirtualDom_pushPatch(patches, 0, index, y);
            return;
        }
        var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
        factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);
        diffKids(x, y, patches, index);
    }
    function _VirtualDom_diffFacts(x, y, category) {
        var diff;
        for (var xKey in x) {
            if (xKey === "a1" || xKey === "a0" || xKey === "a3" || xKey === "a4") {
                var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
                if (subDiff) {
                    diff = diff || {};
                    diff[xKey] = subDiff;
                }
                continue;
            }
            if (!(xKey in y)) {
                diff = diff || {};
                diff[xKey] =
                    !category
                        ? (typeof x[xKey] === "string" ? "" : null)
                        :
                            (category === "a1")
                                ? ""
                                :
                                    (category === "a0" || category === "a3")
                                        ? undefined
                                        :
                                            { f: x[xKey].f, o: undefined };
                continue;
            }
            var xValue = x[xKey];
            var yValue = y[xKey];
            if (xValue === yValue && xKey !== "value" && xKey !== "checked"
                || category === "a0" && _VirtualDom_equalEvents(xValue, yValue)) {
                continue;
            }
            diff = diff || {};
            diff[xKey] = yValue;
        }
        for (var yKey in y) {
            if (!(yKey in x)) {
                diff = diff || {};
                diff[yKey] = y[yKey];
            }
        }
        return diff;
    }
    function _VirtualDom_diffKids(xParent, yParent, patches, index) {
        var xKids = xParent.e;
        var yKids = yParent.e;
        var xLen = xKids.length;
        var yLen = yKids.length;
        if (xLen > yLen) {
            _VirtualDom_pushPatch(patches, 6, index, {
                v: yLen,
                i: xLen - yLen
            });
        }
        else if (xLen < yLen) {
            _VirtualDom_pushPatch(patches, 7, index, {
                v: xLen,
                e: yKids
            });
        }
        for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++) {
            var xKid = xKids[i];
            _VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
            index += xKid.b || 0;
        }
    }
    function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex) {
        var localPatches = [];
        var changes = {};
        var inserts = [];
        var xKids = xParent.e;
        var yKids = yParent.e;
        var xLen = xKids.length;
        var yLen = yKids.length;
        var xIndex = 0;
        var yIndex = 0;
        var index = rootIndex;
        while (xIndex < xLen && yIndex < yLen) {
            var x = xKids[xIndex];
            var y = yKids[yIndex];
            var xKey = x.a;
            var yKey = y.a;
            var xNode = x.b;
            var yNode = y.b;
            var newMatch = undefined;
            var oldMatch = undefined;
            if (xKey === yKey) {
                index++;
                _VirtualDom_diffHelp(xNode, yNode, localPatches, index);
                index += xNode.b || 0;
                xIndex++;
                yIndex++;
                continue;
            }
            var xNext = xKids[xIndex + 1];
            var yNext = yKids[yIndex + 1];
            if (xNext) {
                var xNextKey = xNext.a;
                var xNextNode = xNext.b;
                oldMatch = yKey === xNextKey;
            }
            if (yNext) {
                var yNextKey = yNext.a;
                var yNextNode = yNext.b;
                newMatch = xKey === yNextKey;
            }
            if (newMatch && oldMatch) {
                index++;
                _VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
                _VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
                index += xNode.b || 0;
                index++;
                _VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
                index += xNextNode.b || 0;
                xIndex += 2;
                yIndex += 2;
                continue;
            }
            if (newMatch) {
                index++;
                _VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
                _VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
                index += xNode.b || 0;
                xIndex += 1;
                yIndex += 2;
                continue;
            }
            if (oldMatch) {
                index++;
                _VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
                index += xNode.b || 0;
                index++;
                _VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
                index += xNextNode.b || 0;
                xIndex += 2;
                yIndex += 1;
                continue;
            }
            if (xNext && xNextKey === yNextKey) {
                index++;
                _VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
                _VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
                index += xNode.b || 0;
                index++;
                _VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
                index += xNextNode.b || 0;
                xIndex += 2;
                yIndex += 2;
                continue;
            }
            break;
        }
        while (xIndex < xLen) {
            index++;
            var x = xKids[xIndex];
            var xNode = x.b;
            _VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
            index += xNode.b || 0;
            xIndex++;
        }
        while (yIndex < yLen) {
            var endInserts = endInserts || [];
            var y = yKids[yIndex];
            _VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
            yIndex++;
        }
        if (localPatches.length > 0 || inserts.length > 0 || endInserts) {
            _VirtualDom_pushPatch(patches, 8, rootIndex, {
                w: localPatches,
                x: inserts,
                y: endInserts
            });
        }
    }
    var _VirtualDom_POSTFIX = "_elmW6BL";
    function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts) {
        var entry = changes[key];
        if (!entry) {
            entry = {
                c: 0,
                z: vnode,
                r: yIndex,
                s: undefined
            };
            inserts.push({ r: yIndex, A: entry });
            changes[key] = entry;
            return;
        }
        if (entry.c === 1) {
            inserts.push({ r: yIndex, A: entry });
            entry.c = 2;
            var subPatches = [];
            _VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
            entry.r = yIndex;
            entry.s.s = {
                w: subPatches,
                A: entry
            };
            return;
        }
        _VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
    }
    function _VirtualDom_removeNode(changes, localPatches, key, vnode, index) {
        var entry = changes[key];
        if (!entry) {
            var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);
            changes[key] = {
                c: 1,
                z: vnode,
                r: index,
                s: patch
            };
            return;
        }
        if (entry.c === 0) {
            entry.c = 2;
            var subPatches = [];
            _VirtualDom_diffHelp(vnode, entry.z, subPatches, index);
            _VirtualDom_pushPatch(localPatches, 9, index, {
                w: subPatches,
                A: entry
            });
            return;
        }
        _VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
    }
    function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode) {
        _VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
    }
    function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode) {
        var patch = patches[i];
        var index = patch.r;
        while (index === low) {
            var patchType = patch.$;
            if (patchType === 1) {
                _VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
            }
            else if (patchType === 8) {
                patch.t = domNode;
                patch.u = eventNode;
                var subPatches = patch.s.w;
                if (subPatches.length > 0) {
                    _VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
                }
            }
            else if (patchType === 9) {
                patch.t = domNode;
                patch.u = eventNode;
                var data = patch.s;
                if (data) {
                    data.A.s = domNode;
                    var subPatches = data.w;
                    if (subPatches.length > 0) {
                        _VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
                    }
                }
            }
            else {
                patch.t = domNode;
                patch.u = eventNode;
            }
            i++;
            if (!(patch = patches[i]) || (index = patch.r) > high) {
                return i;
            }
        }
        var tag = vNode.$;
        if (tag === 4) {
            var subNode = vNode.k;
            while (subNode.$ === 4) {
                subNode = subNode.k;
            }
            return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
        }
        var vKids = vNode.e;
        var childNodes = domNode.childNodes;
        for (var j = 0; j < vKids.length; j++) {
            low++;
            var vKid = tag === 1 ? vKids[j] : vKids[j].b;
            var nextLow = low + (vKid.b || 0);
            if (low <= index && index <= nextLow) {
                i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
                if (!(patch = patches[i]) || (index = patch.r) > high) {
                    return i;
                }
            }
            low = nextLow;
        }
        return i;
    }
    function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode) {
        if (patches.length === 0) {
            return rootDomNode;
        }
        _VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
        return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
    }
    function _VirtualDom_applyPatchesHelp(rootDomNode, patches) {
        for (var i = 0; i < patches.length; i++) {
            var patch = patches[i];
            var localDomNode = patch.t;
            var newNode = _VirtualDom_applyPatch(localDomNode, patch);
            if (localDomNode === rootDomNode) {
                rootDomNode = newNode;
            }
        }
        return rootDomNode;
    }
    function _VirtualDom_applyPatch(domNode, patch) {
        switch (patch.$) {
            case 0:
                return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);
            case 4:
                _VirtualDom_applyFacts(domNode, patch.u, patch.s);
                return domNode;
            case 3:
                domNode.replaceData(0, domNode.length, patch.s);
                return domNode;
            case 1:
                return _VirtualDom_applyPatchesHelp(domNode, patch.s);
            case 2:
                if (domNode.elm_event_node_ref) {
                    domNode.elm_event_node_ref.j = patch.s;
                }
                else {
                    domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
                }
                return domNode;
            case 6:
                var data = patch.s;
                for (var i = 0; i < data.i; i++) {
                    domNode.removeChild(domNode.childNodes[data.v]);
                }
                return domNode;
            case 7:
                var data = patch.s;
                var kids = data.e;
                var i = data.v;
                var theEnd = domNode.childNodes[i];
                for (; i < kids.length; i++) {
                    domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
                }
                return domNode;
            case 9:
                var data = patch.s;
                if (!data) {
                    domNode.parentNode.removeChild(domNode);
                    return domNode;
                }
                var entry = data.A;
                if (typeof entry.r !== "undefined") {
                    domNode.parentNode.removeChild(domNode);
                }
                entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
                return domNode;
            case 8:
                return _VirtualDom_applyPatchReorder(domNode, patch);
            case 5:
                return patch.s(domNode);
            default:
                _Debug_crash(10);
        }
    }
    function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode) {
        var parentNode = domNode.parentNode;
        var newNode = _VirtualDom_render(vNode, eventNode);
        if (!newNode.elm_event_node_ref) {
            newNode.elm_event_node_ref = domNode.elm_event_node_ref;
        }
        if (parentNode && newNode !== domNode) {
            parentNode.replaceChild(newNode, domNode);
        }
        return newNode;
    }
    function _VirtualDom_applyPatchReorder(domNode, patch) {
        var data = patch.s;
        var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);
        domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);
        var inserts = data.x;
        for (var i = 0; i < inserts.length; i++) {
            var insert = inserts[i];
            var entry = insert.A;
            var node = entry.c === 2
                ? entry.s
                : _VirtualDom_render(entry.z, patch.u);
            domNode.insertBefore(node, domNode.childNodes[insert.r]);
        }
        if (frag) {
            _VirtualDom_appendChild(domNode, frag);
        }
        return domNode;
    }
    function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch) {
        if (!endInserts) {
            return;
        }
        var frag = _VirtualDom_doc.createDocumentFragment();
        for (var i = 0; i < endInserts.length; i++) {
            var insert = endInserts[i];
            var entry = insert.A;
            _VirtualDom_appendChild(frag, entry.c === 2
                ? entry.s
                : _VirtualDom_render(entry.z, patch.u));
        }
        return frag;
    }
    function _VirtualDom_virtualize(node) {
        if (node.nodeType === 3) {
            return _VirtualDom_text(node.textContent);
        }
        if (node.nodeType !== 1) {
            return _VirtualDom_text("");
        }
        var attrList = _List_Nil;
        var attrs = node.attributes;
        for (var i = attrs.length; i--;) {
            var attr = attrs[i];
            var name = attr.name;
            var value = attr.value;
            attrList = _List_Cons(_VirtualDom_attribute_fn(name, value), attrList);
        }
        var tag = node.tagName.toLowerCase();
        var kidList = _List_Nil;
        var kids = node.childNodes;
        for (var i = kids.length; i--;) {
            kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
        }
        return A3(_VirtualDom_node, tag, attrList, kidList);
    }
    function _VirtualDom_dekey(keyedNode) {
        var keyedKids = keyedNode.e;
        var len = keyedKids.length;
        var kids = new Array(len);
        for (var i = 0; i < len; i++) {
            kids[i] = keyedKids[i].b;
        }
        return {
            $: 1,
            c: keyedNode.c,
            d: keyedNode.d,
            e: kids,
            f: keyedNode.f,
            b: keyedNode.b
        };
    }
    var _Debugger_element;
    var _Browser_element = _Debugger_element || F4(function (impl, flagDecoder, debugMetadata, args) {
        return _Platform_initialize(flagDecoder, args, impl.du, impl.ey, impl.eo, function (sendToApp, initialModel) {
            var view = impl.eB;
            var domNode = args["node"];
            var currNode = _VirtualDom_virtualize(domNode);
            return _Browser_makeAnimator(initialModel, function (model) {
                var nextNode = view(model);
                var patches = _VirtualDom_diff(currNode, nextNode);
                domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
                currNode = nextNode;
            });
        });
    });
    var _Debugger_document;
    var _Browser_document = _Debugger_document || F4(function (impl, flagDecoder, debugMetadata, args) {
        return _Platform_initialize(flagDecoder, args, impl.du, impl.ey, impl.eo, function (sendToApp, initialModel) {
            var divertHrefToApp = impl.bn && impl.bn(sendToApp);
            var view = impl.eB;
            var title = _VirtualDom_doc.title;
            var bodyNode = _VirtualDom_doc.body;
            var currNode = _VirtualDom_virtualize(bodyNode);
            return _Browser_makeAnimator(initialModel, function (model) {
                _VirtualDom_divertHrefToApp = divertHrefToApp;
                var doc = view(model);
                var nextNode = _VirtualDom_nodeNS_fn(_VirtualDom_node_a0, "body")(_List_Nil)(doc.ag);
                var patches = _VirtualDom_diff(currNode, nextNode);
                bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
                currNode = nextNode;
                _VirtualDom_divertHrefToApp = 0;
                (title !== doc.eu) && (_VirtualDom_doc.title = title = doc.eu);
            });
        });
    });
    var _Browser_cancelAnimationFrame = typeof cancelAnimationFrame !== "undefined"
        ? cancelAnimationFrame
        : function (id) { clearTimeout(id); };
    var _Browser_requestAnimationFrame = typeof requestAnimationFrame !== "undefined"
        ? requestAnimationFrame
        : function (callback) { return setTimeout(callback, 1000 / 60); };
    function _Browser_makeAnimator(model, draw) {
        draw(model);
        var state = 0;
        function updateIfNeeded() {
            state = state === 1
                ? 0
                : (_Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1);
        }
        return function (nextModel, isSync) {
            model = nextModel;
            isSync
                ? (draw(model),
                    state === 2 && (state = 1))
                : (state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
                    state = 2);
        };
    }
    function _Browser_application(impl) {
        var onUrlChange = impl.d5;
        var onUrlRequest = impl.d6;
        var key = function () { key.a(onUrlChange(_Browser_getUrl())); };
        return _Browser_document({
            bn: function (sendToApp) {
                key.a = sendToApp;
                _Browser_window.addEventListener("popstate", key);
                _Browser_window.navigator.userAgent.indexOf("Trident") < 0 || _Browser_window.addEventListener("hashchange", key);
                return F2(function (domNode, event) {
                    if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute("download")) {
                        event.preventDefault();
                        var href = domNode.href;
                        var curr = _Browser_getUrl();
                        var next = $elm$url$Url$fromString(href).a;
                        sendToApp(onUrlRequest((next
                            && curr.b9 === next.b9
                            && curr.bU === next.bU
                            && curr.b5.a === next.b5.a)
                            ? $elm$browser$Browser$Internal(next)
                            : $elm$browser$Browser$External(href)));
                    }
                });
            },
            du: function (flags) {
                return A3(impl.du, flags, _Browser_getUrl(), key);
            },
            eB: impl.eB,
            ey: impl.ey,
            eo: impl.eo
        });
    }
    function _Browser_getUrl() {
        return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
    }
    var _Browser_go_fn = function (key, n) {
        return $elm$core$Task$perform_fn($elm$core$Basics$never, _Scheduler_binding(function () {
            n && history.go(n);
            key();
        }));
    }, _Browser_go = F2(_Browser_go_fn);
    var _Browser_pushUrl_fn = function (key, url) {
        return $elm$core$Task$perform_fn($elm$core$Basics$never, _Scheduler_binding(function () {
            history.pushState({}, "", url);
            key();
        }));
    }, _Browser_pushUrl = F2(_Browser_pushUrl_fn);
    var _Browser_replaceUrl_fn = function (key, url) {
        return $elm$core$Task$perform_fn($elm$core$Basics$never, _Scheduler_binding(function () {
            history.replaceState({}, "", url);
            key();
        }));
    }, _Browser_replaceUrl = F2(_Browser_replaceUrl_fn);
    var _Browser_fakeNode = { addEventListener: function () { }, removeEventListener: function () { } };
    var _Browser_doc = typeof document !== "undefined" ? document : _Browser_fakeNode;
    var _Browser_window = typeof window !== "undefined" ? window : _Browser_fakeNode;
    var _Browser_on_fn = function (node, eventName, sendToSelf) {
        return _Scheduler_spawn(_Scheduler_binding(function (callback) {
            function handler(event) { _Scheduler_rawSpawn(sendToSelf(event)); }
            node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
            return function () { node.removeEventListener(eventName, handler); };
        }));
    }, _Browser_on = F3(_Browser_on_fn);
    var _Browser_decodeEvent_fn = function (decoder, event) {
        var result = _Json_runHelp(decoder, event);
        return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
    }, _Browser_decodeEvent = F2(_Browser_decodeEvent_fn);
    function _Browser_visibilityInfo() {
        return (typeof _VirtualDom_doc.hidden !== "undefined")
            ? { dr: "hidden", cZ: "visibilitychange" }
            :
                (typeof _VirtualDom_doc.mozHidden !== "undefined")
                    ? { dr: "mozHidden", cZ: "mozvisibilitychange" }
                    :
                        (typeof _VirtualDom_doc.msHidden !== "undefined")
                            ? { dr: "msHidden", cZ: "msvisibilitychange" }
                            :
                                (typeof _VirtualDom_doc.webkitHidden !== "undefined")
                                    ? { dr: "webkitHidden", cZ: "webkitvisibilitychange" }
                                    : { dr: "hidden", cZ: "visibilitychange" };
    }
    function _Browser_rAF() {
        return _Scheduler_binding(function (callback) {
            var id = _Browser_requestAnimationFrame(function () {
                callback(_Scheduler_succeed(Date.now()));
            });
            return function () {
                _Browser_cancelAnimationFrame(id);
            };
        });
    }
    function _Browser_now() {
        return _Scheduler_binding(function (callback) {
            callback(_Scheduler_succeed(Date.now()));
        });
    }
    function _Browser_withNode(id, doStuff) {
        return _Scheduler_binding(function (callback) {
            _Browser_requestAnimationFrame(function () {
                var node = document.getElementById(id);
                callback(node
                    ? _Scheduler_succeed(doStuff(node))
                    : _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id)));
            });
        });
    }
    function _Browser_withWindow(doStuff) {
        return _Scheduler_binding(function (callback) {
            _Browser_requestAnimationFrame(function () {
                callback(_Scheduler_succeed(doStuff()));
            });
        });
    }
    var _Browser_call_fn = function (functionName, id) {
        return _Browser_withNode(id, function (node) {
            node[functionName]();
            return _Utils_Tuple0;
        });
    }, _Browser_call = F2(_Browser_call_fn);
    function _Browser_getViewport() {
        return {
            ck: _Browser_getScene(),
            cC: {
                cE: _Browser_window.pageXOffset,
                cF: _Browser_window.pageYOffset,
                cD: _Browser_doc.documentElement.clientWidth,
                bS: _Browser_doc.documentElement.clientHeight
            }
        };
    }
    function _Browser_getScene() {
        var body = _Browser_doc.body;
        var elem = _Browser_doc.documentElement;
        return {
            cD: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
            bS: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
        };
    }
    var _Browser_setViewport_fn = function (x, y) {
        return _Browser_withWindow(function () {
            _Browser_window.scroll(x, y);
            return _Utils_Tuple0;
        });
    }, _Browser_setViewport = F2(_Browser_setViewport_fn);
    function _Browser_getViewportOf(id) {
        return _Browser_withNode(id, function (node) {
            return {
                ck: {
                    cD: node.scrollWidth,
                    bS: node.scrollHeight
                },
                cC: {
                    cE: node.scrollLeft,
                    cF: node.scrollTop,
                    cD: node.clientWidth,
                    bS: node.clientHeight
                }
            };
        });
    }
    var _Browser_setViewportOf_fn = function (id, x, y) {
        return _Browser_withNode(id, function (node) {
            node.scrollLeft = x;
            node.scrollTop = y;
            return _Utils_Tuple0;
        });
    }, _Browser_setViewportOf = F3(_Browser_setViewportOf_fn);
    function _Browser_getElement(id) {
        return _Browser_withNode(id, function (node) {
            var rect = node.getBoundingClientRect();
            var x = _Browser_window.pageXOffset;
            var y = _Browser_window.pageYOffset;
            return {
                ck: _Browser_getScene(),
                cC: {
                    cE: x,
                    cF: y,
                    cD: _Browser_doc.documentElement.clientWidth,
                    bS: _Browser_doc.documentElement.clientHeight
                },
                df: {
                    cE: x + rect.left,
                    cF: y + rect.top,
                    cD: rect.width,
                    bS: rect.height
                }
            };
        });
    }
    function _Browser_reload(skipCache) {
        return $elm$core$Task$perform_fn($elm$core$Basics$never, _Scheduler_binding(function (callback) {
            _VirtualDom_doc.location.reload(skipCache);
        }));
    }
    function _Browser_load(url) {
        return $elm$core$Task$perform_fn($elm$core$Basics$never, _Scheduler_binding(function (callback) {
            try {
                _Browser_window.location = url;
            }
            catch (err) {
                _VirtualDom_doc.location.reload(false);
            }
        }));
    }
    var _Bitwise_and_fn = function (a, b) {
        return a & b;
    }, _Bitwise_and = F2(_Bitwise_and_fn);
    var _Bitwise_or_fn = function (a, b) {
        return a | b;
    }, _Bitwise_or = F2(_Bitwise_or_fn);
    var _Bitwise_xor_fn = function (a, b) {
        return a ^ b;
    }, _Bitwise_xor = F2(_Bitwise_xor_fn);
    function _Bitwise_complement(a) {
        return ~a;
    }
    ;
    var _Bitwise_shiftLeftBy_fn = function (offset, a) {
        return a << offset;
    }, _Bitwise_shiftLeftBy = F2(_Bitwise_shiftLeftBy_fn);
    var _Bitwise_shiftRightBy_fn = function (offset, a) {
        return a >> offset;
    }, _Bitwise_shiftRightBy = F2(_Bitwise_shiftRightBy_fn);
    var _Bitwise_shiftRightZfBy_fn = function (offset, a) {
        return a >>> offset;
    }, _Bitwise_shiftRightZfBy = F2(_Bitwise_shiftRightZfBy_fn);
    var _Http_toTask_fn = function (router, toTask, request) {
        return _Scheduler_binding(function (callback) {
            function done(response) {
                callback(toTask(request.aj.a(response)));
            }
            var xhr = new XMLHttpRequest();
            xhr.addEventListener("error", function () { done($elm$http$Http$NetworkError_); });
            xhr.addEventListener("timeout", function () { done($elm$http$Http$Timeout_); });
            xhr.addEventListener("load", function () { done(_Http_toResponse(request.aj.b, xhr)); });
            $elm$core$Maybe$isJust(request.aw) && _Http_track(router, xhr, request.aw.a);
            try {
                xhr.open(request.an, request.o, true);
            }
            catch (e) {
                return done($elm$http$Http$BadUrl_(request.o));
            }
            _Http_configureRequest(xhr, request);
            request.ag.a && xhr.setRequestHeader("Content-Type", request.ag.a);
            xhr.send(request.ag.b);
            return function () { xhr.c = true; xhr.abort(); };
        });
    }, _Http_toTask = F3(_Http_toTask_fn);
    function _Http_configureRequest(xhr, request) {
        for (var headers = request.al; headers.b; headers = headers.b) {
            xhr.setRequestHeader(headers.a.a, headers.a.b);
        }
        xhr.timeout = request.au.a || 0;
        xhr.responseType = request.aj.d;
        xhr.withCredentials = request.cK;
    }
    function _Http_toResponse(toBody, xhr) {
        return A2(200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_, _Http_toMetadata(xhr), toBody(xhr.response));
    }
    function _Http_toMetadata(xhr) {
        return {
            o: xhr.responseURL,
            el: xhr.status,
            em: xhr.statusText,
            al: _Http_parseHeaders(xhr.getAllResponseHeaders())
        };
    }
    function _Http_parseHeaders(rawHeaders) {
        if (!rawHeaders) {
            return $elm$core$Dict$empty;
        }
        var headers = $elm$core$Dict$empty;
        var headerPairs = rawHeaders.split("\r\n");
        for (var i = headerPairs.length; i--;) {
            var headerPair = headerPairs[i];
            var index = headerPair.indexOf(": ");
            if (index > 0) {
                var key = headerPair.substring(0, index);
                var value = headerPair.substring(index + 2);
                headers = $elm$core$Dict$update_fn(key, function (oldValue) {
                    return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
                        ? value + ", " + oldValue.a
                        : value);
                }, headers);
            }
        }
        return headers;
    }
    var _Http_expect_fn = function (type, toBody, toValue) {
        return {
            $: 0,
            d: type,
            b: toBody,
            a: toValue
        };
    }, _Http_expect = F3(_Http_expect_fn);
    var _Http_mapExpect_fn = function (func, expect) {
        return {
            $: 0,
            d: expect.d,
            b: expect.b,
            a: function (x) { return func(expect.a(x)); }
        };
    }, _Http_mapExpect = F2(_Http_mapExpect_fn);
    function _Http_toDataView(arrayBuffer) {
        return new DataView(arrayBuffer);
    }
    var _Http_emptyBody = { $: 0 };
    var _Http_pair_fn = function (a, b) { return { $: 0, a: a, b: b }; }, _Http_pair = F2(_Http_pair_fn);
    function _Http_toFormData(parts) {
        for (var formData = new FormData(); parts.b; parts = parts.b) {
            var part = parts.a;
            formData.append(part.a, part.b);
        }
        return formData;
    }
    var _Http_bytesToBlob_fn = function (mime, bytes) {
        return new Blob([bytes], { type: mime });
    }, _Http_bytesToBlob = F2(_Http_bytesToBlob_fn);
    function _Http_track(router, xhr, tracker) {
        xhr.upload.addEventListener("progress", function (event) {
            if (xhr.c) {
                return;
            }
            _Scheduler_rawSpawn(_Platform_sendToSelf_fn(router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
                ek: event.loaded,
                as: event.total
            }))));
        });
        xhr.addEventListener("progress", function (event) {
            if (xhr.c) {
                return;
            }
            _Scheduler_rawSpawn(_Platform_sendToSelf_fn(router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
                ec: event.loaded,
                as: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
            }))));
        });
    }
    var _Regex_never = /.^/;
    var _Regex_fromStringWith_fn = function (options, string) {
        var flags = "g";
        if (options.dQ) {
            flags += "m";
        }
        if (options.cX) {
            flags += "i";
        }
        try {
            return $elm$core$Maybe$Just(new RegExp(string, flags));
        }
        catch (error) {
            return $elm$core$Maybe$Nothing;
        }
    }, _Regex_fromStringWith = F2(_Regex_fromStringWith_fn);
    var _Regex_contains_fn = function (re, string) {
        return string.match(re) !== null;
    }, _Regex_contains = F2(_Regex_contains_fn);
    var _Regex_findAtMost_fn = function (n, re, str) {
        var out = [];
        var number = 0;
        var string = str;
        var lastIndex = re.lastIndex;
        var prevLastIndex = -1;
        var result;
        while (number++ < n && (result = re.exec(string))) {
            if (prevLastIndex == re.lastIndex)
                break;
            var i = result.length - 1;
            var subs = new Array(i);
            while (i > 0) {
                var submatch = result[i];
                subs[--i] = submatch
                    ? $elm$core$Maybe$Just(submatch)
                    : $elm$core$Maybe$Nothing;
            }
            out.push($elm$regex$Regex$Match_fn(result[0], result.index, number, _List_fromArray(subs)));
            prevLastIndex = re.lastIndex;
        }
        re.lastIndex = lastIndex;
        return _List_fromArray(out);
    }, _Regex_findAtMost = F3(_Regex_findAtMost_fn);
    var _Regex_replaceAtMost_fn = function (n, re, replacer, string) {
        var count = 0;
        function jsReplacer(match) {
            if (count++ >= n) {
                return match;
            }
            var i = arguments.length - 3;
            var submatches = new Array(i);
            while (i > 0) {
                var submatch = arguments[i];
                submatches[--i] = submatch
                    ? $elm$core$Maybe$Just(submatch)
                    : $elm$core$Maybe$Nothing;
            }
            return replacer($elm$regex$Regex$Match_fn(match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
        }
        return string.replace(re, jsReplacer);
    }, _Regex_replaceAtMost = F4(_Regex_replaceAtMost_fn);
    var _Regex_splitAtMost_fn = function (n, re, str) {
        var string = str;
        var out = [];
        var start = re.lastIndex;
        var restoreLastIndex = re.lastIndex;
        while (n--) {
            var result = re.exec(string);
            if (!result)
                break;
            out.push(string.slice(start, result.index));
            start = re.lastIndex;
        }
        out.push(string.slice(start));
        re.lastIndex = restoreLastIndex;
        return _List_fromArray(out);
    }, _Regex_splitAtMost = F3(_Regex_splitAtMost_fn);
    var _Regex_infinity = Infinity;
    function _Url_percentEncode(string) {
        return encodeURIComponent(string);
    }
    function _Url_percentDecode(string) {
        try {
            return $elm$core$Maybe$Just(decodeURIComponent(string));
        }
        catch (e) {
            return $elm$core$Maybe$Nothing;
        }
    }
    function _Time_now(millisToPosix) {
        return _Scheduler_binding(function (callback) {
            callback(_Scheduler_succeed(millisToPosix(Date.now())));
        });
    }
    var _Time_setInterval_fn = function (interval, task) {
        return _Scheduler_binding(function (callback) {
            var id = setInterval(function () { _Scheduler_rawSpawn(task); }, interval);
            return function () { clearInterval(id); };
        });
    }, _Time_setInterval = F2(_Time_setInterval_fn);
    function _Time_here() {
        return _Scheduler_binding(function (callback) {
            callback(_Scheduler_succeed($elm$time$Time$Zone_fn(-(new Date().getTimezoneOffset()), _List_Nil)));
        });
    }
    function _Time_getZoneName() {
        return _Scheduler_binding(function (callback) {
            try {
                var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
            }
            catch (e) {
                var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
            }
            callback(_Scheduler_succeed(name));
        });
    }
    var _Parser_isSubString_fn = function (smallString, offset, row, col, bigString) {
        var smallLength = smallString.length;
        var isGood = offset + smallLength <= bigString.length;
        for (var i = 0; isGood && i < smallLength;) {
            var code = bigString.charCodeAt(offset);
            isGood =
                smallString[i++] === bigString[offset++]
                    && (code === 10
                        ? (row++, col = 1)
                        : (col++, (code & 63488) === 55296 ? smallString[i++] === bigString[offset++] : 1));
        }
        return _Utils_Tuple3(isGood ? offset : -1, row, col);
    }, _Parser_isSubString = F5(_Parser_isSubString_fn);
    var _Parser_isSubChar_fn = function (predicate, offset, string) {
        return (string.length <= offset
            ? -1
            :
                (string.charCodeAt(offset) & 63488) === 55296
                    ? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
                    :
                        (predicate(_Utils_chr(string[offset]))
                            ? ((string[offset] === "\n") ? -2 : (offset + 1))
                            : -1));
    }, _Parser_isSubChar = F3(_Parser_isSubChar_fn);
    var _Parser_isAsciiCode_fn = function (code, offset, string) {
        return string.charCodeAt(offset) === code;
    }, _Parser_isAsciiCode = F3(_Parser_isAsciiCode_fn);
    var _Parser_chompBase10_fn = function (offset, string) {
        for (; offset < string.length; offset++) {
            var code = string.charCodeAt(offset);
            if (code < 48 || 57 < code) {
                return offset;
            }
        }
        return offset;
    }, _Parser_chompBase10 = F2(_Parser_chompBase10_fn);
    var _Parser_consumeBase_fn = function (base, offset, string) {
        for (var total = 0; offset < string.length; offset++) {
            var digit = string.charCodeAt(offset) - 48;
            if (digit < 0 || base <= digit)
                break;
            total = base * total + digit;
        }
        return _Utils_Tuple2(offset, total);
    }, _Parser_consumeBase = F3(_Parser_consumeBase_fn);
    var _Parser_consumeBase16_fn = function (offset, string) {
        for (var total = 0; offset < string.length; offset++) {
            var code = string.charCodeAt(offset);
            if (48 <= code && code <= 57) {
                total = 16 * total + code - 48;
            }
            else if (65 <= code && code <= 70) {
                total = 16 * total + code - 55;
            }
            else if (97 <= code && code <= 102) {
                total = 16 * total + code - 87;
            }
            else {
                break;
            }
        }
        return _Utils_Tuple2(offset, total);
    }, _Parser_consumeBase16 = F2(_Parser_consumeBase16_fn);
    var _Parser_findSubString_fn = function (smallString, offset, row, col, bigString) {
        var newOffset = bigString.indexOf(smallString, offset);
        var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;
        while (offset < target) {
            var code = bigString.charCodeAt(offset++);
            code === 10
                ? (col = 1, row++)
                : (col++, (code & 63488) === 55296 && offset++);
        }
        return _Utils_Tuple3(newOffset, row, col);
    }, _Parser_findSubString = F5(_Parser_findSubString_fn);
    var $author$project$Main$Msg$UrlChanged = function (a) {
        return { $: 24, a: a };
    };
    var $author$project$Main$Msg$UrlRequested = function (a) {
        return { $: 25, a: a };
    };
    var $elm$core$List$cons = _List_cons;
    var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
    var $elm$core$Array$foldr_fn = function (func, baseCase, _v0) {
        var tree = _v0.c;
        var tail = _v0.d;
        var helper = F2(function (node, acc) {
            if (!node.$) {
                var subTree = node.a;
                return _JsArray_foldr_fn(helper, acc, subTree);
            }
            else {
                var values = node.a;
                return _JsArray_foldr_fn(func, acc, values);
            }
        });
        return _JsArray_foldr_fn(helper, _JsArray_foldr_fn(func, baseCase, tail), tree);
    }, $elm$core$Array$foldr = F3($elm$core$Array$foldr_fn);
    var $elm$core$Array$toList = function (array) {
        return $elm$core$Array$foldr_fn($elm$core$List$cons, _List_Nil, array);
    };
    var $elm$core$Dict$foldr_fn = function (func, acc, t) {
        foldr: while (true) {
            if (t.$ === -2) {
                return acc;
            }
            else {
                var key = t.b;
                var value = t.c;
                var left = t.d;
                var right = t.e;
                var $temp$func = func, $temp$acc = A3(func, key, value, $elm$core$Dict$foldr_fn(func, acc, right)), $temp$t = left;
                func = $temp$func;
                acc = $temp$acc;
                t = $temp$t;
                continue foldr;
            }
        }
    }, $elm$core$Dict$foldr_fn_unwrapped = function (func, acc, t) {
        foldr: while (true) {
            if (t.$ === -2) {
                return acc;
            }
            else {
                var key = t.b;
                var value = t.c;
                var left = t.d;
                var right = t.e;
                var $temp$func = func, $temp$acc = func(key, value, $elm$core$Dict$foldr_fn_unwrapped(func, acc, right)), $temp$t = left;
                func = $temp$func;
                acc = $temp$acc;
                t = $temp$t;
                continue foldr;
            }
        }
    }, $elm$core$Dict$foldr = F3($elm$core$Dict$foldr_fn);
    var $elm$core$Dict$toList = function (dict) {
        return $elm$core$Dict$foldr_fn_unwrapped(function (key, value, list) {
            return _List_Cons(_Utils_Tuple2(key, value), list);
        }, _List_Nil, dict);
    };
    var $elm$core$Dict$keys = function (dict) {
        return $elm$core$Dict$foldr_fn_unwrapped(function (key, value, keyList) {
            return _List_Cons(key, keyList);
        }, _List_Nil, dict);
    };
    var $elm$core$Set$toList = function (_v0) {
        var dict = _v0;
        return $elm$core$Dict$keys(dict);
    };
    var $elm$core$Basics$EQ = 1;
    var $elm$core$Basics$GT = 2;
    var $elm$core$Basics$LT = 0;
    var $elm$core$Result$Err = function (a) {
        return { $: 1, a: a };
    };
    var $elm$json$Json$Decode$Failure_fn = function (a, b) {
        return { $: 3, a: a, b: b };
    }, $elm$json$Json$Decode$Failure = F2($elm$json$Json$Decode$Failure_fn);
    var $elm$json$Json$Decode$Field_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $elm$json$Json$Decode$Field = F2($elm$json$Json$Decode$Field_fn);
    var $elm$json$Json$Decode$Index_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $elm$json$Json$Decode$Index = F2($elm$json$Json$Decode$Index_fn);
    var $elm$core$Result$Ok = function (a) {
        return { $: 0, a: a };
    };
    var $elm$json$Json$Decode$OneOf = function (a) {
        return { $: 2, a: a };
    };
    var $elm$core$Basics$False = 1;
    var $elm$core$Basics$add = _Basics_add;
    var $elm$core$Maybe$Just = function (a) { return { $: 0, a: a }; };
    var $elm$core$Maybe$Nothing = { $: 1, a: null };
    var $elm$core$String$all = _String_all;
    var $elm$core$Basics$and = _Basics_and;
    var $elm$core$Basics$append = _Utils_append;
    var $elm$json$Json$Encode$encode = _Json_encode;
    var $elm$core$String$fromInt = _String_fromNumber;
    var $elm$core$String$join_fn = function (sep, chunks) {
        return _String_join_fn(sep, _List_toArray(chunks));
    }, $elm$core$String$join = F2($elm$core$String$join_fn);
    var $elm$core$String$split_fn = function (sep, string) {
        return _List_fromArray(_String_split_fn(sep, string));
    }, $elm$core$String$split = F2($elm$core$String$split_fn);
    var $elm$json$Json$Decode$indent = function (str) {
        return $elm$core$String$join_fn("\n    ", $elm$core$String$split_fn("\n", str));
    };
    var $elm$core$List$foldl_fn = function (func, acc, list) {
        foldl: while (true) {
            if (!list.b) {
                return acc;
            }
            else {
                var x = list.a;
                var xs = list.b;
                var $temp$func = func, $temp$acc = A2(func, x, acc), $temp$list = xs;
                func = $temp$func;
                acc = $temp$acc;
                list = $temp$list;
                continue foldl;
            }
        }
    }, $elm$core$List$foldl_fn_unwrapped = function (func, acc, list) {
        foldl: while (true) {
            if (!list.b) {
                return acc;
            }
            else {
                var x = list.a;
                var xs = list.b;
                var $temp$func = func, $temp$acc = func(x, acc), $temp$list = xs;
                func = $temp$func;
                acc = $temp$acc;
                list = $temp$list;
                continue foldl;
            }
        }
    }, $elm$core$List$foldl = F3($elm$core$List$foldl_fn);
    var $elm$core$List$length = function (xs) {
        return $elm$core$List$foldl_fn_unwrapped(function (_v0, i) {
            return i + 1;
        }, 0, xs);
    };
    var $elm$core$List$map2 = _List_map2;
    var $elm$core$Basics$le = _Utils_le;
    var $elm$core$Basics$sub = _Basics_sub;
    var $elm$core$List$rangeHelp_fn = function (lo, hi, list) {
        rangeHelp: while (true) {
            if (_Utils_cmp(lo, hi) < 1) {
                var $temp$lo = lo, $temp$hi = hi - 1, $temp$list = _List_Cons(hi, list);
                lo = $temp$lo;
                hi = $temp$hi;
                list = $temp$list;
                continue rangeHelp;
            }
            else {
                return list;
            }
        }
    }, $elm$core$List$rangeHelp = F3($elm$core$List$rangeHelp_fn);
    var $elm$core$List$range_fn = function (lo, hi) {
        return $elm$core$List$rangeHelp_fn(lo, hi, _List_Nil);
    }, $elm$core$List$range = F2($elm$core$List$range_fn);
    var $elm$core$List$indexedMap_fn = function (f, xs) {
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (var i = 0; xs.b; i++, xs = xs.b) {
            var next = _List_Cons(A2(f, i, xs.a), _List_Nil);
            end.b = next;
            end = next;
        }
        return tmp.b;
    }, $elm$core$List$indexedMap_fn_unwrapped = function (f, xs) {
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (var i = 0; xs.b; i++, xs = xs.b) {
            var next = _List_Cons(f(i, xs.a), _List_Nil);
            end.b = next;
            end = next;
        }
        return tmp.b;
    }, $elm$core$List$indexedMap = F2($elm$core$List$indexedMap_fn);
    var $elm$core$Char$toCode = _Char_toCode;
    var $elm$core$Char$isLower = function (_char) {
        var code = $elm$core$Char$toCode(_char);
        return (97 <= code) && (code <= 122);
    };
    var $elm$core$Char$isUpper = function (_char) {
        var code = $elm$core$Char$toCode(_char);
        return (code <= 90) && (65 <= code);
    };
    var $elm$core$Basics$or = _Basics_or;
    var $elm$core$Char$isAlpha = function (_char) {
        return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
    };
    var $elm$core$Char$isDigit = function (_char) {
        var code = $elm$core$Char$toCode(_char);
        return (code <= 57) && (48 <= code);
    };
    var $elm$core$Char$isAlphaNum = function (_char) {
        return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
    };
    var $elm$core$List$reverse = function (list) {
        return $elm$core$List$foldl_fn($elm$core$List$cons, _List_Nil, list);
    };
    var $elm$core$String$uncons = _String_uncons;
    var $elm$json$Json$Decode$errorOneOf_fn = function (i, error) {
        return "\n\n(" + ($elm$core$String$fromInt(i + 1) + (") " + $elm$json$Json$Decode$indent($elm$json$Json$Decode$errorToString(error))));
    }, $elm$json$Json$Decode$errorOneOf = F2($elm$json$Json$Decode$errorOneOf_fn);
    var $elm$json$Json$Decode$errorToString = function (error) {
        return $elm$json$Json$Decode$errorToStringHelp_fn(error, _List_Nil);
    };
    var $elm$json$Json$Decode$errorToStringHelp_fn = function (error, context) {
        errorToStringHelp: while (true) {
            switch (error.$) {
                case 0:
                    var f = error.a;
                    var err = error.b;
                    var isSimple = function () {
                        var _v1 = $elm$core$String$uncons(f);
                        if (_v1.$ === 1) {
                            return false;
                        }
                        else {
                            var _v2 = _v1.a;
                            var _char = _v2.a;
                            var rest = _v2.b;
                            return $elm$core$Char$isAlpha(_char) && _String_all_fn($elm$core$Char$isAlphaNum, rest);
                        }
                    }();
                    var fieldName = isSimple ? ("." + f) : ("['" + (f + "']"));
                    var $temp$error = err, $temp$context = _List_Cons(fieldName, context);
                    error = $temp$error;
                    context = $temp$context;
                    continue errorToStringHelp;
                case 1:
                    var i = error.a;
                    var err = error.b;
                    var indexName = "[" + ($elm$core$String$fromInt(i) + "]");
                    var $temp$error = err, $temp$context = _List_Cons(indexName, context);
                    error = $temp$error;
                    context = $temp$context;
                    continue errorToStringHelp;
                case 2:
                    var errors = error.a;
                    if (!errors.b) {
                        return "Ran into a Json.Decode.oneOf with no possibilities" + function () {
                            if (!context.b) {
                                return "!";
                            }
                            else {
                                return " at json" + $elm$core$String$join_fn("", $elm$core$List$reverse(context));
                            }
                        }();
                    }
                    else {
                        if (!errors.b.b) {
                            var err = errors.a;
                            var $temp$error = err, $temp$context = context;
                            error = $temp$error;
                            context = $temp$context;
                            continue errorToStringHelp;
                        }
                        else {
                            var starter = function () {
                                if (!context.b) {
                                    return "Json.Decode.oneOf";
                                }
                                else {
                                    return "The Json.Decode.oneOf at json" + $elm$core$String$join_fn("", $elm$core$List$reverse(context));
                                }
                            }();
                            var introduction = starter + (" failed in the following " + ($elm$core$String$fromInt($elm$core$List$length(errors)) + " ways:"));
                            return $elm$core$String$join_fn("\n\n", _List_Cons(introduction, $elm$core$List$indexedMap_fn($elm$json$Json$Decode$errorOneOf, errors)));
                        }
                    }
                default:
                    var msg = error.a;
                    var json = error.b;
                    var introduction = function () {
                        if (!context.b) {
                            return "Problem with the given value:\n\n";
                        }
                        else {
                            return "Problem with the value at json" + ($elm$core$String$join_fn("", $elm$core$List$reverse(context)) + ":\n\n    ");
                        }
                    }();
                    return introduction + ($elm$json$Json$Decode$indent(_Json_encode_fn(4, json)) + ("\n\n" + msg));
            }
        }
    }, $elm$json$Json$Decode$errorToStringHelp = F2($elm$json$Json$Decode$errorToStringHelp_fn);
    var $elm$core$Array$branchFactor = 32;
    var $elm$core$Array$Array_elm_builtin_fn = function (a, b, c, d) {
        return { $: 0, a: a, b: b, c: c, d: d };
    }, $elm$core$Array$Array_elm_builtin = F4($elm$core$Array$Array_elm_builtin_fn);
    var $elm$core$Elm$JsArray$empty = _JsArray_empty;
    var $elm$core$Basics$ceiling = _Basics_ceiling;
    var $elm$core$Basics$fdiv = _Basics_fdiv;
    var $elm$core$Basics$logBase_fn = function (base, number) {
        return _Basics_log(number) / _Basics_log(base);
    }, $elm$core$Basics$logBase = F2($elm$core$Basics$logBase_fn);
    var $elm$core$Basics$toFloat = _Basics_toFloat;
    var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling($elm$core$Basics$logBase_fn(2, $elm$core$Array$branchFactor));
    var $elm$core$Array$empty = $elm$core$Array$Array_elm_builtin_fn(0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
    var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
    var $elm$core$Array$Leaf = function (a) {
        return { $: 1, a: a };
    };
    var $elm$core$Basics$apL_fn = function (f, x) {
        return f(x);
    }, $elm$core$Basics$apL = F2($elm$core$Basics$apL_fn);
    var $elm$core$Basics$apR_fn = function (x, f) {
        return f(x);
    }, $elm$core$Basics$apR = F2($elm$core$Basics$apR_fn);
    var $elm$core$Basics$eq = _Utils_equal;
    var $elm$core$Basics$floor = _Basics_floor;
    var $elm$core$Elm$JsArray$length = _JsArray_length;
    var $elm$core$Basics$gt = _Utils_gt;
    var $elm$core$Basics$max_fn = function (x, y) {
        return (_Utils_cmp(x, y) > 0) ? x : y;
    }, $elm$core$Basics$max = F2($elm$core$Basics$max_fn);
    var $elm$core$Basics$mul = _Basics_mul;
    var $elm$core$Array$SubTree = function (a) {
        return { $: 0, a: a };
    };
    var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
    var $elm$core$Array$compressNodes_fn = function (nodes, acc) {
        compressNodes: while (true) {
            var _v0 = _JsArray_initializeFromList_fn($elm$core$Array$branchFactor, nodes);
            var node = _v0.a;
            var remainingNodes = _v0.b;
            var newAcc = _List_Cons($elm$core$Array$SubTree(node), acc);
            if (!remainingNodes.b) {
                return $elm$core$List$reverse(newAcc);
            }
            else {
                var $temp$nodes = remainingNodes, $temp$acc = newAcc;
                nodes = $temp$nodes;
                acc = $temp$acc;
                continue compressNodes;
            }
        }
    }, $elm$core$Array$compressNodes = F2($elm$core$Array$compressNodes_fn);
    var $elm$core$Tuple$first = function (_v0) {
        var x = _v0.a;
        return x;
    };
    var $elm$core$Array$treeFromBuilder_fn = function (nodeList, nodeListSize) {
        treeFromBuilder: while (true) {
            var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
            if (newNodeSize === 1) {
                return _JsArray_initializeFromList_fn($elm$core$Array$branchFactor, nodeList).a;
            }
            else {
                var $temp$nodeList = $elm$core$Array$compressNodes_fn(nodeList, _List_Nil), $temp$nodeListSize = newNodeSize;
                nodeList = $temp$nodeList;
                nodeListSize = $temp$nodeListSize;
                continue treeFromBuilder;
            }
        }
    }, $elm$core$Array$treeFromBuilder = F2($elm$core$Array$treeFromBuilder_fn);
    var $elm$core$Array$builderToArray_fn = function (reverseNodeList, builder) {
        if (!builder.g) {
            return $elm$core$Array$Array_elm_builtin_fn($elm$core$Elm$JsArray$length(builder.i), $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, builder.i);
        }
        else {
            var treeLen = builder.g * $elm$core$Array$branchFactor;
            var depth = $elm$core$Basics$floor($elm$core$Basics$logBase_fn($elm$core$Array$branchFactor, treeLen - 1));
            var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.j) : builder.j;
            var tree = $elm$core$Array$treeFromBuilder_fn(correctNodeList, builder.g);
            return $elm$core$Array$Array_elm_builtin_fn($elm$core$Elm$JsArray$length(builder.i) + treeLen, $elm$core$Basics$max_fn(5, depth * $elm$core$Array$shiftStep), tree, builder.i);
        }
    }, $elm$core$Array$builderToArray = F2($elm$core$Array$builderToArray_fn);
    var $elm$core$Basics$idiv = _Basics_idiv;
    var $elm$core$Basics$lt = _Utils_lt;
    var $elm$core$Array$initializeHelp_fn = function (fn, fromIndex, len, nodeList, tail) {
        initializeHelp: while (true) {
            if (fromIndex < 0) {
                return $elm$core$Array$builderToArray_fn(false, { j: nodeList, g: (len / $elm$core$Array$branchFactor) | 0, i: tail });
            }
            else {
                var leaf = $elm$core$Array$Leaf(_JsArray_initialize_fn($elm$core$Array$branchFactor, fromIndex, fn));
                var $temp$fn = fn, $temp$fromIndex = fromIndex - $elm$core$Array$branchFactor, $temp$len = len, $temp$nodeList = _List_Cons(leaf, nodeList), $temp$tail = tail;
                fn = $temp$fn;
                fromIndex = $temp$fromIndex;
                len = $temp$len;
                nodeList = $temp$nodeList;
                tail = $temp$tail;
                continue initializeHelp;
            }
        }
    }, $elm$core$Array$initializeHelp = F5($elm$core$Array$initializeHelp_fn);
    var $elm$core$Basics$remainderBy = _Basics_remainderBy;
    var $elm$core$Array$initialize_fn = function (len, fn) {
        if (len <= 0) {
            return $elm$core$Array$empty;
        }
        else {
            var tailLen = len % $elm$core$Array$branchFactor;
            var tail = _JsArray_initialize_fn(tailLen, len - tailLen, fn);
            var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
            return $elm$core$Array$initializeHelp_fn(fn, initialFromIndex, len, _List_Nil, tail);
        }
    }, $elm$core$Array$initialize = F2($elm$core$Array$initialize_fn);
    var $elm$core$Basics$True = 0;
    var $elm$core$Result$isOk = function (result) {
        if (!result.$) {
            return true;
        }
        else {
            return false;
        }
    };
    var $elm$json$Json$Decode$andThen = _Json_andThen;
    var $elm$json$Json$Decode$map = _Json_map1;
    var $elm$json$Json$Decode$map2 = _Json_map2;
    var $elm$json$Json$Decode$succeed = _Json_succeed;
    var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
        switch (handler.$) {
            case 0:
                return 0;
            case 1:
                return 1;
            case 2:
                return 2;
            default:
                return 3;
        }
    };
    var $elm$browser$Browser$External = function (a) {
        return { $: 1, a: a };
    };
    var $elm$browser$Browser$Internal = function (a) {
        return { $: 0, a: a };
    };
    var $elm$core$Basics$identity = function (x) {
        return x;
    };
    var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
    var $elm$url$Url$Http = 0;
    var $elm$url$Url$Https = 1;
    var $elm$url$Url$Url_fn = function (protocol, host, port_, path, query, fragment) {
        return { bQ: fragment, bU: host, b3: path, b5: port_, b9: protocol, ca: query };
    }, $elm$url$Url$Url = F6($elm$url$Url$Url_fn);
    var $elm$core$String$contains = _String_contains;
    var $elm$core$String$length = _String_length;
    var $elm$core$String$slice = _String_slice;
    var $elm$core$String$dropLeft_fn = function (n, string) {
        return (n < 1) ? string : _String_slice_fn(n, $elm$core$String$length(string), string);
    }, $elm$core$String$dropLeft = F2($elm$core$String$dropLeft_fn);
    var $elm$core$String$indexes = _String_indexes;
    var $elm$core$String$isEmpty = function (string) {
        return string === "";
    };
    var $elm$core$String$left_fn = function (n, string) {
        return (n < 1) ? "" : _String_slice_fn(0, n, string);
    }, $elm$core$String$left = F2($elm$core$String$left_fn);
    var $elm$core$String$toInt = _String_toInt;
    var $elm$url$Url$chompBeforePath_fn = function (protocol, path, params, frag, str) {
        if ($elm$core$String$isEmpty(str) || _String_contains_fn("@", str)) {
            return $elm$core$Maybe$Nothing;
        }
        else {
            var _v0 = _String_indexes_fn(":", str);
            if (!_v0.b) {
                return $elm$core$Maybe$Just($elm$url$Url$Url_fn(protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
            }
            else {
                if (!_v0.b.b) {
                    var i = _v0.a;
                    var _v1 = $elm$core$String$toInt($elm$core$String$dropLeft_fn(i + 1, str));
                    if (_v1.$ === 1) {
                        return $elm$core$Maybe$Nothing;
                    }
                    else {
                        var port_ = _v1;
                        return $elm$core$Maybe$Just($elm$url$Url$Url_fn(protocol, $elm$core$String$left_fn(i, str), port_, path, params, frag));
                    }
                }
                else {
                    return $elm$core$Maybe$Nothing;
                }
            }
        }
    }, $elm$url$Url$chompBeforePath = F5($elm$url$Url$chompBeforePath_fn);
    var $elm$url$Url$chompBeforeQuery_fn = function (protocol, params, frag, str) {
        if ($elm$core$String$isEmpty(str)) {
            return $elm$core$Maybe$Nothing;
        }
        else {
            var _v0 = _String_indexes_fn("/", str);
            if (!_v0.b) {
                return $elm$url$Url$chompBeforePath_fn(protocol, "/", params, frag, str);
            }
            else {
                var i = _v0.a;
                return $elm$url$Url$chompBeforePath_fn(protocol, $elm$core$String$dropLeft_fn(i, str), params, frag, $elm$core$String$left_fn(i, str));
            }
        }
    }, $elm$url$Url$chompBeforeQuery = F4($elm$url$Url$chompBeforeQuery_fn);
    var $elm$url$Url$chompBeforeFragment_fn = function (protocol, frag, str) {
        if ($elm$core$String$isEmpty(str)) {
            return $elm$core$Maybe$Nothing;
        }
        else {
            var _v0 = _String_indexes_fn("?", str);
            if (!_v0.b) {
                return $elm$url$Url$chompBeforeQuery_fn(protocol, $elm$core$Maybe$Nothing, frag, str);
            }
            else {
                var i = _v0.a;
                return $elm$url$Url$chompBeforeQuery_fn(protocol, $elm$core$Maybe$Just($elm$core$String$dropLeft_fn(i + 1, str)), frag, $elm$core$String$left_fn(i, str));
            }
        }
    }, $elm$url$Url$chompBeforeFragment = F3($elm$url$Url$chompBeforeFragment_fn);
    var $elm$url$Url$chompAfterProtocol_fn = function (protocol, str) {
        if ($elm$core$String$isEmpty(str)) {
            return $elm$core$Maybe$Nothing;
        }
        else {
            var _v0 = _String_indexes_fn("#", str);
            if (!_v0.b) {
                return $elm$url$Url$chompBeforeFragment_fn(protocol, $elm$core$Maybe$Nothing, str);
            }
            else {
                var i = _v0.a;
                return $elm$url$Url$chompBeforeFragment_fn(protocol, $elm$core$Maybe$Just($elm$core$String$dropLeft_fn(i + 1, str)), $elm$core$String$left_fn(i, str));
            }
        }
    }, $elm$url$Url$chompAfterProtocol = F2($elm$url$Url$chompAfterProtocol_fn);
    var $elm$core$String$startsWith = _String_startsWith;
    var $elm$url$Url$fromString = function (str) {
        return _String_startsWith_fn("http://", str) ? $elm$url$Url$chompAfterProtocol_fn(0, $elm$core$String$dropLeft_fn(7, str)) : (_String_startsWith_fn("https://", str) ? $elm$url$Url$chompAfterProtocol_fn(1, $elm$core$String$dropLeft_fn(8, str)) : $elm$core$Maybe$Nothing);
    };
    var $elm$core$Basics$never = function (_v0) {
        never: while (true) {
            var nvr = _v0;
            var $temp$_v0 = nvr;
            _v0 = $temp$_v0;
            continue never;
        }
    };
    var $elm$core$Task$Perform = $elm$core$Basics$identity;
    var $elm$core$Task$succeed = _Scheduler_succeed;
    var $elm$core$Task$init = $elm$core$Task$succeed(0);
    var $elm$core$List$foldrHelper_fn = function (fn, acc, ctr, ls) {
        if (!ls.b) {
            return acc;
        }
        else {
            var a = ls.a;
            var r1 = ls.b;
            if (!r1.b) {
                return A2(fn, a, acc);
            }
            else {
                var b = r1.a;
                var r2 = r1.b;
                if (!r2.b) {
                    return A2(fn, a, A2(fn, b, acc));
                }
                else {
                    var c = r2.a;
                    var r3 = r2.b;
                    if (!r3.b) {
                        return A2(fn, a, A2(fn, b, A2(fn, c, acc)));
                    }
                    else {
                        var d = r3.a;
                        var r4 = r3.b;
                        var res = (ctr > 500) ? $elm$core$List$foldl_fn(fn, acc, $elm$core$List$reverse(r4)) : $elm$core$List$foldrHelper_fn(fn, acc, ctr + 1, r4);
                        return A2(fn, a, A2(fn, b, A2(fn, c, A2(fn, d, res))));
                    }
                }
            }
        }
    }, $elm$core$List$foldrHelper_fn_unwrapped = function (fn, acc, ctr, ls) {
        if (!ls.b) {
            return acc;
        }
        else {
            var a = ls.a;
            var r1 = ls.b;
            if (!r1.b) {
                return fn(a, acc);
            }
            else {
                var b = r1.a;
                var r2 = r1.b;
                if (!r2.b) {
                    return fn(a, fn(b, acc));
                }
                else {
                    var c = r2.a;
                    var r3 = r2.b;
                    if (!r3.b) {
                        return fn(a, fn(b, fn(c, acc)));
                    }
                    else {
                        var d = r3.a;
                        var r4 = r3.b;
                        var res = (ctr > 500) ? $elm$core$List$foldl_fn_unwrapped(fn, acc, $elm$core$List$reverse(r4)) : $elm$core$List$foldrHelper_fn_unwrapped(fn, acc, ctr + 1, r4);
                        return fn(a, fn(b, fn(c, fn(d, res))));
                    }
                }
            }
        }
    }, $elm$core$List$foldrHelper = F4($elm$core$List$foldrHelper_fn);
    var $elm$core$List$foldr_fn = function (fn, acc, ls) {
        return $elm$core$List$foldrHelper_fn(fn, acc, 0, ls);
    }, $elm$core$List$foldr = F3($elm$core$List$foldr_fn);
    var $elm$core$List$map_fn = function (f, xs) {
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (; xs.b; xs
            = xs.b) {
            var next = _List_Cons(f(xs.a), _List_Nil);
            end.b = next;
            end = next;
        }
        return tmp.b;
    }, $elm$core$List$map = F2($elm$core$List$map_fn);
    var $elm$core$Task$andThen = _Scheduler_andThen;
    var $elm$core$Task$map_fn = function (func, taskA) {
        return _Scheduler_andThen_fn(function (a) {
            return $elm$core$Task$succeed(func(a));
        }, taskA);
    }, $elm$core$Task$map = F2($elm$core$Task$map_fn);
    var $elm$core$Task$map2_fn = function (func, taskA, taskB) {
        return _Scheduler_andThen_fn(function (a) {
            return _Scheduler_andThen_fn(function (b) {
                return $elm$core$Task$succeed(A2(func, a, b));
            }, taskB);
        }, taskA);
    }, $elm$core$Task$map2_fn_unwrapped = function (func, taskA, taskB) {
        return _Scheduler_andThen_fn(function (a) {
            return _Scheduler_andThen_fn(function (b) {
                return $elm$core$Task$succeed(func(a, b));
            }, taskB);
        }, taskA);
    }, $elm$core$Task$map2 = F3($elm$core$Task$map2_fn);
    var $elm$core$Task$sequence = function (tasks) {
        return $elm$core$List$foldr_fn($elm$core$Task$map2($elm$core$List$cons), $elm$core$Task$succeed(_List_Nil), tasks);
    };
    var $elm$core$Platform$sendToApp = _Platform_sendToApp;
    var $elm$core$Task$spawnCmd_fn = function (router, _v0) {
        var task = _v0;
        return _Scheduler_spawn(_Scheduler_andThen_fn($elm$core$Platform$sendToApp(router), task));
    }, $elm$core$Task$spawnCmd = F2($elm$core$Task$spawnCmd_fn);
    var $elm$core$Task$onEffects_fn = function (router, commands, state) {
        return $elm$core$Task$map_fn(function (_v0) {
            return 0;
        }, $elm$core$Task$sequence($elm$core$List$map_fn($elm$core$Task$spawnCmd(router), commands)));
    }, $elm$core$Task$onEffects = F3($elm$core$Task$onEffects_fn);
    var $elm$core$Task$onSelfMsg_fn = function (_v0, _v1, _v2) {
        return $elm$core$Task$succeed(0);
    }, $elm$core$Task$onSelfMsg = F3($elm$core$Task$onSelfMsg_fn);
    var $elm$core$Task$cmdMap_fn = function (tagger, _v0) {
        var task = _v0;
        return $elm$core$Task$map_fn(tagger, task);
    }, $elm$core$Task$cmdMap = F2($elm$core$Task$cmdMap_fn);
    _Platform_effectManagers["Task"] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
    var $elm$core$Task$command = _Platform_leaf("Task");
    var $elm$core$Task$perform_fn = function (toMessage, task) {
        return $elm$core$Task$command($elm$core$Task$map_fn(toMessage, task));
    }, $elm$core$Task$perform = F2($elm$core$Task$perform_fn);
    var $elm$browser$Browser$application = _Browser_application;
    var $elm$core$Basics$composeR_fn = function (f, g, x) {
        return g(f(x));
    }, $elm$core$Basics$composeR = F3($elm$core$Basics$composeR_fn);
    var $author$project$Main$Model$getSession = function (session) {
        if (session.$ === 3) {
            var session_ = session.a;
            return $elm$core$Maybe$Just(session_);
        }
        else {
            return $elm$core$Maybe$Nothing;
        }
    };
    var $dzuk_mutant$elm_css$Css$Value$Value = $elm$core$Basics$identity;
    var $dzuk_mutant$elm_css$Css$column = "column";
    var $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute = F3($dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn);
    var $elm$virtual_dom$VirtualDom$attribute_fn = function (key, value) {
        return _VirtualDom_attribute_fn(_VirtualDom_noOnOrFormAction(key), _VirtualDom_noJavaScriptOrHtmlUri(value));
    }, $elm$virtual_dom$VirtualDom$attribute = F2($elm$virtual_dom$VirtualDom$attribute_fn);
    var $elm$core$List$any_fn = function (isOkay, list) {
        any: while (true) {
            if (!list.b) {
                return false;
            }
            else {
                var x = list.a;
                var xs = list.b;
                if (isOkay(x)) {
                    return true;
                }
                else {
                    var $temp$isOkay = isOkay, $temp$list = xs;
                    isOkay = $temp$isOkay;
                    list = $temp$list;
                    continue any;
                }
            }
        }
    }, $elm$core$List$any = F2($elm$core$List$any_fn);
    var $elm$core$Basics$composeL_fn = function (g, f, x) {
        return g(f(x));
    }, $elm$core$Basics$composeL = F3($elm$core$Basics$composeL_fn);
    var $elm$core$Basics$not = _Basics_not;
    var $elm$core$List$all_fn = function (isOkay, list) {
        all: while (true) {
            if (!list.b) {
                return true;
            }
            else {
                var x = list.a;
                if (!isOkay(x)) {
                    return false;
                }
                list = list.b;
                continue all;
            }
        }
    }, $elm$core$List$all = F2($elm$core$List$all_fn);
    var $elm$core$Dict$Black = 1;
    var $elm$core$Dict$RBNode_elm_builtin_fn = function (a, b, c, d, e) {
        return { $: -1, a: a, b: b, c: c, d: d, e: e };
    }, $elm$core$Dict$RBNode_elm_builtin = F5($elm$core$Dict$RBNode_elm_builtin_fn);
    var $elm$core$Dict$RBEmpty_elm_builtin = { $: -2 };
    var $elm$core$Dict$Red = 0;
    var $elm$core$Dict$balance_fn = function (color, key, value, left, right) {
        if ((right.$ === -1) && (!right.a)) {
            var _v1 = right.a;
            var rK = right.b;
            var rV = right.c;
            var rLeft = right.d;
            var rRight = right.e;
            if ((left.$ === -1) && (!left.a)) {
                var _v3 = left.a;
                var lK = left.b;
                var lV = left.c;
                var lLeft = left.d;
                var lRight = left.e;
                return $elm$core$Dict$RBNode_elm_builtin_fn(0, key, value, $elm$core$Dict$RBNode_elm_builtin_fn(1, lK, lV, lLeft, lRight), $elm$core$Dict$RBNode_elm_builtin_fn(1, rK, rV, rLeft, rRight));
            }
            else {
                return $elm$core$Dict$RBNode_elm_builtin_fn(color, rK, rV, $elm$core$Dict$RBNode_elm_builtin_fn(0, key, value, left, rLeft), rRight);
            }
        }
        else {
            if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
                var _v5 = left.a;
                var lK = left.b;
                var lV = left.c;
                var _v6 = left.d;
                var _v7 = _v6.a;
                var llK = _v6.b;
                var llV = _v6.c;
                var llLeft = _v6.d;
                var llRight = _v6.e;
                var lRight = left.e;
                return $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, $elm$core$Dict$RBNode_elm_builtin_fn(1, llK, llV, llLeft, llRight), $elm$core$Dict$RBNode_elm_builtin_fn(1, key, value, lRight, right));
            }
            else {
                return $elm$core$Dict$RBNode_elm_builtin_fn(color, key, value, left, right);
            }
        }
    }, $elm$core$Dict$balance = F5($elm$core$Dict$balance_fn);
    var $elm$core$Basics$compare = _Utils_compare;
    var $elm$core$Dict$insertHelp_fn = function (key, value, dict) {
        if (dict.$ === -2) {
            return $elm$core$Dict$RBNode_elm_builtin_fn(0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
        }
        else {
            var nColor = dict.a;
            var nKey = dict.b;
            var nValue = dict.c;
            var nLeft = dict.d;
            var nRight = dict.e;
            var _v1 = _Utils_compare_fn(key, nKey);
            switch (_v1) {
                case 0:
                    return $elm$core$Dict$balance_fn(nColor, nKey, nValue, $elm$core$Dict$insertHelp_fn(key, value, nLeft), nRight);
                case 1:
                    return $elm$core$Dict$RBNode_elm_builtin_fn(nColor, nKey, value, nLeft, nRight);
                default:
                    return $elm$core$Dict$balance_fn(nColor, nKey, nValue, nLeft, $elm$core$Dict$insertHelp_fn(key, value, nRight));
            }
        }
    }, $elm$core$Dict$insertHelp = F3($elm$core$Dict$insertHelp_fn);
    var $elm$core$Dict$insert_fn = function (key, value, dict) {
        var _v0 = $elm$core$Dict$insertHelp_fn(key, value, dict);
        if ((_v0.$ === -1) && (!_v0.a)) {
            var _v1 = _v0.a;
            var k = _v0.b;
            var v = _v0.c;
            var l = _v0.d;
            var r = _v0.e;
            return $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, l, r);
        }
        else {
            var x = _v0;
            return x;
        }
    }, $elm$core$Dict$insert = F3($elm$core$Dict$insert_fn);
    var $elm$core$List$isEmpty = function (xs) {
        if (!xs.b) {
            return true;
        }
        else {
            return false;
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$compactHelp_fn = function (declaration, _v0) {
        var keyframesByName = _v0.a;
        var declarations = _v0.b;
        switch (declaration.$) {
            case 0:
                var _v2 = declaration.a;
                var properties = _v2.c;
                return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 1:
                var styleBlocks = declaration.b;
                return $elm$core$List$all_fn(function (_v3) {
                    var properties = _v3.c;
                    return $elm$core$List$isEmpty(properties);
                }, styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 2:
                var otherDeclarations = declaration.b;
                return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 3:
                return _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 4:
                var properties = declaration.a;
                return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 5:
                var properties = declaration.a;
                return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 6:
                var record = declaration.a;
                return $elm$core$String$isEmpty(record.c8) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2($elm$core$Dict$insert_fn(record.dS, record.c8, keyframesByName), declarations);
            case 7:
                var properties = declaration.a;
                return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            case 8:
                var properties = declaration.a;
                return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
            default:
                var tuples = declaration.a;
                return $elm$core$List$all_fn(function (_v4) {
                    var properties = _v4.b;
                    return $elm$core$List$isEmpty(properties);
                }, tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(keyframesByName, _List_Cons(declaration, declarations));
        }
    }, $dzuk_mutant$elm_css$Css$Structure$compactHelp = F2($dzuk_mutant$elm_css$Css$Structure$compactHelp_fn);
    var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
    var $dzuk_mutant$elm_css$Css$Structure$Keyframes = function (a) {
        return { $: 6, a: a };
    };
    var $elm$core$List$append_fn = function (xs, ys) {
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (; xs.b; xs = xs.b) {
            var next = _List_Cons(xs.a, _List_Nil);
            end.b = next;
            end = next;
        }
        end.b = ys;
        return tmp.b;
    }, $elm$core$List$append = F2($elm$core$List$append_fn);
    var $dzuk_mutant$elm_css$Css$Structure$withKeyframeDeclarations_fn = function (keyframesByName, compactedDeclarations) {
        return $elm$core$List$append_fn($elm$core$List$map_fn(function (_v0) {
            var name = _v0.a;
            var decl = _v0.b;
            return $dzuk_mutant$elm_css$Css$Structure$Keyframes({ c8: decl, dS: name });
        }, $elm$core$Dict$toList(keyframesByName)), compactedDeclarations);
    }, $dzuk_mutant$elm_css$Css$Structure$withKeyframeDeclarations = F2($dzuk_mutant$elm_css$Css$Structure$withKeyframeDeclarations_fn);
    var $dzuk_mutant$elm_css$Css$Structure$compactDeclarations = function (declarations) {
        var _v0 = $elm$core$List$foldr_fn($dzuk_mutant$elm_css$Css$Structure$compactHelp, _Utils_Tuple2($elm$core$Dict$empty, _List_Nil), declarations);
        var keyframesByName = _v0.a;
        var compactedDeclarations = _v0.b;
        return $dzuk_mutant$elm_css$Css$Structure$withKeyframeDeclarations_fn(keyframesByName, compactedDeclarations);
    };
    var $dzuk_mutant$elm_css$Css$Structure$compactStylesheet = function (_v0) {
        var charset = _v0.bx;
        var imports = _v0.bW;
        var namespaces = _v0.b0;
        var declarations = _v0.c9;
        return {
            bx: charset,
            c9: $dzuk_mutant$elm_css$Css$Structure$compactDeclarations(declarations),
            bW: imports,
            b0: namespaces
        };
    };
    var $elm$core$Maybe$map_fn = function (f, maybe) {
        if (!maybe.$) {
            var value = maybe.a;
            return $elm$core$Maybe$Just(f(value));
        }
        else {
            return $elm$core$Maybe$Nothing;
        }
    }, $elm$core$Maybe$map = F2($elm$core$Maybe$map_fn);
    var $elm$core$Maybe$withDefault_fn = function (_default, maybe) {
        if (!maybe.$) {
            var value = maybe.a;
            return value;
        }
        else {
            return _default;
        }
    }, $elm$core$Maybe$withDefault = F2($elm$core$Maybe$withDefault_fn);
    var $dzuk_mutant$elm_css$Css$Structure$Output$charsetToString = function (charset) {
        return $elm$core$Maybe$withDefault_fn("", $elm$core$Maybe$map_fn(function (str) {
            return "@charset \"" + (str + "\"");
        }, charset));
    };
    var $dzuk_mutant$elm_css$Css$String$mapJoinHelp_fn = function (map, sep, strs, result) {
        mapJoinHelp: while (true) {
            if (!strs.b) {
                return result;
            }
            else {
                if (!strs.b.b) {
                    var first = strs.a;
                    return result + (map(first) + "");
                }
                else {
                    var first = strs.a;
                    var rest = strs.b;
                    var $temp$map = map, $temp$sep = sep, $temp$strs = rest, $temp$result = result + (map(first) + (sep + ""));
                    map = $temp$map;
                    sep = $temp$sep;
                    strs = $temp$strs;
                    result = $temp$result;
                    continue mapJoinHelp;
                }
            }
        }
    }, $dzuk_mutant$elm_css$Css$String$mapJoinHelp = F4($dzuk_mutant$elm_css$Css$String$mapJoinHelp_fn);
    var $dzuk_mutant$elm_css$Css$String$mapJoin_fn = function (map, sep, strs) {
        return $dzuk_mutant$elm_css$Css$String$mapJoinHelp_fn(map, sep, strs, "");
    }, $dzuk_mutant$elm_css$Css$String$mapJoin = F3($dzuk_mutant$elm_css$Css$String$mapJoin_fn);
    var $dzuk_mutant$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
        return "(" + (expression.bN + ($elm$core$Maybe$withDefault_fn("", $elm$core$Maybe$map_fn($elm$core$Basics$append(": "), expression.az)) + ")"));
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
        switch (mediaType) {
            case 0:
                return "print";
            case 1:
                return "screen";
            default:
                return "speech";
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
        var prefixWith = F3(function (str, mediaType, expressions) {
            return str + (" " + $elm$core$String$join_fn(" and ", _List_Cons($dzuk_mutant$elm_css$Css$Structure$Output$mediaTypeToString(mediaType), $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
        });
        switch (mediaQuery.$) {
            case 0:
                var expressions = mediaQuery.a;
                return $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$mediaExpressionToString, " and ", expressions);
            case 1:
                var mediaType = mediaQuery.a;
                var expressions = mediaQuery.b;
                return A3(prefixWith, "only", mediaType, expressions);
            case 2:
                var mediaType = mediaQuery.a;
                var expressions = mediaQuery.b;
                return A3(prefixWith, "not", mediaType, expressions);
            default:
                var str = mediaQuery.a;
                return str;
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$importMediaQueryToString_fn = function (name, mediaQuery) {
        return "@import \"" + (name + ($dzuk_mutant$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + "\""));
    }, $dzuk_mutant$elm_css$Css$Structure$Output$importMediaQueryToString = F2($dzuk_mutant$elm_css$Css$Structure$Output$importMediaQueryToString_fn);
    var $dzuk_mutant$elm_css$Css$Structure$Output$importToString = function (_v0) {
        var name = _v0.a;
        var mediaQueries = _v0.b;
        return $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$importMediaQueryToString(name), "\n", mediaQueries);
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
        var prefix = _v0.a;
        var str = _v0.b;
        return "@namespace " + (prefix + ("\"" + (str + "\"")));
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$emitProperties = function (properties) {
        return $dzuk_mutant$elm_css$Css$String$mapJoin_fn(function (_v0) {
            var prop = _v0;
            return prop + ";";
        }, "", properties);
    };
    var $elm$core$String$append = _String_append;
    var $dzuk_mutant$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
        var str = _v0;
        return "::" + str;
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
        switch (combinator) {
            case 0:
                return "+";
            case 1:
                return "~";
            case 2:
                return ">";
            default:
                return "";
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
        switch (repeatableSimpleSelector.$) {
            case 0:
                var str = repeatableSimpleSelector.a;
                return "." + str;
            case 1:
                var str = repeatableSimpleSelector.a;
                return "#" + str;
            case 2:
                var str = repeatableSimpleSelector.a;
                return ":" + str;
            default:
                var str = repeatableSimpleSelector.a;
                return "[" + (str + "]");
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
        switch (simpleSelectorSequence.$) {
            case 0:
                var str = simpleSelectorSequence.a;
                var repeatableSimpleSelectors = simpleSelectorSequence.b;
                return _Utils_ap(str, $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, "", repeatableSimpleSelectors));
            case 1:
                var repeatableSimpleSelectors = simpleSelectorSequence.a;
                return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? "*" : $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, "", repeatableSimpleSelectors);
            default:
                var str = simpleSelectorSequence.a;
                var repeatableSimpleSelectors = simpleSelectorSequence.b;
                return _Utils_ap(str, $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, "", repeatableSimpleSelectors));
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
        var combinator = _v0.a;
        var sequence = _v0.b;
        return $dzuk_mutant$elm_css$Css$Structure$Output$combinatorToString(combinator) + (" " + $dzuk_mutant$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence));
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
        var simpleSelectorSequence = _v0.a;
        var chain = _v0.b;
        var pseudoElement = _v0.c;
        var segments = _List_Cons($dzuk_mutant$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence), $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Structure$Output$selectorChainToString, chain));
        var pseudoElementsString = $elm$core$Maybe$withDefault_fn("", $elm$core$Maybe$map_fn($dzuk_mutant$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement));
        return _String_append_fn($elm$core$String$join_fn(" ", segments), pseudoElementsString);
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$prettyPrintStyleBlock = function (_v0) {
        var firstSelector = _v0.a;
        var otherSelectors = _v0.b;
        var properties = _v0.c;
        var selectorStr = $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$selectorToString, ",", _List_Cons(firstSelector, otherSelectors));
        return selectorStr + ("{" + ($dzuk_mutant$elm_css$Css$Structure$Output$emitProperties(properties) + "}"));
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
        switch (decl.$) {
            case 0:
                var styleBlock = decl.a;
                return $dzuk_mutant$elm_css$Css$Structure$Output$prettyPrintStyleBlock(styleBlock);
            case 1:
                var mediaQueries = decl.a;
                var styleBlocks = decl.b;
                var query = $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$mediaQueryToString, ", ", mediaQueries);
                var blocks = $dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$prettyPrintStyleBlock, "\n", styleBlocks);
                return "@media " + (query + ("{" + (blocks + "}")));
            case 2:
                return "TODO";
            case 3:
                return "TODO";
            case 4:
                return "TODO";
            case 5:
                return "TODO";
            case 6:
                var name = decl.a.dS;
                var declaration = decl.a.c8;
                return "@keyframes " + (name + ("{" + (declaration + "}")));
            case 7:
                return "TODO";
            case 8:
                return "TODO";
            default:
                return "TODO";
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
        var charset = _v0.bx;
        var imports = _v0.bW;
        var namespaces = _v0.b0;
        var declarations = _v0.c9;
        return $dzuk_mutant$elm_css$Css$Structure$Output$charsetToString(charset) + ($dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$importToString, "\n", imports) + ($dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$namespaceToString, "\n", namespaces) + ($dzuk_mutant$elm_css$Css$String$mapJoin_fn($dzuk_mutant$elm_css$Css$Structure$Output$prettyPrintDeclaration, "\n", declarations) + "")));
    };
    var $elm$core$List$concat = function (lists) {
        if (!lists.b) {
            return _List_Nil;
        }
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (; lists.b.b; lists = lists.b) {
            var xs = lists.a;
            for (; xs.b; xs = xs.b) {
                var next = _List_Cons(xs.a, _List_Nil);
                end.b = next;
                end = next;
            }
        }
        end.b = lists.a;
        return tmp.b;
    };
    var $elm$core$List$concatMap_fn = function (f, lists) {
        if (!lists.b) {
            return _List_Nil;
        }
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (; lists.b.
            b; lists = lists.b) {
            var xs = f(lists.a);
            for (; xs.b; xs = xs.b) {
                var next = _List_Cons(xs.a, _List_Nil);
                end.b = next;
                end = next;
            }
        }
        end.b = f(lists.a);
        return tmp.b;
    }, $elm$core$List$concatMap = F2($elm$core$List$concatMap_fn);
    var $dzuk_mutant$elm_css$Css$Structure$CounterStyle = function (a) {
        return { $: 8, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Structure$FontFace = function (a) {
        return { $: 5, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Structure$PageRule = function (a) {
        return { $: 4, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Structure$Property = $elm$core$Basics$identity;
    var $dzuk_mutant$elm_css$Css$Structure$Selector_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dzuk_mutant$elm_css$Css$Structure$Selector = F3($dzuk_mutant$elm_css$Css$Structure$Selector_fn);
    var $dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dzuk_mutant$elm_css$Css$Structure$StyleBlock = F3($dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn);
    var $dzuk_mutant$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
        return { $: 0, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Structure$SupportsRule_fn = function (a, b) {
        return { $: 2, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Structure$SupportsRule = F2($dzuk_mutant$elm_css$Css$Structure$SupportsRule_fn);
    var $dzuk_mutant$elm_css$Css$Structure$Viewport = function (a) {
        return { $: 7, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Structure$MediaRule = F2($dzuk_mutant$elm_css$Css$Structure$MediaRule_fn);
    var $dzuk_mutant$elm_css$Css$Structure$mapLast_fn = function (update, list) {
        if (!list.b) {
            return list;
        }
        else {
            if (!list.b.b) {
                var only = list.a;
                return _List_fromArray([
                    update(only)
                ]);
            }
            else {
                var first = list.a;
                var rest = list.b;
                return _List_Cons(first, $dzuk_mutant$elm_css$Css$Structure$mapLast_fn(update, rest));
            }
        }
    }, $dzuk_mutant$elm_css$Css$Structure$mapLast = F2($dzuk_mutant$elm_css$Css$Structure$mapLast_fn);
    var $dzuk_mutant$elm_css$Css$Structure$withPropertyAppended_fn = function (property, _v0) {
        var firstSelector = _v0.a;
        var otherSelectors = _v0.b;
        var properties = _v0.c;
        return $dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(firstSelector, otherSelectors, _Utils_ap(properties, _List_fromArray([property])));
    }, $dzuk_mutant$elm_css$Css$Structure$withPropertyAppended = F2($dzuk_mutant$elm_css$Css$Structure$withPropertyAppended_fn);
    var $dzuk_mutant$elm_css$Css$Structure$appendProperty_fn = function (property, declarations) {
        if (!declarations.b) {
            return declarations;
        }
        else {
            if (!declarations.b.b) {
                switch (declarations.a.$) {
                    case 0:
                        var styleBlock = declarations.a.a;
                        return _List_fromArray([
                            $dzuk_mutant$elm_css$Css$Structure$StyleBlockDeclaration($dzuk_mutant$elm_css$Css$Structure$withPropertyAppended_fn(property, styleBlock))
                        ]);
                    case 1:
                        var _v1 = declarations.a;
                        var mediaQueries = _v1.a;
                        var styleBlocks = _v1.b;
                        return _List_fromArray([
                            $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(mediaQueries, $dzuk_mutant$elm_css$Css$Structure$mapLast_fn($dzuk_mutant$elm_css$Css$Structure$withPropertyAppended(property), styleBlocks))
                        ]);
                    default:
                        return declarations;
                }
            }
            else {
                var first = declarations.a;
                var rest = declarations.b;
                return _List_Cons(first, $dzuk_mutant$elm_css$Css$Structure$appendProperty_fn(property, rest));
            }
        }
    }, $dzuk_mutant$elm_css$Css$Structure$appendProperty = F2($dzuk_mutant$elm_css$Css$Structure$appendProperty_fn);
    var $dzuk_mutant$elm_css$Css$Structure$appendToLastSelector_fn = function (f, styleBlock) {
        if (!styleBlock.b.b) {
            var only = styleBlock.a;
            var properties = styleBlock.c;
            return _List_fromArray([
                $dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(only, _List_Nil, properties),
                $dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(f(only), _List_Nil, _List_Nil)
            ]);
        }
        else {
            var first = styleBlock.a;
            var rest = styleBlock.b;
            var properties = styleBlock.c;
            var newRest = $elm$core$List$map_fn(f, rest);
            var newFirst = f(first);
            return _List_fromArray([
                $dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(first, rest, properties),
                $dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(newFirst, newRest, _List_Nil)
            ]);
        }
    }, $dzuk_mutant$elm_css$Css$Structure$appendToLastSelector = F2($dzuk_mutant$elm_css$Css$Structure$appendToLastSelector_fn);
    var $dzuk_mutant$elm_css$Css$Structure$applyPseudoElement_fn = function (pseudo, _v0) {
        var sequence = _v0.a;
        var selectors = _v0.b;
        return $dzuk_mutant$elm_css$Css$Structure$Selector_fn(sequence, selectors, $elm$core$Maybe$Just(pseudo));
    }, $dzuk_mutant$elm_css$Css$Structure$applyPseudoElement = F2($dzuk_mutant$elm_css$Css$Structure$applyPseudoElement_fn);
    var $dzuk_mutant$elm_css$Css$Structure$appendPseudoElementToLastSelector_fn = function (pseudo, styleBlock) {
        return $dzuk_mutant$elm_css$Css$Structure$appendToLastSelector_fn($dzuk_mutant$elm_css$Css$Structure$applyPseudoElement(pseudo), styleBlock);
    }, $dzuk_mutant$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2($dzuk_mutant$elm_css$Css$Structure$appendPseudoElementToLastSelector_fn);
    var $dzuk_mutant$elm_css$Css$Structure$CustomSelector_fn = function (a, b) {
        return { $: 2, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Structure$CustomSelector = F2($dzuk_mutant$elm_css$Css$Structure$CustomSelector_fn);
    var $dzuk_mutant$elm_css$Css$Structure$TypeSelectorSequence_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Structure$TypeSelectorSequence = F2($dzuk_mutant$elm_css$Css$Structure$TypeSelectorSequence_fn);
    var $dzuk_mutant$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
        return { $: 1, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Structure$appendRepeatable_fn = function (selector, sequence) {
        switch (sequence.$) {
            case 0:
                var typeSelector = sequence.a;
                var list = sequence.b;
                return $dzuk_mutant$elm_css$Css$Structure$TypeSelectorSequence_fn(typeSelector, _Utils_ap(list, _List_fromArray([selector])));
            case 1:
                var list = sequence.a;
                return $dzuk_mutant$elm_css$Css$Structure$UniversalSelectorSequence(_Utils_ap(list, _List_fromArray([selector])));
            default:
                var str = sequence.a;
                var list = sequence.b;
                return $dzuk_mutant$elm_css$Css$Structure$CustomSelector_fn(str, _Utils_ap(list, _List_fromArray([selector])));
        }
    }, $dzuk_mutant$elm_css$Css$Structure$appendRepeatable = F2($dzuk_mutant$elm_css$Css$Structure$appendRepeatable_fn);
    var $dzuk_mutant$elm_css$Css$Structure$appendRepeatableWithCombinator_fn = function (selector, list) {
        if (!list.b) {
            return _List_Nil;
        }
        else {
            if (!list.b.b) {
                var _v1 = list.a;
                var combinator = _v1.a;
                var sequence = _v1.b;
                return _List_fromArray([
                    _Utils_Tuple2(combinator, $dzuk_mutant$elm_css$Css$Structure$appendRepeatable_fn(selector, sequence))
                ]);
            }
            else {
                var first = list.a;
                var rest = list.b;
                return _List_Cons(first, $dzuk_mutant$elm_css$Css$Structure$appendRepeatableWithCombinator_fn(selector, rest));
            }
        }
    }, $dzuk_mutant$elm_css$Css$Structure$appendRepeatableWithCombinator = F2($dzuk_mutant$elm_css$Css$Structure$appendRepeatableWithCombinator_fn);
    var $dzuk_mutant$elm_css$Css$Structure$appendRepeatableSelector_fn = function (repeatableSimpleSelector, selector) {
        if (!selector.b.b) {
            var sequence = selector.a;
            var pseudoElement = selector.c;
            return $dzuk_mutant$elm_css$Css$Structure$Selector_fn($dzuk_mutant$elm_css$Css$Structure$appendRepeatable_fn(repeatableSimpleSelector, sequence), _List_Nil, pseudoElement);
        }
        else {
            var firstSelector = selector.a;
            var tuples = selector.b;
            var pseudoElement = selector.c;
            return $dzuk_mutant$elm_css$Css$Structure$Selector_fn(firstSelector, $dzuk_mutant$elm_css$Css$Structure$appendRepeatableWithCombinator_fn(repeatableSimpleSelector, tuples), pseudoElement);
        }
    }, $dzuk_mutant$elm_css$Css$Structure$appendRepeatableSelector = F2($dzuk_mutant$elm_css$Css$Structure$appendRepeatableSelector_fn);
    var $dzuk_mutant$elm_css$Css$Structure$appendRepeatableToLastSelector_fn = function (selector, styleBlock) {
        return $dzuk_mutant$elm_css$Css$Structure$appendToLastSelector_fn($dzuk_mutant$elm_css$Css$Structure$appendRepeatableSelector(selector), styleBlock);
    }, $dzuk_mutant$elm_css$Css$Structure$appendRepeatableToLastSelector = F2($dzuk_mutant$elm_css$Css$Structure$appendRepeatableToLastSelector_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
        collectSelectors: while (true) {
            if (!declarations.b) {
                return _List_Nil;
            }
            else {
                if (!declarations.a.$) {
                    var _v1 = declarations.a.a;
                    var firstSelector = _v1.a;
                    var otherSelectors = _v1.b;
                    var rest = declarations.b;
                    return _Utils_ap(_List_Cons(firstSelector, otherSelectors), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
                }
                else {
                    var rest = declarations.b;
                    var $temp$declarations = rest;
                    declarations = $temp$declarations;
                    continue collectSelectors;
                }
            }
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$DocumentRule_fn = function (a, b, c, d, e) {
        return { $: 3, a: a, b: b, c: c, d: d, e: e };
    }, $dzuk_mutant$elm_css$Css$Structure$DocumentRule = F5($dzuk_mutant$elm_css$Css$Structure$DocumentRule_fn);
    var $dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock_fn = function (update, declarations) {
        _v0$12: while (true) {
            if (!declarations.b) {
                return declarations;
            }
            else {
                if (!declarations.b.b) {
                    switch (declarations.a.$) {
                        case 0:
                            var styleBlock = declarations.a.a;
                            return $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Structure$StyleBlockDeclaration, update(styleBlock));
                        case 1:
                            if (declarations.a.b.b) {
                                if (!declarations.a.b.b.b) {
                                    var _v1 = declarations.a;
                                    var mediaQueries = _v1.a;
                                    var _v2 = _v1.b;
                                    var styleBlock = _v2.a;
                                    return _List_fromArray([
                                        $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(mediaQueries, update(styleBlock))
                                    ]);
                                }
                                else {
                                    var _v3 = declarations.a;
                                    var mediaQueries = _v3.a;
                                    var _v4 = _v3.b;
                                    var first = _v4.a;
                                    var rest = _v4.b;
                                    var _v5 = $dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock_fn(update, _List_fromArray([
                                        $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(mediaQueries, rest)
                                    ]));
                                    if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
                                        var _v6 = _v5.a;
                                        var newMediaQueries = _v6.a;
                                        var newStyleBlocks = _v6.b;
                                        return _List_fromArray([
                                            $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(newMediaQueries, _List_Cons(first, newStyleBlocks))
                                        ]);
                                    }
                                    else {
                                        var newDeclarations = _v5;
                                        return newDeclarations;
                                    }
                                }
                            }
                            else {
                                break _v0$12;
                            }
                        case 2:
                            var _v7 = declarations.a;
                            var str = _v7.a;
                            var nestedDeclarations = _v7.b;
                            return _List_fromArray([
                                $dzuk_mutant$elm_css$Css$Structure$SupportsRule_fn(str, $dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock_fn(update, nestedDeclarations))
                            ]);
                        case 3:
                            var _v8 = declarations.a;
                            var str1 = _v8.a;
                            var str2 = _v8.b;
                            var str3 = _v8.c;
                            var str4 = _v8.d;
                            var styleBlock = _v8.e;
                            return $elm$core$List$map_fn(A4($dzuk_mutant$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4), update(styleBlock));
                        case 4:
                            return declarations;
                        case 5:
                            return declarations;
                        case 6:
                            return declarations;
                        case 7:
                            return declarations;
                        case 8:
                            return declarations;
                        default:
                            return declarations;
                    }
                }
                else {
                    break _v0$12;
                }
            }
        }
        var first = declarations.a;
        var rest = declarations.b;
        return _List_Cons(first, $dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock_fn(update, rest));
    }, $dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock = F2($dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock_fn);
    var $elm$core$String$cons = _String_cons;
    var $robinheghan$murmur3$Murmur3$HashData_fn = function (shift, seed, hash, charsProcessed) {
        return { S: charsProcessed, U: hash, M: seed, Z: shift };
    }, $robinheghan$murmur3$Murmur3$HashData = F4($robinheghan$murmur3$Murmur3$HashData_fn);
    var $robinheghan$murmur3$Murmur3$c1 = 3432918353;
    var $robinheghan$murmur3$Murmur3$c2 = 461845907;
    var $elm$core$Bitwise$and = _Bitwise_and;
    var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
    var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
    var $robinheghan$murmur3$Murmur3$multiplyBy_fn = function (b, a) {
        return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
    }, $robinheghan$murmur3$Murmur3$multiplyBy = F2($robinheghan$murmur3$Murmur3$multiplyBy_fn);
    var $elm$core$Basics$neq = _Utils_notEqual;
    var $elm$core$Bitwise$or = _Bitwise_or;
    var $robinheghan$murmur3$Murmur3$rotlBy_fn = function (b, a) {
        return (a << b) | (a >>> (32 - b));
    }, $robinheghan$murmur3$Murmur3$rotlBy = F2($robinheghan$murmur3$Murmur3$rotlBy_fn);
    var $elm$core$Bitwise$xor = _Bitwise_xor;
    var $robinheghan$murmur3$Murmur3$finalize = function (data) {
        var acc = (!(!data.U)) ? (data.M ^ $robinheghan$murmur3$Murmur3$multiplyBy_fn($robinheghan$murmur3$Murmur3$c2, $robinheghan$murmur3$Murmur3$rotlBy_fn(15, $robinheghan$murmur3$Murmur3$multiplyBy_fn($robinheghan$murmur3$Murmur3$c1, data.U)))) : data.M;
        var h0 = acc ^ data.S;
        var h1 = $robinheghan$murmur3$Murmur3$multiplyBy_fn(2246822507, h0 ^ (h0 >>> 16));
        var h2 = $robinheghan$murmur3$Murmur3$multiplyBy_fn(3266489909, h1 ^ (h1 >>> 13));
        return (h2 ^ (h2 >>> 16)) >>> 0;
    };
    var $elm$core$String$foldl = _String_foldl;
    var $robinheghan$murmur3$Murmur3$mix_fn = function (h1, k1) {
        return $robinheghan$murmur3$Murmur3$multiplyBy_fn(5, $robinheghan$murmur3$Murmur3$rotlBy_fn(13, h1 ^ $robinheghan$murmur3$Murmur3$multiplyBy_fn($robinheghan$murmur3$Murmur3$c2, $robinheghan$murmur3$Murmur3$rotlBy_fn(15, $robinheghan$murmur3$Murmur3$multiplyBy_fn($robinheghan$murmur3$Murmur3$c1, k1))))) + 3864292196;
    }, $robinheghan$murmur3$Murmur3$mix = F2($robinheghan$murmur3$Murmur3$mix_fn);
    var $robinheghan$murmur3$Murmur3$hashFold_fn = function (c, data) {
        var res = data.U | ((255 & $elm$core$Char$toCode(c)) << data.Z);
        var _v0 = data.Z;
        if (_v0 === 24) {
            return {
                S: data.S + 1,
                U: 0,
                M: $robinheghan$murmur3$Murmur3$mix_fn(data.M, res),
                Z: 0
            };
        }
        else {
            return { S: data.S + 1, U: res, M: data.M, Z: data.Z + 8 };
        }
    }, $robinheghan$murmur3$Murmur3$hashFold = F2($robinheghan$murmur3$Murmur3$hashFold_fn);
    var $robinheghan$murmur3$Murmur3$hashString_fn = function (seed, str) {
        return $robinheghan$murmur3$Murmur3$finalize(_String_foldl_fn($robinheghan$murmur3$Murmur3$hashFold, $robinheghan$murmur3$Murmur3$HashData_fn(0, seed, 0, 0), str));
    }, $robinheghan$murmur3$Murmur3$hashString = F2($robinheghan$murmur3$Murmur3$hashString_fn);
    var $dzuk_mutant$elm_css$Hash$initialSeed = 15739;
    var $elm$core$String$fromList = _String_fromList;
    var $elm$core$Basics$negate = function (n) {
        return -n;
    };
    var $elm$core$Basics$modBy = _Basics_modBy;
    var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
        unsafeToDigit: while (true) {
            switch (num) {
                case 0:
                    return "0";
                case 1:
                    return "1";
                case 2:
                    return "2";
                case 3:
                    return "3";
                case 4:
                    return "4";
                case 5:
                    return "5";
                case 6:
                    return "6";
                case 7:
                    return "7";
                case 8:
                    return "8";
                case 9:
                    return "9";
                case 10:
                    return "a";
                case 11:
                    return "b";
                case 12:
                    return "c";
                case 13:
                    return "d";
                case 14:
                    return "e";
                case 15:
                    return "f";
                default:
                    var $temp$num = num;
                    num = $temp$num;
                    continue unsafeToDigit;
            }
        }
    };
    var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits_fn = function (digits, num) {
        unsafePositiveToDigits: while (true) {
            if (num < 16) {
                return _List_Cons($rtfeldman$elm_hex$Hex$unsafeToDigit(num), digits);
            }
            else {
                var $temp$digits = _List_Cons($rtfeldman$elm_hex$Hex$unsafeToDigit(_Basics_modBy_fn(16, num)), digits), $temp$num = (num / 16) | 0;
                digits = $temp$digits;
                num = $temp$num;
                continue unsafePositiveToDigits;
            }
        }
    }, $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits_fn);
    var $rtfeldman$elm_hex$Hex$toString = function (num) {
        return $elm$core$String$fromList((num < 0) ? _List_Cons("-", $rtfeldman$elm_hex$Hex$unsafePositiveToDigits_fn(_List_Nil, -num)) : $rtfeldman$elm_hex$Hex$unsafePositiveToDigits_fn(_List_Nil, num));
    };
    var $dzuk_mutant$elm_css$Hash$fromString = function (str) {
        return _String_cons_fn("_", $rtfeldman$elm_hex$Hex$toString($robinheghan$murmur3$Murmur3$hashString_fn($dzuk_mutant$elm_css$Hash$initialSeed, str)));
    };
    var $elm$core$List$head = function (list) {
        if (list.b) {
            var x = list.a;
            var xs = list.b;
            return $elm$core$Maybe$Just(x);
        }
        else {
            return $elm$core$Maybe$Nothing;
        }
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$last = function (list) {
        last: while (true) {
            if (!list.b) {
                return $elm$core$Maybe$Nothing;
            }
            else {
                if (!list.b.b) {
                    var singleton = list.a;
                    return $elm$core$Maybe$Just(singleton);
                }
                else {
                    var rest = list.b;
                    var $temp$list = rest;
                    list = $temp$list;
                    continue last;
                }
            }
        }
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
        lastDeclaration: while (true) {
            if (!declarations.b) {
                return $elm$core$Maybe$Nothing;
            }
            else {
                if (!declarations.b.b) {
                    var x = declarations.a;
                    return $elm$core$Maybe$Just(_List_fromArray([x]));
                }
                else {
                    var xs = declarations.b;
                    var $temp$declarations = xs;
                    declarations = $temp$declarations;
                    continue lastDeclaration;
                }
            }
        }
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
        oneOf: while (true) {
            if (!maybes.b) {
                return $elm$core$Maybe$Nothing;
            }
            else {
                var maybe = maybes.a;
                var rest = maybes.b;
                if (maybe.$ === 1) {
                    var $temp$maybes = rest;
                    maybes = $temp$maybes;
                    continue oneOf;
                }
                else {
                    return maybe;
                }
            }
        }
    };
    var $dzuk_mutant$elm_css$Css$Structure$FontFeatureValues = function (a) {
        return { $: 9, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
        var expandTuples = function (tuplesToExpand) {
            if (!tuplesToExpand.b) {
                return _List_Nil;
            }
            else {
                var properties = tuplesToExpand.a;
                var rest = tuplesToExpand.b;
                return _List_Cons(properties, expandTuples(rest));
            }
        };
        var newTuples = expandTuples(tuples);
        return _List_fromArray([
            $dzuk_mutant$elm_css$Css$Structure$FontFeatureValues(newTuples)
        ]);
    };
    var $elm$core$List$singleton = function (value) {
        return _List_fromArray([value]);
    };
    var $dzuk_mutant$elm_css$Css$Structure$styleBlockToMediaRule_fn = function (mediaQueries, declaration) {
        if (!declaration.$) {
            var styleBlock = declaration.a;
            return $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(mediaQueries, _List_fromArray([styleBlock]));
        }
        else {
            return declaration;
        }
    }, $dzuk_mutant$elm_css$Css$Structure$styleBlockToMediaRule = F2($dzuk_mutant$elm_css$Css$Structure$styleBlockToMediaRule_fn);
    var $elm$core$List$tail = function (list) {
        if (list.b) {
            var x = list.a;
            var xs = list.b;
            return $elm$core$Maybe$Just(xs);
        }
        else {
            return $elm$core$Maybe$Nothing;
        }
    };
    var $elm$core$List$takeReverse_fn = function (n, list, kept) {
        takeReverse: while (true) {
            if (n <= 0) {
                return kept;
            }
            else {
                if (!list.b) {
                    return kept;
                }
                else {
                    var x = list.a;
                    var xs = list.b;
                    var $temp$n = n - 1, $temp$list = xs, $temp$kept = _List_Cons(x, kept);
                    n = $temp$n;
                    list = $temp$list;
                    kept = $temp$kept;
                    continue takeReverse;
                }
            }
        }
    }, $elm$core$List$takeReverse = F3($elm$core$List$takeReverse_fn);
    var $elm$core$List$takeTailRec_fn = function (n, list) {
        return $elm$core$List$reverse($elm$core$List$takeReverse_fn(n, list, _List_Nil));
    }, $elm$core$List$takeTailRec = F2($elm$core$List$takeTailRec_fn);
    var $elm$core$List$takeFast_fn = function (ctr, n, list) {
        if (n <= 0) {
            return _List_Nil;
        }
        else {
            var _v0 = _Utils_Tuple2(n, list);
            _v0$1: while (true) {
                _v0$5: while (true) {
                    if (!_v0.b.b) {
                        return list;
                    }
                    else {
                        if (_v0.b.b.b) {
                            switch (_v0.a) {
                                case 1:
                                    break _v0$1;
                                case 2:
                                    var _v2 = _v0.b;
                                    var x = _v2.a;
                                    var _v3 = _v2.b;
                                    var y = _v3.a;
                                    return _List_fromArray([x, y]);
                                case 3:
                                    if (_v0.b.b.b.b) {
                                        var _v4 = _v0.b;
                                        var x = _v4.a;
                                        var _v5 = _v4.b;
                                        var y = _v5.a;
                                        var _v6 = _v5.b;
                                        var z = _v6.a;
                                        return _List_fromArray([x, y, z]);
                                    }
                                    else {
                                        break _v0$5;
                                    }
                                default:
                                    if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
                                        var _v7 = _v0.b;
                                        var x = _v7.a;
                                        var _v8 = _v7.b;
                                        var y = _v8.a;
                                        var _v9 = _v8.b;
                                        var z = _v9.a;
                                        var _v10 = _v9.b;
                                        var w = _v10.a;
                                        var tl = _v10.b;
                                        return (ctr > 1000) ? _List_Cons(x, _List_Cons(y, _List_Cons(z, _List_Cons(w, $elm$core$List$takeTailRec_fn(n - 4, tl))))) : _List_Cons(x, _List_Cons(y, _List_Cons(z, _List_Cons(w, $elm$core$List$takeFast_fn(ctr + 1, n - 4, tl)))));
                                    }
                                    else {
                                        break _v0$5;
                                    }
                            }
                        }
                        else {
                            if (_v0.a === 1) {
                                break _v0$1;
                            }
                            else {
                                break _v0$5;
                            }
                        }
                    }
                }
                return list;
            }
            var _v1 = _v0.b;
            var x = _v1.a;
            return _List_fromArray([x]);
        }
    }, $elm$core$List$takeFast = F3($elm$core$List$takeFast_fn);
    var $elm$core$List$take_fn = function (n, xs) {
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (var i = 0; i < n && xs.b; xs = xs.
            b, i++) {
            var next = _List_Cons(xs.a, _List_Nil);
            end.b = next;
            end = next;
        }
        return tmp.b;
    }, $elm$core$List$take = F2($elm$core$List$take_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDocumentRule_fn = function (str1, str2, str3, str4, declaration) {
        if (!declaration.$) {
            var structureStyleBlock = declaration.a;
            return $dzuk_mutant$elm_css$Css$Structure$DocumentRule_fn(str1, str2, str3, str4, structureStyleBlock);
        }
        else {
            return declaration;
        }
    }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDocumentRule_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$toMediaRule_fn = function (mediaQueries, declaration) {
        switch (declaration.$) {
            case 0:
                var structureStyleBlock = declaration.a;
                return $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(mediaQueries, _List_fromArray([structureStyleBlock]));
            case 1:
                var newMediaQueries = declaration.a;
                var structureStyleBlocks = declaration.b;
                return $dzuk_mutant$elm_css$Css$Structure$MediaRule_fn(_Utils_ap(mediaQueries, newMediaQueries), structureStyleBlocks);
            case 2:
                var str = declaration.a;
                var declarations = declaration.b;
                return $dzuk_mutant$elm_css$Css$Structure$SupportsRule_fn(str, $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries), declarations));
            case 3:
                var str1 = declaration.a;
                var str2 = declaration.b;
                var str3 = declaration.c;
                var str4 = declaration.d;
                var structureStyleBlock = declaration.e;
                return $dzuk_mutant$elm_css$Css$Structure$DocumentRule_fn(str1, str2, str3, str4, structureStyleBlock);
            case 4:
                return declaration;
            case 5:
                return declaration;
            case 6:
                return declaration;
            case 7:
                return declaration;
            case 8:
                return declaration;
            default:
                return declaration;
        }
    }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$toMediaRule = F2($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toMediaRule_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
        var declarations = _v0;
        return declarations;
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast_fn = function (nestedStyles, rest, f, declarations) {
        var withoutParent = function (decls) {
            return $elm$core$Maybe$withDefault_fn(_List_Nil, $elm$core$List$tail(decls));
        };
        var nextResult = $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(rest, $elm$core$Maybe$withDefault_fn(_List_Nil, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
        var newDeclarations = function () {
            var _v14 = _Utils_Tuple2($elm$core$List$head(nextResult), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$last(declarations));
            if ((!_v14.a.$) && (!_v14.b.$)) {
                var nextResultParent = _v14.a.a;
                var originalParent = _v14.b.a;
                return _Utils_ap($elm$core$List$take_fn($elm$core$List$length(declarations) - 1, declarations), _List_fromArray([
                    (!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
                ]));
            }
            else {
                return declarations;
            }
        }();
        var insertStylesToNestedDecl = function (lastDecl) {
            return $elm$core$List$concat($dzuk_mutant$elm_css$Css$Structure$mapLast_fn($dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles), $elm$core$List$map_fn($elm$core$List$singleton, $dzuk_mutant$elm_css$Css$Structure$concatMapLastStyleBlock_fn(f, lastDecl))));
        };
        var initialResult = $elm$core$Maybe$withDefault_fn(_List_Nil, $elm$core$Maybe$map_fn(insertStylesToNestedDecl, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
        return _Utils_ap(newDeclarations, _Utils_ap(withoutParent(initialResult), withoutParent(nextResult)));
    }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4($dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn = function (styles, declarations) {
        if (!styles.b) {
            return declarations;
        }
        else {
            switch (styles.a.$) {
                case 0:
                    var property = styles.a.a;
                    var rest = styles.b;
                    return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(rest, $dzuk_mutant$elm_css$Css$Structure$appendProperty_fn(property, declarations));
                case 1:
                    var _v4 = styles.a;
                    var selector = _v4.a;
                    var nestedStyles = _v4.b;
                    var rest = styles.b;
                    return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast_fn(nestedStyles, rest, $dzuk_mutant$elm_css$Css$Structure$appendRepeatableToLastSelector(selector), declarations);
                case 2:
                    var _v5 = styles.a;
                    var selectorCombinator = _v5.a;
                    var snippets = _v5.b;
                    var rest = styles.b;
                    var chain = F2(function (_v9, _v10) {
                        var originalSequence = _v9.a;
                        var originalTuples = _v9.b;
                        var originalPseudoElement = _v9.c;
                        var newSequence = _v10.a;
                        var newTuples = _v10.b;
                        var newPseudoElement = _v10.c;
                        return $dzuk_mutant$elm_css$Css$Structure$Selector_fn(originalSequence, _Utils_ap(originalTuples, _List_Cons(_Utils_Tuple2(selectorCombinator, newSequence), newTuples)), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$oneOf(_List_fromArray([newPseudoElement, originalPseudoElement])));
                    });
                    var expandDeclaration = function (declaration) {
                        switch (declaration.$) {
                            case 0:
                                var _v7 = declaration.a;
                                var firstSelector = _v7.a;
                                var otherSelectors = _v7.b;
                                var nestedStyles = _v7.c;
                                var newSelectors = $elm$core$List$concatMap_fn(function (originalSelector) {
                                    return $elm$core$List$map_fn(chain(originalSelector), _List_Cons(firstSelector, otherSelectors));
                                }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
                                var newDeclarations = function () {
                                    if (!newSelectors.b) {
                                        return _List_Nil;
                                    }
                                    else {
                                        var first = newSelectors.a;
                                        var remainder = newSelectors.b;
                                        return _List_fromArray([
                                            $dzuk_mutant$elm_css$Css$Structure$StyleBlockDeclaration($dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(first, remainder, _List_Nil))
                                        ]);
                                    }
                                }();
                                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(nestedStyles, newDeclarations);
                            case 1:
                                var mediaQueries = declaration.a;
                                var styleBlocks = declaration.b;
                                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveMediaRule_fn(mediaQueries, styleBlocks);
                            case 2:
                                var str = declaration.a;
                                var otherSnippets = declaration.b;
                                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveSupportsRule_fn(str, otherSnippets);
                            case 3:
                                var str1 = declaration.a;
                                var str2 = declaration.b;
                                var str3 = declaration.c;
                                var str4 = declaration.d;
                                var styleBlock = declaration.e;
                                return $elm$core$List$map_fn(A4($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
                            case 4:
                                var properties = declaration.a;
                                return _List_fromArray([
                                    $dzuk_mutant$elm_css$Css$Structure$PageRule(properties)
                                ]);
                            case 5:
                                var properties = declaration.a;
                                return _List_fromArray([
                                    $dzuk_mutant$elm_css$Css$Structure$FontFace(properties)
                                ]);
                            case 6:
                                var properties = declaration.a;
                                return _List_fromArray([
                                    $dzuk_mutant$elm_css$Css$Structure$Viewport(properties)
                                ]);
                            case 7:
                                var properties = declaration.a;
                                return _List_fromArray([
                                    $dzuk_mutant$elm_css$Css$Structure$CounterStyle(properties)
                                ]);
                            default:
                                var tuples = declaration.a;
                                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
                        }
                    };
                    return $elm$core$List$concat(_Utils_ap(_List_fromArray([
                        $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(rest, declarations)
                    ]), $elm$core$List$map_fn(expandDeclaration, $elm$core$List$concatMap_fn($dzuk_mutant$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
                case 3:
                    var _v11 = styles.a;
                    var pseudoElement = _v11.a;
                    var nestedStyles = _v11.b;
                    var rest = styles.b;
                    return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast_fn(nestedStyles, rest, $dzuk_mutant$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement), declarations);
                case 5:
                    var str = styles.a.a;
                    var rest = styles.b;
                    var name = $dzuk_mutant$elm_css$Hash$fromString(str);
                    var newProperty = "animation-name:" + name;
                    var newDeclarations = $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(rest, $dzuk_mutant$elm_css$Css$Structure$appendProperty_fn(newProperty, declarations));
                    return $elm$core$List$append_fn(newDeclarations, _List_fromArray([
                        $dzuk_mutant$elm_css$Css$Structure$Keyframes({ c8: str, dS: name })
                    ]));
                case 4:
                    var _v12 = styles.a;
                    var mediaQueries = _v12.a;
                    var nestedStyles = _v12.b;
                    var rest = styles.b;
                    var extraDeclarations = function () {
                        var _v13 = $dzuk_mutant$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
                        if (!_v13.b) {
                            return _List_Nil;
                        }
                        else {
                            var firstSelector = _v13.a;
                            var otherSelectors = _v13.b;
                            return $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(nestedStyles, $elm$core$List$singleton($dzuk_mutant$elm_css$Css$Structure$StyleBlockDeclaration($dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(firstSelector, otherSelectors, _List_Nil)))));
                        }
                    }();
                    return _Utils_ap($dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(rest, declarations), extraDeclarations);
                default:
                    var otherStyles = styles.a.a;
                    var rest = styles.b;
                    return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(_Utils_ap(otherStyles, rest), declarations);
            }
        }
    }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles = F2($dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
        var firstSelector = _v2.a;
        var otherSelectors = _v2.b;
        var styles = _v2.c;
        return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$applyStyles_fn(styles, _List_fromArray([
            $dzuk_mutant$elm_css$Css$Structure$StyleBlockDeclaration($dzuk_mutant$elm_css$Css$Structure$StyleBlock_fn(firstSelector, otherSelectors, _List_Nil))
        ]));
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
        if (!snippetDeclarations.b) {
            return _List_Nil;
        }
        else {
            var first = snippetDeclarations.a;
            var rest = snippetDeclarations.b;
            return _Utils_ap($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDeclarations(first), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$extract(rest));
        }
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveMediaRule_fn = function (mediaQueries, styleBlocks) {
        var handleStyleBlock = function (styleBlock) {
            return $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
        };
        return $elm$core$List$concatMap_fn(handleStyleBlock, styleBlocks);
    }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2($dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveMediaRule_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveSupportsRule_fn = function (str, snippets) {
        var declarations = $dzuk_mutant$elm_css$Css$Preprocess$Resolve$extract($elm$core$List$concatMap_fn($dzuk_mutant$elm_css$Css$Preprocess$unwrapSnippet, snippets));
        return _List_fromArray([
            $dzuk_mutant$elm_css$Css$Structure$SupportsRule_fn(str, declarations)
        ]);
    }, $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2($dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveSupportsRule_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
        switch (snippetDeclaration.$) {
            case 0:
                var styleBlock = snippetDeclaration.a;
                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
            case 1:
                var mediaQueries = snippetDeclaration.a;
                var styleBlocks = snippetDeclaration.b;
                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveMediaRule_fn(mediaQueries, styleBlocks);
            case 2:
                var str = snippetDeclaration.a;
                var snippets = snippetDeclaration.b;
                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveSupportsRule_fn(str, snippets);
            case 3:
                var str1 = snippetDeclaration.a;
                var str2 = snippetDeclaration.b;
                var str3 = snippetDeclaration.c;
                var str4 = snippetDeclaration.d;
                var styleBlock = snippetDeclaration.e;
                return $elm$core$List$map_fn(A4($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4), $dzuk_mutant$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
            case 4:
                var properties = snippetDeclaration.a;
                return _List_fromArray([
                    $dzuk_mutant$elm_css$Css$Structure$PageRule(properties)
                ]);
            case 5:
                var properties = snippetDeclaration.a;
                return _List_fromArray([
                    $dzuk_mutant$elm_css$Css$Structure$FontFace(properties)
                ]);
            case 6:
                var properties = snippetDeclaration.a;
                return _List_fromArray([
                    $dzuk_mutant$elm_css$Css$Structure$Viewport(properties)
                ]);
            case 7:
                var properties = snippetDeclaration.a;
                return _List_fromArray([
                    $dzuk_mutant$elm_css$Css$Structure$CounterStyle(properties)
                ]);
            default:
                var tuples = snippetDeclaration.a;
                return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
        }
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
        var charset = _v0.bx;
        var imports = _v0.bW;
        var namespaces = _v0.b0;
        var snippets = _v0.cq;
        var declarations = $dzuk_mutant$elm_css$Css$Preprocess$Resolve$extract($elm$core$List$concatMap_fn($dzuk_mutant$elm_css$Css$Preprocess$unwrapSnippet, snippets));
        return { bx: charset, c9: declarations, bW: imports, b0: namespaces };
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Resolve$compile = function (sheet) {
        return $dzuk_mutant$elm_css$Css$Structure$Output$prettyPrint($dzuk_mutant$elm_css$Css$Structure$compactStylesheet($dzuk_mutant$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
    var $dzuk_mutant$elm_css$Css$Preprocess$StyleBlock_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dzuk_mutant$elm_css$Css$Preprocess$StyleBlock = F3($dzuk_mutant$elm_css$Css$Preprocess$StyleBlock_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
        return { $: 0, a: a };
    };
    var $dzuk_mutant$elm_css$VirtualDom$Styled$makeSnippet_fn = function (styles, sequence) {
        var selector = $dzuk_mutant$elm_css$Css$Structure$Selector_fn(sequence, _List_Nil, $elm$core$Maybe$Nothing);
        return _List_fromArray([
            $dzuk_mutant$elm_css$Css$Preprocess$StyleBlockDeclaration($dzuk_mutant$elm_css$Css$Preprocess$StyleBlock_fn(selector, _List_Nil, styles))
        ]);
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$makeSnippet = F2($dzuk_mutant$elm_css$VirtualDom$Styled$makeSnippet_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$stylesheet = function (snippets) {
        return { bx: $elm$core$Maybe$Nothing, bW: _List_Nil, b0: _List_Nil, cq: snippets };
    };
    var $dzuk_mutant$elm_css$Css$Structure$ClassSelector = function (a) {
        return { $: 0, a: a };
    };
    var $dzuk_mutant$elm_css$VirtualDom$Styled$classnameStandin = "\u0007";
    var $dzuk_mutant$elm_css$VirtualDom$Styled$templateSelector = $dzuk_mutant$elm_css$Css$Structure$UniversalSelectorSequence(_List_fromArray([
        $dzuk_mutant$elm_css$Css$Structure$ClassSelector($dzuk_mutant$elm_css$VirtualDom$Styled$classnameStandin)
    ]));
    var $dzuk_mutant$elm_css$VirtualDom$Styled$getCssTemplate = function (styles) {
        if (!styles.b) {
            return "";
        }
        else {
            var otherwise = styles;
            return $dzuk_mutant$elm_css$Css$Preprocess$Resolve$compile($dzuk_mutant$elm_css$Css$Preprocess$stylesheet(_List_fromArray([
                $dzuk_mutant$elm_css$VirtualDom$Styled$makeSnippet_fn(styles, $dzuk_mutant$elm_css$VirtualDom$Styled$templateSelector)
            ])));
        }
    };
    var $dzuk_mutant$elm_css$Html$Styled$Internal$css = function (styles) {
        var cssTemplate = $dzuk_mutant$elm_css$VirtualDom$Styled$getCssTemplate(styles);
        var classProperty = $elm$virtual_dom$VirtualDom$attribute_fn("", "");
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn(classProperty, true, cssTemplate);
    };
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$css = $dzuk_mutant$elm_css$Html$Styled$Internal$css;
    var $dzuk_mutant$elm_css$Css$Preprocess$AppendProperty = function (a) {
        return { $: 0, a: a };
    };
    var $dzuk_mutant$elm_css$Css$appendProperty = function (str) {
        return $dzuk_mutant$elm_css$Css$Preprocess$AppendProperty(str);
    };
    var $dzuk_mutant$elm_css$Css$flexDirection = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("flex-direction:" + val);
    };
    var $elm$core$String$endsWith = _String_endsWith;
    var $elm$core$String$toLower = _String_toLower;
    var $dzuk_mutant$elm_css$Css$makeImportant = function (_v0) {
        var str = _v0;
        return _String_endsWith_fn(" !important", $elm$core$String$toLower(str)) ? str : (str + " !important");
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$ApplyStyles = function (a) {
        return { $: 6, a: a };
    };
    var $dzuk_mutant$elm_css$Css$Preprocess$ExtendSelector_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Preprocess$ExtendSelector = F2($dzuk_mutant$elm_css$Css$Preprocess$ExtendSelector_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$mapAllProperties_fn = function (update, styles) {
        if (!styles.b) {
            return styles;
        }
        else {
            if (!styles.b.b) {
                var only = styles.a;
                return _List_fromArray([
                    $dzuk_mutant$elm_css$Css$Preprocess$mapProperties_fn(update, only)
                ]);
            }
            else {
                var first = styles.a;
                var rest = styles.b;
                return _List_Cons(first, $dzuk_mutant$elm_css$Css$Preprocess$mapAllProperties_fn(update, rest));
            }
        }
    }, $dzuk_mutant$elm_css$Css$Preprocess$mapAllProperties = F2($dzuk_mutant$elm_css$Css$Preprocess$mapAllProperties_fn);
    var $dzuk_mutant$elm_css$Css$Preprocess$mapProperties_fn = function (update, style) {
        switch (style.$) {
            case 0:
                var property = style.a;
                return $dzuk_mutant$elm_css$Css$Preprocess$AppendProperty(update(property));
            case 1:
                var selector = style.a;
                var styles = style.b;
                return $dzuk_mutant$elm_css$Css$Preprocess$ExtendSelector_fn(selector, $dzuk_mutant$elm_css$Css$Preprocess$mapAllProperties_fn(update, styles));
            case 2:
                return style;
            case 3:
                return style;
            case 4:
                return style;
            case 5:
                return style;
            default:
                var otherStyles = style.a;
                return $dzuk_mutant$elm_css$Css$Preprocess$ApplyStyles($elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Preprocess$mapProperties(update), otherStyles));
        }
    }, $dzuk_mutant$elm_css$Css$Preprocess$mapProperties = F2($dzuk_mutant$elm_css$Css$Preprocess$mapProperties_fn);
    var $dzuk_mutant$elm_css$Css$important_a0 = $dzuk_mutant$elm_css$Css$makeImportant, $dzuk_mutant$elm_css$Css$important = $dzuk_mutant$elm_css$Css$Preprocess$mapProperties($dzuk_mutant$elm_css$Css$important_a0);
    var $dzuk_mutant$elm_css$Css$Media$feature_fn = function (key, val) {
        return {
            bN: key,
            az: $elm$core$Maybe$Just(val)
        };
    }, $dzuk_mutant$elm_css$Css$Media$feature = F2($dzuk_mutant$elm_css$Css$Media$feature_fn);
    var $dzuk_mutant$elm_css$Css$Media$maxWidth = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$Media$feature_fn("max-width", val);
    };
    var $dzuk_mutant$elm_css$Css$Structure$OnlyQuery_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Structure$OnlyQuery = F2($dzuk_mutant$elm_css$Css$Structure$OnlyQuery_fn);
    var $dzuk_mutant$elm_css$Css$Media$only = $dzuk_mutant$elm_css$Css$Structure$OnlyQuery;
    var $elm$core$String$fromFloat = _String_fromNumber;
    var $dzuk_mutant$elm_css$Css$px = function (value) {
        return $elm$core$String$fromFloat(value) + "px";
    };
    var $dzuk_mutant$elm_css$Css$Structure$Screen = 1;
    var $dzuk_mutant$elm_css$Css$Media$screen = 1;
    var $dzuk_mutant$elm_css$Css$Preprocess$WithMedia_fn = function (a, b) {
        return { $: 4, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Preprocess$WithMedia = F2($dzuk_mutant$elm_css$Css$Preprocess$WithMedia_fn);
    var $dzuk_mutant$elm_css$Css$Media$withMedia = $dzuk_mutant$elm_css$Css$Preprocess$WithMedia;
    var $author$project$UI$screenMaxSm_a0 = _List_fromArray([
        $dzuk_mutant$elm_css$Css$Structure$OnlyQuery_fn($dzuk_mutant$elm_css$Css$Media$screen, _List_fromArray([
            $dzuk_mutant$elm_css$Css$Media$maxWidth($dzuk_mutant$elm_css$Css$px(640))
        ]))
    ]), $author$project$UI$screenMaxSm = $dzuk_mutant$elm_css$Css$Media$withMedia($author$project$UI$screenMaxSm_a0);
    var $author$project$UI$columnOnSm = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$Preprocess$WithMedia_fn($author$project$UI$screenMaxSm_a0, _List_fromArray([
            $dzuk_mutant$elm_css$Css$Preprocess$mapProperties_fn($dzuk_mutant$elm_css$Css$important_a0, $dzuk_mutant$elm_css$Css$flexDirection($dzuk_mutant$elm_css$Css$column))
        ]))
    ]));
    var $dzuk_mutant$elm_css$Css$alignItems = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("align-items:" + val);
    };
    var $dzuk_mutant$elm_css$Css$center = "center";
    var $author$project$UI$axisCrossCenter = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$alignItems($dzuk_mutant$elm_css$Css$center)
    ]));
    var $dzuk_mutant$elm_css$Css$borderInline2_fn = function (_v0, _v1) {
        var widthVal = _v0;
        var styleVal = _v1;
        return $dzuk_mutant$elm_css$Css$appendProperty("border-inline:" + (widthVal + (" " + styleVal)));
    }, $dzuk_mutant$elm_css$Css$borderInline2 = F2($dzuk_mutant$elm_css$Css$borderInline2_fn);
    var $dzuk_mutant$elm_css$Css$borderStyle = function (_v0) {
        var styleVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("border-style:" + styleVal);
    };
    var $dzuk_mutant$elm_css$Css$borderWidth2_fn = function (_v0, _v1) {
        var widthTopBottom = _v0;
        var widthRightLeft = _v1;
        return $dzuk_mutant$elm_css$Css$appendProperty("border-width:" + (widthTopBottom + (" " + widthRightLeft)));
    }, $dzuk_mutant$elm_css$Css$borderWidth2 = F2($dzuk_mutant$elm_css$Css$borderWidth2_fn);
    var $dzuk_mutant$elm_css$Css$solid = "solid";
    var $author$project$UI$border = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$borderInline2_fn($dzuk_mutant$elm_css$Css$px(1), $dzuk_mutant$elm_css$Css$solid),
        $dzuk_mutant$elm_css$Css$borderStyle($dzuk_mutant$elm_css$Css$solid),
        $dzuk_mutant$elm_css$Css$borderWidth2_fn($dzuk_mutant$elm_css$Css$px(3), $dzuk_mutant$elm_css$Css$px(1))
    ]));
    var $dzuk_mutant$elm_css$Css$borderRadius = function (_v0) {
        var radius = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("border-radius:" + radius);
    };
    var $dzuk_mutant$elm_css$Css$rem = function (value) {
        return $elm$core$String$fromFloat(value) + "rem";
    };
    var $author$project$UI$borderRounded = function (length) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$borderRadius($dzuk_mutant$elm_css$Css$rem(length))
        ]));
    };
    var $dzuk_mutant$elm_css$Css$fontSize = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("font-size:" + val);
    };
    var $author$project$UI$fontSize = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$fontSize($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $author$project$Design$Typo$defaultSize = $author$project$UI$fontSize(1.5);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$Node = F3($dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$node = $dzuk_mutant$elm_css$VirtualDom$Styled$Node;
    var $dzuk_mutant$elm_css$Html$Styled$node = $dzuk_mutant$elm_css$VirtualDom$Styled$node;
    var $dzuk_mutant$elm_css$Html$Styled$div_a0 = "div", $dzuk_mutant$elm_css$Html$Styled$div = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$div_a0);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$Unstyled = function (a) {
        return { $: 4, a: a };
    };
    var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
    var $dzuk_mutant$elm_css$VirtualDom$Styled$text = function (str) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Unstyled($elm$virtual_dom$VirtualDom$text(str));
    };
    var $dzuk_mutant$elm_css$Html$Styled$text = $dzuk_mutant$elm_css$VirtualDom$Styled$text;
    var $dzuk_mutant$elm_css$Css$textAlign = function (_v0) {
        var str = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("text-align:" + str);
    };
    var $dzuk_mutant$elm_css$Css$pct = function (value) {
        return $elm$core$String$fromFloat(value) + "%";
    };
    var $dzuk_mutant$elm_css$Css$width = function (_v0) {
        var sizeVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("width:" + sizeVal);
    };
    var $author$project$UI$widthFill = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$width($dzuk_mutant$elm_css$Css$pct(100))
    ]));
    var $author$project$Design$Typo$centeredLabel_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$centeredLabel_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$div(_List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$textAlign($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$widthFill,
        $author$project$Design$Typo$defaultSize
    ]))), $author$project$Design$Typo$centeredLabel = A2($elm$core$Basics$composeR, $author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1);
    var $dzuk_mutant$elm_css$Css$display = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("display:" + val);
    };
    var $dzuk_mutant$elm_css$Css$flex_ = "flex";
    var $author$project$UI$column_fn = function (attrs, children) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$div_a0, _List_Cons($dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$display($dzuk_mutant$elm_css$Css$flex_),
            $dzuk_mutant$elm_css$Css$flexDirection($dzuk_mutant$elm_css$Css$column)
        ])), attrs), children);
    }, $author$project$UI$column = F2($author$project$UI$column_fn);
    var $author$project$Design$Palette$RGB_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $author$project$Design$Palette$RGB = F3($author$project$Design$Palette$RGB_fn);
    var $author$project$Design$Palette$danger600 = $author$project$Design$Palette$RGB_fn(222, 55, 44);
    var $author$project$Design$Palette$genericWhite = $author$project$Design$Palette$RGB_fn(255, 255, 255);
    var $dzuk_mutant$elm_css$Html$Styled$h1_a0 = "h1", $dzuk_mutant$elm_css$Html$Styled$h1 = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$h1_a0);
    var $dzuk_mutant$elm_css$Css$fitContent = "fit-content";
    var $dzuk_mutant$elm_css$Css$flex = function (_v0) {
        var growOrBasis = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("flex:" + growOrBasis);
    };
    var $dzuk_mutant$elm_css$Css$height = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("height:" + val);
    };
    var $dzuk_mutant$elm_css$Css$none = "none";
    var $author$project$UI$shrink = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$width($dzuk_mutant$elm_css$Css$fitContent),
        $dzuk_mutant$elm_css$Css$height($dzuk_mutant$elm_css$Css$fitContent),
        $dzuk_mutant$elm_css$Css$flex($dzuk_mutant$elm_css$Css$none)
    ]));
    var $author$project$Design$Typo$h6_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$h6_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$h1(_List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$textAlign($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$shrink,
        $author$project$UI$fontSize(1.75)
    ]))), $author$project$Design$Typo$h6 = A2($elm$core$Basics$composeR, $author$project$Design$Typo$h6_a0, $author$project$Design$Typo$h6_a1);
    var $dzuk_mutant$elm_css$Css$padding = function (_v0) {
        var value = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("padding:" + value);
    };
    var $author$project$UI$padding = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$padding($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $dzuk_mutant$elm_css$Css$backgroundColor = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("background-color:" + val);
    };
    var $dzuk_mutant$elm_css$Css$rgba_fn = function (red, green, blue, alphaVal) {
        return "rgba(" + ($elm$core$String$fromInt(red) + ("," + ($elm$core$String$fromInt(green) + ("," + ($elm$core$String$fromInt(blue) + ("," + ($elm$core$String$fromFloat(alphaVal) + ")")))))));
    }, $dzuk_mutant$elm_css$Css$rgba = F4($dzuk_mutant$elm_css$Css$rgba_fn);
    var $author$project$Design$Palette$unbox_fn = function (fn, color) {
        if (!color.$) {
            var r = color.a;
            var g = color.b;
            var b = color.c;
            return A4(fn, r, g, b, 1);
        }
        else {
            var r = color.a;
            var g = color.b;
            var b = color.c;
            var alpha = color.d;
            return A4(fn, r, g, b, alpha);
        }
    }, $author$project$Design$Palette$unbox_fn_unwrapped = function (fn, color) {
        if (!color.$) {
            var r = color.a;
            var g = color.b;
            var b = color.c;
            return fn(r, g, b, 1);
        }
        else {
            var r = color.a;
            var g = color.b;
            var b = color.c;
            var alpha = color.d;
            return fn(r, g, b, alpha);
        }
    }, $author$project$Design$Palette$unbox = F2($author$project$Design$Palette$unbox_fn);
    var $author$project$Design$Palette$toBackground_a0 = $author$project$Design$Palette$unbox($dzuk_mutant$elm_css$Css$rgba), $author$project$Design$Palette$toBackground_a1 = A2($elm$core$Basics$composeR, $dzuk_mutant$elm_css$Css$backgroundColor, A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$Attributes$css)), $author$project$Design$Palette$toBackground = A2($elm$core$Basics$composeR, $author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1);
    var $dzuk_mutant$elm_css$Css$borderColor = function (_v0) {
        var colorVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("border-color:" + colorVal);
    };
    var $dzuk_mutant$elm_css$Css$borderInlineColor = function (_v0) {
        var colorVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("border-inline-color:" + colorVal);
    };
    var $author$project$Design$Palette$toBorder = function (color) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$borderColor($author$project$Design$Palette$unbox_fn($dzuk_mutant$elm_css$Css$rgba, color)),
            $dzuk_mutant$elm_css$Css$borderInlineColor($author$project$Design$Palette$unbox_fn($dzuk_mutant$elm_css$Css$rgba, color))
        ]));
    };
    var $dzuk_mutant$elm_css$Css$color = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("color:" + val);
    };
    var $author$project$Design$Palette$toText_a0 = $author$project$Design$Palette$unbox($dzuk_mutant$elm_css$Css$rgba), $author$project$Design$Palette$toText_a1 = A2($elm$core$Basics$composeR, $dzuk_mutant$elm_css$Css$color, A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$Attributes$css)), $author$project$Design$Palette$toText = A2($elm$core$Basics$composeR, $author$project$Design$Palette$toText_a0, $author$project$Design$Palette$toText_a1);
    var $author$project$Pages$Dashboard$View$connectionFailure = $author$project$UI$column_fn(_List_fromArray([
        $author$project$UI$padding(1),
        $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$danger600),
        $author$project$UI$borderRounded(1),
        $author$project$UI$border,
        $author$project$Design$Palette$toBorder($author$project$Design$Palette$genericWhite),
        $elm$core$Basics$composeR_fn($author$project$Design$Palette$toText_a0, $author$project$Design$Palette$toText_a1, $author$project$Design$Palette$genericWhite),
        $author$project$UI$axisCrossCenter
    ]), _List_fromArray([
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$h6_a0, $author$project$Design$Typo$h6_a1, "Severe connectivity issue detected!"),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "The data displayed on the screen could be obsolete or inaccurate."),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Please verify your connection and refresh the page.")
    ]));
    var $dzuk_mutant$elm_css$Css$num = function (value) {
        return $elm$core$String$fromFloat(value);
    };
    var $author$project$UI$flexFill = function (amount) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$flex($dzuk_mutant$elm_css$Css$num(amount))
        ]));
    };
    var $dzuk_mutant$elm_css$Css$gap = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("gap:" + val);
    };
    var $author$project$UI$gap = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$gap($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $author$project$UI$heightFill = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$height($dzuk_mutant$elm_css$Css$pct(100))
    ]));
    var $dzuk_mutant$elm_css$VirtualDom$Styled$KeyedNode_fn = function (a, b, c) {
        return { $: 2, a: a, b: b, c: c };
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$KeyedNode = F3($dzuk_mutant$elm_css$VirtualDom$Styled$KeyedNode_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$keyedNode = $dzuk_mutant$elm_css$VirtualDom$Styled$KeyedNode;
    var $dzuk_mutant$elm_css$Html$Styled$Keyed$node = $dzuk_mutant$elm_css$VirtualDom$Styled$keyedNode;
    var $author$project$UI$keyedColumn_fn = function (attrs, children) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$KeyedNode_fn("div", _List_Cons($dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$display($dzuk_mutant$elm_css$Css$flex_),
            $dzuk_mutant$elm_css$Css$flexDirection($dzuk_mutant$elm_css$Css$column)
        ])), attrs), children);
    }, $author$project$UI$keyedColumn = F2($author$project$UI$keyedColumn_fn);
    var $author$project$UI$keyedRow_fn = function (attrs, children) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$KeyedNode_fn("div", _List_Cons($dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$display($dzuk_mutant$elm_css$Css$flex_)
        ])), attrs), children);
    }, $author$project$UI$keyedRow = F2($author$project$UI$keyedRow_fn);
    var $author$project$Design$Button$Button_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $author$project$Design$Button$Button = F2($author$project$Design$Button$Button_fn);
    var $author$project$Design$Palette$StylePrimary = 1;
    var $author$project$Design$Button$default = { aG: $elm$core$Maybe$Nothing, aH: $elm$core$Maybe$Nothing, ao: $elm$core$Maybe$Nothing, aR: 1 };
    var $author$project$Design$Button$fromText = function (label) {
        return $author$project$Design$Button$Button_fn({ V: label }, $author$project$Design$Button$default);
    };
    var $author$project$Main$Msg$CartInsert_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $author$project$Main$Msg$CartInsert = F2($author$project$Main$Msg$CartInsert_fn);
    var $author$project$Main$Msg$CartRemove = function (a) {
        return { $: 2, a: a };
    };
    var $author$project$Design$Palette$StyleDanger = 0;
    var $author$project$Design$Icon$getHint = function (_v0) {
        var hint = _v0.a.a_;
        return hint;
    };
    var $author$project$Design$Button$fromIcon = function (icon) {
        return $author$project$Design$Button$Button_fn({
            V: $author$project$Design$Icon$getHint(icon)
        }, _Utils_update($author$project$Design$Button$default, {
            aH: $elm$core$Maybe$Just(icon)
        }));
    };
    var $author$project$Design$Icon$Icon_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $author$project$Design$Icon$Icon = F2($author$project$Design$Icon$Icon_fn);
    var $author$project$Design$Icon$Properties_fn = function (hint, glyph) {
        return { bR: glyph, a_: hint };
    }, $author$project$Design$Icon$Properties = F2($author$project$Design$Icon$Properties_fn);
    var $author$project$Design$Palette$genericBlack = $author$project$Design$Palette$RGB_fn(0, 0, 0);
    var $author$project$Design$Icon$defaultOptions = { bD: $author$project$Design$Palette$genericBlack, as: 2, aO: false };
    var $author$project$Design$Icon$iTrashCan = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "trash-can"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iUserMinus = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "user-minus"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iUserPlus = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "user-plus"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$UI$lone_fn = function (attrs, body) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$div_a0, _List_Cons($dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$display($dzuk_mutant$elm_css$Css$flex_)
        ])), attrs), _List_fromArray([body]));
    }, $author$project$UI$lone = F2($author$project$UI$lone_fn);
    var $elm$core$Tuple$pair_fn = function (a, b) {
        return _Utils_Tuple2(a, b);
    }, $elm$core$Tuple$pair = F2($elm$core$Tuple$pair_fn);
    var $author$project$UI$row_fn = function (attrs, children) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$div_a0, _List_Cons($dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$display($dzuk_mutant$elm_css$Css$flex_)
        ])), attrs), children);
    }, $author$project$UI$row = F2($author$project$UI$row_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn = function (key, value) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn($elm$virtual_dom$VirtualDom$attribute_fn(key, value), false, "");
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$attribute = F2($dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn);
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$attribute = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute;
    var $dzuk_mutant$elm_css$VirtualDom$Styled$NodeNS_fn = function (a, b, c, d) {
        return { $: 1, a: a, b: b, c: c, d: d };
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$NodeNS = F4($dzuk_mutant$elm_css$VirtualDom$Styled$NodeNS_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$nodeNS = $dzuk_mutant$elm_css$VirtualDom$Styled$NodeNS;
    var $dzuk_mutant$elm_css$Svg$Styled$node_a0 = "http://www.w3.org/2000/svg", $dzuk_mutant$elm_css$Svg$Styled$node = $dzuk_mutant$elm_css$VirtualDom$Styled$nodeNS($dzuk_mutant$elm_css$Svg$Styled$node_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$animateTransform = $dzuk_mutant$elm_css$Svg$Styled$node("animateTransform");
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$attributeName_a0 = "attributeName", $dzuk_mutant$elm_css$Svg$Styled$Attributes$attributeName = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$attributeName_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$dur_a0 = "dur", $dzuk_mutant$elm_css$Svg$Styled$Attributes$dur = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$dur_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$from = function (value) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("from", value);
    };
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$repeatCount_a0 = "repeatCount", $dzuk_mutant$elm_css$Svg$Styled$Attributes$repeatCount = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$repeatCount_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$to = function (value) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("to", value);
    };
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$type__a0 = "type", $dzuk_mutant$elm_css$Svg$Styled$Attributes$type_ = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$type__a0);
    var $author$project$Utils$Svg$animateSpin_fn = function (centerX, centerY) {
        var centerYStr = $elm$core$String$fromFloat(centerY);
        var centerXStr = $elm$core$String$fromFloat(centerX);
        return A2($dzuk_mutant$elm_css$Svg$Styled$animateTransform, _List_fromArray([
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$attributeName_a0, "transform"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$type__a0, "rotate"),
            $dzuk_mutant$elm_css$Svg$Styled$Attributes$from("0 " + (centerXStr + (" " + centerYStr))),
            $dzuk_mutant$elm_css$Svg$Styled$Attributes$to("360 " + (centerXStr + (" " + centerYStr))),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$dur_a0, "1s"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$repeatCount_a0, "indefinite")
        ]), _List_Nil);
    }, $author$project$Utils$Svg$animateSpin = F2($author$project$Utils$Svg$animateSpin_fn);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$fill_a0 = "fill", $dzuk_mutant$elm_css$Svg$Styled$Attributes$fill = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$fill_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$height_a0 = "height", $dzuk_mutant$elm_css$Svg$Styled$Attributes$height = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$height_a0);
    var $author$project$UI$height = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$height($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $dzuk_mutant$elm_css$Svg$Styled$svg = $dzuk_mutant$elm_css$Svg$Styled$node("svg");
    var $elm$virtual_dom$VirtualDom$property_fn = function (key, value) {
        return _VirtualDom_property_fn(_VirtualDom_noInnerHtmlOrFormAction(key), _VirtualDom_noJavaScriptOrHtmlJson(value));
    }, $elm$virtual_dom$VirtualDom$property = F2($elm$virtual_dom$VirtualDom$property_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$property_fn = function (key, value) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn($elm$virtual_dom$VirtualDom$property_fn(key, value), false, "");
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$property = F2($dzuk_mutant$elm_css$VirtualDom$Styled$property_fn);
    var $elm$json$Json$Encode$string = _Json_wrap;
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn = function (key, string) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$property_fn(key, $elm$json$Json$Encode$string(string));
    }, $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty = F2($dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn);
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$title_a0 = "title", $dzuk_mutant$elm_css$Html$Styled$Attributes$title = $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty($dzuk_mutant$elm_css$Html$Styled$Attributes$title_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$use = $dzuk_mutant$elm_css$Svg$Styled$node("use");
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$version_a0 = "version", $dzuk_mutant$elm_css$Svg$Styled$Attributes$version = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$version_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$viewBox_a0 = "viewBox", $dzuk_mutant$elm_css$Svg$Styled$Attributes$viewBox = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$viewBox_a0);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$width_a0 = "width", $dzuk_mutant$elm_css$Svg$Styled$Attributes$width = $dzuk_mutant$elm_css$VirtualDom$Styled$attribute($dzuk_mutant$elm_css$Svg$Styled$Attributes$width_a0);
    var $author$project$UI$width = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$width($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $elm$virtual_dom$VirtualDom$attributeNS_fn = function (namespace, key, value) {
        return _VirtualDom_attributeNS_fn(namespace, _VirtualDom_noOnOrFormAction(key), _VirtualDom_noJavaScriptOrHtmlUri(value));
    }, $elm$virtual_dom$VirtualDom$attributeNS = F3($elm$virtual_dom$VirtualDom$attributeNS_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$attributeNS_fn = function (namespace, key, value) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn($elm$virtual_dom$VirtualDom$attributeNS_fn(namespace, key, value), false, "");
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$attributeNS = F3($dzuk_mutant$elm_css$VirtualDom$Styled$attributeNS_fn);
    var $dzuk_mutant$elm_css$Svg$Styled$Attributes$xlinkHref = function (value) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$attributeNS_fn("http://www.w3.org/1999/xlink", "xlink:href", value);
    };
    var $author$project$Design$Icon$toUI = function (_v0) {
        var hint = _v0.a.a_;
        var glyph = _v0.a.bR;
        var opt = _v0.b;
        var bodyWithSpin = opt.aO ? _List_fromArray([
            $author$project$Utils$Svg$animateSpin_fn(50, 50)
        ]) : _List_Nil;
        return $author$project$UI$lone_fn(_List_fromArray([
            $author$project$UI$width(opt.as),
            $author$project$UI$height(opt.as),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("aria-role", "image"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("aria-label", hint),
            $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$title_a0, hint)
        ]), A2($dzuk_mutant$elm_css$Svg$Styled$svg, _List_fromArray([
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$version_a0, "1.1"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$width_a0, "100%"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$height_a0, "100%"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$fill_a0, "currentColor"),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn($dzuk_mutant$elm_css$Svg$Styled$Attributes$viewBox_a0, "0 0 100 100")
        ]), _List_fromArray([
            A2($dzuk_mutant$elm_css$Svg$Styled$use, _List_fromArray([
                $dzuk_mutant$elm_css$Svg$Styled$Attributes$xlinkHref("#" + glyph)
            ]), bodyWithSpin)
        ])));
    };
    var $author$project$Design$Icon$withSize_fn = function (newSize, _v0) {
        var props = _v0.a;
        var opt = _v0.b;
        return $author$project$Design$Icon$Icon_fn(props, _Utils_update(opt, { as: newSize }));
    }, $author$project$Design$Icon$withSize = F2($author$project$Design$Icon$withSize_fn);
    var $author$project$Design$Button$bodyView_fn = function (_v0, _v1) {
        var label = _v0.V;
        var icon = _v1.aH;
        return $author$project$UI$lone_fn(_List_fromArray([
            $author$project$UI$padding(1),
            $author$project$Design$Typo$defaultSize
        ]), function () {
            if (!icon.$) {
                var icon_ = icon.a;
                return $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(1.875, icon_));
            }
            else {
                return $dzuk_mutant$elm_css$Html$Styled$text(label);
            }
        }());
    }, $author$project$Design$Button$bodyView = F2($author$project$Design$Button$bodyView_fn);
    var $dzuk_mutant$elm_css$Html$Styled$button_a0 = "button", $dzuk_mutant$elm_css$Html$Styled$button = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$button_a0);
    var $dzuk_mutant$elm_css$Css$cursor = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("cursor:" + val);
    };
    var $author$project$Design$Palette$danger400 = $author$project$Design$Palette$RGB_fn(255, 135, 129);
    var $elm$json$Json$Encode$bool = _Json_wrap;
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$boolProperty_fn = function (key, bool) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$property_fn(key, $elm$json$Json$Encode$bool(bool));
    }, $dzuk_mutant$elm_css$Html$Styled$Attributes$boolProperty = F2($dzuk_mutant$elm_css$Html$Styled$Attributes$boolProperty_fn);
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$disabled_a0 = "disabled", $dzuk_mutant$elm_css$Html$Styled$Attributes$disabled = $dzuk_mutant$elm_css$Html$Styled$Attributes$boolProperty($dzuk_mutant$elm_css$Html$Styled$Attributes$disabled_a0);
    var $elm$core$Basics$ge = _Utils_ge;
    var $dzuk_mutant$elm_css$Css$Value$unpack = function (_v0) {
        var value = _v0;
        return value;
    };
    var $dzuk_mutant$elm_css$Css$unset = "unset";
    var $dzuk_mutant$elm_css$Css$valueListToString_fn = function (separator, list) {
        return ($elm$core$List$length(list) >= 1) ? $elm$core$String$join_fn(separator, $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Value$unpack, list)) : $dzuk_mutant$elm_css$Css$Value$unpack($dzuk_mutant$elm_css$Css$unset);
    }, $dzuk_mutant$elm_css$Css$valueListToString = F2($dzuk_mutant$elm_css$Css$valueListToString_fn);
    var $dzuk_mutant$elm_css$Css$backgroundImageMany = function (values) {
        return $dzuk_mutant$elm_css$Css$appendProperty("background-image:" + $dzuk_mutant$elm_css$Css$valueListToString_fn(",", values));
    };
    var $dzuk_mutant$elm_css$Css$backgroundSize = function (_v0) {
        var sizeVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("background-size:" + sizeVal);
    };
    var $dzuk_mutant$elm_css$Css$cover = "cover";
    var $dzuk_mutant$elm_css$Css$linearGradient2_fn = function (_v0, _v1, _v2, moreStops) {
        var angle = _v0;
        var firstStop = _v1;
        var secondStop = _v2;
        var peeledStops = $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$Value$unpack, moreStops);
        var stops = $elm$core$String$join_fn(",", _List_Cons(firstStop, _List_Cons(secondStop, peeledStops)));
        return "linear-gradient(" + (angle + ("," + (stops + ")")));
    }, $dzuk_mutant$elm_css$Css$linearGradient2 = F4($dzuk_mutant$elm_css$Css$linearGradient2_fn);
    var $author$project$UI$none = $dzuk_mutant$elm_css$Html$Styled$text("");
    var $dzuk_mutant$elm_css$Css$stop = function (_v0) {
        var colorVal = _v0;
        return colorVal;
    };
    var $dzuk_mutant$elm_css$Css$toBottom = "to bottom";
    var $dzuk_mutant$elm_css$Css$url = function (str) {
        return "url(" + (str + ")");
    };
    var $author$project$Design$Palette$RGBA_fn = function (a, b, c, d) {
        return { $: 1, a: a, b: b, c: c, d: d };
    }, $author$project$Design$Palette$RGBA = F4($author$project$Design$Palette$RGBA_fn);
    var $author$project$Design$Palette$withAlpha_fn = function (newAlpha, color) {
        if (!color.$) {
            var r = color.a;
            var g = color.b;
            var b = color.c;
            return $author$project$Design$Palette$RGBA_fn(r, g, b, newAlpha);
        }
        else {
            var r = color.a;
            var g = color.b;
            var b = color.c;
            return $author$project$Design$Palette$RGBA_fn(r, g, b, newAlpha);
        }
    }, $author$project$Design$Palette$withAlpha = F2($author$project$Design$Palette$withAlpha_fn);
    var $author$project$Design$Button$graphicsView_fn = function (_v0, color) {
        var graphics = _v0.aG;
        if (!graphics.$) {
            var graphicsUrl = graphics.a;
            var cssTransparent = $author$project$Design$Palette$unbox_fn($dzuk_mutant$elm_css$Css$rgba, $author$project$Design$Palette$withAlpha_fn(0, color));
            var css400 = $author$project$Design$Palette$unbox_fn($dzuk_mutant$elm_css$Css$rgba, color);
            return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$div_a0, _List_fromArray([
                $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
                    $dzuk_mutant$elm_css$Css$backgroundImageMany(_List_fromArray([
                        $dzuk_mutant$elm_css$Css$linearGradient2_fn($dzuk_mutant$elm_css$Css$toBottom, $dzuk_mutant$elm_css$Css$stop(css400), $dzuk_mutant$elm_css$Css$stop(cssTransparent), _List_fromArray([
                            $dzuk_mutant$elm_css$Css$stop(cssTransparent)
                        ])),
                        $dzuk_mutant$elm_css$Css$url(graphicsUrl)
                    ])),
                    $dzuk_mutant$elm_css$Css$backgroundSize($dzuk_mutant$elm_css$Css$cover)
                ])),
                $author$project$UI$widthFill,
                $author$project$UI$height(8)
            ]), _List_Nil);
        }
        else {
            return $author$project$UI$none;
        }
    }, $author$project$Design$Button$graphicsView = F2($author$project$Design$Button$graphicsView_fn);
    var $author$project$Design$Palette$gray600 = $author$project$Design$Palette$RGB_fn(115, 124, 130);
    var $dzuk_mutant$elm_css$Css$maxWidth = function (_v0) {
        var sizeVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("max-width:" + sizeVal);
    };
    var $author$project$UI$maxWidthPct = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$maxWidth($dzuk_mutant$elm_css$Css$pct(size))
        ]));
    };
    var $author$project$Design$Palette$primary800 = $author$project$Design$Palette$RGB_fn(0, 39, 120);
    var $author$project$Design$Container$containerAttributes = _List_fromArray([
        $author$project$UI$maxWidthPct(100),
        $author$project$UI$borderRounded(1),
        $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$withAlpha_fn(0.75, $author$project$Design$Palette$primary800)),
        $author$project$UI$border,
        $author$project$Design$Palette$toBorder($author$project$Design$Palette$genericWhite)
    ]);
    var $author$project$Design$Container$hold_fn = function (fn, attrs, body) {
        return A2(fn, _Utils_ap($author$project$Design$Container$containerAttributes, attrs), body);
    }, $author$project$Design$Container$hold_fn_unwrapped = function (fn, attrs, body) {
        return fn(_Utils_ap($author$project$Design$Container$containerAttributes, attrs), body);
    }, $author$project$Design$Container$hold = F3($author$project$Design$Container$hold_fn);
    var $dzuk_mutant$elm_css$Css$notAllowed = "not-allowed";
    var $elm$virtual_dom$VirtualDom$Normal = function (a) {
        return { $: 0, a: a };
    };
    var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
    var $dzuk_mutant$elm_css$VirtualDom$Styled$on_fn = function (eventName, handler) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Attribute_fn(_VirtualDom_on_fn(eventName, handler), false, "");
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$on = F2($dzuk_mutant$elm_css$VirtualDom$Styled$on_fn);
    var $dzuk_mutant$elm_css$Html$Styled$Events$on_fn = function (event, decoder) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$on_fn(event, $elm$virtual_dom$VirtualDom$Normal(decoder));
    }, $dzuk_mutant$elm_css$Html$Styled$Events$on = F2($dzuk_mutant$elm_css$Html$Styled$Events$on_fn);
    var $dzuk_mutant$elm_css$Html$Styled$Events$onClick = function (msg) {
        return $dzuk_mutant$elm_css$Html$Styled$Events$on_fn("click", $elm$json$Json$Decode$succeed(msg));
    };
    var $dzuk_mutant$elm_css$Css$pointer = "pointer";
    var $author$project$Design$Palette$primary400 = $author$project$Design$Palette$RGB_fn(82, 84, 216);
    var $author$project$Design$Palette$success400 = $author$project$Design$Palette$RGB_fn(110, 231, 158);
    var $author$project$Design$Palette$warning400 = $author$project$Design$Palette$RGB_fn(252, 211, 77);
    var $author$project$Design$Button$toUI = function (_v0) {
        var props = _v0.a;
        var opt = _v0.b;
        var onClickMsg = opt.ao;
        var ifEnabled = F2(function (this_, that_) {
            return (!_Utils_eq(onClickMsg, $elm$core$Maybe$Nothing)) ? this_ : that_;
        });
        var activeColor = function () {
            var _v2 = opt.aR;
            switch (_v2) {
                case 0:
                    return $author$project$Design$Palette$danger400;
                case 1:
                    return $author$project$Design$Palette$primary400;
                case 2:
                    return $author$project$Design$Palette$success400;
                default:
                    return $author$project$Design$Palette$warning400;
            }
        }();
        return $author$project$Design$Container$hold_fn($dzuk_mutant$elm_css$Html$Styled$button, _List_fromArray([
            $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
                $dzuk_mutant$elm_css$Css$cursor(A2(ifEnabled, $dzuk_mutant$elm_css$Css$pointer, $dzuk_mutant$elm_css$Css$notAllowed))
            ])),
            $author$project$UI$shrink,
            $author$project$UI$fontSize(2),
            $author$project$UI$padding(0),
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$withAlpha_fn(0.85, A2(ifEnabled, activeColor, $author$project$Design$Palette$gray600))),
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toText_a0, $author$project$Design$Palette$toText_a1, $author$project$Design$Palette$genericWhite),
            function () {
                if (!onClickMsg.$) {
                    var msg = onClickMsg.a;
                    return $dzuk_mutant$elm_css$Html$Styled$Events$onClick(msg);
                }
                else {
                    return $dzuk_mutant$elm_css$Html$Styled$Attributes$boolProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$disabled_a0, true);
                }
            }(),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("aria-label", props.V),
            $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$title_a0, props.V)
        ]), _List_fromArray([
            $author$project$Design$Button$bodyView_fn(props, opt),
            $author$project$Design$Button$graphicsView_fn(opt, activeColor)
        ]));
    };
    var $author$project$Design$Button$withClick_fn = function (msg, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Button$Button_fn(props, _Utils_update(opts, {
            ao: $elm$core$Maybe$Just(msg)
        }));
    }, $author$project$Design$Button$withClick = F2($author$project$Design$Button$withClick_fn);
    var $author$project$Design$Button$withClickMaybe_fn = function (maybeMsg, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Button$Button_fn(props, _Utils_update(opts, { ao: maybeMsg }));
    }, $author$project$Design$Button$withClickMaybe = F2($author$project$Design$Button$withClickMaybe_fn);
    var $author$project$Design$Button$withStyle_fn = function (style, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Button$Button_fn(props, _Utils_update(opts, { aR: style }));
    }, $author$project$Design$Button$withStyle = F2($author$project$Design$Button$withStyle_fn);
    var $author$project$Components$Cart$gameInCart = function (_v0) {
        var name = _v0.a;
        var playersAmount = _v0.b;
        return $elm$core$Tuple$pair_fn(name, $author$project$UI$row_fn(_List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$axisCrossCenter,
            $author$project$UI$widthFill
        ]), _List_fromArray([
            $author$project$UI$lone_fn(_List_fromArray([
                $author$project$UI$flexFill(6)
            ]), $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, name)),
            $author$project$UI$lone_fn(_List_fromArray([
                $author$project$UI$flexFill(1)
            ]), $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, $elm$core$String$fromInt(playersAmount))),
            $author$project$UI$lone_fn(_List_fromArray([
                $author$project$UI$flexFill(1)
            ]), $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn((playersAmount > 20) ? $elm$core$Maybe$Just($author$project$Main$Msg$CartInsert_fn(name, playersAmount - 20)) : $elm$core$Maybe$Nothing, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iUserMinus("Less players"))))),
            $author$project$UI$lone_fn(_List_fromArray([
                $author$project$UI$flexFill(1)
            ]), $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn((playersAmount < 200) ? $elm$core$Maybe$Just($author$project$Main$Msg$CartInsert_fn(name, playersAmount + 20)) : $elm$core$Maybe$Nothing, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iUserPlus("Add more players"))))),
            $author$project$UI$lone_fn(_List_fromArray([
                $author$project$UI$flexFill(1)
            ]), $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(0, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$CartRemove(name), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iTrashCan("Remove from cart"))))))
        ])));
    };
    var $author$project$Design$Typo$h1_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$h1_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$h1(_List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$textAlign($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$shrink,
        $author$project$UI$fontSize(3)
    ]))), $author$project$Design$Typo$h1 = A2($elm$core$Basics$composeR, $author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1);
    var $dzuk_mutant$elm_css$Css$backgroundPosition = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("background-position:" + val);
    };
    var $dzuk_mutant$elm_css$Css$animationDuration = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("animation-duration:" + val);
    };
    var $dzuk_mutant$elm_css$Css$animationIterationCount = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("animation-iteration-count:" + val);
    };
    var $dzuk_mutant$elm_css$Css$animationName = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("animation-name:" + val);
    };
    var $dzuk_mutant$elm_css$Css$animationTimingFunction = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("animation-timing-function:" + val);
    };
    var $dzuk_mutant$elm_css$Css$customIdent = $elm$core$Basics$identity;
    var $dzuk_mutant$elm_css$Css$linear = "linear";
    var $dzuk_mutant$elm_css$Css$s = function (value) {
        return $elm$core$String$fromFloat(value) + "s";
    };
    var $author$project$UI$showAnimation = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$animationName($dzuk_mutant$elm_css$Css$customIdent("turn-on")),
        $dzuk_mutant$elm_css$Css$animationDuration($dzuk_mutant$elm_css$Css$s(1)),
        $dzuk_mutant$elm_css$Css$animationTimingFunction($dzuk_mutant$elm_css$Css$linear),
        $dzuk_mutant$elm_css$Css$animationIterationCount($dzuk_mutant$elm_css$Css$num(1))
    ]));
    var $author$project$Design$Container$windowAttributes = _Utils_ap($author$project$Design$Container$containerAttributes, _List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$backgroundImageMany(_List_fromArray([
                $dzuk_mutant$elm_css$Css$url("/assets/pattern-diagonal.png")
            ])),
            $dzuk_mutant$elm_css$Css$backgroundPosition($dzuk_mutant$elm_css$Css$center),
            $dzuk_mutant$elm_css$Css$backgroundPosition($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$showAnimation,
        $elm$core$Basics$composeR_fn($author$project$Design$Palette$toText_a0, $author$project$Design$Palette$toText_a1, $author$project$Design$Palette$genericWhite),
        $author$project$UI$flexFill(1)
    ]));
    var $author$project$Design$Container$window_fn = function (fn, attrs, body) {
        return A2(fn, _Utils_ap($author$project$Design$Container$windowAttributes, attrs), body);
    }, $author$project$Design$Container$window_fn_unwrapped = function (fn, attrs, body) {
        return fn(_Utils_ap($author$project$Design$Container$windowAttributes, attrs), body);
    }, $author$project$Design$Container$window = F3($author$project$Design$Container$window_fn);
    var $author$project$Design$Container$hero = function (attrs) {
        return A2($elm$core$Basics$composeR, $author$project$UI$column(_List_fromArray([
            $author$project$UI$axisCrossCenter,
            $author$project$UI$padding(2),
            $author$project$UI$gap(2),
            $author$project$UI$flexFill(1)
        ])), A2($author$project$Design$Container$window, $author$project$UI$lone, attrs));
    };
    var $dzuk_mutant$elm_css$Html$Styled$hr_a0 = "hr", $dzuk_mutant$elm_css$Html$Styled$hr = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$hr_a0);
    var $author$project$Design$Container$hr = $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$hr_a0, _List_fromArray([
        $author$project$Design$Palette$toBorder($author$project$Design$Palette$genericWhite),
        $author$project$UI$padding(0),
        $author$project$UI$widthFill
    ]), _List_Nil);
    var $dzuk_mutant$elm_css$Css$auto = "auto";
    var $dzuk_mutant$elm_css$Css$overflowY = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("overflow-y:" + val);
    };
    var $author$project$UI$overflowYAuto = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$overflowY($dzuk_mutant$elm_css$Css$auto)
    ]));
    var $dzuk_mutant$elm_css$Html$Styled$p_a0 = "p", $dzuk_mutant$elm_css$Html$Styled$p = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$p_a0);
    var $author$project$Design$Typo$paragraphs_a0 = $elm$core$List$map(A2($elm$core$Basics$composeR, $dzuk_mutant$elm_css$Html$Styled$text, A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$p(_List_Nil)))), $author$project$Design$Typo$paragraphs_a1 = $author$project$UI$column(_List_fromArray([
        $author$project$UI$gap(1),
        $author$project$Design$Typo$defaultSize
    ])), $author$project$Design$Typo$paragraphs = A2($elm$core$Basics$composeR, $author$project$Design$Typo$paragraphs_a0, $author$project$Design$Typo$paragraphs_a1);
    var $elm$core$Dict$foldl_fn = function (func, acc, dict) {
        foldl: while (true) {
            if (dict.$ === -2) {
                return acc;
            }
            else {
                var key = dict.b;
                var value = dict.c;
                var left = dict.d;
                var right = dict.e;
                var $temp$func = func, $temp$acc = A3(func, key, value, $elm$core$Dict$foldl_fn(func, acc, left)), $temp$dict = right;
                func = $temp$func;
                acc = $temp$acc;
                dict = $temp$dict;
                continue foldl;
            }
        }
    }, $elm$core$Dict$foldl_fn_unwrapped = function (func, acc, dict) {
        foldl: while (true) {
            if (dict.$ === -2) {
                return acc;
            }
            else {
                var key = dict.b;
                var value = dict.c;
                var left = dict.d;
                var right = dict.e;
                var $temp$func = func, $temp$acc = func(key, value, $elm$core$Dict$foldl_fn_unwrapped(func, acc, left)), $temp$dict = right;
                func = $temp$func;
                acc = $temp$acc;
                dict = $temp$dict;
                continue foldl;
            }
        }
    }, $elm$core$Dict$foldl = F3($elm$core$Dict$foldl_fn);
    var $author$project$Components$Cart$recommendPlan = function (model) {
        var playersSum = $elm$core$Dict$foldl_fn_unwrapped(function (_v0, v, a) {
            return a + v;
        }, 0, model.aX);
        return (playersSum > 400) ? _Utils_Tuple2("The Valmanway", "79.40") : ((playersSum > 200) ? _Utils_Tuple2("Laser Sword", "39.70") : ((playersSum > 40) ? _Utils_Tuple2("Long Sword", "19.90") : _Utils_Tuple2("Short Sword", "1.99")));
    };
    var $elm$core$Dict$sizeHelp_fn = function (n, dict) {
        sizeHelp: while (true) {
            if (dict.$ === -2) {
                return n;
            }
            else {
                var left = dict.d;
                var right = dict.e;
                var $temp$n = $elm$core$Dict$sizeHelp_fn(n + 1, right), $temp$dict = left;
                n = $temp$n;
                dict = $temp$dict;
                continue sizeHelp;
            }
        }
    }, $elm$core$Dict$sizeHelp = F2($elm$core$Dict$sizeHelp_fn);
    var $elm$core$Dict$size = function (dict) {
        return $elm$core$Dict$sizeHelp_fn(0, dict);
    };
    var $author$project$Components$Cart$box_fn = function (attrs, model) {
        return ($elm$core$Dict$size(model.aX) > 0) ? A2($author$project$Design$Container$hero, attrs, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Cart"),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$widthFill
            ]), _List_fromArray([
                $author$project$UI$lone_fn(_List_fromArray([
                    $author$project$UI$flexFill(6)
                ]), $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Game")),
                $author$project$UI$lone_fn(_List_fromArray([
                    $author$project$UI$flexFill(3)
                ]), $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Max. Players")),
                $author$project$UI$lone_fn(_List_fromArray([
                    $author$project$UI$flexFill(1)
                ]), $author$project$UI$none)
            ])),
            $author$project$Design$Container$hr,
            $author$project$UI$keyedColumn_fn(_List_fromArray([
                $author$project$UI$widthFill,
                $author$project$UI$gap(1),
                $author$project$UI$flexFill(1),
                $author$project$UI$overflowYAuto
            ]), $elm$core$List$map_fn($author$project$Components$Cart$gameInCart, $elm$core$Dict$toList(model.aX))),
            $author$project$Design$Container$hr,
            function () {
                var _v0 = $author$project$Components$Cart$recommendPlan(model);
                var name = _v0.a;
                var price = _v0.b;
                return $elm$core$Basics$composeR_fn($author$project$Design$Typo$paragraphs_a0, $author$project$Design$Typo$paragraphs_a1, _List_fromArray(["Recommended product:", name + " tier Server", "USD " + (price + "/month")]));
            }(),
            $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Checkout"))
        ])) : $author$project$UI$none;
    }, $author$project$Components$Cart$box = F2($author$project$Components$Cart$box_fn);
    var $dzuk_mutant$elm_css$Css$justifyContent = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("justify-content:" + val);
    };
    var $author$project$UI$axisMainCenter = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$justifyContent($dzuk_mutant$elm_css$Css$center)
    ]));
    var $author$project$UI$maxWidth = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$maxWidth($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $elm$core$Dict$get_fn = function (targetKey, dict) {
        get: while (true) {
            if (dict.$ === -2) {
                return $elm$core$Maybe$Nothing;
            }
            else {
                var key = dict.b;
                var value = dict.c;
                var left = dict.d;
                var right = dict.e;
                var _v1 = _Utils_compare_fn(targetKey, key);
                switch (_v1) {
                    case 0:
                        var $temp$targetKey = targetKey, $temp$dict = left;
                        targetKey = $temp$targetKey;
                        dict = $temp$dict;
                        continue get;
                    case 1:
                        return $elm$core$Maybe$Just(value);
                    default:
                        var $temp$targetKey = targetKey, $temp$dict = right;
                        targetKey = $temp$targetKey;
                        dict = $temp$dict;
                        continue get;
                }
            }
        }
    }, $elm$core$Dict$get = F2($elm$core$Dict$get_fn);
    var $elm$core$Dict$member_fn = function (key, dict) {
        var _v0 = $elm$core$Dict$get_fn(key, dict);
        if (!_v0.$) {
            return true;
        }
        else {
            return false;
        }
    }, $elm$core$Dict$member = F2($elm$core$Dict$member_fn);
    var $author$project$Design$Button$withGraphicsMaybe_fn = function (maybeGraphics, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Button$Button_fn(props, _Utils_update(opts, { aG: maybeGraphics }));
    }, $author$project$Design$Button$withGraphicsMaybe = F2($author$project$Design$Button$withGraphicsMaybe_fn);
    var $dzuk_mutant$elm_css$Css$flexWrap = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("flex-wrap:" + val);
    };
    var $dzuk_mutant$elm_css$Css$wrap = "wrap";
    var $author$project$UI$wrap = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$flexWrap($dzuk_mutant$elm_css$Css$wrap)
    ]));
    var $author$project$Components$GameShelf$gameButtons = function (model) {
        return A2($elm$core$Basics$composeR, $elm$core$List$map(function (_v0) {
            var title = _v0.a;
            var graphics = _v0.b;
            return $elm$core$Tuple$pair_fn(title, $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn($elm$core$Dict$member_fn(title, model.aX) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just($author$project$Main$Msg$CartInsert_fn(title, 20)), $author$project$Design$Button$withGraphicsMaybe_fn(graphics, $author$project$Design$Button$fromText(title)))));
        }), $author$project$UI$keyedRow(_List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$maxWidth(48),
            $author$project$UI$wrap,
            $author$project$UI$axisMainCenter
        ])));
    };
    var $author$project$Components$GameShelf$box_fn = function (attrs, model) {
        return A2($author$project$Design$Container$hero, attrs, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Host your gaming server"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "We provide support for a diverse range of games and numerous plugins!!"),
            A2($author$project$Components$GameShelf$gameButtons, model, _List_fromArray([
                _Utils_Tuple2("Minecraft (Mojang)", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Minecraft (Spigot)", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Terraria", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Valheim", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Factorio", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("L4D2", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("CS2", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("ARK", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("RUST", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Garry\u2019s Mode", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("FiveM", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Arma 3", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Satisfactory", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Conan Exiles", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("AmongUS", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Project Zomboid", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Just Cause 3", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("8bit MMO", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Hytale", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("Nanos", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("OpenMohaa", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("OpenRCT2", $elm$core$Maybe$Nothing),
                _Utils_Tuple2("SA-MP", $elm$core$Maybe$Nothing)
            ]))
        ]));
    }, $author$project$Components$GameShelf$box = F2($author$project$Components$GameShelf$box_fn);
    var $elm$virtual_dom$VirtualDom$lazy4 = _VirtualDom_lazy4;
    var $dzuk_mutant$elm_css$VirtualDom$Styled$UnscopedStyles = function (a) {
        return { $: 0, a: a };
    };
    var $dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles_fn = function (_v0, styles) {
        var isCssStyles = _v0.b;
        var cssTemplate = _v0.c;
        if (isCssStyles) {
            var _v1 = $elm$core$Dict$get_fn(cssTemplate, styles);
            if (!_v1.$) {
                return styles;
            }
            else {
                return $elm$core$Dict$insert_fn(cssTemplate, $dzuk_mutant$elm_css$Hash$fromString(cssTemplate), styles);
            }
        }
        else {
            return styles;
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles = F2($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute_fn = function (styles, _v0) {
        var val = _v0.a;
        var isCssStyles = _v0.b;
        var cssTemplate = _v0.c;
        if (isCssStyles) {
            var _v1 = $elm$core$Dict$get_fn(cssTemplate, styles);
            if (!_v1.$) {
                var classname = _v1.a;
                return $elm$virtual_dom$VirtualDom$property_fn("className", $elm$json$Json$Encode$string(classname));
            }
            else {
                return $elm$virtual_dom$VirtualDom$property_fn("className", $elm$json$Json$Encode$string("_unstyled"));
            }
        }
        else {
            return val;
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute = F2($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS_fn = function (styles, _v0) {
        var val = _v0.a;
        var isCssStyles = _v0.b;
        var cssTemplate = _v0.c;
        if (isCssStyles) {
            var _v1 = $elm$core$Dict$get_fn(cssTemplate, styles);
            if (!_v1.$) {
                var classname = _v1.a;
                return $elm$virtual_dom$VirtualDom$attribute_fn("class", classname);
            }
            else {
                return $elm$virtual_dom$VirtualDom$attribute_fn("class", "_unstyled");
            }
        }
        else {
            return val;
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS = F2($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS_fn);
    var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
        return _VirtualDom_keyedNodeNS_fn(_VirtualDom_keyedNode_a0, _VirtualDom_noScript(tag));
    };
    var $elm$virtual_dom$VirtualDom$keyedNodeNS_fn = function (namespace, tag) {
        return _VirtualDom_keyedNodeNS_fn(namespace, _VirtualDom_noScript(tag));
    }, $elm$virtual_dom$VirtualDom$keyedNodeNS = F2($elm$virtual_dom$VirtualDom$keyedNodeNS_fn);
    var $elm$virtual_dom$VirtualDom$node = function (tag) {
        return _VirtualDom_nodeNS_fn(_VirtualDom_node_a0, _VirtualDom_noScript(tag));
    };
    var $elm$virtual_dom$VirtualDom$nodeNS_fn = function (namespace, tag) {
        return _VirtualDom_nodeNS_fn(namespace, _VirtualDom_noScript(tag));
    }, $elm$virtual_dom$VirtualDom$nodeNS = F2($elm$virtual_dom$VirtualDom$nodeNS_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml_fn = function (_v6, _v7) {
        var key = _v6.a;
        var html = _v6.b;
        var pairs = _v7.a;
        var styles = _v7.b;
        switch (html.$) {
            case 4:
                var vdom = html.a;
                return _Utils_Tuple2(_List_Cons(_Utils_Tuple2(key, vdom), pairs), styles);
            case 0:
                var elemType = html.a;
                var properties = html.b;
                var children = html.c;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v9 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v9.a;
                var finalStyles = _v9.b;
                var vdom = A3($elm$virtual_dom$VirtualDom$node, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(_Utils_Tuple2(key, vdom), pairs), finalStyles);
            case 1:
                var ns = html.a;
                var elemType = html.b;
                var properties = html.c;
                var children = html.d;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v10 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v10.a;
                var finalStyles = _v10.b;
                var vdom = A4($elm$virtual_dom$VirtualDom$nodeNS, ns, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(_Utils_Tuple2(key, vdom), pairs), finalStyles);
            case 2:
                var elemType = html.a;
                var properties = html.b;
                var children = html.c;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v11 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v11.a;
                var finalStyles = _v11.b;
                var vdom = A3($elm$virtual_dom$VirtualDom$keyedNode, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(_Utils_Tuple2(key, vdom), pairs), finalStyles);
            default:
                var ns = html.a;
                var elemType = html.b;
                var properties = html.c;
                var children = html.d;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v12 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v12.a;
                var finalStyles = _v12.b;
                var vdom = A4($elm$virtual_dom$VirtualDom$keyedNodeNS, ns, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(_Utils_Tuple2(key, vdom), pairs), finalStyles);
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml_fn = function (html, _v0) {
        var nodes = _v0.a;
        var styles = _v0.b;
        switch (html.$) {
            case 4:
                var vdomNode = html.a;
                return _Utils_Tuple2(_List_Cons(vdomNode, nodes), styles);
            case 0:
                var elemType = html.a;
                var properties = html.b;
                var children = html.c;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v2 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v2.a;
                var finalStyles = _v2.b;
                var vdomNode = A3($elm$virtual_dom$VirtualDom$node, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(vdomNode, nodes), finalStyles);
            case 1:
                var ns = html.a;
                var elemType = html.b;
                var properties = html.c;
                var children = html.d;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v3 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v3.a;
                var finalStyles = _v3.b;
                var vdomNode = A4($elm$virtual_dom$VirtualDom$nodeNS, ns, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(vdomNode, nodes), finalStyles);
            case 2:
                var elemType = html.a;
                var properties = html.b;
                var children = html.c;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v4 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v4.a;
                var finalStyles = _v4.b;
                var vdomNode = A3($elm$virtual_dom$VirtualDom$keyedNode, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(vdomNode, nodes), finalStyles);
            default:
                var ns = html.a;
                var elemType = html.b;
                var properties = html.c;
                var children = html.d;
                var combinedStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
                var _v5 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml, _Utils_Tuple2(_List_Nil, combinedStyles), children);
                var childNodes = _v5.a;
                var finalStyles = _v5.b;
                var vdomNode = A4($elm$virtual_dom$VirtualDom$keyedNodeNS, ns, elemType, $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(finalStyles), properties), $elm$core$List$reverse(childNodes));
                return _Utils_Tuple2(_List_Cons(vdomNode, nodes), finalStyles);
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml_fn);
    var $elm$core$String$replace_fn = function (before, after, string) {
        return $elm$core$String$join_fn(after, $elm$core$String$split_fn(before, string));
    }, $elm$core$String$replace = F3($elm$core$String$replace_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$styleToDeclaration_fn = function (template, classname, declaration) {
        return declaration + ("\n" + $elm$core$String$replace_fn($dzuk_mutant$elm_css$VirtualDom$Styled$classnameStandin, classname, template));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$styleToDeclaration = F3($dzuk_mutant$elm_css$VirtualDom$Styled$styleToDeclaration_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
        return $elm$core$Dict$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$styleToDeclaration, "", dict);
    };
    var $dzuk_mutant$elm_css$VirtualDom$Styled$toScopedDeclaration_fn = function (scopingPrefix, dict) {
        return $elm$core$Dict$foldl_fn_unwrapped(function (template, classname, declaration) {
            return declaration + ("\n" + $elm$core$String$replace_fn("." + $dzuk_mutant$elm_css$VirtualDom$Styled$classnameStandin, "#" + (scopingPrefix + ("." + classname)), template));
        }, "", dict);
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$toScopedDeclaration = F2($dzuk_mutant$elm_css$VirtualDom$Styled$toScopedDeclaration_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$toStyleNode_fn = function (maybeNonce, accumulatedStyles) {
        var cssText = function () {
            if (!accumulatedStyles.$) {
                var allStyles = accumulatedStyles.a;
                return $dzuk_mutant$elm_css$VirtualDom$Styled$toDeclaration(allStyles);
            }
            else {
                var scope = accumulatedStyles.a;
                var rootStyles = accumulatedStyles.b;
                var descendantStyles = accumulatedStyles.c;
                return $dzuk_mutant$elm_css$VirtualDom$Styled$toScopedDeclaration_fn(scope, rootStyles) + ("\n" + $dzuk_mutant$elm_css$VirtualDom$Styled$toScopedDeclaration_fn(scope + " ", descendantStyles));
            }
        }();
        return A3($elm$virtual_dom$VirtualDom$node, "span", _List_fromArray([
            $elm$virtual_dom$VirtualDom$attribute_fn("style", "display: none;"),
            $elm$virtual_dom$VirtualDom$attribute_fn("class", "elm-css-style-wrapper")
        ]), _List_fromArray([
            A3($elm$virtual_dom$VirtualDom$node, "style", function () {
                if (!maybeNonce.$) {
                    var nonce = maybeNonce.a;
                    return _List_fromArray([
                        $elm$virtual_dom$VirtualDom$attribute_fn("nonce", nonce)
                    ]);
                }
                else {
                    return _List_Nil;
                }
            }(), $elm$core$List$singleton($elm$virtual_dom$VirtualDom$text(cssText)))
        ]));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$toStyleNode = F2($dzuk_mutant$elm_css$VirtualDom$Styled$toStyleNode_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$unstyle_fn = function (maybeNonce, elemType, properties, children) {
        var initialStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
        var _v0 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml, _Utils_Tuple2(_List_Nil, initialStyles), children);
        var childNodes = _v0.a;
        var styles = _v0.b;
        var styleNode = $dzuk_mutant$elm_css$VirtualDom$Styled$toStyleNode_fn(maybeNonce, $dzuk_mutant$elm_css$VirtualDom$Styled$UnscopedStyles(styles));
        var unstyledProperties = $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(styles), properties);
        return A3($elm$virtual_dom$VirtualDom$node, elemType, unstyledProperties, _List_Cons(styleNode, $elm$core$List$reverse(childNodes)));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$unstyle = F4($dzuk_mutant$elm_css$VirtualDom$Styled$unstyle_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$containsKey_fn = function (key, pairs) {
        containsKey: while (true) {
            if (!pairs.b) {
                return false;
            }
            else {
                var _v1 = pairs.a;
                var str = _v1.a;
                var rest = pairs.b;
                if (_Utils_eq(key, str)) {
                    return true;
                }
                else {
                    var $temp$key = key, $temp$pairs = rest;
                    key = $temp$key;
                    pairs = $temp$pairs;
                    continue containsKey;
                }
            }
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$containsKey = F2($dzuk_mutant$elm_css$VirtualDom$Styled$containsKey_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$getUnusedKey_fn = function (_default, pairs) {
        getUnusedKey: while (true) {
            if (!pairs.b) {
                return _default;
            }
            else {
                var _v1 = pairs.a;
                var firstKey = _v1.a;
                var rest = pairs.b;
                var newKey = "_" + firstKey;
                if ($dzuk_mutant$elm_css$VirtualDom$Styled$containsKey_fn(newKey, rest)) {
                    var $temp$default = newKey, $temp$pairs = rest;
                    _default = $temp$default;
                    pairs = $temp$pairs;
                    continue getUnusedKey;
                }
                else {
                    return newKey;
                }
            }
        }
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$getUnusedKey = F2($dzuk_mutant$elm_css$VirtualDom$Styled$getUnusedKey_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$toKeyedStyleNode_fn = function (maybeNonce, accumulatedStyles, keyedChildNodes) {
        var styleNodeKey = $dzuk_mutant$elm_css$VirtualDom$Styled$getUnusedKey_fn("_", keyedChildNodes);
        var finalNode = $dzuk_mutant$elm_css$VirtualDom$Styled$toStyleNode_fn(maybeNonce, accumulatedStyles);
        return _Utils_Tuple2(styleNodeKey, finalNode);
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$toKeyedStyleNode = F3($dzuk_mutant$elm_css$VirtualDom$Styled$toKeyedStyleNode_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyed_fn = function (maybeNonce, elemType, properties, keyedChildren) {
        var initialStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
        var _v0 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml, _Utils_Tuple2(_List_Nil, initialStyles), keyedChildren);
        var keyedChildNodes = _v0.a;
        var styles = _v0.b;
        var keyedStyleNode = $dzuk_mutant$elm_css$VirtualDom$Styled$toKeyedStyleNode_fn(maybeNonce, $dzuk_mutant$elm_css$VirtualDom$Styled$UnscopedStyles(styles), keyedChildNodes);
        var unstyledProperties = $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttribute(styles), properties);
        return A3($elm$virtual_dom$VirtualDom$keyedNode, elemType, unstyledProperties, _List_Cons(keyedStyleNode, $elm$core$List$reverse(keyedChildNodes)));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyed = F4($dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyed_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyedNS_fn = function (maybeNonce, ns, elemType, properties, keyedChildren) {
        var initialStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
        var _v0 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml, _Utils_Tuple2(_List_Nil, initialStyles), keyedChildren);
        var keyedChildNodes = _v0.a;
        var styles = _v0.b;
        var keyedStyleNode = $dzuk_mutant$elm_css$VirtualDom$Styled$toKeyedStyleNode_fn(maybeNonce, $dzuk_mutant$elm_css$VirtualDom$Styled$UnscopedStyles(styles), keyedChildNodes);
        var unstyledProperties = $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(styles), properties);
        return A4($elm$virtual_dom$VirtualDom$keyedNodeNS, ns, elemType, unstyledProperties, _List_Cons(keyedStyleNode, $elm$core$List$reverse(keyedChildNodes)));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyedNS = F5($dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyedNS_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleNS_fn = function (maybeNonce, ns, elemType, properties, children) {
        var initialStyles = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
        var _v0 = $elm$core$List$foldl_fn($dzuk_mutant$elm_css$VirtualDom$Styled$accumulateStyledHtml, _Utils_Tuple2(_List_Nil, initialStyles), children);
        var childNodes = _v0.a;
        var styles = _v0.b;
        var styleNode = $dzuk_mutant$elm_css$VirtualDom$Styled$toStyleNode_fn(maybeNonce, $dzuk_mutant$elm_css$VirtualDom$Styled$UnscopedStyles(styles));
        var unstyledProperties = $elm$core$List$map_fn($dzuk_mutant$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(styles), properties);
        return A4($elm$virtual_dom$VirtualDom$nodeNS, ns, elemType, unstyledProperties, _List_Cons(styleNode, $elm$core$List$reverse(childNodes)));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleNS = F5($dzuk_mutant$elm_css$VirtualDom$Styled$unstyleNS_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
        switch (vdom.$) {
            case 4:
                var plainNode = vdom.a;
                return plainNode;
            case 0:
                var elemType = vdom.a;
                var properties = vdom.b;
                var children = vdom.c;
                return $dzuk_mutant$elm_css$VirtualDom$Styled$unstyle_fn($elm$core$Maybe$Nothing, elemType, properties, children);
            case 1:
                var ns = vdom.a;
                var elemType = vdom.b;
                var properties = vdom.c;
                var children = vdom.d;
                return $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleNS_fn($elm$core$Maybe$Nothing, ns, elemType, properties, children);
            case 2:
                var elemType = vdom.a;
                var properties = vdom.b;
                var children = vdom.c;
                return $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyed_fn($elm$core$Maybe$Nothing, elemType, properties, children);
            default:
                var ns = vdom.a;
                var elemType = vdom.b;
                var properties = vdom.c;
                var children = vdom.d;
                return $dzuk_mutant$elm_css$VirtualDom$Styled$unstyleKeyedNS_fn($elm$core$Maybe$Nothing, ns, elemType, properties, children);
        }
    };
    var $dzuk_mutant$elm_css$VirtualDom$Styled$lazyHelp3_fn = function (fn, arg1, arg2, arg3) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$toUnstyled(A3(fn, arg1, arg2, arg3));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$lazyHelp3_fn_unwrapped = function (fn, arg1, arg2, arg3) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$toUnstyled(fn(arg1, arg2, arg3));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$lazyHelp3 = F4($dzuk_mutant$elm_css$VirtualDom$Styled$lazyHelp3_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$lazy3_fn = function (fn, arg1, arg2, arg3) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Unstyled(_VirtualDom_lazy4_fn($dzuk_mutant$elm_css$VirtualDom$Styled$lazyHelp3, fn, arg1, arg2, arg3));
    }, $dzuk_mutant$elm_css$VirtualDom$Styled$lazy3 = F4($dzuk_mutant$elm_css$VirtualDom$Styled$lazy3_fn);
    var $dzuk_mutant$elm_css$Html$Styled$Lazy$lazy3 = $dzuk_mutant$elm_css$VirtualDom$Styled$lazy3;
    var $author$project$UI$lazy3 = $dzuk_mutant$elm_css$Html$Styled$Lazy$lazy3;
    var $dzuk_mutant$elm_css$Css$minWidth = function (_v0) {
        var sizeVal = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("min-width:" + sizeVal);
    };
    var $author$project$UI$minWidth = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$minWidth($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $author$project$UI$minWidthSmallest = $author$project$UI$minWidth(28);
    var $author$project$UI$centerLone = function (attrs) {
        return $author$project$UI$lone(_List_Cons($author$project$UI$axisMainCenter, _List_Cons($author$project$UI$axisCrossCenter, _List_Cons($author$project$UI$flexFill(1), attrs))));
    };
    var $author$project$Pages$Dashboard$View$overview_fn = function (_v0, _v1) {
        return _Utils_Tuple2("overview", $author$project$Design$Container$hold_fn($author$project$UI$centerLone, _List_Nil, $author$project$UI$lone_fn(_List_Nil, A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Overview"),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$wrap,
                $author$project$UI$axisMainCenter
            ]), _List_fromArray([
                $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Profile")),
                $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Billing")),
                $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Set e-mails")),
                $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Change password"))
            ]))
        ])))));
    }, $author$project$Pages$Dashboard$View$overview = F2($author$project$Pages$Dashboard$View$overview_fn);
    var $author$project$Design$Typo$h4_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$h4_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$h1(_List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$textAlign($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$shrink,
        $author$project$UI$fontSize(2.25)
    ]))), $author$project$Design$Typo$h4 = A2($elm$core$Basics$composeR, $author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1);
    var $author$project$Design$Container$hero1 = function (attrs) {
        return A2($author$project$Design$Container$window, $author$project$UI$lone, _List_Cons($author$project$UI$padding(2), attrs));
    };
    var $author$project$Maple$Data$Game$instanceName = function (_v0) {
        var cuteId = _v0.bE;
        var nickname = _v0.b1;
        return $elm$core$Maybe$withDefault_fn(cuteId, nickname);
    };
    var $author$project$Pages$Dashboard$Server$View$gameLog = function (instance) {
        return A2($author$project$Design$Container$hero1, _List_Nil, $author$project$UI$column_fn(_List_fromArray([
            $author$project$UI$gap(1)
        ]), _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "Logs (" + ($author$project$Maple$Data$Game$instanceName(instance) + ")")),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$paragraphs_a0, $author$project$Design$Typo$paragraphs_a1, $elm$core$List$map_fn(function ($) {
                return $.c2;
            }, instance.dF.dD))
        ])));
    };
    var $dzuk_mutant$elm_css$Css$fixed = "fixed";
    var $dzuk_mutant$elm_css$Css$int = function (value) {
        return $elm$core$String$fromInt(value);
    };
    var $dzuk_mutant$elm_css$Css$position = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("position:" + val);
    };
    var $dzuk_mutant$elm_css$Css$right = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("right:" + val);
    };
    var $dzuk_mutant$elm_css$Css$top = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("top:" + val);
    };
    var $dzuk_mutant$elm_css$Css$zIndex = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("z-index:" + val);
    };
    var $author$project$UI$floating = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$position($dzuk_mutant$elm_css$Css$fixed),
        $dzuk_mutant$elm_css$Css$zIndex($dzuk_mutant$elm_css$Css$int(2)),
        $dzuk_mutant$elm_css$Css$right($dzuk_mutant$elm_css$Css$rem(2)),
        $dzuk_mutant$elm_css$Css$top($dzuk_mutant$elm_css$Css$rem(2))
    ]));
    var $dzuk_mutant$elm_css$Css$property_fn = function (key, value) {
        return $dzuk_mutant$elm_css$Css$appendProperty(key + (":" + value));
    }, $dzuk_mutant$elm_css$Css$property = F2($dzuk_mutant$elm_css$Css$property_fn);
    var $author$project$UI$fullTransition = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$property_fn("transition", "all 1s cubic-bezier(0.4, 0, 0.2, 1)")
    ]));
    var $dzuk_mutant$elm_css$Css$clip = "clip";
    var $dzuk_mutant$elm_css$Css$overflow = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("overflow:" + val);
    };
    var $author$project$UI$overflowBothClip = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$overflow($dzuk_mutant$elm_css$Css$clip)
    ]));
    var $author$project$Design$Palette$warning600 = $author$project$Design$Palette$RGB_fn(235, 160, 10);
    var $author$project$Components$Dashboard$refreshing = function (now) {
        var base = _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$warning600),
            $author$project$UI$borderRounded(1),
            $author$project$UI$border,
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toText_a0, $author$project$Design$Palette$toText_a1, $author$project$Design$Palette$genericWhite),
            $author$project$Design$Palette$toBorder($author$project$Design$Palette$genericWhite),
            $author$project$UI$fullTransition,
            $author$project$UI$overflowBothClip,
            $author$project$UI$floating
        ]);
        var attrs = now ? _List_Cons($author$project$UI$padding(1), base) : _List_Cons($author$project$UI$padding(0), _List_Cons($author$project$UI$height(0), _List_Cons($author$project$UI$width(0), base)));
        return $author$project$UI$lone_fn(attrs, $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "Fetching most recent data..."));
    };
    var $author$project$Main$Msg$Logout = { $: 9 };
    var $author$project$Design$Palette$StyleWarning = 3;
    var $author$project$UI$flexStuff = function (amount) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$div_a0, _List_fromArray([
            $author$project$UI$flexFill(amount)
        ]), _List_Nil);
    };
    var $author$project$Design$Input$Input_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $author$project$Design$Input$Input = F2($author$project$Design$Input$Input_fn);
    var $author$project$Design$Input$default = { aI: $elm$core$Maybe$Nothing, ap: $elm$core$Maybe$Nothing, Y: $elm$core$Maybe$Nothing };
    var $author$project$Design$Input$forText_fn = function (label, value) {
        return $author$project$Design$Input$Input_fn({ am: "text", V: label, az: value }, _Utils_update($author$project$Design$Input$default, {
            Y: $elm$core$Maybe$Just("New name")
        }));
    }, $author$project$Design$Input$forText = F2($author$project$Design$Input$forText_fn);
    var $author$project$Maple$Data$Server$getName = function (_v0) {
        var cuteId = _v0.bE;
        var nickname = _v0.b1;
        return $elm$core$Maybe$withDefault_fn(cuteId, nickname);
    };
    var $elm$json$Json$Decode$fail = _Json_fail;
    var $elm$json$Json$Decode$field = _Json_decodeField;
    var $author$project$Design$Palette$gray400 = $author$project$Design$Palette$RGB_fn(194, 200, 203);
    var $dzuk_mutant$elm_css$Html$Styled$input_a0 = "input", $dzuk_mutant$elm_css$Html$Styled$input = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$input_a0);
    var $author$project$UI$nilAttr = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_Nil);
    var $dzuk_mutant$elm_css$Html$Styled$Events$alwaysStop = function (x) {
        return _Utils_Tuple2(x, true);
    };
    var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
        return { $: 1, a: a };
    };
    var $dzuk_mutant$elm_css$Html$Styled$Events$stopPropagationOn_fn = function (event, decoder) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$on_fn(event, $elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
    }, $dzuk_mutant$elm_css$Html$Styled$Events$stopPropagationOn = F2($dzuk_mutant$elm_css$Html$Styled$Events$stopPropagationOn_fn);
    var $elm$json$Json$Decode$at_fn = function (fields, decoder) {
        return $elm$core$List$foldr_fn($elm$json$Json$Decode$field, decoder, fields);
    }, $elm$json$Json$Decode$at = F2($elm$json$Json$Decode$at_fn);
    var $elm$json$Json$Decode$string = _Json_decodeString;
    var $dzuk_mutant$elm_css$Html$Styled$Events$targetValue = $elm$json$Json$Decode$at_fn(_List_fromArray(["target", "value"]), $elm$json$Json$Decode$string);
    var $dzuk_mutant$elm_css$Html$Styled$Events$onInput = function (tagger) {
        return $dzuk_mutant$elm_css$Html$Styled$Events$stopPropagationOn_fn("input", _Json_map1_fn($dzuk_mutant$elm_css$Html$Styled$Events$alwaysStop, _Json_map1_fn(tagger, $dzuk_mutant$elm_css$Html$Styled$Events$targetValue)));
    };
    var $dzuk_mutant$elm_css$Css$padding4_fn = function (_v0, _v1, _v2, _v3) {
        var valueTop = _v0;
        var valueRight = _v1;
        var valueBottom = _v2;
        var valueLeft = _v3;
        return $dzuk_mutant$elm_css$Css$appendProperty("padding:" + (valueTop + (" " + (valueRight + (" " + (valueBottom + (" " + valueLeft)))))));
    }, $dzuk_mutant$elm_css$Css$padding4 = F4($dzuk_mutant$elm_css$Css$padding4_fn);
    var $author$project$UI$paddingEach = function (_v0) {
        var top = _v0.ev;
        var right = _v0.ef;
        var bottom = _v0.cQ;
        var left = _v0.dB;
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css($elm$core$List$singleton($dzuk_mutant$elm_css$Css$padding4_fn($dzuk_mutant$elm_css$Css$rem(top), $dzuk_mutant$elm_css$Css$rem(right), $dzuk_mutant$elm_css$Css$rem(bottom), $dzuk_mutant$elm_css$Css$rem(left))));
    };
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$placeholder_a0 = "placeholder", $dzuk_mutant$elm_css$Html$Styled$Attributes$placeholder = $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty($dzuk_mutant$elm_css$Html$Styled$Attributes$placeholder_a0);
    var $author$project$Design$Palette$primary600 = $author$project$Design$Palette$RGB_fn(25, 34, 148);
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$type__a0 = "type", $dzuk_mutant$elm_css$Html$Styled$Attributes$type_ = $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty($dzuk_mutant$elm_css$Html$Styled$Attributes$type__a0);
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$value_a0 = "value", $dzuk_mutant$elm_css$Html$Styled$Attributes$value = $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty($dzuk_mutant$elm_css$Html$Styled$Attributes$value_a0);
    var $author$project$Design$Input$toUI = function (_v0) {
        var label = _v0.a.V;
        var value = _v0.a.az;
        var kind = _v0.a.am;
        var onChangeMsg = _v0.b.aI;
        var onReturnKeyMsg = _v0.b.ap;
        var placeholder = _v0.b.Y;
        var ifEnabled = F2(function (this_, that_) {
            return (!_Utils_eq(onChangeMsg, $elm$core$Maybe$Nothing)) ? this_ : that_;
        });
        return $author$project$Design$Container$hold_fn($dzuk_mutant$elm_css$Html$Styled$input, _List_fromArray([
            $author$project$UI$widthFill,
            $author$project$Design$Typo$defaultSize,
            $author$project$UI$paddingEach({ cQ: 1, dB: 1, ef: 2, ev: 1 }),
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$withAlpha_fn(0.85, A2(ifEnabled, $author$project$Design$Palette$primary400, $author$project$Design$Palette$gray600))),
            $author$project$Design$Palette$toBorder(A2(ifEnabled, $author$project$Design$Palette$primary600, $author$project$Design$Palette$gray400)),
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toText_a0, $author$project$Design$Palette$toText_a1, $author$project$Design$Palette$genericWhite),
            $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$value_a0, value),
            $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$type__a0, kind),
            $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$title_a0, label),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("aria-label", label),
            function () {
                if (!placeholder.$) {
                    var placeholderValue = placeholder.a;
                    return $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$placeholder_a0, placeholderValue);
                }
                else {
                    return $author$project$UI$nilAttr;
                }
            }(),
            function () {
                if (!onChangeMsg.$) {
                    var msg = onChangeMsg.a;
                    return $dzuk_mutant$elm_css$Html$Styled$Events$onInput(msg);
                }
                else {
                    return $dzuk_mutant$elm_css$Html$Styled$Attributes$boolProperty_fn($dzuk_mutant$elm_css$Html$Styled$Attributes$disabled_a0, true);
                }
            }(),
            function () {
                if (!onReturnKeyMsg.$) {
                    var msg = onReturnKeyMsg.a;
                    return $dzuk_mutant$elm_css$Html$Styled$Events$on_fn("keyup", _Json_andThen_fn(function (key) {
                        return (key === "Enter") ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail("Not the enter key");
                    }, _Json_decodeField_fn("key", $elm$json$Json$Decode$string)));
                }
                else {
                    return $author$project$UI$nilAttr;
                }
            }()
        ]), _List_Nil);
    };
    var $author$project$Pages$Dashboard$Server$View$serverHeader_fn = function (server, _v0) {
        return $author$project$UI$row_fn(_List_fromArray([
            $author$project$UI$gap(2),
            $author$project$UI$axisCrossCenter,
            $author$project$UI$widthFill,
            $author$project$UI$wrap
        ]), _List_fromArray([
            A2($author$project$Design$Container$hero1, _List_fromArray([$author$project$UI$shrink]), $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Server " + $author$project$Maple$Data$Server$getName(server))),
            $author$project$UI$flexStuff(1),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$axisCrossCenter
            ]), _List_fromArray([
                $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(3, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromText("Stop (Power-off)")))),
                $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(0, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromText("Delete (Permanently)"))))
            ])),
            $author$project$UI$flexStuff(1),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$axisCrossCenter
            ]), _List_fromArray([
                $author$project$UI$lone_fn(_List_fromArray([$author$project$UI$shrink]), $author$project$Design$Input$toUI($author$project$Design$Input$forText_fn("Name", $elm$core$Maybe$withDefault_fn(server.bE, server.b1)))),
                $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromText("Rename")))
            ]))
        ]));
    }, $author$project$Pages$Dashboard$Server$View$serverHeader = F2($author$project$Pages$Dashboard$Server$View$serverHeader_fn);
    var $dzuk_mutant$elm_css$Css$stop2_fn = function (_v0, _v1) {
        var colorVal = _v0;
        var positionVal = _v1;
        return colorVal + (" " + positionVal);
    }, $dzuk_mutant$elm_css$Css$stop2 = F2($dzuk_mutant$elm_css$Css$stop2_fn);
    var $author$project$Design$ProgressBar$bar__fn = function (progress, s2, s4, s6, s8) {
        var stop = F2(function (x, color) {
            return $dzuk_mutant$elm_css$Css$stop2_fn($author$project$Design$Palette$unbox_fn($dzuk_mutant$elm_css$Css$rgba, color), $dzuk_mutant$elm_css$Css$pct(x));
        });
        return $author$project$UI$lone_fn(_List_fromArray([
            $author$project$UI$widthFill,
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, s2)
        ]), $author$project$UI$lone_fn(_List_fromArray([
            $author$project$UI$widthFill,
            $author$project$UI$height(3),
            $author$project$UI$maxWidthPct(progress),
            $dzuk_mutant$elm_css$Html$Styled$Attributes$css($elm$core$List$singleton($dzuk_mutant$elm_css$Css$backgroundImageMany($elm$core$List$singleton($dzuk_mutant$elm_css$Css$linearGradient2_fn($dzuk_mutant$elm_css$Css$toBottom, A2(stop, 0, s4), A2(stop, 70, s6), _List_fromArray([
                A2(stop, 100, s8)
            ]))))))
        ]), $author$project$UI$none));
    }, $author$project$Design$ProgressBar$bar_ = F5($author$project$Design$ProgressBar$bar__fn);
    var $author$project$Design$Palette$danger200 = $author$project$Design$Palette$RGB_fn(254, 226, 226);
    var $author$project$Design$Palette$danger800 = $author$project$Design$Palette$RGB_fn(140, 16, 16);
    var $author$project$Design$ProgressBar$danger = function (progress) {
        return $author$project$Design$ProgressBar$bar__fn(progress, $author$project$Design$Palette$danger200, $author$project$Design$Palette$danger400, $author$project$Design$Palette$danger600, $author$project$Design$Palette$danger800);
    };
    var $dzuk_mutant$elm_css$Html$Styled$span_a0 = "span", $dzuk_mutant$elm_css$Html$Styled$span = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$span_a0);
    var $author$project$Design$Typo$label_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$label_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$span(_List_fromArray([$author$project$UI$shrink, $author$project$Design$Typo$defaultSize]))), $author$project$Design$Typo$label = A2($elm$core$Basics$composeR, $author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1);
    var $dzuk_mutant$elm_css$Css$Media$minWidth = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$Media$feature_fn("min-width", val);
    };
    var $author$project$UI$screenMinLg_a0 = _List_fromArray([
        $dzuk_mutant$elm_css$Css$Structure$OnlyQuery_fn($dzuk_mutant$elm_css$Css$Media$screen, _List_fromArray([
            $dzuk_mutant$elm_css$Css$Media$minWidth($dzuk_mutant$elm_css$Css$px(1024))
        ]))
    ]), $author$project$UI$screenMinLg = $dzuk_mutant$elm_css$Css$Media$withMedia($author$project$UI$screenMinLg_a0);
    var $author$project$UI$maxWidthLg = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$Preprocess$WithMedia_fn($author$project$UI$screenMinLg_a0, _List_fromArray([
                $dzuk_mutant$elm_css$Css$maxWidth($dzuk_mutant$elm_css$Css$rem(size))
            ]))
        ]));
    };
    var $author$project$Design$Palette$success200 = $author$project$Design$Palette$RGB_fn(209, 250, 225);
    var $author$project$Design$Palette$success600 = $author$project$Design$Palette$RGB_fn(7, 171, 102);
    var $author$project$Design$Palette$success800 = $author$project$Design$Palette$RGB_fn(6, 78, 43);
    var $author$project$Design$ProgressBar$success = function (progress) {
        return $author$project$Design$ProgressBar$bar__fn(progress, $author$project$Design$Palette$success200, $author$project$Design$Palette$success400, $author$project$Design$Palette$success600, $author$project$Design$Palette$success800);
    };
    var $author$project$Pages$Dashboard$Server$View$serverResources_fn = function (_v0, extra) {
        return A2($author$project$Design$Container$hero1, _List_fromArray([
            $author$project$UI$minWidthSmallest,
            $author$project$UI$maxWidthLg(40),
            $author$project$UI$flexFill(1)
        ]), $author$project$UI$column_fn(_List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$widthFill
        ]), _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "RAM"),
            $author$project$Design$ProgressBar$danger((extra.es.dM.dA * 100) / extra.es.dM.dI),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "Storage"),
            $author$project$Design$ProgressBar$success((extra.es.ct.dA * 100) / extra.es.ct.dI),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "CPU Load"),
            $author$project$Design$ProgressBar$success((extra.es.c6.dA * 100) / extra.es.c6.dI),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "Uptime"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1, "32 days, 6 hours, 5 minutes, and 32 seconds.")
        ])));
    }, $author$project$Pages$Dashboard$Server$View$serverResources = F2($author$project$Pages$Dashboard$Server$View$serverResources_fn);
    var $author$project$Maple$Data$Game$gameName = function (game) {
        if (!game.$) {
            return "Minecraft";
        }
        else {
            return "Terraria";
        }
    };
    var $author$project$Pages$Dashboard$Server$View$gamePlayers = function (instance) {
        var game = instance.dj;
        var players = instance.d9;
        return $elm$core$Basics$composeR_fn($author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1, $author$project$Maple$Data$Game$instanceName(instance) + (": " + ($author$project$Maple$Data$Game$gameName(game) + (", " + ($elm$core$String$fromFloat(players.dA) + (" in-game, " + ($elm$core$String$fromInt(instance.cH) + " registered.")))))));
    };
    var $author$project$Pages$Dashboard$Server$View$serverSummary_fn = function (_v0, extra) {
        return A2($author$project$Design$Container$hero1, _List_Nil, $author$project$UI$column_fn(_List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$flexFill(1),
            $author$project$UI$minWidthSmallest
        ]), _List_fromArray([
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$axisCrossCenter
            ]), _List_fromArray([
                $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "Team Members"),
                $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iUserPlus("Add more members"))))
            ])),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$axisCrossCenter,
                $author$project$UI$wrap
            ]), _List_fromArray([
                $elm$core$Basics$composeR_fn($author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1, "You and:"),
                $author$project$Design$Container$hold_fn($author$project$UI$row, _List_fromArray([
                    $author$project$UI$gap(1),
                    $author$project$UI$axisCrossCenter,
                    $author$project$UI$padding(1)
                ]), _List_fromArray([
                    $elm$core$Basics$composeR_fn($author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1, "Rafael"),
                    $author$project$Design$Button$toUI($author$project$Design$Button$fromIcon($author$project$Design$Icon$iTrashCan("Remove Rafael")))
                ])),
                $author$project$Design$Container$hold_fn($author$project$UI$row, _List_fromArray([
                    $author$project$UI$gap(1),
                    $author$project$UI$axisCrossCenter,
                    $author$project$UI$padding(1)
                ]), _List_fromArray([
                    $elm$core$Basics$composeR_fn($author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1, "Pedro"),
                    $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iTrashCan("Remove Pedro"))))
                ])),
                $author$project$Design$Container$hold_fn($author$project$UI$row, _List_fromArray([
                    $author$project$UI$gap(1),
                    $author$project$UI$axisCrossCenter,
                    $author$project$UI$padding(1)
                ]), _List_fromArray([
                    $elm$core$Basics$composeR_fn($author$project$Design$Typo$label_a0, $author$project$Design$Typo$label_a1, "Melina"),
                    $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iTrashCan("Remove Melina"))))
                ]))
            ])),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h4_a0, $author$project$Design$Typo$h4_a1, "Players"),
            $author$project$UI$column_fn(_List_fromArray([
                $author$project$UI$gap(1)
            ]), $elm$core$List$map_fn($author$project$Pages$Dashboard$Server$View$gamePlayers, extra.dk))
        ])));
    }, $author$project$Pages$Dashboard$Server$View$serverSummary = F2($author$project$Pages$Dashboard$Server$View$serverSummary_fn);
    var $author$project$Pages$Dashboard$Server$View$loadedServer_fn = function (loading, server, extra) {
        return $author$project$Design$Container$hold_fn($author$project$UI$column, _List_fromArray([
            $author$project$UI$gap(2),
            $author$project$UI$flexFill(1),
            $author$project$UI$padding(1),
            $author$project$UI$overflowYAuto
        ]), _List_Cons($author$project$Components$Dashboard$refreshing(loading), _List_Cons($author$project$Pages$Dashboard$Server$View$serverHeader_fn(server, extra), _List_Cons($author$project$UI$row_fn(_List_fromArray([
            $author$project$UI$gap(2),
            $author$project$UI$wrap
        ]), _List_fromArray([
            $author$project$Pages$Dashboard$Server$View$serverResources_fn(server, extra),
            $author$project$Pages$Dashboard$Server$View$serverSummary_fn(server, extra)
        ])), $elm$core$List$map_fn($author$project$Pages$Dashboard$Server$View$gameLog, extra.dk)))));
    }, $author$project$Pages$Dashboard$Server$View$loadedServer = F3($author$project$Pages$Dashboard$Server$View$loadedServer_fn);
    var $author$project$Design$Icon$iMagnifyingGlass = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "magnifying-glass"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iSpinner = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "spinner"), _Utils_update($author$project$Design$Icon$defaultOptions, { aO: true }));
    };
    var $author$project$UI$stuff = function (size) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$div_a0, _List_fromArray([
            $author$project$UI$shrink,
            $author$project$UI$padding(size)
        ]), _List_Nil);
    };
    var $author$project$Components$Dashboard$searching_fn = function (title, what) {
        return $author$project$Design$Container$hold_fn($author$project$UI$centerLone, _List_Nil, $author$project$UI$lone_fn(_List_Nil, A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, title),
            $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(6, $author$project$Design$Icon$iMagnifyingGlass("Searching"))),
            $author$project$UI$column_fn(_List_fromArray([$author$project$UI$widthFill, $author$project$UI$axisCrossCenter]), _List_fromArray([
                $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "We're searching for " + what),
                $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Please wait..."),
                $author$project$UI$stuff(1),
                $author$project$Design$Icon$toUI($author$project$Design$Icon$iSpinner("Loading"))
            ]))
        ]))));
    }, $author$project$Components$Dashboard$searching = F2($author$project$Components$Dashboard$searching_fn);
    var $author$project$Components$Dashboard$searchingServer = function (cuteId) {
        return $author$project$Components$Dashboard$searching_fn("Server " + cuteId, "your server");
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$findl_fn = function (predicate, dict) {
        var find_ = function (dict_) {
            find_: while (true) {
                if (dict_.$ === -2) {
                    return $elm$core$Maybe$Nothing;
                }
                else {
                    var key = dict_.b;
                    var value = dict_.c;
                    var left = dict_.d;
                    var right = dict_.e;
                    if (A2(predicate, key, value)) {
                        return $elm$core$Maybe$Just(_Utils_Tuple2(key, value));
                    }
                    else {
                        var _v1 = find_(left);
                        if (_v1.$ === 1) {
                            var $temp$dict_ = right;
                            dict_ = $temp$dict_;
                            continue find_;
                        }
                        else {
                            var found = _v1;
                            return found;
                        }
                    }
                }
            }
        };
        return find_(dict);
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$findl_fn_unwrapped = function (predicate, dict) {
        var find_ = function (dict_) {
            find_: while (true) {
                if (dict_.$ === -2) {
                    return $elm$core$Maybe$Nothing;
                }
                else {
                    var key = dict_.b;
                    var value = dict_.c;
                    var left = dict_.d;
                    var right = dict_.e;
                    if (predicate(key, value)) {
                        return $elm$core$Maybe$Just(_Utils_Tuple2(key, value));
                    }
                    else {
                        var _v1 = find_(left);
                        if (_v1.$ === 1) {
                            var $temp$dict_ = right;
                            dict_ = $temp$dict_;
                            continue find_;
                        }
                        else {
                            var found = _v1;
                            return found;
                        }
                    }
                }
            }
        };
        return find_(dict);
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$findl = F2($PedroHLC$elm_uuid_dict$UUID$Dict$findl_fn);
    var $elm$core$Tuple$second = function (_v0) {
        var y = _v0.b;
        return y;
    };
    var $author$project$Maple$Data$Server$slowGetByCute = function (cuteId) {
        return A2($elm$core$Basics$composeR, $PedroHLC$elm_uuid_dict$UUID$Dict$findl(F2(function (_v0, v) {
            return _Utils_eq(cuteId, v.bE);
        })), $elm$core$Maybe$map($elm$core$Tuple$second));
    };
    var $author$project$Pages$Dashboard$Server$View$someServer_fn = function (reloading, servers, cuteId) {
        var _v0 = A2($author$project$Maple$Data$Server$slowGetByCute, cuteId, servers);
        if (!_v0.$) {
            var server = _v0.a;
            var _v1 = server.bM;
            if (!_v1.$) {
                var extra = _v1.a;
                return $author$project$Pages$Dashboard$Server$View$loadedServer_fn(reloading, server, extra);
            }
            else {
                return $author$project$Components$Dashboard$searchingServer(cuteId);
            }
        }
        else {
            return $author$project$Components$Dashboard$searchingServer(cuteId);
        }
    }, $author$project$Pages$Dashboard$Server$View$someServer = F3($author$project$Pages$Dashboard$Server$View$someServer_fn);
    var $author$project$Main$Msg$GoToInternal = function (a) {
        return { $: 4, a: a };
    };
    var $author$project$Pages$Route$GoingHome = { $: 4 };
    var $author$project$Main$Msg$goToHome = $author$project$Main$Msg$GoToInternal($author$project$Pages$Route$GoingHome);
    var $author$project$Pages$Dashboard$Teams$View$error = $elm$core$Tuple$pair_fn("dead", $author$project$Design$Container$hold_fn($author$project$UI$centerLone, _List_Nil, $author$project$UI$lone_fn(_List_Nil, A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Unable to load your teams"),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Please check your internet connection and try again."),
        $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToHome, $author$project$Design$Button$fromText("Go to home page")))
    ])))));
    var $author$project$Main$Msg$TeamCreate = function (a) {
        return { $: 15, a: a };
    };
    var $author$project$Main$Msg$TeamCreateReset = { $: 17 };
    var $author$project$Main$Msg$TeamCreateFill = function (a) {
        return { $: 16, a: a };
    };
    var $author$project$Design$Icon$iCirclePlus = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "circle-plus"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Input$withEditing_fn = function (msg, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Input$Input_fn(props, _Utils_update(opts, {
            aI: $elm$core$Maybe$Just(msg)
        }));
    }, $author$project$Design$Input$withEditing = F2($author$project$Design$Input$withEditing_fn);
    var $author$project$Design$Input$withSubmitMaybe_fn = function (maybeMsg, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Input$Input_fn(props, _Utils_update(opts, { ap: maybeMsg }));
    }, $author$project$Design$Input$withSubmitMaybe = F2($author$project$Design$Input$withSubmitMaybe_fn);
    var $author$project$Pages$Dashboard$Teams$View$createItemFilling_fn = function (value, submitMsg) {
        return _List_fromArray([
            $author$project$Design$Container$hold_fn($author$project$UI$row, _List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$padding(1)
            ]), _List_fromArray([
                $author$project$Design$Input$toUI($author$project$Design$Input$withSubmitMaybe_fn(submitMsg, $author$project$Design$Input$withEditing_fn($author$project$Main$Msg$TeamCreateFill, $author$project$Design$Input$forText_fn("New team's name", value)))),
                $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(submitMsg, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iCirclePlus("Create team"))))
            ]))
        ]);
    }, $author$project$Pages$Dashboard$Teams$View$createItemFilling = F2($author$project$Pages$Dashboard$Teams$View$createItemFilling_fn);
    var $author$project$Design$Typo$h2_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$h2_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$h1(_List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$textAlign($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$shrink,
        $author$project$UI$fontSize(2.75)
    ]))), $author$project$Design$Typo$h2 = A2($elm$core$Basics$composeR, $author$project$Design$Typo$h2_a0, $author$project$Design$Typo$h2_a1);
    var $author$project$Pages$Dashboard$Teams$View$createItem = function (model) {
        var body = function () {
            var _v0 = model.cw;
            switch (_v0.$) {
                case 0:
                    if (_v0.a.b) {
                        var _v1 = _v0.a;
                        var value_ = _v1.a;
                        return $author$project$Pages$Dashboard$Teams$View$createItemFilling_fn(value_, $elm$core$Maybe$Just($author$project$Main$Msg$TeamCreate(value_)));
                    }
                    else {
                        if (_v0.a.a === "") {
                            var _v2 = _v0.a;
                            return $author$project$Pages$Dashboard$Teams$View$createItemFilling_fn("", $elm$core$Maybe$Just($author$project$Main$Msg$TeamCreate("")));
                        }
                        else {
                            var _v3 = _v0.a;
                            var value_ = _v3.a;
                            return $author$project$Pages$Dashboard$Teams$View$createItemFilling_fn(value_, $elm$core$Maybe$Nothing);
                        }
                    }
                case 1:
                    return _List_fromArray([
                        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Failed"),
                        $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$TeamCreateReset, $author$project$Design$Button$fromText("Retry")))
                    ]);
                case 2:
                    return _List_fromArray([
                        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Success!"),
                        $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$TeamCreateReset, $author$project$Design$Button$fromText("Repeat")))
                    ]);
                default:
                    return _List_fromArray([
                        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Creating team..."),
                        $author$project$Design$Icon$toUI($author$project$Design$Icon$iSpinner("Processing"))
                    ]);
            }
        }();
        return $elm$core$Tuple$pair_fn("create-team", A2($author$project$Design$Container$hero, _List_fromArray([$author$project$UI$axisCrossCenter]), _List_Cons($elm$core$Basics$composeR_fn($author$project$Design$Typo$h2_a0, $author$project$Design$Typo$h2_a1, "Create a new team"), body)));
    };
    var $author$project$Utils$List$into_fn = function (map, trigger, accu) {
        return _List_Cons(map(trigger), accu);
    }, $author$project$Utils$List$into = F3($author$project$Utils$List$into_fn);
    var $author$project$Main$Msg$TeamInvitationReset = function (a) {
        return { $: 21, a: a };
    };
    var $TSFoster$elm_uuid$UUID$compare_fn = function (_v0, _v1) {
        var a1 = _v0.a;
        var b1 = _v0.b;
        var c1 = _v0.c;
        var d1 = _v0.d;
        var a2 = _v1.a;
        var b2 = _v1.b;
        var c2 = _v1.c;
        var d2 = _v1.d;
        var _v2 = _Utils_compare_fn(a1, a2);
        if (_v2 === 1) {
            var _v3 = _Utils_compare_fn(b1, b2);
            if (_v3 === 1) {
                var _v4 = _Utils_compare_fn(c1, c2);
                if (_v4 === 1) {
                    return _Utils_compare_fn(d1, d2);
                }
                else {
                    var c = _v4;
                    return c;
                }
            }
            else {
                var b = _v3;
                return b;
            }
        }
        else {
            var a = _v2;
            return a;
        }
    }, $TSFoster$elm_uuid$UUID$compare = F2($TSFoster$elm_uuid$UUID$compare_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$get_fn = function (targetKey, dict) {
        get: while (true) {
            if (dict.$ === -2) {
                return $elm$core$Maybe$Nothing;
            }
            else {
                var key = dict.b;
                var value = dict.c;
                var left = dict.d;
                var right = dict.e;
                var _v1 = $TSFoster$elm_uuid$UUID$compare_fn(targetKey, key);
                switch (_v1) {
                    case 0:
                        var $temp$targetKey = targetKey, $temp$dict = left;
                        targetKey = $temp$targetKey;
                        dict = $temp$dict;
                        continue get;
                    case 1:
                        return $elm$core$Maybe$Just(value);
                    default:
                        var $temp$targetKey = targetKey, $temp$dict = right;
                        targetKey = $temp$targetKey;
                        dict = $temp$dict;
                        continue get;
                }
            }
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$get = F2($PedroHLC$elm_uuid_dict$UUID$Dict$get_fn);
    var $author$project$Main$Msg$TeamInvitation_fn = function (a, b) {
        return { $: 19, a: a, b: b };
    }, $author$project$Main$Msg$TeamInvitation = F2($author$project$Main$Msg$TeamInvitation_fn);
    var $author$project$Main$Msg$TeamInvitationFill_fn = function (a, b) {
        return { $: 20, a: a, b: b };
    }, $author$project$Main$Msg$TeamInvitationFill = F2($author$project$Main$Msg$TeamInvitationFill_fn);
    var $author$project$Design$Input$forEmail_fn = function (label, value) {
        return $author$project$Design$Input$Input_fn({ am: "email", V: label, az: value }, _Utils_update($author$project$Design$Input$default, {
            Y: $elm$core$Maybe$Just("name@example.tld")
        }));
    }, $author$project$Design$Input$forEmail = F2($author$project$Design$Input$forEmail_fn);
    var $author$project$Pages$Dashboard$Teams$View$itemInvite_fn = function (team, _v0) {
        var value = _v0.a;
        var validEmail = _v0.b;
        var maybeSubmitMsg = validEmail ? $elm$core$Maybe$Just($author$project$Main$Msg$TeamInvitation_fn(team.bV, value)) : $elm$core$Maybe$Nothing;
        return _List_fromArray([
            $author$project$Design$Input$toUI($author$project$Design$Input$withSubmitMaybe_fn(maybeSubmitMsg, $author$project$Design$Input$withEditing_fn($author$project$Main$Msg$TeamInvitationFill(team.bV), $author$project$Design$Input$forEmail_fn("E-mail", value)))),
            $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(maybeSubmitMsg, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iUserPlus("Invite"))))
        ]);
    }, $author$project$Pages$Dashboard$Teams$View$itemInvite = F2($author$project$Pages$Dashboard$Teams$View$itemInvite_fn);
    var $author$project$Maple$Data$Team$teamName = function (_v0) {
        var cuteId = _v0.bE;
        var name = _v0.dS;
        return $elm$core$Maybe$withDefault_fn(cuteId, name);
    };
    var $author$project$Pages$Dashboard$Teams$View$item_fn = function (model, team) {
        return $elm$core$Tuple$pair_fn("team-" + team.bE, A2($author$project$Design$Container$hero, _List_fromArray([
            $author$project$UI$minWidth(28)
        ]), _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Team " + $author$project$Maple$Data$Team$teamName(team)),
            $author$project$UI$column_fn(_List_fromArray([
                $author$project$UI$gap(1)
            ]), $elm$core$List$map_fn(A2($elm$core$Basics$composeR, function ($) {
                return $.bH;
            }, $author$project$Design$Typo$centeredLabel), team.dL)),
            $author$project$UI$flexStuff(1),
            $author$project$Design$Container$hold_fn($author$project$UI$row, _List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$padding(1),
                $author$project$UI$axisCrossCenter
            ]), function () {
                var _v0 = $PedroHLC$elm_uuid_dict$UUID$Dict$get_fn(team.bV, model.cx);
                if (!_v0.$) {
                    switch (_v0.a.$) {
                        case 0:
                            var formData = _v0.a.a;
                            return $author$project$Pages$Dashboard$Teams$View$itemInvite_fn(team, formData);
                        case 1:
                            return _List_fromArray([
                                $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Failed"),
                                $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$TeamInvitationReset(team.bV), $author$project$Design$Button$fromText("Retry")))
                            ]);
                        case 2:
                            var _v1 = _v0.a;
                            return _List_fromArray([
                                $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Success!"),
                                $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$TeamInvitationReset(team.bV), $author$project$Design$Button$fromText("Repeat")))
                            ]);
                        default:
                            var _v2 = _v0.a;
                            return _List_fromArray([
                                $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Sending invite..."),
                                $author$project$Design$Icon$toUI($author$project$Design$Icon$iSpinner("Processing"))
                            ]);
                    }
                }
                else {
                    return $author$project$Pages$Dashboard$Teams$View$itemInvite_fn(team, _Utils_Tuple2("", false));
                }
            }()),
            $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Delete entire team"))
        ])));
    }, $author$project$Pages$Dashboard$Teams$View$item = F2($author$project$Pages$Dashboard$Teams$View$item_fn);
    var $author$project$Pages$Dashboard$Teams$View$loaded_fn = function (model, teamsReloading, teamsList) {
        return $elm$core$Tuple$pair_fn("teams", $author$project$Design$Container$hold_fn($author$project$UI$centerLone, _List_fromArray([$author$project$UI$overflowYAuto]), $author$project$UI$keyedRow_fn(_List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$padding(1),
            $author$project$UI$wrap
        ]), _List_Cons(_Utils_Tuple2("refreshing", $author$project$Components$Dashboard$refreshing(teamsReloading)), $elm$core$List$foldr_fn($author$project$Utils$List$into($author$project$Pages$Dashboard$Teams$View$item(model)), _List_fromArray([
            $author$project$Pages$Dashboard$Teams$View$createItem(model)
        ]), teamsList)))));
    }, $author$project$Pages$Dashboard$Teams$View$loaded = F3($author$project$Pages$Dashboard$Teams$View$loaded_fn);
    var $author$project$Components$Dashboard$searchingTeams = $author$project$Components$Dashboard$searching_fn("Teams", "your teams");
    var $author$project$Pages$Dashboard$Teams$View$teams_fn = function (model, session) {
        var _v0 = session.aa;
        _v0$4: while (true) {
            switch (_v0.$) {
                case 0:
                    return _Utils_Tuple2("loading-teams", $author$project$Components$Dashboard$searchingTeams);
                case 1:
                    return _Utils_Tuple2("loading-teams", $author$project$Components$Dashboard$searchingTeams);
                case 3:
                    var teamsList = _v0.a;
                    return $author$project$Pages$Dashboard$Teams$View$loaded_fn(model, false, teamsList);
                case 4:
                    if (!_v0.b.$) {
                        var teamsList = _v0.a;
                        var _v1 = _v0.b;
                        return $author$project$Pages$Dashboard$Teams$View$loaded_fn(model, true, teamsList);
                    }
                    else {
                        break _v0$4;
                    }
                default:
                    break _v0$4;
            }
        }
        return $author$project$Pages$Dashboard$Teams$View$error;
    }, $author$project$Pages$Dashboard$Teams$View$teams = F2($author$project$Pages$Dashboard$Teams$View$teams_fn);
    var $author$project$Pages$Dashboard$View$mainContent_fn = function (model, session, serversReloading, servers, viewing) {
        switch (viewing.$) {
            case 0:
                return $author$project$Pages$Dashboard$View$overview_fn(model, session);
            case 1:
                return $author$project$Pages$Dashboard$Teams$View$teams_fn(model, session);
            case 2:
                var cuteId = viewing.a;
                return _Utils_Tuple2("server-" + cuteId, $dzuk_mutant$elm_css$VirtualDom$Styled$lazy3_fn($author$project$Pages$Dashboard$Server$View$someServer, serversReloading, servers, cuteId));
            default:
                return _Utils_Tuple2("create", $author$project$UI$keyedRow_fn(_List_fromArray([
                    $author$project$UI$gap(1),
                    $author$project$UI$flexFill(1),
                    $author$project$UI$wrap
                ]), _List_fromArray([
                    _Utils_Tuple2("shelf", $author$project$Components$GameShelf$box_fn(_List_fromArray([$author$project$UI$minWidthSmallest]), model)),
                    _Utils_Tuple2("cart", $author$project$Components$Cart$box_fn(_List_fromArray([$author$project$UI$minWidthSmallest]), model))
                ])));
        }
    }, $author$project$Pages$Dashboard$View$mainContent = F5($author$project$Pages$Dashboard$View$mainContent_fn);
    var $author$project$Pages$Route$GoingDashboard = { $: 0 };
    var $author$project$Pages$Route$GoingDashboardNewServer = { $: 1 };
    var $author$project$Pages$Route$GoingDashboardTeams = { $: 3 };
    var $author$project$Main$Msg$SidebarSetOpen = function (a) {
        return { $: 12, a: a };
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn = function (func, acc, t) {
        foldr: while (true) {
            if (t.$ === -2) {
                return acc;
            }
            else {
                var key = t.b;
                var value = t.c;
                var left = t.d;
                var right = t.e;
                var $temp$func = func, $temp$acc = A3(func, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn(func, acc, right)), $temp$t = left;
                func = $temp$func;
                acc = $temp$acc;
                t = $temp$t;
                continue foldr;
            }
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn_unwrapped = function (func, acc, t) {
        foldr: while (true) {
            if (t.$ === -2) {
                return acc;
            }
            else {
                var key = t.b;
                var value = t.c;
                var left = t.d;
                var right = t.e;
                var $temp$func = func, $temp$acc = func(key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn_unwrapped(func, acc, right)), $temp$t = left;
                func = $temp$func;
                acc = $temp$acc;
                t = $temp$t;
                continue foldr;
            }
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$foldr = F3($PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn);
    var $author$project$Design$Icon$iAddressBook = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "address-book"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iBars = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "bars"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iCircleQuestion = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "circle-question"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iDoorOpen = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "door-open"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iHouse = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "house"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Design$Icon$iPeopleGroup = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "people-group"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Utils$List$into2_fn = function (map, trigger1, trigger2, accu) {
        return _List_Cons(A2(map, trigger1, trigger2), accu);
    }, $author$project$Utils$List$into2_fn_unwrapped = function (map, trigger1, trigger2, accu) {
        return _List_Cons(map(trigger1, trigger2), accu);
    }, $author$project$Utils$List$into2 = F4($author$project$Utils$List$into2_fn);
    var $author$project$UI$overflowBothAuto = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$overflow($dzuk_mutant$elm_css$Css$auto)
    ]));
    var $dzuk_mutant$elm_css$Css$row = "row";
    var $author$project$UI$rowOnSm = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$Preprocess$WithMedia_fn($author$project$UI$screenMaxSm_a0, _List_fromArray([
            $dzuk_mutant$elm_css$Css$Preprocess$mapProperties_fn($dzuk_mutant$elm_css$Css$important_a0, $dzuk_mutant$elm_css$Css$flexDirection($dzuk_mutant$elm_css$Css$row))
        ]))
    ]));
    var $author$project$Pages$Route$GoingDashboardServer = function (a) {
        return { $: 2, a: a };
    };
    var $author$project$Design$Icon$iServer = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "server"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Pages$Dashboard$Sidebar$View$serverFolderMini_fn = function (linkFun, _v0, server) {
        return $elm$core$Tuple$pair_fn("server-" + server.bE, $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(linkFun($author$project$Pages$Route$GoingDashboardServer(server.bE)), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iServer($author$project$Maple$Data$Server$getName(server))))));
    }, $author$project$Pages$Dashboard$Sidebar$View$serverFolderMini = F3($author$project$Pages$Dashboard$Sidebar$View$serverFolderMini_fn);
    var $author$project$Pages$Dashboard$Sidebar$View$sidebarCollapsed_fn = function (_v0, servers, linkFun) {
        return $elm$core$Tuple$pair_fn("sidebar", $author$project$UI$keyedColumn_fn(_List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$fullTransition,
            $author$project$UI$rowOnSm,
            $author$project$UI$overflowBothAuto
        ]), _List_fromArray([
            _Utils_Tuple2("head", $author$project$UI$keyedColumn_fn(_List_fromArray([$author$project$UI$fullTransition]), _List_fromArray([
                $elm$core$Tuple$pair_fn("toggle", $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$SidebarSetOpen(true), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iBars("Toggle Sidebar")))))
            ]))),
            $elm$core$Tuple$pair_fn("home", $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToHome, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iHouse("Go to home page"))))),
            _Utils_Tuple2("space", $author$project$UI$flexStuff(1)),
            $elm$core$Tuple$pair_fn("servers", $author$project$UI$keyedColumn_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$rowOnSm
            ]), $PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn($author$project$Utils$List$into2($author$project$Pages$Dashboard$Sidebar$View$serverFolderMini(linkFun)), _List_Nil, servers))),
            $elm$core$Tuple$pair_fn("new-server", $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(linkFun($author$project$Pages$Route$GoingDashboardNewServer), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iCirclePlus("Add new server"))))),
            _Utils_Tuple2("footer-space", $author$project$UI$flexStuff(1)),
            $elm$core$Tuple$pair_fn("overview", $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(linkFun($author$project$Pages$Route$GoingDashboard), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iAddressBook("Account overview"))))),
            $elm$core$Tuple$pair_fn("teams", $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(linkFun($author$project$Pages$Route$GoingDashboardTeams), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iPeopleGroup("My Teams"))))),
            $elm$core$Tuple$pair_fn("help", $author$project$Design$Button$toUI($author$project$Design$Button$fromIcon($author$project$Design$Icon$iCircleQuestion("Help")))),
            $elm$core$Tuple$pair_fn("leave", $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromIcon($author$project$Design$Icon$iDoorOpen("Leave")))))
        ])));
    }, $author$project$Pages$Dashboard$Sidebar$View$sidebarCollapsed = F3($author$project$Pages$Dashboard$Sidebar$View$sidebarCollapsed_fn);
    var $author$project$UI$cursorPointer = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$cursor($dzuk_mutant$elm_css$Css$pointer)
    ]));
    var $dzuk_mutant$elm_css$Html$Styled$img_a0 = "img", $dzuk_mutant$elm_css$Html$Styled$img = $dzuk_mutant$elm_css$Html$Styled$node($dzuk_mutant$elm_css$Html$Styled$img_a0);
    var $dzuk_mutant$elm_css$Html$Styled$Attributes$src = function (url) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$stringProperty_fn("src", url);
    };
    var $author$project$Design$Logo$logo = function (size) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$Node_fn($dzuk_mutant$elm_css$Html$Styled$img_a0, _List_fromArray([
            $dzuk_mutant$elm_css$Html$Styled$Attributes$src("/assets/logo.png"),
            $author$project$UI$maxWidthPct(100),
            $author$project$UI$width(size)
        ]), _List_Nil);
    };
    var $author$project$UI$onClick = function (msg) {
        return $dzuk_mutant$elm_css$Html$Styled$Events$onClick(msg);
    };
    var $author$project$Pages$Dashboard$Sidebar$View$serverFolder_fn = function (linkFun, _v0, server) {
        return _Utils_Tuple2(linkFun($author$project$Pages$Route$GoingDashboardServer(server.bE)), "Server " + $author$project$Maple$Data$Server$getName(server));
    }, $author$project$Pages$Dashboard$Sidebar$View$serverFolder = F3($author$project$Pages$Dashboard$Sidebar$View$serverFolder_fn);
    var $elm$core$List$intersperse_fn = function (sep, xs) {
        if (!xs.b) {
            return xs;
        }
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        end.b = _List_Cons(xs.a, _List_Nil);
        end = end.b;
        xs = xs.b;
        for (; xs.b; xs = xs.b) {
            var valNode = _List_Cons(xs.a, _List_Nil);
            var sepNode = _List_Cons(sep, valNode);
            end.b = sepNode;
            end = valNode;
        }
        return tmp.b;
    }, $elm$core$List$intersperse = F2($elm$core$List$intersperse_fn);
    var $author$project$Design$Typo$h5_a0 = $dzuk_mutant$elm_css$Html$Styled$text, $author$project$Design$Typo$h5_a1 = A2($elm$core$Basics$composeR, $elm$core$List$singleton, $dzuk_mutant$elm_css$Html$Styled$h1(_List_fromArray([
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$textAlign($dzuk_mutant$elm_css$Css$center)
        ])),
        $author$project$UI$shrink,
        $author$project$UI$fontSize(2)
    ]))), $author$project$Design$Typo$h5 = A2($elm$core$Basics$composeR, $author$project$Design$Typo$h5_a0, $author$project$Design$Typo$h5_a1);
    var $dzuk_mutant$elm_css$Css$zero = "0";
    var $dzuk_mutant$elm_css$Css$defaultTextShadow = { cP: $elm$core$Maybe$Nothing, bD: $elm$core$Maybe$Nothing, W: $dzuk_mutant$elm_css$Css$zero, X: $dzuk_mutant$elm_css$Css$zero };
    var $dzuk_mutant$elm_css$Css$hex = function (str) {
        return _String_startsWith_fn("#", str) ? str : ("#" + str);
    };
    var $dzuk_mutant$elm_css$Css$Structure$PseudoClassSelector = function (a) {
        return { $: 2, a: a };
    };
    var $dzuk_mutant$elm_css$Css$pseudoClass = function (pseudoClassName) {
        return $dzuk_mutant$elm_css$Css$Preprocess$ExtendSelector($dzuk_mutant$elm_css$Css$Structure$PseudoClassSelector(pseudoClassName));
    };
    var $dzuk_mutant$elm_css$Css$hover = $dzuk_mutant$elm_css$Css$pseudoClass("hover");
    var $dzuk_mutant$elm_css$Css$textShadowConfigToString = function (config) {
        var offsetY = $dzuk_mutant$elm_css$Css$Value$unpack(config.X);
        var offsetX = $dzuk_mutant$elm_css$Css$Value$unpack(config.W);
        var colorSetting = $elm$core$Maybe$withDefault_fn("", $elm$core$Maybe$map_fn(A2($elm$core$Basics$composeR, $dzuk_mutant$elm_css$Css$Value$unpack, $elm$core$Basics$append(" ")), config.bD));
        var blurRadius = $elm$core$Maybe$withDefault_fn("", $elm$core$Maybe$map_fn(A2($elm$core$Basics$composeR, $dzuk_mutant$elm_css$Css$Value$unpack, $elm$core$Basics$append(" ")), config.cP));
        return offsetX + (" " + (offsetY + (blurRadius + colorSetting)));
    };
    var $dzuk_mutant$elm_css$Css$textShadowMany = function (configs) {
        var values = function () {
            if (!configs.b) {
                return "unset";
            }
            else {
                return $elm$core$String$join_fn(",", $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$textShadowConfigToString, configs));
            }
        }();
        return $dzuk_mutant$elm_css$Css$appendProperty("text-shadow:" + values);
    };
    var $author$project$Design$Menu$hoverShadow = $dzuk_mutant$elm_css$Html$Styled$Attributes$css($elm$core$List$singleton($dzuk_mutant$elm_css$Css$hover($elm$core$List$singleton($dzuk_mutant$elm_css$Css$textShadowMany(_List_fromArray([
        _Utils_update($dzuk_mutant$elm_css$Css$defaultTextShadow, {
            cP: $elm$core$Maybe$Just($dzuk_mutant$elm_css$Css$px(4)),
            bD: $elm$core$Maybe$Just($dzuk_mutant$elm_css$Css$hex("#FFF"))
        })
    ]))))));
    var $author$project$Design$Icon$iHandPointRight = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "hand-point-right"), $author$project$Design$Icon$defaultOptions);
    };
    var $dzuk_mutant$elm_css$Css$infinite = "infinite";
    var $author$project$Design$Menu$selectedIcon = $author$project$UI$lone_fn(_List_fromArray([
        $author$project$UI$shrink,
        $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$animationName($dzuk_mutant$elm_css$Css$customIdent("point")),
            $dzuk_mutant$elm_css$Css$animationDuration($dzuk_mutant$elm_css$Css$s(1.5)),
            $dzuk_mutant$elm_css$Css$animationTimingFunction($dzuk_mutant$elm_css$Css$linear),
            $dzuk_mutant$elm_css$Css$animationIterationCount($dzuk_mutant$elm_css$Css$infinite)
        ]))
    ]), $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(3, $author$project$Design$Icon$iHandPointRight("Selected"))));
    var $author$project$Design$Menu$item_fn = function (maybeMessage, label) {
        var baseAttrs = _List_fromArray([
            $author$project$UI$gap(1),
            $author$project$UI$axisCrossCenter,
            $author$project$UI$padding(1),
            $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("role", "radio")
        ]);
        var ariaChecked = $dzuk_mutant$elm_css$Html$Styled$Attributes$attribute("aria-checked");
        var _v0 = function () {
            if (!maybeMessage.$) {
                var msg = maybeMessage.a;
                return _Utils_Tuple2(_List_Cons($author$project$UI$cursorPointer, _List_Cons(ariaChecked("false"), _List_Cons($dzuk_mutant$elm_css$Html$Styled$Events$onClick(msg), _List_Cons($author$project$Design$Menu$hoverShadow, baseAttrs)))), _List_fromArray([
                    $elm$core$Basics$composeR_fn($author$project$Design$Typo$h6_a0, $author$project$Design$Typo$h6_a1, label)
                ]));
            }
            else {
                return _Utils_Tuple2(_List_Cons($elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$withAlpha_fn(0.2, $author$project$Design$Palette$genericWhite)), _List_Cons(ariaChecked("true"), baseAttrs)), _List_fromArray([
                    $author$project$Design$Menu$selectedIcon,
                    $elm$core$Basics$composeR_fn($author$project$Design$Typo$h5_a0, $author$project$Design$Typo$h5_a1, label)
                ]));
            }
        }();
        var attrs = _v0.a;
        var body = _v0.b;
        return $author$project$UI$row_fn(attrs, body);
    }, $author$project$Design$Menu$item = F2($author$project$Design$Menu$item_fn);
    var $author$project$Design$Menu$row = function (_v0) {
        var maybeMessage = _v0.a;
        var label = _v0.b;
        return _Utils_Tuple2(label, $author$project$Design$Menu$item_fn(maybeMessage, label));
    };
    var $author$project$Design$Menu$separator = _Utils_Tuple2("sep", $author$project$Design$Container$hr);
    var $author$project$Design$Menu$window_a0 = $elm$core$List$map($elm$core$List$map($author$project$Design$Menu$row)), $author$project$Design$Menu$window_a1 = A2($elm$core$Basics$composeR, $elm$core$List$intersperse(_List_fromArray([$author$project$Design$Menu$separator])), A2($elm$core$Basics$composeR, $elm$core$List$concat, A2($elm$core$Basics$composeR, A2($author$project$Design$Container$window, $author$project$UI$keyedColumn, _List_fromArray([
        $author$project$UI$padding(1)
    ])), $author$project$UI$lone(_List_fromArray([
        $dzuk_mutant$elm_css$VirtualDom$Styled$attribute_fn("role", "radiogroup"),
        $author$project$UI$widthFill
    ]))))), $author$project$Design$Menu$window = A2($elm$core$Basics$composeR, $author$project$Design$Menu$window_a0, $author$project$Design$Menu$window_a1);
    var $author$project$Pages$Dashboard$Sidebar$View$sidebarFull_fn = function (session, servers, linkFun) {
        return $elm$core$Tuple$pair_fn("sidebar", $author$project$Design$Container$hold_fn($author$project$UI$keyedColumn, _List_fromArray([
            $author$project$UI$axisCrossCenter,
            $author$project$UI$width(36),
            $author$project$UI$gap(1),
            $author$project$UI$padding(1),
            $author$project$UI$fullTransition,
            $author$project$UI$overflowYAuto
        ]), _List_fromArray([
            $elm$core$Tuple$pair_fn("head", $author$project$UI$keyedRow_fn(_List_fromArray([
                $author$project$UI$gap(1),
                $author$project$UI$axisCrossCenter,
                $author$project$UI$fullTransition
            ]), _List_fromArray([
                $elm$core$Tuple$pair_fn("toggle", $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$SidebarSetOpen(false), $author$project$Design$Button$fromIcon($author$project$Design$Icon$iBars("Toggle Sidebar"))))),
                $elm$core$Tuple$pair_fn("logo", $author$project$UI$lone_fn(_List_fromArray([
                    $author$project$UI$onClick($author$project$Main$Msg$goToHome),
                    $author$project$UI$cursorPointer
                ]), $author$project$Design$Logo$logo(24)))
            ]))),
            $elm$core$Tuple$pair_fn("menu", $elm$core$Basics$composeR_fn($author$project$Design$Menu$window_a0, $author$project$Design$Menu$window_a1, _List_fromArray([
                $PedroHLC$elm_uuid_dict$UUID$Dict$foldr_fn($author$project$Utils$List$into2($author$project$Pages$Dashboard$Sidebar$View$serverFolder(linkFun)), _List_Nil, servers),
                _List_fromArray([
                    _Utils_Tuple2(linkFun($author$project$Pages$Route$GoingDashboardNewServer), "New Server")
                ])
            ]))),
            _Utils_Tuple2("space", $author$project$UI$flexStuff(1)),
            $elm$core$Tuple$pair_fn("account", $elm$core$Basics$composeR_fn($author$project$Design$Menu$window_a0, $author$project$Design$Menu$window_a1, _List_fromArray([
                _List_fromArray([
                    _Utils_Tuple2(linkFun($author$project$Pages$Route$GoingDashboard), session.A.b1 + " (Overview)"),
                    _Utils_Tuple2(linkFun($author$project$Pages$Route$GoingDashboardTeams), "My Teams"),
                    _Utils_Tuple2($elm$core$Maybe$Just($author$project$Main$Msg$Logout), "Help"),
                    _Utils_Tuple2($elm$core$Maybe$Just($author$project$Main$Msg$Logout), "Leave")
                ])
            ])))
        ])));
    }, $author$project$Pages$Dashboard$Sidebar$View$sidebarFull = F3($author$project$Pages$Dashboard$Sidebar$View$sidebarFull_fn);
    var $author$project$Pages$Dashboard$Sidebar$View$unlessMsg_fn = function (old, _new) {
        return _Utils_eq(old, _new) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just($author$project$Main$Msg$GoToInternal(_new));
    }, $author$project$Pages$Dashboard$Sidebar$View$unlessMsg = F2($author$project$Pages$Dashboard$Sidebar$View$unlessMsg_fn);
    var $author$project$Pages$Dashboard$Sidebar$View$unlessSame = function (current) {
        return $author$project$Pages$Dashboard$Sidebar$View$unlessMsg(function () {
            switch (current.$) {
                case 0:
                    return $author$project$Pages$Route$GoingDashboard;
                case 1:
                    return $author$project$Pages$Route$GoingDashboardTeams;
                case 2:
                    var id = current.a;
                    return $author$project$Pages$Route$GoingDashboardServer(id);
                default:
                    return $author$project$Pages$Route$GoingDashboardNewServer;
            }
        }());
    };
    var $author$project$Pages$Dashboard$Sidebar$View$sidebar_fn = function (model, session, servers, viewing) {
        var g = $author$project$Pages$Dashboard$Sidebar$View$unlessSame(viewing);
        return model.aN ? $author$project$Pages$Dashboard$Sidebar$View$sidebarFull_fn(session, servers, g) : $author$project$Pages$Dashboard$Sidebar$View$sidebarCollapsed_fn(session, servers, g);
    }, $author$project$Pages$Dashboard$Sidebar$View$sidebar = F4($author$project$Pages$Dashboard$Sidebar$View$sidebar_fn);
    var $author$project$Pages$Dashboard$View$mainContainer_fn = function (model, viewing, session, serversFailure, serversReloading, servers) {
        return $elm$core$Tuple$pair_fn("mainC", $author$project$UI$keyedRow_fn(_List_fromArray([
            $author$project$UI$flexFill(1),
            $author$project$UI$gap(1),
            $author$project$UI$overflowBothClip,
            $author$project$UI$columnOnSm,
            $author$project$UI$widthFill,
            $author$project$UI$heightFill
        ]), _List_fromArray([
            $author$project$Pages$Dashboard$Sidebar$View$sidebar_fn(model, session, servers, viewing),
            $elm$core$Tuple$pair_fn("main", $author$project$UI$keyedColumn_fn(_List_fromArray([
                $author$project$UI$flexFill(1),
                $author$project$UI$gap(1),
                $author$project$UI$heightFill
            ]), _List_fromArray([
                _Utils_Tuple2("alert", (!_Utils_eq(serversFailure, $elm$core$Maybe$Nothing)) ? $author$project$Pages$Dashboard$View$connectionFailure : $author$project$UI$none),
                $author$project$Pages$Dashboard$View$mainContent_fn(model, session, serversReloading, servers, viewing)
            ])))
        ])));
    }, $author$project$Pages$Dashboard$View$mainContainer = F6($author$project$Pages$Dashboard$View$mainContainer_fn);
    var $author$project$Pages$Dashboard$View$serverListError = $elm$core$Tuple$pair_fn("dead", $author$project$UI$lone_fn(_List_fromArray([
        $author$project$UI$axisMainCenter,
        $author$project$UI$axisCrossCenter,
        $author$project$UI$flexFill(1)
    ]), A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Unable to load server list"),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Please check your internet connection and try again."),
        $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToHome, $author$project$Design$Button$fromText("Go to home page")))
    ]))));
    var $author$project$Pages$Dashboard$View$serversLoading = $elm$core$Tuple$pair_fn("loading", $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(24, $author$project$Design$Icon$iSpinner("Loading servers"))));
    var $author$project$Pages$Dashboard$View$unauthorized = $elm$core$Tuple$pair_fn("unauthenticated", $author$project$UI$lone_fn(_List_fromArray([
        $author$project$UI$axisMainCenter,
        $author$project$UI$axisCrossCenter,
        $author$project$UI$flexFill(1)
    ]), A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Unauthorized Access"),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "You need an account to see this page."),
        $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToHome, $author$project$Design$Button$fromText("Go to home page")))
    ]))));
    var $author$project$Pages$Dashboard$View$view_fn = function (model, viewing) {
        var _v0 = $author$project$Main$Model$getSession(model.ar);
        if (!_v0.$) {
            var session = _v0.a;
            var _v1 = session.O;
            switch (_v1.$) {
                case 1:
                    return $author$project$Pages$Dashboard$View$serversLoading;
                case 3:
                    var servers = _v1.a;
                    return $author$project$Pages$Dashboard$View$mainContainer_fn(model, viewing, session, $elm$core$Maybe$Nothing, false, servers);
                case 4:
                    if (!_v1.b.$) {
                        var servers = _v1.a;
                        var _v2 = _v1.b;
                        return $author$project$Pages$Dashboard$View$mainContainer_fn(model, viewing, session, $elm$core$Maybe$Nothing, true, servers);
                    }
                    else {
                        var servers = _v1.a;
                        var error = _v1.b.a;
                        return $author$project$Pages$Dashboard$View$mainContainer_fn(model, viewing, session, $elm$core$Maybe$Just(error), false, servers);
                    }
                default:
                    return $author$project$Pages$Dashboard$View$serverListError;
            }
        }
        else {
            return $author$project$Pages$Dashboard$View$unauthorized;
        }
    }, $author$project$Pages$Dashboard$View$view = F2($author$project$Pages$Dashboard$View$view_fn);
    var $dzuk_mutant$elm_css$Css$Structure$TypeSelector = $elm$core$Basics$identity;
    var $dzuk_mutant$elm_css$Css$Global$typeSelector_fn = function (selectorStr, styles) {
        var sequence = $dzuk_mutant$elm_css$Css$Structure$TypeSelectorSequence_fn(selectorStr, _List_Nil);
        var sel = $dzuk_mutant$elm_css$Css$Structure$Selector_fn(sequence, _List_Nil, $elm$core$Maybe$Nothing);
        return _List_fromArray([
            $dzuk_mutant$elm_css$Css$Preprocess$StyleBlockDeclaration($dzuk_mutant$elm_css$Css$Preprocess$StyleBlock_fn(sel, _List_Nil, styles))
        ]);
    }, $dzuk_mutant$elm_css$Css$Global$typeSelector = F2($dzuk_mutant$elm_css$Css$Global$typeSelector_fn);
    var $dzuk_mutant$elm_css$Css$Global$body_a0 = "body", $dzuk_mutant$elm_css$Css$Global$body = $dzuk_mutant$elm_css$Css$Global$typeSelector($dzuk_mutant$elm_css$Css$Global$body_a0);
    var $dzuk_mutant$elm_css$Css$double = "double";
    var $dzuk_mutant$elm_css$Css$em = function (value) {
        return $elm$core$String$fromFloat(value) + "em";
    };
    var $dzuk_mutant$elm_css$Css$Global$everything = function (styles) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$makeSnippet_fn(styles, $dzuk_mutant$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil));
    };
    var $dzuk_mutant$elm_css$Css$focus = $dzuk_mutant$elm_css$Css$pseudoClass("focus");
    var $elm$core$String$fromChar = function (_char) {
        return _String_cons_fn(_char, "");
    };
    var $dzuk_mutant$elm_css$Css$enquoteString = function (str) {
        var escapeChar = function (_char) {
            switch (_char) {
                case "\n":
                    return "\\A ";
                case "\"":
                    return "\\\"";
                case "\\":
                    return "\\\\";
                default:
                    return $elm$core$String$fromChar(_char);
            }
        };
        var escapeChars = F2(function (_char, rest) {
            return _Utils_ap(rest, escapeChar(_char));
        });
        return "\"" + (_String_foldl_fn(escapeChars, "", str) + "\"");
    };
    var $dzuk_mutant$elm_css$Css$enquoteIfNotGeneric = function (fontName) {
        switch (fontName) {
            case "serif":
                return fontName;
            case "sans-serif":
                return fontName;
            case "monospace":
                return fontName;
            case "cursive":
                return fontName;
            case "fantasy":
                return fontName;
            case "system-ui":
                return fontName;
            default:
                return $dzuk_mutant$elm_css$Css$enquoteString(fontName);
        }
    };
    var $dzuk_mutant$elm_css$Css$fontFamilyMany_fn = function (list, _v0) {
        var genericFont = _v0;
        if (!list.b) {
            return $dzuk_mutant$elm_css$Css$appendProperty("font-family:" + genericFont);
        }
        else {
            var fonts = list;
            return $dzuk_mutant$elm_css$Css$appendProperty("font-family:" + ($elm$core$String$join_fn(",", $elm$core$List$map_fn($dzuk_mutant$elm_css$Css$enquoteIfNotGeneric, fonts)) + ("," + genericFont)));
        }
    }, $dzuk_mutant$elm_css$Css$fontFamilyMany = F2($dzuk_mutant$elm_css$Css$fontFamilyMany_fn);
    var $dzuk_mutant$elm_css$VirtualDom$Styled$unstyledNode = $dzuk_mutant$elm_css$VirtualDom$Styled$Unstyled;
    var $dzuk_mutant$elm_css$Css$Global$global = function (snippets) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$unstyledNode(A3($elm$virtual_dom$VirtualDom$node, "span", _List_fromArray([
            $elm$virtual_dom$VirtualDom$attribute_fn("style", "display: none;"),
            $elm$virtual_dom$VirtualDom$attribute_fn("class", "elm-css-style-wrapper")
        ]), $elm$core$List$singleton(A3($elm$virtual_dom$VirtualDom$node, "style", _List_Nil, $elm$core$List$singleton($elm$virtual_dom$VirtualDom$text($dzuk_mutant$elm_css$Css$Preprocess$Resolve$compile($dzuk_mutant$elm_css$Css$Preprocess$stylesheet(snippets))))))));
    };
    var $dzuk_mutant$elm_css$Css$Global$input_a0 = "input", $dzuk_mutant$elm_css$Css$Global$input = $dzuk_mutant$elm_css$Css$Global$typeSelector($dzuk_mutant$elm_css$Css$Global$input_a0);
    var $dzuk_mutant$elm_css$Css$lineHeight = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("line-height:" + val);
    };
    var $dzuk_mutant$elm_css$Css$margin = function (_v0) {
        var value = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("margin:" + value);
    };
    var $dzuk_mutant$elm_css$Css$opacity = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("opacity:" + val);
    };
    var $dzuk_mutant$elm_css$Css$outline3_fn = function (_v0, _v1, _v2) {
        var widthVal = _v0;
        var styleVal = _v1;
        var colorVal = _v2;
        return $dzuk_mutant$elm_css$Css$appendProperty("outline:" + (widthVal + (" " + (styleVal + (" " + colorVal)))));
    }, $dzuk_mutant$elm_css$Css$outline3 = F3($dzuk_mutant$elm_css$Css$outline3_fn);
    var $dzuk_mutant$elm_css$Css$Structure$PseudoElement = $elm$core$Basics$identity;
    var $dzuk_mutant$elm_css$Css$Preprocess$WithPseudoElement_fn = function (a, b) {
        return { $: 3, a: a, b: b };
    }, $dzuk_mutant$elm_css$Css$Preprocess$WithPseudoElement = F2($dzuk_mutant$elm_css$Css$Preprocess$WithPseudoElement_fn);
    var $dzuk_mutant$elm_css$Css$pseudoElement = function (element) {
        return $dzuk_mutant$elm_css$Css$Preprocess$WithPseudoElement(element);
    };
    var $dzuk_mutant$elm_css$Css$placeholder = $dzuk_mutant$elm_css$Css$pseudoElement("placeholder");
    var $dzuk_mutant$elm_css$Css$rgb_fn = function (red, green, blue) {
        return "rgb(" + ($elm$core$String$fromInt(red) + ("," + ($elm$core$String$fromInt(green) + ("," + ($elm$core$String$fromInt(blue) + ")")))));
    }, $dzuk_mutant$elm_css$Css$rgb = F3($dzuk_mutant$elm_css$Css$rgb_fn);
    var $dzuk_mutant$elm_css$Css$root = $dzuk_mutant$elm_css$Css$pseudoClass("root");
    var $dzuk_mutant$elm_css$Css$sansSerif = "sans-serif";
    var $author$project$UI$screenMinXL_a0 = _List_fromArray([
        $dzuk_mutant$elm_css$Css$Structure$OnlyQuery_fn($dzuk_mutant$elm_css$Css$Media$screen, _List_fromArray([
            $dzuk_mutant$elm_css$Css$Media$minWidth($dzuk_mutant$elm_css$Css$px(1280))
        ]))
    ]), $author$project$UI$screenMinXL = $dzuk_mutant$elm_css$Css$Media$withMedia($author$project$UI$screenMinXL_a0);
    var $dzuk_mutant$elm_css$Css$Global$selector_fn = function (selectorStr, styles) {
        return $dzuk_mutant$elm_css$VirtualDom$Styled$makeSnippet_fn(styles, $dzuk_mutant$elm_css$Css$Structure$CustomSelector_fn(selectorStr, _List_Nil));
    }, $dzuk_mutant$elm_css$Css$Global$selector = F2($dzuk_mutant$elm_css$Css$Global$selector_fn);
    var $dzuk_mutant$elm_css$Css$vw = function (value) {
        return $elm$core$String$fromFloat(value) + "vw";
    };
    var $author$project$Pages$Layout$globalCss = $dzuk_mutant$elm_css$Css$Global$global(_List_fromArray([
        $dzuk_mutant$elm_css$Css$Global$selector_fn("", _List_fromArray([
            $dzuk_mutant$elm_css$Css$root(_List_fromArray([
                $dzuk_mutant$elm_css$Css$fontFamilyMany_fn(_List_fromArray(["Helvetica", "Arial"]), $dzuk_mutant$elm_css$Css$sansSerif),
                $dzuk_mutant$elm_css$Css$fontSize($dzuk_mutant$elm_css$Css$px(8)),
                $dzuk_mutant$elm_css$Css$Preprocess$WithMedia_fn($author$project$UI$screenMinXL_a0, _List_fromArray([
                    $dzuk_mutant$elm_css$Css$fontSize($dzuk_mutant$elm_css$Css$vw(0.625))
                ]))
            ])),
            $dzuk_mutant$elm_css$Css$focus(_List_fromArray([
                $dzuk_mutant$elm_css$Css$outline3_fn($dzuk_mutant$elm_css$Css$px(4), $dzuk_mutant$elm_css$Css$double, $dzuk_mutant$elm_css$Css$rgb_fn(255, 255, 255))
            ]))
        ])),
        $dzuk_mutant$elm_css$Css$Global$typeSelector_fn($dzuk_mutant$elm_css$Css$Global$input_a0, $elm$core$List$singleton($dzuk_mutant$elm_css$Css$placeholder(_List_fromArray([
            $dzuk_mutant$elm_css$Css$color($dzuk_mutant$elm_css$Css$rgb_fn(255, 255, 255)),
            $dzuk_mutant$elm_css$Css$opacity($dzuk_mutant$elm_css$Css$num(0.65))
        ])))),
        $dzuk_mutant$elm_css$Css$Global$everything(_List_fromArray([
            $dzuk_mutant$elm_css$Css$lineHeight($dzuk_mutant$elm_css$Css$em(1.25)),
            $dzuk_mutant$elm_css$Css$margin($dzuk_mutant$elm_css$Css$rem(0))
        ])),
        $dzuk_mutant$elm_css$Css$Global$typeSelector_fn($dzuk_mutant$elm_css$Css$Global$body_a0, _List_fromArray([
            $dzuk_mutant$elm_css$Css$fontSize($dzuk_mutant$elm_css$Css$rem(2)),
            $dzuk_mutant$elm_css$Css$overflow($dzuk_mutant$elm_css$Css$clip)
        ]))
    ]));
    var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
    var $author$project$Pages$Layout$importSprite = A3($elm$html$Html$node, "sprite-sheet", _List_Nil, _List_Nil);
    var $dzuk_mutant$elm_css$Html$Styled$toUnstyled = $dzuk_mutant$elm_css$VirtualDom$Styled$toUnstyled;
    var $author$project$Pages$Layout$footer = A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Copyright \u00A9 2023 Gaming Palace. All Rights Reserved"),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Disclaimer: This website is not affiliated with any of the companies that own any of the video games mentioned here.")
    ]));
    var $author$project$Design$Logo$title = function (fn) {
        return $author$project$UI$lone_fn(_List_fromArray([
            $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
                $dzuk_mutant$elm_css$Css$textShadowMany(_List_fromArray([
                    _Utils_update($dzuk_mutant$elm_css$Css$defaultTextShadow, {
                        cP: $elm$core$Maybe$Just($dzuk_mutant$elm_css$Css$px(4)),
                        bD: $elm$core$Maybe$Just($dzuk_mutant$elm_css$Css$hex("#FFF"))
                    })
                ]))
            ]))
        ]), fn("Gaming Palace"));
    };
    var $author$project$Pages$Layout$logo = $author$project$UI$row_fn(_List_fromArray([
        $author$project$UI$gap(1),
        $author$project$UI$maxWidthPct(100),
        $author$project$UI$axisCrossCenter,
        $author$project$UI$wrap,
        $author$project$UI$axisMainCenter,
        $author$project$UI$cursorPointer,
        $dzuk_mutant$elm_css$Html$Styled$Events$onClick($author$project$Main$Msg$goToHome)
    ]), _List_fromArray([
        $author$project$Design$Logo$logo(16),
        $author$project$Design$Logo$title($author$project$Design$Typo$h1)
    ]));
    var $dzuk_mutant$elm_css$Css$backgroundImage = function (_v0) {
        var value = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("background-image:" + value);
    };
    var $dzuk_mutant$elm_css$Css$backgroundPosition2_fn = function (_v0, _v1) {
        var horiz = _v0;
        var vert = _v1;
        return $dzuk_mutant$elm_css$Css$appendProperty("background-position:" + (horiz + (" " + vert)));
    }, $dzuk_mutant$elm_css$Css$backgroundPosition2 = F2($dzuk_mutant$elm_css$Css$backgroundPosition2_fn);
    var $author$project$Pages$Layout$genericGamingBackground = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$backgroundImage($dzuk_mutant$elm_css$Css$url("https://img.freepik.com/premium-vector/seamless-grass-texture-with-old-stone-tiles_172107-2543.jpg")),
        $dzuk_mutant$elm_css$Css$backgroundPosition2_fn($dzuk_mutant$elm_css$Css$px(-626), $dzuk_mutant$elm_css$Css$px(-626)),
        $dzuk_mutant$elm_css$Css$animationName($dzuk_mutant$elm_css$Css$customIdent("scroll")),
        $dzuk_mutant$elm_css$Css$animationDuration($dzuk_mutant$elm_css$Css$s(64)),
        $dzuk_mutant$elm_css$Css$animationTimingFunction($dzuk_mutant$elm_css$Css$linear),
        $dzuk_mutant$elm_css$Css$animationIterationCount($dzuk_mutant$elm_css$Css$infinite)
    ]));
    var $dzuk_mutant$elm_css$Css$vh = function (value) {
        return $elm$core$String$fromFloat(value) + "vh";
    };
    var $author$project$UI$heightScreen = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$height($dzuk_mutant$elm_css$Css$vh(100))
    ]));
    var $author$project$Utils$List$optionalAndThen_fn = function (fn, x, pred, xs) {
        return pred ? fn(_List_Cons(x, xs)) : fn(xs);
    }, $author$project$Utils$List$optionalAndThen = F4($author$project$Utils$List$optionalAndThen_fn);
    var $author$project$Pages$Layout$mainView_fn = function (isDashboard, layoutBody) {
        return A5($author$project$Utils$List$optionalAndThen, $author$project$UI$lone, $author$project$Pages$Layout$genericGamingBackground, !isDashboard, _List_fromArray([
            $author$project$UI$heightScreen,
            $author$project$UI$overflowBothClip,
            $elm$core$Basics$composeR_fn($author$project$Design$Palette$toBackground_a0, $author$project$Design$Palette$toBackground_a1, $author$project$Design$Palette$genericBlack)
        ]), $author$project$UI$keyedColumn_fn(_List_fromArray([
            $author$project$UI$axisMainCenter,
            $author$project$UI$axisCrossCenter,
            $author$project$UI$flexFill(1),
            $author$project$UI$gap(1),
            $author$project$UI$padding(1),
            $author$project$UI$overflowBothClip
        ]), layoutBody));
    }, $author$project$Pages$Layout$mainView = F2($author$project$Pages$Layout$mainView_fn);
    var $author$project$Pages$Layout$view_fn = function (model, body) {
        var _v0 = model.cB;
        switch (_v0.$) {
            case 0:
                return $author$project$UI$lone_fn(_List_Nil, $dzuk_mutant$elm_css$Html$Styled$text("Sorry, nothing for you to see here yet."));
            case 3:
                return $author$project$Pages$Layout$mainView_fn(true, _List_fromArray([body]));
            default:
                return $author$project$Pages$Layout$mainView_fn(false, _List_fromArray([
                    _Utils_Tuple2("logo", $author$project$Pages$Layout$logo),
                    body,
                    _Utils_Tuple2("footer", $author$project$UI$lone_fn(_List_fromArray([$author$project$UI$shrink]), $author$project$Pages$Layout$footer))
                ]));
        }
    }, $author$project$Pages$Layout$view = F2($author$project$Pages$Layout$view_fn);
    var $author$project$Pages$Layout$wrap_fn = function (model, title, _v0) {
        var inKey = _v0.a;
        var body = _v0.b;
        return {
            ag: _List_fromArray([
                $dzuk_mutant$elm_css$Html$Styled$toUnstyled($author$project$Pages$Layout$globalCss),
                $dzuk_mutant$elm_css$Html$Styled$toUnstyled($author$project$Pages$Layout$view_fn(model, _Utils_Tuple2(title + ("@" + inKey), body))),
                $author$project$Pages$Layout$importSprite
            ]),
            eu: title + " - Gaming Palace"
        };
    }, $author$project$Pages$Layout$wrap = F3($author$project$Pages$Layout$wrap_fn);
    var $author$project$Pages$Dashboard$View$page_fn = function (model, viewing) {
        return $author$project$Pages$Layout$wrap_fn(model, "Dashboard", $author$project$Pages$Dashboard$View$view_fn(model, viewing));
    }, $author$project$Pages$Dashboard$View$page = F2($author$project$Pages$Dashboard$View$page_fn);
    var $author$project$Main$Msg$goToDashboard = $author$project$Main$Msg$GoToInternal($author$project$Pages$Route$GoingDashboard);
    var $author$project$Design$Icon$iBug = function (hint) {
        return $author$project$Design$Icon$Icon_fn($author$project$Design$Icon$Properties_fn(hint, "bug"), $author$project$Design$Icon$defaultOptions);
    };
    var $author$project$Pages$Leaving$View$view_fn = function (_v0, hasFailed) {
        return hasFailed ? $elm$core$Tuple$pair_fn("failure", A2($author$project$UI$centerLone, _List_Nil, A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Failure"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "We were unable to finish the request, you're still logged in..."),
            $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(12, $author$project$Design$Icon$iBug("Request failed"))),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1)
            ]), _List_fromArray([
                $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(3, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromText("Retry")))),
                $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(0, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToDashboard, $author$project$Design$Button$fromText("My dashboard"))))
            ]))
        ])))) : $elm$core$Tuple$pair_fn("trying", A2($author$project$UI$centerLone, _List_Nil, A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Terminating session..."),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Wait a second, you'll be redirected in a while..."),
            $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(12, $author$project$Design$Icon$iSpinner("Processing sign in")))
        ]))));
    }, $author$project$Pages$Leaving$View$view = F2($author$project$Pages$Leaving$View$view_fn);
    var $author$project$Pages$Leaving$View$page_fn = function (model, hasFailed) {
        return $author$project$Pages$Layout$wrap_fn(model, "Not Found", $author$project$Pages$Leaving$View$view_fn(model, hasFailed));
    }, $author$project$Pages$Leaving$View$page = F2($author$project$Pages$Leaving$View$page_fn);
    var $author$project$Pages$NotFound$View$view = function (_v0) {
        return $elm$core$Tuple$pair_fn("main", $author$project$UI$lone_fn(_List_fromArray([
            $author$project$UI$axisMainCenter,
            $author$project$UI$axisCrossCenter,
            $author$project$UI$flexFill(1)
        ]), A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Page Not Found"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "The page you were looking for could not be found."),
            $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToHome, $author$project$Design$Button$fromText("Go to home page")))
        ]))));
    };
    var $author$project$Pages$NotFound$View$page = function (model) {
        return $author$project$Pages$Layout$wrap_fn(model, "Not Found", $author$project$Pages$NotFound$View$view(model));
    };
    var $author$project$Pages$Welcome$View$column = _List_fromArray([
        $author$project$UI$flexFill(1),
        $author$project$UI$minWidthSmallest,
        $author$project$UI$maxWidthLg(48)
    ]);
    var $author$project$Pages$Welcome$View$loginFailed = function (_v0) {
        return $elm$core$Tuple$pair_fn("failed", A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Trying to sign in..."),
            $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(8, $author$project$Design$Icon$iBug("Request failed"))),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h2_a0, $author$project$Design$Typo$h2_a1, "Request failed"),
            $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(3, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromText("Retry"))))
        ])));
    };
    var $author$project$Pages$Welcome$View$loginSuccessView_fn = function (_v0, account) {
        return A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Sign in successful!"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Welcome back " + (account.b1 + ".")),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1)
            ]), _List_fromArray([
                $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$goToDashboard, $author$project$Design$Button$fromText("My dashboard"))),
                $author$project$Design$Button$toUI($author$project$Design$Button$withStyle_fn(0, $author$project$Design$Button$withClick_fn($author$project$Main$Msg$Logout, $author$project$Design$Button$fromText("Leave"))))
            ]))
        ]));
    }, $author$project$Pages$Welcome$View$loginSuccessView = F2($author$project$Pages$Welcome$View$loginSuccessView_fn);
    var $author$project$Main$Msg$Login_fn = function (a, b) {
        return { $: 5, a: a, b: b };
    }, $author$project$Main$Msg$Login = F2($author$project$Main$Msg$Login_fn);
    var $author$project$Main$Msg$LoginFillEmail = function (a) {
        return { $: 6, a: a };
    };
    var $dzuk_mutant$elm_css$Css$padding2_fn = function (_v0, _v1) {
        var valueTopBottom = _v0;
        var valueRightLeft = _v1;
        return $dzuk_mutant$elm_css$Css$appendProperty("padding:" + (valueTopBottom + (" " + valueRightLeft)));
    }, $dzuk_mutant$elm_css$Css$padding2 = F2($dzuk_mutant$elm_css$Css$padding2_fn);
    var $author$project$UI$paddingXY_fn = function (x, y) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$padding2_fn($dzuk_mutant$elm_css$Css$rem(y), $dzuk_mutant$elm_css$Css$rem(x))
        ]));
    }, $author$project$UI$paddingXY = F2($author$project$UI$paddingXY_fn);
    var $author$project$Pages$Welcome$View$typeEmailView_fn = function (_v0, emailInput, emailValid) {
        var maybeSubmitMsg = emailValid ? $elm$core$Maybe$Just($author$project$Main$Msg$Login_fn(emailInput, $elm$core$Maybe$Nothing)) : $elm$core$Maybe$Nothing;
        return A2($author$project$Design$Container$hero, _List_fromArray([
            $author$project$UI$paddingXY_fn(2, 1)
        ]), _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Already registered?"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Type your e-mail to start:"),
            $author$project$Design$Input$toUI($author$project$Design$Input$withSubmitMaybe_fn(maybeSubmitMsg, $author$project$Design$Input$withEditing_fn($author$project$Main$Msg$LoginFillEmail, $author$project$Design$Input$forEmail_fn("E-mail", emailInput)))),
            $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(maybeSubmitMsg, $author$project$Design$Button$fromText("Sign in")))
        ]));
    }, $author$project$Pages$Welcome$View$typeEmailView = F3($author$project$Pages$Welcome$View$typeEmailView_fn);
    var $author$project$Main$Msg$LoginFillOTP_fn = function (a, b) {
        return { $: 7, a: a, b: b };
    }, $author$project$Main$Msg$LoginFillOTP = F2($author$project$Main$Msg$LoginFillOTP_fn);
    var $author$project$Design$Input$forPassword_fn = function (label, value) {
        return $author$project$Design$Input$Input_fn({ am: "password", V: label, az: value }, $author$project$Design$Input$default);
    }, $author$project$Design$Input$forPassword = F2($author$project$Design$Input$forPassword_fn);
    var $author$project$Design$Input$withPlaceholder_fn = function (newPlaceholder, _v0) {
        var props = _v0.a;
        var opts = _v0.b;
        return $author$project$Design$Input$Input_fn(props, _Utils_update(opts, {
            Y: $elm$core$Maybe$Just(newPlaceholder)
        }));
    }, $author$project$Design$Input$withPlaceholder = F2($author$project$Design$Input$withPlaceholder_fn);
    var $author$project$Pages$Welcome$View$typeOTPView_fn = function (_v0, email, otpInput, optValid) {
        var maybeSubmitMsg = optValid ? $elm$core$Maybe$Just($author$project$Main$Msg$Login_fn(email, $elm$core$Maybe$Just(otpInput))) : $elm$core$Maybe$Nothing;
        return A2($author$project$Design$Container$hero, _List_fromArray([
            $author$project$UI$paddingXY_fn(2, 1)
        ]), _List_fromArray([
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Already registered?"),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "One-time code sent to " + email),
            $elm$core$Basics$composeR_fn($author$project$Design$Typo$centeredLabel_a0, $author$project$Design$Typo$centeredLabel_a1, "Check your inbox and type the code:"),
            $author$project$Design$Input$toUI($author$project$Design$Input$withPlaceholder_fn("OTP Code", $author$project$Design$Input$withSubmitMaybe_fn(maybeSubmitMsg, $author$project$Design$Input$withEditing_fn($author$project$Main$Msg$LoginFillOTP(email), $author$project$Design$Input$forPassword_fn("OTP Code", otpInput))))),
            $author$project$UI$row_fn(_List_fromArray([
                $author$project$UI$gap(1)
            ]), _List_fromArray([
                $author$project$Design$Button$toUI($author$project$Design$Button$withClickMaybe_fn(maybeSubmitMsg, $author$project$Design$Button$fromText("Proceed"))),
                $author$project$Design$Button$toUI($author$project$Design$Button$withClick_fn($author$project$Main$Msg$LoginFillEmail(email), $author$project$Design$Button$withStyle_fn(0, $author$project$Design$Button$fromText("Wrong e-mail"))))
            ]))
        ]));
    }, $author$project$Pages$Welcome$View$typeOTPView = F4($author$project$Pages$Welcome$View$typeOTPView_fn);
    var $author$project$Pages$Welcome$View$loginBox = function (model) {
        var _v0 = model.ar;
        switch (_v0.$) {
            case 0:
                var emailInput = _v0.a;
                var emailValid = _v0.b;
                return $elm$core$Tuple$pair_fn("email", $author$project$Pages$Welcome$View$typeEmailView_fn(model, emailInput, emailValid));
            case 1:
                var email = _v0.a;
                var otpInput = _v0.b;
                var optValid = _v0.c;
                return $elm$core$Tuple$pair_fn("otp", $author$project$Pages$Welcome$View$typeOTPView_fn(model, email, otpInput, optValid));
            case 2:
                return $author$project$Pages$Welcome$View$loginFailed(model);
            case 3:
                var account = _v0.a.A;
                return $elm$core$Tuple$pair_fn("success", $author$project$Pages$Welcome$View$loginSuccessView_fn(model, account));
            default:
                return $elm$core$Tuple$pair_fn("waiting", A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
                    $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Sign in to Gaming Palace"),
                    $author$project$Design$Icon$toUI($author$project$Design$Icon$withSize_fn(8, $author$project$Design$Icon$iSpinner("Processing sign in")))
                ])));
        }
    };
    var $author$project$Pages$Welcome$View$welcomeBox = A2($author$project$Design$Container$hero, _List_Nil, _List_fromArray([
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$h1_a0, $author$project$Design$Typo$h1_a1, "Welcome"),
        $elm$core$Basics$composeR_fn($author$project$Design$Typo$paragraphs_a0, $author$project$Design$Typo$paragraphs_a1, _List_fromArray(["We're pleased to meet you!"])),
        $author$project$UI$row_fn(_List_fromArray([
            $author$project$UI$gap(1)
        ]), _List_fromArray([
            $author$project$Design$Button$toUI($author$project$Design$Button$fromText("About")),
            $author$project$Design$Button$toUI($author$project$Design$Button$fromText("Support"))
        ]))
    ]));
    var $author$project$Pages$Welcome$View$firstColumn = function (model) {
        return $author$project$UI$keyedColumn_fn(_List_Cons($author$project$UI$gap(2), $author$project$Pages$Welcome$View$column), _List_fromArray([
            _Utils_Tuple2("welcome", $author$project$Pages$Welcome$View$welcomeBox),
            $author$project$Pages$Welcome$View$loginBox(model)
        ]));
    };
    var $dzuk_mutant$elm_css$Css$maxHeight = function (_v0) {
        var val = _v0;
        return $dzuk_mutant$elm_css$Css$appendProperty("max-height:" + val);
    };
    var $author$project$UI$maxHeight = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$maxHeight($dzuk_mutant$elm_css$Css$rem(size))
        ]));
    };
    var $author$project$UI$maxHeightPct = function (size) {
        return $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
            $dzuk_mutant$elm_css$Css$maxHeight($dzuk_mutant$elm_css$Css$pct(size))
        ]));
    };
    var $author$project$UI$overflowYClip = $dzuk_mutant$elm_css$Html$Styled$Attributes$css(_List_fromArray([
        $dzuk_mutant$elm_css$Css$overflowY($dzuk_mutant$elm_css$Css$clip)
    ]));
    var $author$project$Pages$Welcome$View$view = function (model) {
        return $elm$core$Tuple$pair_fn("main", $author$project$UI$keyedRow_fn(_List_fromArray([
            $author$project$UI$axisMainCenter,
            $author$project$UI$axisCrossCenter,
            $author$project$UI$flexFill(1),
            $author$project$UI$gap(2),
            $author$project$UI$wrap,
            $author$project$UI$widthFill,
            $author$project$UI$maxHeightPct(100),
            $author$project$UI$overflowYAuto
        ]), _List_fromArray([
            _Utils_Tuple2("first", $author$project$Pages$Welcome$View$firstColumn(model)),
            _Utils_Tuple2("games", $author$project$Components$GameShelf$box_fn($author$project$Pages$Welcome$View$column, model)),
            _Utils_Tuple2("cart", $author$project$Components$Cart$box_fn(_List_Cons($author$project$UI$maxHeight(58), _List_Cons($author$project$UI$overflowYClip, $author$project$Pages$Welcome$View$column)), model))
        ])));
    };
    var $author$project$Pages$Welcome$View$page = function (model) {
        return $author$project$Pages$Layout$wrap_fn(model, "Home", $author$project$Pages$Welcome$View$view(model));
    };
    var $author$project$Main$View$document = function (model) {
        var _v0 = model.cB;
        switch (_v0.$) {
            case 2:
                return $author$project$Pages$Welcome$View$page(model);
            case 3:
                var subView = _v0.a;
                return $author$project$Pages$Dashboard$View$page_fn(model, subView);
            case 4:
                var hasFailed = _v0.a;
                return $author$project$Pages$Leaving$View$page_fn(model, hasFailed);
            default:
                return $author$project$Pages$NotFound$View$page(model);
        }
    };
    var $elm$json$Json$Decode$int = _Json_decodeInt;
    var $author$project$Main$Msg$StorageUpdated = function (a) {
        return { $: 14, a: a };
    };
    var $elm$json$Json$Decode$value = _Json_decodeValue;
    var $author$project$Ports$storageChanged = _Platform_incomingPort("storageChanged", $elm$json$Json$Decode$value);
    var $author$project$Main$Subscriptions$subscriptions = function (_v0) {
        return $author$project$Ports$storageChanged($author$project$Main$Msg$StorageUpdated);
    };
    var $TSFoster$elm_uuid$UUID$Seeds_fn = function (seed1, seed2, seed3, seed4) {
        return { cl: seed1, cm: seed2, cn: seed3, co: seed4 };
    }, $TSFoster$elm_uuid$UUID$Seeds = F4($TSFoster$elm_uuid$UUID$Seeds_fn);
    var $elm$core$Platform$Cmd$batch = _Platform_batch;
    var $elm$core$Task$onError = _Scheduler_onError;
    var $elm$core$Task$attempt_fn = function (resultToMessage, task) {
        return $elm$core$Task$command(_Scheduler_onError_fn(A2($elm$core$Basics$composeL, A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage), $elm$core$Result$Err), _Scheduler_andThen_fn(A2($elm$core$Basics$composeL, A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage), $elm$core$Result$Ok), task)));
    }, $elm$core$Task$attempt = F2($elm$core$Task$attempt_fn);
    var $elm$browser$Browser$Dom$focus_a0 = "focus", $elm$browser$Browser$Dom$focus = _Browser_call($elm$browser$Browser$Dom$focus_a0);
    var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
    var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
        return { $: 1, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
    var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return _Json_decodeField_fn("data", decoder_);
    };
    var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest_fn = function (baseUrl, mutationSelectionSet) {
        return {
            af: baseUrl,
            aF: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
            aj: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
            al: _List_Nil,
            H: $elm$core$Maybe$Nothing,
            J: _List_Nil,
            au: $elm$core$Maybe$Nothing,
            Q: false
        };
    }, $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest_fn);
    var $elm$http$Http$Request = function (a) {
        return { $: 1, a: a };
    };
    var $elm$http$Http$State_fn = function (reqs, subs) {
        return { cg: reqs, cu: subs };
    }, $elm$http$Http$State = F2($elm$http$Http$State_fn);
    var $elm$http$Http$init = $elm$core$Task$succeed($elm$http$Http$State_fn($elm$core$Dict$empty, _List_Nil));
    var $elm$http$Http$BadStatus__fn = function (a, b) {
        return { $: 3, a: a, b: b };
    }, $elm$http$Http$BadStatus_ = F2($elm$http$Http$BadStatus__fn);
    var $elm$http$Http$BadUrl_ = function (a) {
        return { $: 0, a: a };
    };
    var $elm$http$Http$GoodStatus__fn = function (a, b) {
        return { $: 4, a: a, b: b };
    }, $elm$http$Http$GoodStatus_ = F2($elm$http$Http$GoodStatus__fn);
    var $elm$http$Http$NetworkError_ = { $: 2 };
    var $elm$http$Http$Receiving = function (a) {
        return { $: 1, a: a };
    };
    var $elm$http$Http$Sending = function (a) {
        return { $: 0, a: a };
    };
    var $elm$http$Http$Timeout_ = { $: 1 };
    var $elm$core$Maybe$isJust = function (maybe) {
        if (!maybe.$) {
            return true;
        }
        else {
            return false;
        }
    };
    var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
    var $elm$core$Dict$getMin = function (dict) {
        getMin: while (true) {
            if ((dict.$ === -1) && (dict.d.$ === -1)) {
                var left = dict.d;
                var $temp$dict = left;
                dict = $temp$dict;
                continue getMin;
            }
            else {
                return dict;
            }
        }
    };
    var $elm$core$Dict$moveRedLeft = function (dict) {
        if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
            if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v1 = dict.d;
                var lClr = _v1.a;
                var lK = _v1.b;
                var lV = _v1.c;
                var lLeft = _v1.d;
                var lRight = _v1.e;
                var _v2 = dict.e;
                var rClr = _v2.a;
                var rK = _v2.b;
                var rV = _v2.c;
                var rLeft = _v2.d;
                var _v3 = rLeft.a;
                var rlK = rLeft.b;
                var rlV = rLeft.c;
                var rlL = rLeft.d;
                var rlR = rLeft.e;
                var rRight = _v2.e;
                return $elm$core$Dict$RBNode_elm_builtin_fn(0, rlK, rlV, $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), rlL), $elm$core$Dict$RBNode_elm_builtin_fn(1, rK, rV, rlR, rRight));
            }
            else {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v4 = dict.d;
                var lClr = _v4.a;
                var lK = _v4.b;
                var lV = _v4.c;
                var lLeft = _v4.d;
                var lRight = _v4.e;
                var _v5 = dict.e;
                var rClr = _v5.a;
                var rK = _v5.b;
                var rV = _v5.c;
                var rLeft = _v5.d;
                var rRight = _v5.e;
                if (clr === 1) {
                    return $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $elm$core$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
                else {
                    return $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $elm$core$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
            }
        }
        else {
            return dict;
        }
    };
    var $elm$core$Dict$moveRedRight = function (dict) {
        if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
            if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v1 = dict.d;
                var lClr = _v1.a;
                var lK = _v1.b;
                var lV = _v1.c;
                var _v2 = _v1.d;
                var _v3 = _v2.a;
                var llK = _v2.b;
                var llV = _v2.c;
                var llLeft = _v2.d;
                var llRight = _v2.e;
                var lRight = _v1.e;
                var _v4 = dict.e;
                var rClr = _v4.a;
                var rK = _v4.b;
                var rV = _v4.c;
                var rLeft = _v4.d;
                var rRight = _v4.e;
                return $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, $elm$core$Dict$RBNode_elm_builtin_fn(1, llK, llV, llLeft, llRight), $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, lRight, $elm$core$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight)));
            }
            else {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v5 = dict.d;
                var lClr = _v5.a;
                var lK = _v5.b;
                var lV = _v5.c;
                var lLeft = _v5.d;
                var lRight = _v5.e;
                var _v6 = dict.e;
                var rClr = _v6.a;
                var rK = _v6.b;
                var rV = _v6.c;
                var rLeft = _v6.d;
                var rRight = _v6.e;
                if (clr === 1) {
                    return $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $elm$core$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
                else {
                    return $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, $elm$core$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $elm$core$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
            }
        }
        else {
            return dict;
        }
    };
    var $elm$core$Dict$removeHelpPrepEQGT_fn = function (targetKey, dict, color, key, value, left, right) {
        if ((left.$ === -1) && (!left.a)) {
            var _v1 = left.a;
            var lK = left.b;
            var lV = left.c;
            var lLeft = left.d;
            var lRight = left.e;
            return $elm$core$Dict$RBNode_elm_builtin_fn(color, lK, lV, lLeft, $elm$core$Dict$RBNode_elm_builtin_fn(0, key, value, lRight, right));
        }
        else {
            _v2$2: while (true) {
                if ((right.$ === -1) && (right.a === 1)) {
                    if (right.d.$ === -1) {
                        if (right.d.a === 1) {
                            var _v3 = right.a;
                            var _v4 = right.d;
                            var _v5 = _v4.a;
                            return $elm$core$Dict$moveRedRight(dict);
                        }
                        else {
                            break _v2$2;
                        }
                    }
                    else {
                        var _v6 = right.a;
                        var _v7 = right.d;
                        return $elm$core$Dict$moveRedRight(dict);
                    }
                }
                else {
                    break _v2$2;
                }
            }
            return dict;
        }
    }, $elm$core$Dict$removeHelpPrepEQGT = F7($elm$core$Dict$removeHelpPrepEQGT_fn);
    var $elm$core$Dict$removeMin = function (dict) {
        if ((dict.$ === -1) && (dict.d.$ === -1)) {
            var color = dict.a;
            var key = dict.b;
            var value = dict.c;
            var left = dict.d;
            var lColor = left.a;
            var lLeft = left.d;
            var right = dict.e;
            if (lColor === 1) {
                if ((lLeft.$ === -1) && (!lLeft.a)) {
                    var _v3 = lLeft.a;
                    return $elm$core$Dict$RBNode_elm_builtin_fn(color, key, value, $elm$core$Dict$removeMin(left), right);
                }
                else {
                    var _v4 = $elm$core$Dict$moveRedLeft(dict);
                    if (_v4.$ === -1) {
                        var nColor = _v4.a;
                        var nKey = _v4.b;
                        var nValue = _v4.c;
                        var nLeft = _v4.d;
                        var nRight = _v4.e;
                        return $elm$core$Dict$balance_fn(nColor, nKey, nValue, $elm$core$Dict$removeMin(nLeft), nRight);
                    }
                    else {
                        return $elm$core$Dict$RBEmpty_elm_builtin;
                    }
                }
            }
            else {
                return $elm$core$Dict$RBNode_elm_builtin_fn(color, key, value, $elm$core$Dict$removeMin(left), right);
            }
        }
        else {
            return $elm$core$Dict$RBEmpty_elm_builtin;
        }
    };
    var $elm$core$Dict$removeHelp_fn = function (targetKey, dict) {
        if (dict.$ === -2) {
            return $elm$core$Dict$RBEmpty_elm_builtin;
        }
        else {
            var color = dict.a;
            var key = dict.b;
            var value = dict.c;
            var left = dict.d;
            var right = dict.e;
            if (_Utils_cmp(targetKey, key) < 0) {
                if ((left.$ === -1) && (left.a === 1)) {
                    var _v4 = left.a;
                    var lLeft = left.d;
                    if ((lLeft.$ === -1) && (!lLeft.a)) {
                        var _v6 = lLeft.a;
                        return $elm$core$Dict$RBNode_elm_builtin_fn(color, key, value, $elm$core$Dict$removeHelp_fn(targetKey, left), right);
                    }
                    else {
                        var _v7 = $elm$core$Dict$moveRedLeft(dict);
                        if (_v7.$ === -1) {
                            var nColor = _v7.a;
                            var nKey = _v7.b;
                            var nValue = _v7.c;
                            var nLeft = _v7.d;
                            var nRight = _v7.e;
                            return $elm$core$Dict$balance_fn(nColor, nKey, nValue, $elm$core$Dict$removeHelp_fn(targetKey, nLeft), nRight);
                        }
                        else {
                            return $elm$core$Dict$RBEmpty_elm_builtin;
                        }
                    }
                }
                else {
                    return $elm$core$Dict$RBNode_elm_builtin_fn(color, key, value, $elm$core$Dict$removeHelp_fn(targetKey, left), right);
                }
            }
            else {
                return $elm$core$Dict$removeHelpEQGT_fn(targetKey, $elm$core$Dict$removeHelpPrepEQGT_fn(targetKey, dict, color, key, value, left, right));
            }
        }
    }, $elm$core$Dict$removeHelp = F2($elm$core$Dict$removeHelp_fn);
    var $elm$core$Dict$removeHelpEQGT_fn = function (targetKey, dict) {
        if (dict.$ === -1) {
            var color = dict.a;
            var key = dict.b;
            var value = dict.c;
            var left = dict.d;
            var right = dict.e;
            if (_Utils_eq(targetKey, key)) {
                var _v1 = $elm$core$Dict$getMin(right);
                if (_v1.$ === -1) {
                    var minKey = _v1.b;
                    var minValue = _v1.c;
                    return $elm$core$Dict$balance_fn(color, minKey, minValue, left, $elm$core$Dict$removeMin(right));
                }
                else {
                    return $elm$core$Dict$RBEmpty_elm_builtin;
                }
            }
            else {
                return $elm$core$Dict$balance_fn(color, key, value, left, $elm$core$Dict$removeHelp_fn(targetKey, right));
            }
        }
        else {
            return $elm$core$Dict$RBEmpty_elm_builtin;
        }
    }, $elm$core$Dict$removeHelpEQGT = F2($elm$core$Dict$removeHelpEQGT_fn);
    var $elm$core$Dict$remove_fn = function (key, dict) {
        var _v0 = $elm$core$Dict$removeHelp_fn(key, dict);
        if ((_v0.$ === -1) && (!_v0.a)) {
            var _v1 = _v0.a;
            var k = _v0.b;
            var v = _v0.c;
            var l = _v0.d;
            var r = _v0.e;
            return $elm$core$Dict$RBNode_elm_builtin_fn(1, k, v, l, r);
        }
        else {
            var x = _v0;
            return x;
        }
    }, $elm$core$Dict$remove = F2($elm$core$Dict$remove_fn);
    var $elm$core$Dict$update_fn = function (targetKey, alter, dictionary) {
        var _v0 = alter($elm$core$Dict$get_fn(targetKey, dictionary));
        if (!_v0.$) {
            var value = _v0.a;
            return $elm$core$Dict$insert_fn(targetKey, value, dictionary);
        }
        else {
            return $elm$core$Dict$remove_fn(targetKey, dictionary);
        }
    }, $elm$core$Dict$update = F3($elm$core$Dict$update_fn);
    var $elm$core$Process$kill = _Scheduler_kill;
    var $elm$core$Process$spawn = _Scheduler_spawn;
    var $elm$http$Http$updateReqs_fn = function (router, cmds, reqs) {
        updateReqs: while (true) {
            if (!cmds.b) {
                return $elm$core$Task$succeed(reqs);
            }
            else {
                var cmd = cmds.a;
                var otherCmds = cmds.b;
                if (!cmd.$) {
                    var tracker = cmd.a;
                    var _v2 = $elm$core$Dict$get_fn(tracker, reqs);
                    if (_v2.$ === 1) {
                        var $temp$router = router, $temp$cmds = otherCmds, $temp$reqs = reqs;
                        router = $temp$router;
                        cmds = $temp$cmds;
                        reqs = $temp$reqs;
                        continue updateReqs;
                    }
                    else {
                        var pid = _v2.a;
                        return _Scheduler_andThen_fn(function (_v3) {
                            return $elm$http$Http$updateReqs_fn(router, otherCmds, $elm$core$Dict$remove_fn(tracker, reqs));
                        }, $elm$core$Process$kill(pid));
                    }
                }
                else {
                    var req = cmd.a;
                    return _Scheduler_andThen_fn(function (pid) {
                        var _v4 = req.aw;
                        if (_v4.$ === 1) {
                            return $elm$http$Http$updateReqs_fn(router, otherCmds, reqs);
                        }
                        else {
                            var tracker = _v4.a;
                            return $elm$http$Http$updateReqs_fn(router, otherCmds, $elm$core$Dict$insert_fn(tracker, pid, reqs));
                        }
                    }, $elm$core$Process$spawn(_Http_toTask_fn(router, $elm$core$Platform$sendToApp(router), req)));
                }
            }
        }
    }, $elm$http$Http$updateReqs = F3($elm$http$Http$updateReqs_fn);
    var $elm$http$Http$onEffects_fn = function (router, cmds, subs, state) {
        return _Scheduler_andThen_fn(function (reqs) {
            return $elm$core$Task$succeed($elm$http$Http$State_fn(reqs, subs));
        }, $elm$http$Http$updateReqs_fn(router, cmds, state.cg));
    }, $elm$http$Http$onEffects = F4($elm$http$Http$onEffects_fn);
    var $elm$core$List$maybeCons_fn = function (f, mx, xs) {
        var _v0 = f(mx);
        if (!_v0.$) {
            var x = _v0.a;
            return _List_Cons(x, xs);
        }
        else {
            return xs;
        }
    }, $elm$core$List$maybeCons = F3($elm$core$List$maybeCons_fn);
    var $elm$core$List$filterMap_fn = function (f, xs) {
        return $elm$core$List$foldr_fn($elm$core$List$maybeCons(f), _List_Nil, xs);
    }, $elm$core$List$filterMap = F2($elm$core$List$filterMap_fn);
    var $elm$http$Http$maybeSend_fn = function (router, desiredTracker, progress, _v0) {
        var actualTracker = _v0.a;
        var toMsg = _v0.b;
        return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(_Platform_sendToApp_fn(router, toMsg(progress))) : $elm$core$Maybe$Nothing;
    }, $elm$http$Http$maybeSend = F4($elm$http$Http$maybeSend_fn);
    var $elm$http$Http$onSelfMsg_fn = function (router, _v0, state) {
        var tracker = _v0.a;
        var progress = _v0.b;
        return _Scheduler_andThen_fn(function (_v1) {
            return $elm$core$Task$succeed(state);
        }, $elm$core$Task$sequence($elm$core$List$filterMap_fn(A3($elm$http$Http$maybeSend, router, tracker, progress), state.cu)));
    }, $elm$http$Http$onSelfMsg = F3($elm$http$Http$onSelfMsg_fn);
    var $elm$http$Http$Cancel = function (a) {
        return { $: 0, a: a };
    };
    var $elm$http$Http$cmdMap_fn = function (func, cmd) {
        if (!cmd.$) {
            var tracker = cmd.a;
            return $elm$http$Http$Cancel(tracker);
        }
        else {
            var r = cmd.a;
            return $elm$http$Http$Request({
                cK: r.cK,
                ag: r.ag,
                aj: _Http_mapExpect_fn(func, r.aj),
                al: r.al,
                an: r.an,
                au: r.au,
                aw: r.aw,
                o: r.o
            });
        }
    }, $elm$http$Http$cmdMap = F2($elm$http$Http$cmdMap_fn);
    var $elm$http$Http$MySub_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $elm$http$Http$MySub = F2($elm$http$Http$MySub_fn);
    var $elm$http$Http$subMap_fn = function (func, _v0) {
        var tracker = _v0.a;
        var toMsg = _v0.b;
        return $elm$http$Http$MySub_fn(tracker, A2($elm$core$Basics$composeR, toMsg, func));
    }, $elm$http$Http$subMap = F2($elm$http$Http$subMap_fn);
    _Platform_effectManagers["Http"] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
    var $elm$http$Http$command = _Platform_leaf("Http");
    var $elm$http$Http$subscription = _Platform_leaf("Http");
    var $elm$http$Http$request = function (r) {
        return $elm$http$Http$command($elm$http$Http$Request({ cK: false, ag: r.ag, aj: r.aj, al: r.al, an: r.an, au: r.au, aw: r.aw, o: r.o }));
    };
    var $elm$http$Http$riskyRequest = function (r) {
        return $elm$http$Http$command($elm$http$Http$Request({ cK: true, ag: r.ag, aj: r.aj, al: r.al, an: r.an, au: r.au, aw: r.aw, o: r.o }));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
        return { $: 1, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
        if (!httpResult.$) {
            var successOrError = httpResult.a;
            if (!successOrError.$) {
                var value = successOrError.a;
                return $elm$core$Result$Ok(value);
            }
            else {
                var _v2 = successOrError.a;
                var possiblyParsedData = _v2.a;
                var error = _v2.b;
                return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$GraphqlError_fn(possiblyParsedData, error));
            }
        }
        else {
            var httpError = httpResult.a;
            return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
        }
    };
    var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
        return { $: 4, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Http$BadStatus_fn = function (a, b) {
        return { $: 3, a: a, b: b };
    }, $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2($dillonkearns$elm_graphql$Graphql$Http$BadStatus_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
        return { $: 0, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = { $: 2 };
    var $dillonkearns$elm_graphql$Graphql$Http$Timeout = { $: 1 };
    var $elm$json$Json$Decode$decodeString = _Json_runOnString;
    var $elm$http$Http$expectStringResponse_fn = function (toMsg, toResult) {
        return _Http_expect_fn("", $elm$core$Basics$identity, A2($elm$core$Basics$composeR, toResult, toMsg));
    }, $elm$http$Http$expectStringResponse = F2($elm$http$Http$expectStringResponse_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$expectJson_fn = function (toMsg, decoder) {
        return $elm$http$Http$expectStringResponse_fn(toMsg, function (response) {
            switch (response.$) {
                case 0:
                    var url = response.a;
                    return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
                case 1:
                    return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
                case 2:
                    return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
                case 3:
                    var metadata = response.a;
                    var body = response.b;
                    return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$BadStatus_fn(metadata, body));
                default:
                    var metadata = response.a;
                    var body = response.b;
                    var _v1 = _Json_runOnString_fn(decoder, body);
                    if (!_v1.$) {
                        var value = _v1.a;
                        return $elm$core$Result$Ok(value);
                    }
                    else {
                        var err = _v1.a;
                        return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
                    }
            }
        });
    }, $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2($dillonkearns$elm_graphql$Graphql$Http$expectJson_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
    var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
    var $elm$http$Http$emptyBody = _Http_emptyBody;
    var $elm$http$Http$jsonBody = function (value) {
        return _Http_pair_fn("application/json", _Json_encode_fn(0, value));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
    var $elm$json$Json$Encode$object = function (pairs) {
        return _Json_wrap($elm$core$List$foldl_fn_unwrapped(function (_v0, obj) {
            var k = _v0.a;
            var v = _v0.b;
            return _Json_addField_fn(k, v, obj);
        }, _Json_emptyObject(0), pairs));
    };
    var $elm$core$Maybe$andThen_fn = function (callback, maybeValue) {
        if (!maybeValue.$) {
            var value = maybeValue.a;
            return callback(value);
        }
        else {
            return $elm$core$Maybe$Nothing;
        }
    }, $elm$core$Maybe$andThen = F2($elm$core$Maybe$andThen_fn);
    var $elm$core$String$dropRight_fn = function (n, string) {
        return (n < 1) ? string : _String_slice_fn(0, -n, string);
    }, $elm$core$String$dropRight = F2($elm$core$String$dropRight_fn);
    var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
    var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
    var $elm$core$Array$getHelp_fn = function (shift, index, tree) {
        getHelp: while (true) {
            var pos = $elm$core$Array$bitMask & (index >>> shift);
            var _v0 = _JsArray_unsafeGet_fn(pos, tree);
            if (!_v0.$) {
                var subTree = _v0.a;
                var $temp$shift = shift - $elm$core$Array$shiftStep, $temp$index = index, $temp$tree = subTree;
                shift = $temp$shift;
                index = $temp$index;
                tree = $temp$tree;
                continue getHelp;
            }
            else {
                var values = _v0.a;
                return _JsArray_unsafeGet_fn($elm$core$Array$bitMask & index, values);
            }
        }
    }, $elm$core$Array$getHelp = F3($elm$core$Array$getHelp_fn);
    var $elm$core$Array$tailIndex = function (len) {
        return (len >>> 5) << 5;
    };
    var $elm$core$Array$get_fn = function (index, _v0) {
        var len = _v0.a;
        var startShift = _v0.b;
        var tree = _v0.c;
        var tail = _v0.d;
        return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(index, $elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(_JsArray_unsafeGet_fn($elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just($elm$core$Array$getHelp_fn(startShift, index, tree)));
    }, $elm$core$Array$get = F2($elm$core$Array$get_fn);
    var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation_fn = function (replacements, _v0) {
        var match = _v0.dH;
        var ordinalString = A2($elm$core$Basics$composeL, $elm$core$String$dropLeft(1), $elm$core$String$dropRight(1))(match);
        return $elm$core$Maybe$withDefault_fn("", $elm$core$Maybe$andThen_fn(function (value) {
            return $elm$core$Array$get_fn(value, replacements);
        }, $elm$core$String$toInt(ordinalString)));
    }, $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2($lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation_fn);
    var $elm$core$Array$fromListHelp_fn = function (list, nodeList, nodeListSize) {
        fromListHelp: while (true) {
            var _v0 = _JsArray_initializeFromList_fn($elm$core$Array$branchFactor, list);
            var jsArray = _v0.a;
            var remainingItems = _v0.b;
            if (_Utils_cmp($elm$core$Elm$JsArray$length(jsArray), $elm$core$Array$branchFactor) < 0) {
                return $elm$core$Array$builderToArray_fn(true, { j: nodeList, g: nodeListSize, i: jsArray });
            }
            else {
                var $temp$list = remainingItems, $temp$nodeList = _List_Cons($elm$core$Array$Leaf(jsArray), nodeList), $temp$nodeListSize = nodeListSize + 1;
                list = $temp$list;
                nodeList = $temp$nodeList;
                nodeListSize = $temp$nodeListSize;
                continue fromListHelp;
            }
        }
    }, $elm$core$Array$fromListHelp = F3($elm$core$Array$fromListHelp_fn);
    var $elm$core$Array$fromList = function (list) {
        if (!list.b) {
            return $elm$core$Array$empty;
        }
        else {
            return $elm$core$Array$fromListHelp_fn(list, _List_Nil, 0);
        }
    };
    var $elm$regex$Regex$Match_fn = function (match, index, number, submatches) {
        return { dt: index, dH: match, d_: number, en: submatches };
    }, $elm$regex$Regex$Match = F4($elm$regex$Regex$Match_fn);
    var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
    var $elm$regex$Regex$fromString = function (string) {
        return _Regex_fromStringWith_fn({ cX: false, dQ: false }, string);
    };
    var $elm$regex$Regex$never = _Regex_never;
    var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = $elm$core$Maybe$withDefault_fn($elm$regex$Regex$never, $elm$regex$Regex$fromString("\\{\\d+\\}"));
    var $elm$regex$Regex$replace_a0 = _Regex_infinity, $elm$regex$Regex$replace = _Regex_replaceAtMost($elm$regex$Regex$replace_a0);
    var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate_fn = function (string, args) {
        var asArray = $elm$core$Array$fromList(args);
        return _Regex_replaceAtMost_fn($elm$regex$Regex$replace_a0, $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex, $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray), string);
    }, $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2($lukewestby$elm_string_interpolate$String$Interpolate$interpolate_fn);
    var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
    var $elm$core$Set$empty = $elm$core$Dict$empty;
    var $elm$core$String$concat = function (strings) {
        return $elm$core$String$join_fn("", strings);
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData_fn = function (shift, seed, hash, charsProcessed) {
        return { S: charsProcessed, U: hash, M: seed, Z: shift };
    }, $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn = function (b, a) {
        return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
    }, $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy_fn = function (b, a) {
        return (a << b) | (a >>> (32 - b));
    }, $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2($dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
        var acc = (!(!data.U)) ? (data.M ^ $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn($dillonkearns$elm_graphql$Graphql$Document$Hash$c2, $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy_fn(15, $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn($dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.U)))) : data.M;
        var h0 = acc ^ data.S;
        var h1 = $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn(2246822507, h0 ^ (h0 >>> 16));
        var h2 = $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn(3266489909, h1 ^ (h1 >>> 13));
        return (h2 ^ (h2 >>> 16)) >>> 0;
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix_fn = function (h1, k1) {
        return $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn(5, $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy_fn(13, h1 ^ $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn($dillonkearns$elm_graphql$Graphql$Document$Hash$c2, $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy_fn(15, $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy_fn($dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
    }, $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold_fn = function (c, data) {
        var res = data.U | ((255 & $elm$core$Char$toCode(c)) << data.Z);
        var _v0 = data.Z;
        if (_v0 === 24) {
            return {
                S: data.S + 1,
                U: 0,
                M: $dillonkearns$elm_graphql$Graphql$Document$Hash$mix_fn(data.M, res),
                Z: 0
            };
        }
        else {
            return { S: data.S + 1, U: res, M: data.M, Z: data.Z + 8 };
        }
    }, $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2($dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString_fn = function (seed, str) {
        return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(_String_foldl_fn($dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold, $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData_fn(0, seed, 0, 0), str));
    }, $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2($dillonkearns$elm_graphql$Graphql$Document$Hash$hashString_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
        return { $: 1, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
        return { $: 2, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
        return { $: 3, a: a };
    };
    var $elm$json$Json$Decode$decodeValue = _Json_run;
    var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
    var $elm$json$Json$Decode$list = _Json_decodeList;
    var $elm$core$Tuple$mapSecond_fn = function (func, _v0) {
        var x = _v0.a;
        var y = _v0.b;
        return _Utils_Tuple2(x, func(y));
    }, $elm$core$Tuple$mapSecond = F2($elm$core$Tuple$mapSecond_fn);
    var $elm$json$Json$Decode$oneOf = _Json_oneOf;
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
        var serializeJson = function (json) {
            var decoder = $elm$json$Json$Decode$oneOf(_List_fromArray([
                _Json_map1_fn(A2($elm$core$Basics$composeL, $dillonkearns$elm_graphql$Graphql$Internal$Encode$List, $elm$core$List$map($dillonkearns$elm_graphql$Graphql$Internal$Encode$Json)), $elm$json$Json$Decode$list($elm$json$Json$Decode$value)),
                _Json_map1_fn(A2($elm$core$Basics$composeL, $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object, $elm$core$List$map($elm$core$Tuple$mapSecond($dillonkearns$elm_graphql$Graphql$Internal$Encode$Json))), $elm$json$Json$Decode$keyValuePairs($elm$json$Json$Decode$value))
            ]));
            var _v2 = _Json_run_fn(decoder, json);
            if (!_v2.$) {
                var v = _v2.a;
                return $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(v);
            }
            else {
                return _Json_encode_fn(0, json);
            }
        };
        switch (value.$) {
            case 0:
                var enumValue = value.a;
                return enumValue;
            case 1:
                var json = value.a;
                return serializeJson(json);
            case 2:
                var values = value.a;
                return "[" + ($elm$core$String$join_fn(", ", $elm$core$List$map_fn($dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + "]");
            default:
                var keyValuePairs = value.a;
                return "{" + ($elm$core$String$join_fn(", ", $elm$core$List$map_fn(function (_v1) {
                    var key = _v1.a;
                    var objectValue = _v1.b;
                    return key + (": " + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
                }, keyValuePairs)) + "}");
        }
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
        var name = _v0.a;
        var value = _v0.b;
        return name + (": " + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
        if (!args.b) {
            return "";
        }
        else {
            var nonemptyArgs = args;
            return "(" + ($elm$core$String$join_fn(", ", $elm$core$List$map_fn($dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ")");
        }
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
        return $elm$core$Maybe$map_fn($dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0), function () {
            if (!field.$) {
                var name = field.a;
                var _arguments = field.b;
                var children = field.c;
                return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
            }
            else {
                var typeString = field.a.cz;
                var fieldName = field.a.bO;
                var _arguments = field.b;
                return (fieldName === "__typename") ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just($elm$core$String$concat($elm$core$List$append_fn(_List_fromArray([typeString]), $elm$core$List$singleton($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
            }
        }());
    };
    var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
        if (!field.$) {
            var fieldName = field.a;
            var argumentList = field.b;
            var fieldList = field.c;
            return fieldName;
        }
        else {
            var typeString = field.a.cz;
            var fieldName = field.a.bO;
            var argumentList = field.b;
            return fieldName;
        }
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
        return $elm$core$Maybe$map_fn(function (aliasHash) {
            return _Utils_ap($dillonkearns$elm_graphql$Graphql$RawField$name(field), $elm$core$String$fromInt(aliasHash));
        }, $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
    };
    var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
    var $j_maas$elm_ordered_containers$OrderedDict$empty = { f: $elm$core$Dict$empty, k: _List_Nil };
    var $elm$core$Dict$filter_fn = function (isGood, dict) {
        return $elm$core$Dict$foldl_fn_unwrapped(function (k, v, d) {
            return A2(isGood, k, v) ? $elm$core$Dict$insert_fn(k, v, d) : d;
        }, $elm$core$Dict$empty, dict);
    }, $elm$core$Dict$filter_fn_unwrapped = function (isGood, dict) {
        return $elm$core$Dict$foldl_fn_unwrapped(function (k, v, d) {
            return isGood(k, v) ? $elm$core$Dict$insert_fn(k, v, d) : d;
        }, $elm$core$Dict$empty, dict);
    }, $elm$core$Dict$filter = F2($elm$core$Dict$filter_fn);
    var $elm$core$Set$insert_fn = function (key, _v0) {
        var dict = _v0;
        return $elm$core$Dict$insert_fn(key, 0, dict);
    }, $elm$core$Set$insert = F2($elm$core$Set$insert_fn);
    var $elm$core$Set$fromList = function (list) {
        return $elm$core$List$foldl_fn($elm$core$Set$insert, $elm$core$Set$empty, list);
    };
    var $elm$core$Dict$singleton_fn = function (key, value) {
        return $elm$core$Dict$RBNode_elm_builtin_fn(1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
    }, $elm$core$Dict$singleton = F2($elm$core$Dict$singleton_fn);
    var $elm$core$Set$singleton = function (key) {
        return $elm$core$Dict$singleton_fn(key, 0);
    };
    var $elm$core$Set$size = function (_v0) {
        var dict = _v0;
        return $elm$core$Dict$size(dict);
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Field$findConflictingTypeFields = function (rawFields) {
        var compositeCount = $elm$core$List$length($elm$core$List$filterMap_fn(function (field) {
            if (!field.$) {
                return $elm$core$Maybe$Just(0);
            }
            else {
                return $elm$core$Maybe$Nothing;
            }
        }, rawFields));
        if (compositeCount <= 1) {
            return $elm$core$Set$empty;
        }
        else {
            var levelBelowNodes = $elm$core$List$concatMap_fn(function (field) {
                if (field.$ === 1) {
                    return _List_Nil;
                }
                else {
                    var children = field.c;
                    return children;
                }
            }, rawFields);
            var fieldTypes = $elm$core$List$foldl_fn_unwrapped(function (_v1, acc) {
                var fieldName = _v1.a;
                var fieldType = _v1.b;
                return $elm$core$Dict$update_fn(fieldName, function (maybeFieldTypes) {
                    if (maybeFieldTypes.$ === 1) {
                        return $elm$core$Maybe$Just($elm$core$Set$singleton(fieldType));
                    }
                    else {
                        var fieldTypes_ = maybeFieldTypes.a;
                        return $elm$core$Maybe$Just($elm$core$Set$insert_fn(fieldType, fieldTypes_));
                    }
                }, acc);
            }, $elm$core$Dict$empty, $elm$core$List$filterMap_fn(function (field) {
                if (field.$ === 1) {
                    var typeString = field.a.cz;
                    return $elm$core$Maybe$Just(_Utils_Tuple2($dillonkearns$elm_graphql$Graphql$RawField$name(field), typeString));
                }
                else {
                    return $elm$core$Maybe$Nothing;
                }
            }, levelBelowNodes));
            return $elm$core$Set$fromList($elm$core$Dict$keys($elm$core$Dict$filter_fn_unwrapped(function (fieldType, fields) {
                return function (size) {
                    return size > 1;
                }($elm$core$Set$size(fields));
            }, fieldTypes)));
        }
    };
    var $j_maas$elm_ordered_containers$OrderedDict$get_fn = function (key, _v0) {
        var orderedDict = _v0;
        return $elm$core$Dict$get_fn(key, orderedDict.f);
    }, $j_maas$elm_ordered_containers$OrderedDict$get = F2($j_maas$elm_ordered_containers$OrderedDict$get_fn);
    var $elm$core$Set$member_fn = function (key, _v0) {
        var dict = _v0;
        return $elm$core$Dict$member_fn(key, dict);
    }, $elm$core$Set$member = F2($elm$core$Set$member_fn);
    var $elm$core$Basics$always_fn = function (a, _v0) {
        return a;
    }, $elm$core$Basics$always = F2($elm$core$Basics$always_fn);
    var $elm$core$List$filter_fn = function (f, xs) {
        var tmp = _List_Cons(undefined, _List_Nil);
        var end = tmp;
        for (; xs.b; xs = xs.b) {
            if (f(xs.a)) {
                var next = _List_Cons(xs.a, _List_Nil);
                end.b = next;
                end = next;
            }
        }
        return tmp.
            b;
    }, $elm$core$List$filter = F2($elm$core$List$filter_fn);
    var $j_maas$elm_ordered_containers$OrderedDict$insert_fn = function (key, value, _v0) {
        var orderedDict = _v0;
        var filteredOrder = $elm$core$Dict$member_fn(key, orderedDict.f) ? $elm$core$List$filter_fn(function (k) {
            return !_Utils_eq(k, key);
        }, orderedDict.k) : orderedDict.k;
        var newOrder = _Utils_ap(filteredOrder, _List_fromArray([key]));
        return {
            f: $elm$core$Dict$insert_fn(key, value, orderedDict.f),
            k: newOrder
        };
    }, $j_maas$elm_ordered_containers$OrderedDict$insert = F3($j_maas$elm_ordered_containers$OrderedDict$insert_fn);
    var $j_maas$elm_ordered_containers$OrderedDict$remove_fn = function (key, _v0) {
        var orderedDict = _v0;
        return {
            f: $elm$core$Dict$remove_fn(key, orderedDict.f),
            k: $elm$core$Dict$member_fn(key, orderedDict.f) ? $elm$core$List$filter_fn(function (k) {
                return !_Utils_eq(k, key);
            }, orderedDict.k) : orderedDict.k
        };
    }, $j_maas$elm_ordered_containers$OrderedDict$remove = F2($j_maas$elm_ordered_containers$OrderedDict$remove_fn);
    var $j_maas$elm_ordered_containers$OrderedDict$update_fn = function (key, alter, original) {
        var orderedDict = original;
        var _v0 = $elm$core$Dict$get_fn(key, orderedDict.f);
        if (!_v0.$) {
            var oldItem = _v0.a;
            var _v1 = alter($elm$core$Maybe$Just(oldItem));
            if (!_v1.$) {
                var newItem = _v1.a;
                return {
                    f: $elm$core$Dict$update_fn(key, $elm$core$Basics$always($elm$core$Maybe$Just(newItem)), orderedDict.f),
                    k: orderedDict.k
                };
            }
            else {
                return $j_maas$elm_ordered_containers$OrderedDict$remove_fn(key, original);
            }
        }
        else {
            var _v2 = alter($elm$core$Maybe$Nothing);
            if (!_v2.$) {
                var newItem = _v2.a;
                return $j_maas$elm_ordered_containers$OrderedDict$insert_fn(key, newItem, original);
            }
            else {
                return original;
            }
        }
    }, $j_maas$elm_ordered_containers$OrderedDict$update = F3($j_maas$elm_ordered_containers$OrderedDict$update_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing_fn = function (forceHashing, rawFields) {
        var fieldCounts = $elm$core$List$foldl_fn_unwrapped(function (fld, acc) {
            return $j_maas$elm_ordered_containers$OrderedDict$update_fn(fld, function (val) {
                return $elm$core$Maybe$Just(function () {
                    if (val.$ === 1) {
                        return 0;
                    }
                    else {
                        var count = val.a;
                        return count + 1;
                    }
                }());
            }, acc);
        }, $j_maas$elm_ordered_containers$OrderedDict$empty, $elm$core$List$map_fn($dillonkearns$elm_graphql$Graphql$RawField$name, rawFields));
        var conflictingTypeFields = $dillonkearns$elm_graphql$Graphql$Document$Field$findConflictingTypeFields(rawFields);
        return $elm$core$List$map_fn(function (field) {
            return _Utils_Tuple3(field, $elm$core$Set$member_fn($dillonkearns$elm_graphql$Graphql$RawField$name(field), forceHashing) ? $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field) : ((!$elm$core$Maybe$withDefault_fn(0, $j_maas$elm_ordered_containers$OrderedDict$get_fn($dillonkearns$elm_graphql$Graphql$RawField$name(field), fieldCounts))) ? $elm$core$Maybe$Nothing : $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field)), conflictingTypeFields);
        }, rawFields);
    }, $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing = F2($dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing_fn);
    var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
    var $elm$core$String$repeatHelp_fn = function (n, chunk, result) {
        return (n <= 0) ? result : $elm$core$String$repeatHelp_fn(n >> 1, _Utils_ap(chunk, chunk), (!(n & 1)) ? result : _Utils_ap(result, chunk));
    }, $elm$core$String$repeatHelp = F3($elm$core$String$repeatHelp_fn);
    var $elm$core$String$repeat_fn = function (n, chunk) {
        return $elm$core$String$repeatHelp_fn(n, chunk, "");
    }, $elm$core$String$repeat = F2($elm$core$String$repeat_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
        return $elm$core$String$repeat_fn(indentationLevel, "  ");
    };
    var $dillonkearns$elm_graphql$Graphql$RawField$Composite_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3($dillonkearns$elm_graphql$Graphql$RawField$Composite_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
        return $elm$core$Maybe$withDefault_fn($dillonkearns$elm_graphql$Graphql$RawField$name(field), $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields = function (rawFields) {
        return $elm$core$List$foldl_fn_unwrapped(function (field, mergedSoFar) {
            if (!field.$) {
                var newChildren = field.c;
                return $j_maas$elm_ordered_containers$OrderedDict$update_fn($dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field), function (maybeChildrenSoFar) {
                    if (maybeChildrenSoFar.$ === 1) {
                        return $elm$core$Maybe$Just(field);
                    }
                    else {
                        if (!maybeChildrenSoFar.a.$) {
                            var _v2 = maybeChildrenSoFar.a;
                            var existingFieldName = _v2.a;
                            var existingArgs = _v2.b;
                            var existingChildren = _v2.c;
                            return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$RawField$Composite_fn(existingFieldName, existingArgs, _Utils_ap(existingChildren, newChildren)));
                        }
                        else {
                            return $elm$core$Maybe$Just(field);
                        }
                    }
                }, mergedSoFar);
            }
            else {
                return $j_maas$elm_ordered_containers$OrderedDict$update_fn($dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field), function (maybeChildrenSoFar) {
                    return $elm$core$Maybe$Just($elm$core$Maybe$withDefault_fn(field, maybeChildrenSoFar));
                }, mergedSoFar);
            }
        }, $j_maas$elm_ordered_containers$OrderedDict$empty, rawFields);
    };
    var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
        var orderedDict = _v0;
        return $elm$core$List$filterMap_fn(function (key) {
            return $elm$core$Dict$get_fn(key, orderedDict.f);
        }, orderedDict.k);
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields = function (children) {
        return $j_maas$elm_ordered_containers$OrderedDict$values($dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields(children));
    };
    var $dillonkearns$elm_graphql$Graphql$RawField$Leaf_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2($dillonkearns$elm_graphql$Graphql$RawField$Leaf_fn);
    var $dillonkearns$elm_graphql$Graphql$RawField$typename = $dillonkearns$elm_graphql$Graphql$RawField$Leaf_fn({ bO: "__typename", cz: "" }, _List_Nil);
    var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
        return $elm$core$List$isEmpty(children) ? _List_Cons($dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
    };
    var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize_fn = function (forceHashing, aliasName, mIndentationLevel, field) {
        var prefix = function () {
            if (!aliasName.$) {
                var aliasName_ = aliasName.a;
                return _Utils_ap(aliasName_, function () {
                    if (!mIndentationLevel.$) {
                        return ": ";
                    }
                    else {
                        return ":";
                    }
                }());
            }
            else {
                return "";
            }
        }();
        return $elm$core$Maybe$map_fn(function (string) {
            return _Utils_ap($dillonkearns$elm_graphql$Graphql$Document$Indent$generate($elm$core$Maybe$withDefault_fn(0, mIndentationLevel)), _Utils_ap(prefix, string));
        }, function () {
            if (!field.$) {
                var fieldName = field.a;
                var args = field.b;
                var children = field.c;
                if (mIndentationLevel.$ === 1) {
                    return $elm$core$Maybe$Just((fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ("{" + $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp_fn(forceHashing, $elm$core$Maybe$Nothing, children)))) + "}");
                }
                else {
                    var indentationLevel = mIndentationLevel.a;
                    return $elm$core$Maybe$Just((fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (" {\n" + $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp_fn(forceHashing, $elm$core$Maybe$Just(indentationLevel), children)))) + ("\n" + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + "}")));
                }
            }
            else {
                var fieldName = field.a.bO;
                var args = field.b;
                return $elm$core$Maybe$Just(_Utils_ap(fieldName, $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
            }
        }());
    }, $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F4($dillonkearns$elm_graphql$Graphql$Document$Field$serialize_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp_fn = function (forceHashing, indentationLevel, children) {
        return $elm$core$String$join_fn(function () {
            if (!indentationLevel.$) {
                return "\n";
            }
            else {
                return " ";
            }
        }(), $elm$core$List$filterMap_fn($elm$core$Basics$identity, $elm$core$List$map_fn(function (_v0) {
            var field = _v0.a;
            var maybeAlias = _v0.b;
            var conflictingTypeFields = _v0.c;
            return $dillonkearns$elm_graphql$Graphql$Document$Field$serialize_fn(conflictingTypeFields, maybeAlias, $elm$core$Maybe$map_fn($elm$core$Basics$add(1), indentationLevel), field);
        }, $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing_fn(forceHashing, $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren($dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields(children))))));
    }, $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp = F3($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren_fn = function (indentationLevel, children) {
        return $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp_fn($elm$core$Set$empty, indentationLevel, children);
    }, $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$serialize_fn = function (operationType, queries) {
        return $lukewestby$elm_string_interpolate$String$Interpolate$interpolate_fn("{0} {\n{1}\n}", _List_fromArray([
            operationType,
            $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren_fn($elm$core$Maybe$Just(0), queries)
        ]));
    }, $dillonkearns$elm_graphql$Graphql$Document$serialize = F2($dillonkearns$elm_graphql$Graphql$Document$serialize_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$Document$serialize_fn("query", fields);
    };
    var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return "{" + ($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren_fn($elm$core$Maybe$Nothing, fields) + "}");
    };
    var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName_fn = function (operationName, _v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return "query " + (operationName + (" {" + ($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren_fn($elm$core$Maybe$Nothing, fields) + "}")));
    }, $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName_fn = function (operationType, operationName, queries) {
        return $lukewestby$elm_string_interpolate$String$Interpolate$interpolate_fn("{0} {1} {\n{2}\n}", _List_fromArray([
            operationType,
            operationName,
            $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren_fn($elm$core$Maybe$Just(0), queries)
        ]));
    }, $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName_fn);
    var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName_fn = function (operationName, _v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName_fn("query", operationName, fields);
    }, $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName_fn);
    var $elm$url$Url$percentEncode = _Url_percentEncode;
    var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace_fn = function (old, _new) {
        return A2($elm$core$Basics$composeR, $elm$core$String$split(old), $elm$core$String$join(_new));
    }, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a0 = $elm$url$Url$percentEncode, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a1 = $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace_fn("%20", "+"), $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a0, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a1);
    var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
        var key = _v0.a;
        var value = _v0.b;
        return $elm$core$Basics$composeR_fn($dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a0, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a1, key) + ("=" + $elm$core$Basics$composeR_fn($dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a0, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape_a1, value));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
        return $elm$core$String$join_fn("&", $elm$core$List$map_fn($dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams_fn = function (queryParams, url) {
        return $elm$core$List$isEmpty(queryParams) ? url : (url + ("?" + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
    }, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build_fn = function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
        var _v0 = function () {
            if (!maybeOperationName.$) {
                var operationName = maybeOperationName.a;
                return _Utils_Tuple2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName_fn(operationName, queryDocument), _List_fromArray([
                    _Utils_Tuple2("operationName", operationName)
                ]));
            }
            else {
                return _Utils_Tuple2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument), _List_Nil);
            }
        }();
        var serializedQueryForGetRequest = _v0.a;
        var operationNameParamForGetRequest = _v0.b;
        var urlForGetRequest = $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams_fn(_Utils_ap(queryParams, _List_Cons(_Utils_Tuple2("query", serializedQueryForGetRequest), operationNameParamForGetRequest)), url);
        if (_Utils_eq(forceMethod, $elm$core$Maybe$Just(1)) || ((_Utils_cmp($elm$core$String$length(urlForGetRequest), $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(forceMethod, $elm$core$Maybe$Just(0))))) {
            var _v2 = function () {
                if (!maybeOperationName.$) {
                    var operationName = maybeOperationName.a;
                    return _Utils_Tuple2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName_fn(operationName, queryDocument), _List_fromArray([
                        _Utils_Tuple2("operationName", $elm$json$Json$Encode$string(operationName))
                    ]));
                }
                else {
                    return _Utils_Tuple2($dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument), _List_Nil);
                }
            }();
            var serializedQuery = _v2.a;
            var operationNameParamForPostRequest = _v2.b;
            return {
                ag: $elm$http$Http$jsonBody($elm$json$Json$Encode$object(_List_Cons(_Utils_Tuple2("query", $elm$json$Json$Encode$string(serializedQuery)), operationNameParamForPostRequest))),
                an: 1,
                o: $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams_fn(queryParams, url)
            };
        }
        else {
            return { ag: $elm$http$Http$emptyBody, an: 0, o: urlForGetRequest };
        }
    }, $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
        return { $: 0, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
        return { $: 1, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError_fn = function (message, locations, details) {
        return { aF: details, dE: locations, dN: message };
    }, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError_fn);
    var $elm$core$Dict$fromList = function (assocs) {
        return $elm$core$List$foldl_fn_unwrapped(function (_v0, dict) {
            var key = _v0.a;
            var value = _v0.b;
            return $elm$core$Dict$insert_fn(key, value, dict);
        }, $elm$core$Dict$empty, assocs);
    };
    var $elm$json$Json$Decode$dict = function (decoder) {
        return _Json_map1_fn($elm$core$Dict$fromList, $elm$json$Json$Decode$keyValuePairs(decoder));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location_fn = function (line, column) {
        return { c1: column, dC: line };
    }, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = _Json_map2_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location, _Json_decodeField_fn("line", $elm$json$Json$Decode$int), _Json_decodeField_fn("column", $elm$json$Json$Decode$int));
    var $elm$json$Json$Decode$map3 = _Json_map3;
    var $elm$json$Json$Decode$maybe = function (decoder) {
        return $elm$json$Json$Decode$oneOf(_List_fromArray([
            _Json_map1_fn($elm$core$Maybe$Just, decoder),
            $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = _Json_decodeField_fn("errors", $elm$json$Json$Decode$list(_Json_map3_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError, _Json_decodeField_fn("message", $elm$json$Json$Decode$string), $elm$json$Json$Decode$maybe(_Json_decodeField_fn("locations", $elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))), _Json_map1_fn($elm$core$Dict$remove("locations"), _Json_map1_fn($elm$core$Dict$remove("message"), $elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
    var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
        return _Json_map1_fn($elm$core$Result$Err, _Json_map1_fn($elm$core$Tuple$pair(data), $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
        nullJsonValue: while (true) {
            var _v0 = _Json_runOnString_fn($elm$json$Json$Decode$value, "null");
            if (!_v0.$) {
                var value = _v0.a;
                return value;
            }
            else {
                var $temp$a = 0;
                a = $temp$a;
                continue nullJsonValue;
            }
        }
    };
    var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
        return $elm$json$Json$Decode$oneOf(_List_fromArray([
            _Json_andThen_fn($dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData, _Json_map1_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
            _Json_andThen_fn($dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData, _Json_map1_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData, _Json_decodeField_fn("data", $elm$json$Json$Decode$value))),
            _Json_andThen_fn($dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData, $elm$json$Json$Decode$succeed($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData($dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
        return $elm$json$Json$Decode$oneOf(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
            _Json_map1_fn($elm$core$Result$Ok, decoder)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$Document$serialize_fn("mutation", fields);
    };
    var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName_fn = function (operationName, _v0) {
        var fields = _v0.a;
        var decoder_ = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName_fn("mutation", operationName, fields);
    }, $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
        var request = _v0;
        var _v1 = request.aF;
        if (!_v1.$) {
            var forcedRequestMethod = _v1.a;
            var querySelectionSet = _v1.b;
            var queryRequestDetails = $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build_fn(function () {
                if (!forcedRequestMethod.$) {
                    if (!forcedRequestMethod.a) {
                        var _v4 = forcedRequestMethod.a;
                        return $elm$core$Maybe$Just(0);
                    }
                    else {
                        var _v5 = forcedRequestMethod.a;
                        return $elm$core$Maybe$Nothing;
                    }
                }
                else {
                    return $elm$core$Maybe$Just(1);
                }
            }(), request.af, request.J, request.H, querySelectionSet);
            return {
                ag: queryRequestDetails.ag,
                da: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.aj),
                al: request.al,
                an: function () {
                    var _v2 = queryRequestDetails.an;
                    if (!_v2) {
                        return "GET";
                    }
                    else {
                        return "Post";
                    }
                }(),
                au: request.au,
                o: queryRequestDetails.o
            };
        }
        else {
            var mutationSelectionSet = _v1.a;
            var serializedMutation = function () {
                var _v7 = request.H;
                if (!_v7.$) {
                    var operationName = _v7.a;
                    return $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName_fn(operationName, mutationSelectionSet);
                }
                else {
                    return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
                }
            }();
            return {
                ag: $elm$http$Http$jsonBody($elm$json$Json$Encode$object($elm$core$List$append_fn(_List_fromArray([
                    _Utils_Tuple2("query", $elm$json$Json$Encode$string(serializedMutation))
                ]), function () {
                    var _v6 = request.H;
                    if (!_v6.$) {
                        var operationName = _v6.a;
                        return _List_fromArray([
                            _Utils_Tuple2("operationName", $elm$json$Json$Encode$string(operationName))
                        ]);
                    }
                    else {
                        return _List_Nil;
                    }
                }()))),
                da: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.aj),
                al: request.al,
                an: "POST",
                au: request.au,
                o: $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams_fn(request.J, request.af)
            };
        }
    };
    var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord_fn = function (resultToMessage, fullRequest) {
        var request = fullRequest;
        return function (readyRequest) {
            return {
                ag: readyRequest.ag,
                aj: $dillonkearns$elm_graphql$Graphql$Http$expectJson_fn(A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage), readyRequest.da),
                al: readyRequest.al,
                an: readyRequest.an,
                au: readyRequest.au,
                aw: $elm$core$Maybe$Nothing,
                o: readyRequest.o
            };
        }($dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
    }, $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$send_fn = function (resultToMessage, fullRequest) {
        var request = fullRequest;
        return (request.Q ? $elm$http$Http$riskyRequest : $elm$http$Http$request)($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord_fn(resultToMessage, fullRequest));
    }, $dillonkearns$elm_graphql$Graphql$Http$send = F2($dillonkearns$elm_graphql$Graphql$Http$send_fn);
    var $elm$http$Http$Header_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $elm$http$Http$Header = F2($elm$http$Http$Header_fn);
    var $elm$http$Http$header = $elm$http$Http$Header;
    var $dillonkearns$elm_graphql$Graphql$Http$withHeader_fn = function (key, value, _v0) {
        var request = _v0;
        return _Utils_update(request, {
            al: _List_Cons($elm$http$Http$Header_fn(key, value), request.al)
        });
    }, $dillonkearns$elm_graphql$Graphql$Http$withHeader = F3($dillonkearns$elm_graphql$Graphql$Http$withHeader_fn);
    var $author$project$Main$graphqlHttpMutation = function (data) {
        return $dillonkearns$elm_graphql$Graphql$Http$send_fn(data.z, $elm$core$List$foldl_fn_unwrapped(function (_v0, accu) {
            var key = _v0.a;
            var value = _v0.b;
            return $dillonkearns$elm_graphql$Graphql$Http$withHeader_fn(key, value, accu);
        }, $dillonkearns$elm_graphql$Graphql$Http$mutationRequest_fn(data.o, data.N), data.x));
    };
    var $dillonkearns$elm_graphql$Graphql$Http$Query_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $dillonkearns$elm_graphql$Graphql$Http$Query = F2($dillonkearns$elm_graphql$Graphql$Http$Query_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$queryRequest_fn = function (baseUrl, query) {
        return {
            af: baseUrl,
            aF: $dillonkearns$elm_graphql$Graphql$Http$Query_fn($elm$core$Maybe$Nothing, query),
            aj: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
            al: _List_Nil,
            H: $elm$core$Maybe$Nothing,
            J: _List_Nil,
            au: $elm$core$Maybe$Nothing,
            Q: false
        };
    }, $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2($dillonkearns$elm_graphql$Graphql$Http$queryRequest_fn);
    var $author$project$Main$graphqlHttpQuery = function (data) {
        return $dillonkearns$elm_graphql$Graphql$Http$send_fn(data.z, $elm$core$List$foldl_fn_unwrapped(function (_v0, accu) {
            var key = _v0.a;
            var value = _v0.b;
            return $dillonkearns$elm_graphql$Graphql$Http$withHeader_fn(key, value, accu);
        }, $dillonkearns$elm_graphql$Graphql$Http$queryRequest_fn(data.o, data.N), data.x));
    };
    var $elm$time$Time$Name = function (a) {
        return { $: 0, a: a };
    };
    var $elm$time$Time$Offset = function (a) {
        return { $: 1, a: a };
    };
    var $elm$time$Time$Zone_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $elm$time$Time$Zone = F2($elm$time$Time$Zone_fn);
    var $elm$time$Time$customZone = $elm$time$Time$Zone;
    var $elm$time$Time$here = _Time_here(0);
    var $elm$http$Http$BadBody = function (a) {
        return { $: 4, a: a };
    };
    var $elm$http$Http$BadStatus = function (a) {
        return { $: 3, a: a };
    };
    var $elm$http$Http$BadUrl = function (a) {
        return { $: 0, a: a };
    };
    var $elm$http$Http$NetworkError = { $: 2 };
    var $elm$http$Http$Timeout = { $: 1 };
    var $elm$core$Result$mapError_fn = function (f, result) {
        if (!result.$) {
            var v = result.a;
            return $elm$core$Result$Ok(v);
        }
        else {
            var e = result.a;
            return $elm$core$Result$Err(f(e));
        }
    }, $elm$core$Result$mapError = F2($elm$core$Result$mapError_fn);
    var $elm$http$Http$resolve_fn = function (toResult, response) {
        switch (response.$) {
            case 0:
                var url = response.a;
                return $elm$core$Result$Err($elm$http$Http$BadUrl(url));
            case 1:
                return $elm$core$Result$Err($elm$http$Http$Timeout);
            case 2:
                return $elm$core$Result$Err($elm$http$Http$NetworkError);
            case 3:
                var metadata = response.a;
                return $elm$core$Result$Err($elm$http$Http$BadStatus(metadata.el));
            default:
                var body = response.b;
                return $elm$core$Result$mapError_fn($elm$http$Http$BadBody, toResult(body));
        }
    }, $elm$http$Http$resolve = F2($elm$http$Http$resolve_fn);
    var $elm$http$Http$expectString = function (toMsg) {
        return $elm$http$Http$expectStringResponse_fn(toMsg, $elm$http$Http$resolve($elm$core$Result$Ok));
    };
    var $author$project$Main$httpRequest = function (data) {
        return $elm$http$Http$request({
            ag: data.ag,
            aj: $elm$http$Http$expectString(data.aj),
            al: data.al,
            an: data.an,
            au: data.au,
            aw: data.aw,
            o: data.o
        });
    };
    var $elm$browser$Browser$Navigation$load = _Browser_load;
    var $lobanov$elm_taskport$TaskPort$moduleVersion = "2.0.1";
    var $lobanov$elm_taskport$TaskPort$buildCallUrl = function (_function) {
        if (!_function.$) {
            var name = _function.a;
            return "elmtaskport:///" + (name + ("?v=" + $lobanov$elm_taskport$TaskPort$moduleVersion));
        }
        else {
            var ns = _function.a;
            var nsVersion = _function.b;
            var name = _function.c;
            return "elmtaskport://" + (ns + ("/" + (name + ("?v=" + ($lobanov$elm_taskport$TaskPort$moduleVersion + ("&nsv=" + nsVersion))))));
        }
    };
    var $lobanov$elm_taskport$TaskPort$CannotDecodeValue_fn = function (a, b) {
        return { $: 3, a: a, b: b };
    }, $lobanov$elm_taskport$TaskPort$CannotDecodeValue = F2($lobanov$elm_taskport$TaskPort$CannotDecodeValue_fn);
    var $lobanov$elm_taskport$TaskPort$InteropError = function (a) {
        return { $: 0, a: a };
    };
    var $lobanov$elm_taskport$TaskPort$JSError = function (a) {
        return { $: 1, a: a };
    };
    var $lobanov$elm_taskport$TaskPort$NotCompatible = function (a) {
        return { $: 2, a: a };
    };
    var $lobanov$elm_taskport$TaskPort$NotFound = function (a) {
        return { $: 1, a: a };
    };
    var $lobanov$elm_taskport$TaskPort$NotInstalled = { $: 0 };
    var $lobanov$elm_taskport$TaskPort$RuntimeError = function (a) {
        return { $: 4, a: a };
    };
    var $lobanov$elm_taskport$TaskPort$ErrorObject_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $lobanov$elm_taskport$TaskPort$ErrorObject = F2($lobanov$elm_taskport$TaskPort$ErrorObject_fn);
    var $lobanov$elm_taskport$TaskPort$ErrorValue = function (a) {
        return { $: 1, a: a };
    };
    var $lobanov$elm_taskport$TaskPort$JSErrorRecord_fn = function (name, message, stackLines, cause) {
        return { bv: cause, dN: message, dS: name, cs: stackLines };
    }, $lobanov$elm_taskport$TaskPort$JSErrorRecord = F4($lobanov$elm_taskport$TaskPort$JSErrorRecord_fn);
    var $elm$json$Json$Decode$lazy = function (thunk) {
        return _Json_andThen_fn(thunk, $elm$json$Json$Decode$succeed(0));
    };
    var $elm$json$Json$Decode$map4 = _Json_map4;
    var $elm$json$Json$Decode$null = _Json_decodeNull;
    function $lobanov$elm_taskport$TaskPort$cyclic$jsErrorDecoder() {
        return $elm$json$Json$Decode$oneOf(_List_fromArray([
            _Json_map2_fn($lobanov$elm_taskport$TaskPort$ErrorObject, _Json_decodeField_fn("name", $elm$json$Json$Decode$string), $lobanov$elm_taskport$TaskPort$cyclic$jsErrorRecordDecoder()),
            _Json_map1_fn($lobanov$elm_taskport$TaskPort$ErrorValue, $elm$json$Json$Decode$value)
        ]));
    }
    function $lobanov$elm_taskport$TaskPort$cyclic$jsErrorRecordDecoder() {
        return _Json_map4_fn($lobanov$elm_taskport$TaskPort$JSErrorRecord, _Json_decodeField_fn("name", $elm$json$Json$Decode$string), _Json_decodeField_fn("message", $elm$json$Json$Decode$string), _Json_decodeField_fn("stackLines", $elm$json$Json$Decode$list($elm$json$Json$Decode$string)), _Json_decodeField_fn("cause", $elm$json$Json$Decode$oneOf(_List_fromArray([
            $elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
            _Json_map1_fn($elm$core$Maybe$Just, $elm$json$Json$Decode$lazy(function (_v0) {
                return $lobanov$elm_taskport$TaskPort$cyclic$jsErrorDecoder();
            }))
        ]))));
    }
    var $lobanov$elm_taskport$TaskPort$jsErrorDecoder = $lobanov$elm_taskport$TaskPort$cyclic$jsErrorDecoder();
    $lobanov$elm_taskport$TaskPort$cyclic$jsErrorDecoder = function () {
        return $lobanov$elm_taskport$TaskPort$jsErrorDecoder;
    };
    var $lobanov$elm_taskport$TaskPort$jsErrorRecordDecoder = $lobanov$elm_taskport$TaskPort$cyclic$jsErrorRecordDecoder();
    $lobanov$elm_taskport$TaskPort$cyclic$jsErrorRecordDecoder = function () {
        return $lobanov$elm_taskport$TaskPort$jsErrorRecordDecoder;
    };
    var $lobanov$elm_taskport$TaskPort$runtimeError = function (msg) {
        return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$InteropError($lobanov$elm_taskport$TaskPort$RuntimeError("Runtime error in JavaScript interop: " + (msg + ". JavaScript console may contain more information about the issue."))));
    };
    var $lobanov$elm_taskport$TaskPort$resolveResponse_fn = function (valueDecoder, res) {
        switch (res.$) {
            case 0:
                var url = res.a;
                return $lobanov$elm_taskport$TaskPort$runtimeError("bad url " + url);
            case 1:
                return $lobanov$elm_taskport$TaskPort$runtimeError("timeout");
            case 2:
                return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$InteropError($lobanov$elm_taskport$TaskPort$NotInstalled));
            case 3:
                var statusCode = res.a.el;
                var body = res.b;
                if (statusCode === 400) {
                    return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$InteropError($lobanov$elm_taskport$TaskPort$NotCompatible(body)));
                }
                else {
                    if (statusCode === 404) {
                        return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$InteropError($lobanov$elm_taskport$TaskPort$NotFound(body)));
                    }
                    else {
                        if (statusCode === 500) {
                            var _v1 = _Json_runOnString_fn($lobanov$elm_taskport$TaskPort$jsErrorDecoder, body);
                            if (!_v1.$) {
                                var errorValue = _v1.a;
                                return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$JSError(errorValue));
                            }
                            else {
                                var decodeError = _v1.a;
                                return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$InteropError($lobanov$elm_taskport$TaskPort$RuntimeError($elm$json$Json$Decode$errorToString(decodeError))));
                            }
                        }
                        else {
                            return $lobanov$elm_taskport$TaskPort$runtimeError("unexpected status " + $elm$core$String$fromInt(statusCode));
                        }
                    }
                }
            default:
                var body = res.b;
                var _v2 = _Json_runOnString_fn(valueDecoder, body);
                if (!_v2.$) {
                    var returnValue = _v2.a;
                    return $elm$core$Result$Ok(returnValue);
                }
                else {
                    var decodeError = _v2.a;
                    return $elm$core$Result$Err($lobanov$elm_taskport$TaskPort$InteropError($lobanov$elm_taskport$TaskPort$CannotDecodeValue_fn(decodeError, body)));
                }
        }
    }, $lobanov$elm_taskport$TaskPort$resolveResponse = F2($lobanov$elm_taskport$TaskPort$resolveResponse_fn);
    var $elm$http$Http$stringResolver_a0 = "", $elm$http$Http$stringResolver_a1 = $elm$core$Basics$identity, $elm$http$Http$stringResolver = A2(_Http_expect, $elm$http$Http$stringResolver_a0, $elm$http$Http$stringResolver_a1);
    var $lobanov$elm_taskport$TaskPort$buildHttpCall_fn = function (_function, valueDecoder, args) {
        return {
            ag: $elm$http$Http$jsonBody(args),
            al: _List_Nil,
            an: "POST",
            ed: _Http_expect_fn($elm$http$Http$stringResolver_a0, $elm$http$Http$stringResolver_a1, $lobanov$elm_taskport$TaskPort$resolveResponse(valueDecoder)),
            au: $elm$core$Maybe$Nothing,
            o: $lobanov$elm_taskport$TaskPort$buildCallUrl(_function)
        };
    }, $lobanov$elm_taskport$TaskPort$buildHttpCall = F3($lobanov$elm_taskport$TaskPort$buildHttpCall_fn);
    var $elm$json$Json$Encode$null = _Json_encodeNull;
    var $elm$core$Task$fail = _Scheduler_fail;
    var $elm$http$Http$resultToTask = function (result) {
        if (!result.$) {
            var a = result.a;
            return $elm$core$Task$succeed(a);
        }
        else {
            var x = result.a;
            return $elm$core$Task$fail(x);
        }
    };
    var $elm$http$Http$task = function (r) {
        return _Http_toTask_fn(0, $elm$http$Http$resultToTask, { cK: false, ag: r.ag, aj: r.ed, al: r.al, an: r.an, au: r.au, aw: $elm$core$Maybe$Nothing, o: r.o });
    };
    var $lobanov$elm_taskport$TaskPort$callNoArgsNS = function (details) {
        return $elm$http$Http$task($lobanov$elm_taskport$TaskPort$buildHttpCall_fn(details.ak, details.aA, $elm$json$Json$Encode$null));
    };
    var $lobanov$elm_taskport$TaskPort$ignoreValue = $elm$json$Json$Decode$succeed(0);
    var $lobanov$elm_localstorage$LocalStorage$clear = function (names) {
        return $lobanov$elm_taskport$TaskPort$callNoArgsNS({ ak: names.aY, aA: $lobanov$elm_taskport$TaskPort$ignoreValue });
    };
    var $lobanov$elm_taskport$TaskPort$WithNS_fn = function (a, b, c) {
        return { $: 1, a: a, b: b, c: c };
    }, $lobanov$elm_taskport$TaskPort$WithNS = F3($lobanov$elm_taskport$TaskPort$WithNS_fn);
    var $lobanov$elm_taskport$TaskPort$inNamespace_fn = function (ns, v, fn) {
        return $lobanov$elm_taskport$TaskPort$WithNS_fn(ns, v, fn);
    }, $lobanov$elm_taskport$TaskPort$inNamespace = F3($lobanov$elm_taskport$TaskPort$inNamespace_fn);
    var $lobanov$elm_localstorage$LocalStorage$moduleVersion = "1.0.1";
    var $lobanov$elm_localstorage$LocalStorage$inNS_a0 = "lobanov/elm-localstorage", $lobanov$elm_localstorage$LocalStorage$inNS_a1 = $lobanov$elm_localstorage$LocalStorage$moduleVersion, $lobanov$elm_localstorage$LocalStorage$inNS = A2($lobanov$elm_taskport$TaskPort$inNamespace, $lobanov$elm_localstorage$LocalStorage$inNS_a0, $lobanov$elm_localstorage$LocalStorage$inNS_a1);
    var $lobanov$elm_localstorage$LocalStorage$localStorage = {
        aY: $lobanov$elm_taskport$TaskPort$inNamespace_fn($lobanov$elm_localstorage$LocalStorage$inNS_a0, $lobanov$elm_localstorage$LocalStorage$inNS_a1, "localClear"),
        aZ: $lobanov$elm_taskport$TaskPort$inNamespace_fn($lobanov$elm_localstorage$LocalStorage$inNS_a0, $lobanov$elm_localstorage$LocalStorage$inNS_a1, "localGet"),
        a1: $lobanov$elm_taskport$TaskPort$inNamespace_fn($lobanov$elm_localstorage$LocalStorage$inNS_a0, $lobanov$elm_localstorage$LocalStorage$inNS_a1, "localList"),
        bl: $lobanov$elm_taskport$TaskPort$inNamespace_fn($lobanov$elm_localstorage$LocalStorage$inNS_a0, $lobanov$elm_localstorage$LocalStorage$inNS_a1, "localPut"),
        bm: $lobanov$elm_taskport$TaskPort$inNamespace_fn($lobanov$elm_localstorage$LocalStorage$inNS_a0, $lobanov$elm_localstorage$LocalStorage$inNS_a1, "localRemove")
    };
    var $lobanov$elm_localstorage$LocalStorage$localClear = $lobanov$elm_localstorage$LocalStorage$clear($lobanov$elm_localstorage$LocalStorage$localStorage);
    var $lobanov$elm_taskport$TaskPort$callNS_fn = function (details, args) {
        return $elm$http$Http$task($lobanov$elm_taskport$TaskPort$buildHttpCall_fn(details.ak, details.aA, details.aW(args)));
    }, $lobanov$elm_taskport$TaskPort$callNS = F2($lobanov$elm_taskport$TaskPort$callNS_fn);
    var $elm$json$Json$Decode$nullable = function (decoder) {
        return $elm$json$Json$Decode$oneOf(_List_fromArray([
            $elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
            _Json_map1_fn($elm$core$Maybe$Just, decoder)
        ]));
    };
    var $lobanov$elm_localstorage$LocalStorage$get = function (names) {
        return $lobanov$elm_taskport$TaskPort$callNS({
            aW: $elm$json$Json$Encode$string,
            ak: names.aZ,
            aA: $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)
        });
    };
    var $lobanov$elm_localstorage$LocalStorage$localGet = $lobanov$elm_localstorage$LocalStorage$get($lobanov$elm_localstorage$LocalStorage$localStorage);
    var $lobanov$elm_localstorage$LocalStorage$listKeys = function (names) {
        return $lobanov$elm_taskport$TaskPort$callNoArgsNS({
            ak: names.a1,
            aA: $elm$json$Json$Decode$list($elm$json$Json$Decode$string)
        });
    };
    var $lobanov$elm_localstorage$LocalStorage$localListKeys = $lobanov$elm_localstorage$LocalStorage$listKeys($lobanov$elm_localstorage$LocalStorage$localStorage);
    var $lobanov$elm_localstorage$LocalStorage$put_fn = function (names, key, value) {
        var encoder = function (_v0) {
            var k = _v0.a;
            var v = _v0.b;
            return $elm$json$Json$Encode$object(_List_fromArray([
                _Utils_Tuple2("key", $elm$json$Json$Encode$string(k)),
                _Utils_Tuple2("value", $elm$json$Json$Encode$string(v))
            ]));
        };
        return $lobanov$elm_taskport$TaskPort$callNS_fn({ aW: encoder, ak: names.bl, aA: $lobanov$elm_taskport$TaskPort$ignoreValue }, _Utils_Tuple2(key, value));
    }, $lobanov$elm_localstorage$LocalStorage$put = F3($lobanov$elm_localstorage$LocalStorage$put_fn);
    var $lobanov$elm_localstorage$LocalStorage$localPut_a0 = $lobanov$elm_localstorage$LocalStorage$localStorage, $lobanov$elm_localstorage$LocalStorage$localPut = $lobanov$elm_localstorage$LocalStorage$put($lobanov$elm_localstorage$LocalStorage$localPut_a0);
    var $lobanov$elm_localstorage$LocalStorage$remove = function (names) {
        return $lobanov$elm_taskport$TaskPort$callNS({ aW: $elm$json$Json$Encode$string, ak: names.bm, aA: $lobanov$elm_taskport$TaskPort$ignoreValue });
    };
    var $lobanov$elm_localstorage$LocalStorage$localRemove = $lobanov$elm_localstorage$LocalStorage$remove($lobanov$elm_localstorage$LocalStorage$localStorage);
    var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
    var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
    var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
    var $TSFoster$elm_uuid$UUID$UUID_fn = function (a, b, c, d) {
        return { $: 0, a: a, b: b, c: c, d: d };
    }, $TSFoster$elm_uuid$UUID$UUID = F4($TSFoster$elm_uuid$UUID$UUID_fn);
    var $TSFoster$elm_uuid$UUID$forceUnsigned_a0 = 0, $TSFoster$elm_uuid$UUID$forceUnsigned = $elm$core$Bitwise$shiftRightZfBy($TSFoster$elm_uuid$UUID$forceUnsigned_a0);
    var $elm$random$Random$Generator = $elm$core$Basics$identity;
    var $elm$random$Random$Seed_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $elm$random$Random$Seed = F2($elm$random$Random$Seed_fn);
    var $elm$random$Random$next = function (_v0) {
        var state0 = _v0.a;
        var incr = _v0.b;
        return $elm$random$Random$Seed_fn(((state0 * 1664525) + incr) >>> 0, incr);
    };
    var $elm$random$Random$peel = function (_v0) {
        var state = _v0.a;
        var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
        return ((word >>> 22) ^ word) >>> 0;
    };
    var $elm$random$Random$int_fn = function (a, b) {
        return function (seed0) {
            var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
            var lo = _v0.a;
            var hi = _v0.b;
            var range = (hi - lo) + 1;
            if (!((range - 1) & range)) {
                return _Utils_Tuple2((((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo, $elm$random$Random$next(seed0));
            }
            else {
                var threshhold = (((-range) >>> 0) % range) >>> 0;
                var accountForBias = function (seed) {
                    accountForBias: while (true) {
                        var x = $elm$random$Random$peel(seed);
                        var seedN = $elm$random$Random$next(seed);
                        if (_Utils_cmp(x, threshhold) < 0) {
                            var $temp$seed = seedN;
                            seed = $temp$seed;
                            continue accountForBias;
                        }
                        else {
                            return _Utils_Tuple2((x % range) + lo, seedN);
                        }
                    }
                };
                return accountForBias(seed0);
            }
        };
    }, $elm$random$Random$int = F2($elm$random$Random$int_fn);
    var $elm$random$Random$map_fn = function (func, _v0) {
        var genA = _v0;
        return function (seed0) {
            var _v1 = genA(seed0);
            var a = _v1.a;
            var seed1 = _v1.b;
            return _Utils_Tuple2(func(a), seed1);
        };
    }, $elm$random$Random$map = F2($elm$random$Random$map_fn);
    var $elm$random$Random$maxInt = 2147483647;
    var $elm$random$Random$minInt = -2147483648;
    var $TSFoster$elm_uuid$UUID$randomU32 = $elm$random$Random$map_fn($TSFoster$elm_uuid$UUID$forceUnsigned, $elm$random$Random$int_fn($elm$random$Random$minInt, $elm$random$Random$maxInt));
    var $elm$random$Random$step_fn = function (_v0, seed) {
        var generator = _v0;
        return generator(seed);
    }, $elm$random$Random$step = F2($elm$random$Random$step_fn);
    var $TSFoster$elm_uuid$UUID$toVariant1 = function (_v0) {
        var a = _v0.a;
        var b = _v0.b;
        var c = _v0.c;
        var d = _v0.d;
        return $TSFoster$elm_uuid$UUID$UUID_fn(a, b, _Bitwise_shiftRightZfBy_fn($TSFoster$elm_uuid$UUID$forceUnsigned_a0, 2147483648 | (1073741823 & c)), d);
    };
    var $TSFoster$elm_uuid$UUID$toVersion_fn = function (v, _v0) {
        var a = _v0.a;
        var b = _v0.b;
        var c = _v0.c;
        var d = _v0.d;
        return $TSFoster$elm_uuid$UUID$UUID_fn(a, _Bitwise_shiftRightZfBy_fn($TSFoster$elm_uuid$UUID$forceUnsigned_a0, (v << 12) | (4294905855 & b)), c, d);
    }, $TSFoster$elm_uuid$UUID$toVersion = F2($TSFoster$elm_uuid$UUID$toVersion_fn);
    var $TSFoster$elm_uuid$UUID$step = function (s) {
        var _v0 = $elm$random$Random$step_fn($TSFoster$elm_uuid$UUID$randomU32, s.co);
        var int4 = _v0.a;
        var seed4 = _v0.b;
        var _v1 = $elm$random$Random$step_fn($TSFoster$elm_uuid$UUID$randomU32, s.cn);
        var int3 = _v1.a;
        var seed3 = _v1.b;
        var _v2 = $elm$random$Random$step_fn($TSFoster$elm_uuid$UUID$randomU32, s.cm);
        var int2 = _v2.a;
        var seed2 = _v2.b;
        var _v3 = $elm$random$Random$step_fn($TSFoster$elm_uuid$UUID$randomU32, s.cl);
        var int1 = _v3.a;
        var seed1 = _v3.b;
        var uuid = $TSFoster$elm_uuid$UUID$toVariant1($TSFoster$elm_uuid$UUID$toVersion_fn(4, $TSFoster$elm_uuid$UUID$UUID_fn(int1, int2, int3, int4)));
        return _Utils_Tuple2(uuid, $TSFoster$elm_uuid$UUID$Seeds_fn(seed1, seed2, seed3, seed4));
    };
    var $author$project$Main$uuidGenerator_fn = function (toMsg, seeds) {
        var _v0 = $TSFoster$elm_uuid$UUID$step(seeds);
        var uuid = _v0.a;
        var seeds_ = _v0.b;
        return _Utils_Tuple2(seeds_, $elm$core$Task$perform_fn($elm$core$Basics$identity, $elm$core$Task$succeed(toMsg(uuid))));
    }, $author$project$Main$uuidGenerator = F2($author$project$Main$uuidGenerator_fn);
    var $author$project$Main$effectPerform_fn = function (key, seeds, effect) {
        switch (effect.$) {
            case 0:
                var toMsg = effect.a;
                var idAttribute = effect.b;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, _Browser_call_fn($elm$browser$Browser$Dom$focus_a0, idAttribute)));
            case 1:
                var toMsg = effect.a;
                var idAttribute = effect.b;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, $elm$browser$Browser$Dom$getElement(idAttribute)));
            case 2:
                var toMsg = effect.a;
                var parent = effect.b;
                var x = effect.c;
                var y = effect.d;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, _Browser_setViewportOf_fn(parent, x, y)));
            case 3:
                var data = effect.a;
                return _Utils_Tuple2(seeds, $author$project$Main$graphqlHttpMutation(data));
            case 4:
                var data = effect.a;
                return _Utils_Tuple2(seeds, $author$project$Main$graphqlHttpQuery(data));
            case 5:
                var data = effect.a;
                return _Utils_Tuple2(seeds, $author$project$Main$httpRequest(data));
            case 6:
                var msg = effect.a;
                return _Utils_Tuple2(seeds, $elm$core$Task$perform_fn($elm$core$Basics$identity, $elm$core$Task$succeed(msg)));
            case 7:
                var toMsg = effect.a;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, $lobanov$elm_localstorage$LocalStorage$localClear));
            case 8:
                var toMsg = effect.a;
                var key_ = effect.b;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, $lobanov$elm_localstorage$LocalStorage$localGet(key_)));
            case 9:
                var toMsg = effect.a;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, $lobanov$elm_localstorage$LocalStorage$localListKeys));
            case 10:
                var toMsg = effect.a;
                var key_ = effect.b;
                var value = effect.c;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, $lobanov$elm_localstorage$LocalStorage$put_fn($lobanov$elm_localstorage$LocalStorage$localPut_a0, key_, value)));
            case 11:
                var toMsg = effect.a;
                var key_ = effect.b;
                return _Utils_Tuple2(seeds, $elm$core$Task$attempt_fn(toMsg, $lobanov$elm_localstorage$LocalStorage$localRemove(key_)));
            case 12:
                var toMsg = effect.a;
                return _Utils_Tuple2(seeds, $elm$core$Task$perform_fn(toMsg, $elm$time$Time$here));
            case 13:
                var url = effect.a;
                return _Utils_Tuple2(seeds, $elm$browser$Browser$Navigation$load(url));
            case 14:
                var url = effect.a;
                return _Utils_Tuple2(seeds, _Browser_pushUrl_fn(key, url));
            case 15:
                var url = effect.a;
                return _Utils_Tuple2(seeds, _Browser_replaceUrl_fn(key, url));
            default:
                var toMsg = effect.a;
                return $author$project$Main$uuidGenerator_fn(toMsg, seeds);
        }
    }, $author$project$Main$effectPerform = F3($author$project$Main$effectPerform_fn);
    var $author$project$Main$effectsApplier_fn = function (key, _v0, effect, _v1) {
        var seeds = _v1.a;
        var accumulator = _v1.b;
        var _v2 = $author$project$Main$effectPerform_fn(key, seeds, effect);
        var newSeeds = _v2.a;
        var cmd = _v2.b;
        return _Utils_Tuple2(newSeeds, $elm$core$Platform$Cmd$batch(_List_fromArray([cmd, accumulator])));
    }, $author$project$Main$effectsApplier = F4($author$project$Main$effectsApplier_fn);
    var $author$project$Main$Model$FormFilling = function (a) {
        return { $: 0, a: a };
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin = { $: -2 };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$empty = $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
    var $author$project$Main$Model$ExpendableFlags_fn = function (storage, language, timeNow) {
        return { dz: language, ct: storage, et: timeNow };
    }, $author$project$Main$Model$ExpendableFlags = F3($author$project$Main$Model$ExpendableFlags_fn);
    var $author$project$Main$Model$KnownStorage_fn = function (account, cart, teams, servers, sidebarOpen) {
        return { A: account, aX: cart, O: servers, aN: sidebarOpen, aa: teams };
    }, $author$project$Main$Model$KnownStorage = F5($author$project$Main$Model$KnownStorage_fn);
    var $elm$json$Json$Decode$bool = _Json_decodeBool;
    var $author$project$Utils$JsonDecode$nestDecode = function (leafDecoder) {
        return _Json_andThen_fn(function (payload) {
            var _v0 = _Json_runOnString_fn(leafDecoder, payload);
            if (!_v0.$) {
                var value = _v0.a;
                return $elm$json$Json$Decode$succeed(value);
            }
            else {
                var error = _v0.a;
                return $elm$json$Json$Decode$fail($elm$json$Json$Decode$errorToString(error));
            }
        }, $elm$json$Json$Decode$string);
    };
    var $author$project$Utils$JsonDecode$failWithNothing = function (decoder) {
        return $elm$json$Json$Decode$oneOf(_List_fromArray([
            _Json_map1_fn($elm$core$Maybe$Just, decoder),
            $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
        ]));
    };
    var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom_a0 = $elm$core$Basics$apR, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom_a0);
    var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder_fn = function (path, valDecoder, fallback) {
        var nullOr = function (decoder) {
            return $elm$json$Json$Decode$oneOf(_List_fromArray([
                decoder,
                $elm$json$Json$Decode$null(fallback)
            ]));
        };
        var handleResult = function (input) {
            var _v0 = _Json_run_fn($elm$json$Json$Decode$at_fn(path, $elm$json$Json$Decode$value), input);
            if (!_v0.$) {
                var rawValue = _v0.a;
                var _v1 = _Json_run_fn(nullOr(valDecoder), rawValue);
                if (!_v1.$) {
                    var finalResult = _v1.a;
                    return $elm$json$Json$Decode$succeed(finalResult);
                }
                else {
                    return $elm$json$Json$Decode$at_fn(path, nullOr(valDecoder));
                }
            }
            else {
                return $elm$json$Json$Decode$succeed(fallback);
            }
        };
        return _Json_andThen_fn(handleResult, $elm$json$Json$Decode$value);
    }, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder_fn);
    var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional_fn = function (key, valDecoder, fallback, decoder) {
        return _Json_map2_fn($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom_a0, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder_fn(_List_fromArray([key]), valDecoder, fallback), decoder);
    }, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional_fn);
    var $author$project$Utils$JsonDecode$optionalWithFailures_fn = function (key, decoder) {
        return A3($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional, key, $author$project$Utils$JsonDecode$failWithNothing(decoder), $elm$core$Maybe$Nothing);
    }, $author$project$Utils$JsonDecode$optionalWithFailures = F2($author$project$Utils$JsonDecode$optionalWithFailures_fn);
    var $author$project$Maple$Data$Account$Account_fn = function (token, id, email, nickname) {
        return { bH: email, bV: id, b1: nickname, bq: token };
    }, $author$project$Maple$Data$Account$Account = F4($author$project$Maple$Data$Account$Account_fn);
    var $TSFoster$elm_uuid$UUID$WrongFormat = 0;
    var $TSFoster$elm_uuid$UUID$WrongLength = 1;
    var $TSFoster$elm_uuid$UUID$IsNil = 3;
    var $TSFoster$elm_uuid$UUID$NoVersion = 4;
    var $TSFoster$elm_uuid$UUID$UnsupportedVariant = 2;
    var $TSFoster$elm_uuid$UUID$isVariant1 = function (_v0) {
        var c = _v0.c;
        return (c >>> 30) === 2;
    };
    var $TSFoster$elm_uuid$UUID$version = function (_v0) {
        var b = _v0.b;
        return 15 & (b >>> 12);
    };
    var $TSFoster$elm_uuid$UUID$fromInt32s_fn = function (a, b, c, d) {
        var wouldBeUUID = $TSFoster$elm_uuid$UUID$UUID_fn(a, b, c, d);
        return ((!a) && ((!b) && ((!c) && (!d)))) ? $elm$core$Result$Err(3) : (($TSFoster$elm_uuid$UUID$version(wouldBeUUID) > 5) ? $elm$core$Result$Err(4) : ((!$TSFoster$elm_uuid$UUID$isVariant1(wouldBeUUID)) ? $elm$core$Result$Err(2) : $elm$core$Result$Ok(wouldBeUUID)));
    }, $TSFoster$elm_uuid$UUID$fromInt32s = F4($TSFoster$elm_uuid$UUID$fromInt32s_fn);
    var $TSFoster$elm_uuid$UUID$nibbleValuesToU32_fn = function (a, b, c, d, e, f, g, h) {
        return _Bitwise_shiftRightZfBy_fn($TSFoster$elm_uuid$UUID$forceUnsigned_a0, (a << 28) | ((b << 24) | ((c << 20) | ((d << 16) | ((e << 12) | ((f << 8) | ((g << 4) | h)))))));
    }, $TSFoster$elm_uuid$UUID$nibbleValuesToU32 = F8($TSFoster$elm_uuid$UUID$nibbleValuesToU32_fn);
    var $elm$core$String$foldr = _String_foldr;
    var $elm$core$String$toList = function (string) {
        return _String_foldr_fn($elm$core$List$cons, _List_Nil, string);
    };
    var $TSFoster$elm_uuid$UUID$toNibbleValue = function (_char) {
        switch (_char) {
            case "0":
                return $elm$core$Maybe$Just(0);
            case "1":
                return $elm$core$Maybe$Just(1);
            case "2":
                return $elm$core$Maybe$Just(2);
            case "3":
                return $elm$core$Maybe$Just(3);
            case "4":
                return $elm$core$Maybe$Just(4);
            case "5":
                return $elm$core$Maybe$Just(5);
            case "6":
                return $elm$core$Maybe$Just(6);
            case "7":
                return $elm$core$Maybe$Just(7);
            case "8":
                return $elm$core$Maybe$Just(8);
            case "9":
                return $elm$core$Maybe$Just(9);
            case "a":
                return $elm$core$Maybe$Just(10);
            case "b":
                return $elm$core$Maybe$Just(11);
            case "c":
                return $elm$core$Maybe$Just(12);
            case "d":
                return $elm$core$Maybe$Just(13);
            case "e":
                return $elm$core$Maybe$Just(14);
            case "f":
                return $elm$core$Maybe$Just(15);
            default:
                return $elm$core$Maybe$Nothing;
        }
    };
    var $TSFoster$elm_uuid$UUID$fromString = function (string) {
        var normalized = function (str) {
            return _String_startsWith_fn("urn:uuid:", str) ? $elm$core$String$dropLeft_fn(9, str) : ((_String_startsWith_fn("{", str) && _String_endsWith_fn("}", str)) ? _String_slice_fn(1, -1, str) : str);
        }($elm$core$String$toLower($elm$core$String$replace_fn("-", "", $elm$core$String$replace_fn(" ", "", $elm$core$String$replace_fn("\t", "", $elm$core$String$replace_fn("\n", "", string))))));
        if ($elm$core$String$length(normalized) !== 32) {
            return $elm$core$Result$Err(1);
        }
        else {
            var _v0 = $elm$core$List$filterMap_fn($TSFoster$elm_uuid$UUID$toNibbleValue, $elm$core$String$toList(normalized));
            if ((((((((((((((((((((((((((((((((_v0.b && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) && _v0.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && _v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && (!_v0.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b)) {
                var a1 = _v0.a;
                var _v1 = _v0.b;
                var a2 = _v1.a;
                var _v2 = _v1.b;
                var a3 = _v2.a;
                var _v3 = _v2.b;
                var a4 = _v3.a;
                var _v4 = _v3.b;
                var a5 = _v4.a;
                var _v5 = _v4.b;
                var a6 = _v5.a;
                var _v6 = _v5.b;
                var a7 = _v6.a;
                var _v7 = _v6.b;
                var a8 = _v7.a;
                var _v8 = _v7.b;
                var b1 = _v8.a;
                var _v9 = _v8.b;
                var b2 = _v9.a;
                var _v10 = _v9.b;
                var b3 = _v10.a;
                var _v11 = _v10.b;
                var b4 = _v11.a;
                var _v12 = _v11.b;
                var b5 = _v12.a;
                var _v13 = _v12.b;
                var b6 = _v13.a;
                var _v14 = _v13.b;
                var b7 = _v14.a;
                var _v15 = _v14.b;
                var b8 = _v15.a;
                var _v16 = _v15.b;
                var c1 = _v16.a;
                var _v17 = _v16.b;
                var c2 = _v17.a;
                var _v18 = _v17.b;
                var c3 = _v18.a;
                var _v19 = _v18.b;
                var c4 = _v19.a;
                var _v20 = _v19.b;
                var c5 = _v20.a;
                var _v21 = _v20.b;
                var c6 = _v21.a;
                var _v22 = _v21.b;
                var c7 = _v22.a;
                var _v23 = _v22.b;
                var c8 = _v23.a;
                var _v24 = _v23.b;
                var d1 = _v24.a;
                var _v25 = _v24.b;
                var d2 = _v25.a;
                var _v26 = _v25.b;
                var d3 = _v26.a;
                var _v27 = _v26.b;
                var d4 = _v27.a;
                var _v28 = _v27.b;
                var d5 = _v28.a;
                var _v29 = _v28.b;
                var d6 = _v29.a;
                var _v30 = _v29.b;
                var d7 = _v30.a;
                var _v31 = _v30.b;
                var d8 = _v31.a;
                return $TSFoster$elm_uuid$UUID$fromInt32s_fn($TSFoster$elm_uuid$UUID$nibbleValuesToU32_fn(a1, a2, a3, a4, a5, a6, a7, a8), $TSFoster$elm_uuid$UUID$nibbleValuesToU32_fn(b1, b2, b3, b4, b5, b6, b7, b8), $TSFoster$elm_uuid$UUID$nibbleValuesToU32_fn(c1, c2, c3, c4, c5, c6, c7, c8), $TSFoster$elm_uuid$UUID$nibbleValuesToU32_fn(d1, d2, d3, d4, d5, d6, d7, d8));
            }
            else {
                return $elm$core$Result$Err(0);
            }
        }
    };
    var $TSFoster$elm_uuid$UUID$stringToJsonDecoder = function (string) {
        var _v0 = $TSFoster$elm_uuid$UUID$fromString(string);
        if (!_v0.$) {
            var uuid = _v0.a;
            return $elm$json$Json$Decode$succeed(uuid);
        }
        else {
            switch (_v0.a) {
                case 0:
                    var _v1 = _v0.a;
                    return $elm$json$Json$Decode$fail("UUID is in wrong format");
                case 1:
                    var _v2 = _v0.a;
                    return $elm$json$Json$Decode$fail("UUID is wrong length");
                case 2:
                    var _v3 = _v0.a;
                    return $elm$json$Json$Decode$fail("UUID is an unsupported variant");
                case 3:
                    var _v4 = _v0.a;
                    return $elm$json$Json$Decode$fail("UUID is nil");
                default:
                    var _v5 = _v0.a;
                    return $elm$json$Json$Decode$fail("UUID is not properly versioned");
            }
        }
    };
    var $TSFoster$elm_uuid$UUID$jsonDecoder = _Json_andThen_fn($TSFoster$elm_uuid$UUID$stringToJsonDecoder, $elm$json$Json$Decode$string);
    var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn = function (key, valDecoder, decoder) {
        return _Json_map2_fn($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom_a0, _Json_decodeField_fn(key, valDecoder), decoder);
    }, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn);
    var $author$project$Maple$Data$Account$storageDecoder = $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("n", $elm$json$Json$Decode$string, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("e", $elm$json$Json$Decode$string, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("i", $TSFoster$elm_uuid$UUID$jsonDecoder, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("t", $elm$json$Json$Decode$string, $elm$json$Json$Decode$succeed($author$project$Maple$Data$Account$Account)))));
    var $author$project$Maple$Data$Server$Server_fn = function (id, cuteId, nickname, extra) {
        return { bE: cuteId, bM: extra, bV: id, b1: nickname };
    }, $author$project$Maple$Data$Server$Server = F4($author$project$Maple$Data$Server$Server_fn);
    var $elm$core$String$padLeft_fn = function (n, _char, string) {
        return _Utils_ap($elm$core$String$repeat_fn(n - $elm$core$String$length(string), $elm$core$String$fromChar(_char)), string);
    }, $elm$core$String$padLeft = F3($elm$core$String$padLeft_fn);
    var $TSFoster$elm_uuid$UUID$toHex_fn = function (acc, _int) {
        toHex: while (true) {
            if (!_int) {
                return $elm$core$String$fromList(acc);
            }
            else {
                var _char = function () {
                    var _v0 = 15 & _int;
                    switch (_v0) {
                        case 0:
                            return "0";
                        case 1:
                            return "1";
                        case 2:
                            return "2";
                        case 3:
                            return "3";
                        case 4:
                            return "4";
                        case 5:
                            return "5";
                        case 6:
                            return "6";
                        case 7:
                            return "7";
                        case 8:
                            return "8";
                        case 9:
                            return "9";
                        case 10:
                            return "a";
                        case 11:
                            return "b";
                        case 12:
                            return "c";
                        case 13:
                            return "d";
                        case 14:
                            return "e";
                        default:
                            return "f";
                    }
                }();
                var $temp$acc = _List_Cons(_char, acc), $temp$int = _int >>> 4;
                acc = $temp$acc;
                _int = $temp$int;
                continue toHex;
            }
        }
    }, $TSFoster$elm_uuid$UUID$toHex = F2($TSFoster$elm_uuid$UUID$toHex_fn);
    var $TSFoster$elm_uuid$UUID$toStringWith_fn = function (sep, _v0) {
        var a = _v0.a;
        var b = _v0.b;
        var c = _v0.c;
        var d = _v0.d;
        return _Utils_ap($elm$core$String$padLeft_fn(8, "0", $TSFoster$elm_uuid$UUID$toHex_fn(_List_Nil, a)), _Utils_ap(sep, _Utils_ap($elm$core$String$padLeft_fn(4, "0", $TSFoster$elm_uuid$UUID$toHex_fn(_List_Nil, b >>> 16)), _Utils_ap(sep, _Utils_ap($elm$core$String$padLeft_fn(4, "0", $TSFoster$elm_uuid$UUID$toHex_fn(_List_Nil, 65535 & b)), _Utils_ap(sep, _Utils_ap($elm$core$String$padLeft_fn(4, "0", $TSFoster$elm_uuid$UUID$toHex_fn(_List_Nil, c >>> 16)), _Utils_ap(sep, _Utils_ap($elm$core$String$padLeft_fn(4, "0", $TSFoster$elm_uuid$UUID$toHex_fn(_List_Nil, 65535 & c)), $elm$core$String$padLeft_fn(8, "0", $TSFoster$elm_uuid$UUID$toHex_fn(_List_Nil, d)))))))))));
    }, $TSFoster$elm_uuid$UUID$toStringWith = F2($TSFoster$elm_uuid$UUID$toStringWith_fn);
    var $TSFoster$elm_uuid$UUID$toString_a0 = "-", $TSFoster$elm_uuid$UUID$toString = $TSFoster$elm_uuid$UUID$toStringWith($TSFoster$elm_uuid$UUID$toString_a0);
    var $elm$core$String$toUpper = _String_toUpper;
    var $author$project$Maple$Data$CuteId$gen_a0 = $TSFoster$elm_uuid$UUID$toString, $author$project$Maple$Data$CuteId$gen_a1 = A2($elm$core$Basics$composeR, $elm$core$String$left(7), $elm$core$String$toUpper), $author$project$Maple$Data$CuteId$gen = A2($elm$core$Basics$composeR, $author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1);
    var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded_a0 = $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded_a1 = $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded_a0, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded_a1);
    var $author$project$Maple$Data$Server$storageItemDecoder = A2($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded, $elm$core$Maybe$Nothing, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional_fn("n", $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string), $elm$core$Maybe$Nothing, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("i", $TSFoster$elm_uuid$UUID$jsonDecoder, $elm$json$Json$Decode$succeed(function (id) {
        return A2($author$project$Maple$Data$Server$Server, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    }))));
    var $author$project$Maple$Data$Server$storageDecoder = $elm$json$Json$Decode$list($author$project$Maple$Data$Server$storageItemDecoder);
    var $author$project$Maple$Data$Team$Team_fn = function (id, cuteId, name, members) {
        return { bE: cuteId, bV: id, dL: members, dS: name };
    }, $author$project$Maple$Data$Team$Team = F4($author$project$Maple$Data$Team$Team_fn);
    var $author$project$Maple$Data$Team$Contributor_fn = function (id, cuteId, email, nickname) {
        return { bE: cuteId, bH: email, bV: id, b1: nickname };
    }, $author$project$Maple$Data$Team$Contributor = F4($author$project$Maple$Data$Team$Contributor_fn);
    var $author$project$Maple$Data$Team$storageContributorDecoder = $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional_fn("n", $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string), $elm$core$Maybe$Nothing, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("e", $elm$json$Json$Decode$string, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("i", $TSFoster$elm_uuid$UUID$jsonDecoder, $elm$json$Json$Decode$succeed(function (id) {
        return A2($author$project$Maple$Data$Team$Contributor, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    }))));
    var $author$project$Maple$Data$Team$storageItemDecoder = $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional_fn("m", $elm$json$Json$Decode$list($author$project$Maple$Data$Team$storageContributorDecoder), _List_Nil, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional_fn("n", $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string), $elm$core$Maybe$Nothing, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required_fn("i", $TSFoster$elm_uuid$UUID$jsonDecoder, $elm$json$Json$Decode$succeed(function (id) {
        return A2($author$project$Maple$Data$Team$Team, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    }))));
    var $author$project$Maple$Data$Team$storageDecoder = $elm$json$Json$Decode$list($author$project$Maple$Data$Team$storageItemDecoder);
    var $author$project$Main$Model$knownStorageDecoder = A3($author$project$Utils$JsonDecode$optionalWithFailures, "o", $author$project$Utils$JsonDecode$nestDecode($elm$json$Json$Decode$bool), A3($author$project$Utils$JsonDecode$optionalWithFailures, "s", $author$project$Utils$JsonDecode$nestDecode($author$project$Maple$Data$Server$storageDecoder), A3($author$project$Utils$JsonDecode$optionalWithFailures, "t", $author$project$Utils$JsonDecode$nestDecode($author$project$Maple$Data$Team$storageDecoder), A3($author$project$Utils$JsonDecode$optionalWithFailures, "c", $author$project$Utils$JsonDecode$nestDecode($elm$json$Json$Decode$dict($elm$json$Json$Decode$int)), A3($author$project$Utils$JsonDecode$optionalWithFailures, "a", $author$project$Utils$JsonDecode$nestDecode($author$project$Maple$Data$Account$storageDecoder), $elm$json$Json$Decode$succeed($author$project$Main$Model$KnownStorage))))));
    var $elm$time$Time$Posix = $elm$core$Basics$identity;
    var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
    var $author$project$Utils$JsonDecode$time = _Json_map1_fn($elm$time$Time$millisToPosix, $elm$json$Json$Decode$int);
    var $author$project$Main$Model$expendableDecoder = A3($author$project$Utils$JsonDecode$optionalWithFailures, "timeNow", $author$project$Utils$JsonDecode$time, A3($author$project$Utils$JsonDecode$optionalWithFailures, "language", $elm$json$Json$Decode$string, A3($author$project$Utils$JsonDecode$optionalWithFailures, "storage", $author$project$Main$Model$knownStorageDecoder, $elm$json$Json$Decode$succeed($author$project$Main$Model$ExpendableFlags))));
    var $author$project$Main$Model$expendableDefault = $author$project$Main$Model$ExpendableFlags_fn($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
    var $author$project$Main$Model$LoginEmail_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $author$project$Main$Model$LoginEmail = F2($author$project$Main$Model$LoginEmail_fn);
    var $author$project$Main$Model$LoginSuccess = function (a) {
        return { $: 3, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Recyclable$NeverAsked = { $: 0 };
    var $PaackEng$paack_remotedata$Remote$Recyclable$Ready = function (a) {
        return { $: 3, a: a };
    };
    var $author$project$Main$Model$Session_fn = function (account, servers, teams) {
        return { A: account, O: servers, aa: teams };
    }, $author$project$Main$Model$Session = F3($author$project$Main$Model$Session_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$Black = 1;
    var $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn = function (a, b, c, d, e) {
        return { $: 0, a: a, b: b, c: c, d: d, e: e };
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin = F5($PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$Red = 0;
    var $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn = function (color, key, value, left, right) {
        if ((!right.$) && (!right.a)) {
            var _v1 = right.a;
            var rK = right.b;
            var rV = right.c;
            var rLeft = right.d;
            var rRight = right.e;
            if ((!left.$) && (!left.a)) {
                var _v3 = left.a;
                var lK = left.b;
                var lV = left.c;
                var lLeft = left.d;
                var lRight = left.e;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, lK, lV, lLeft, lRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, rK, rV, rLeft, rRight));
            }
            else {
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, rK, rV, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, key, value, left, rLeft), rRight);
            }
        }
        else {
            if ((((!left.$) && (!left.a)) && (!left.d.$)) && (!left.d.a)) {
                var _v5 = left.a;
                var lK = left.b;
                var lV = left.c;
                var _v6 = left.d;
                var _v7 = _v6.a;
                var llK = _v6.b;
                var llV = _v6.c;
                var llLeft = _v6.d;
                var llRight = _v6.e;
                var lRight = left.e;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, llK, llV, llLeft, llRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, key, value, lRight, right));
            }
            else {
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, key, value, left, right);
            }
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$balance = F5($PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$insertHelp_fn = function (key, value, dict) {
        if (dict.$ === -2) {
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin, $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin);
        }
        else {
            var nColor = dict.a;
            var nKey = dict.b;
            var nValue = dict.c;
            var nLeft = dict.d;
            var nRight = dict.e;
            var _v1 = $TSFoster$elm_uuid$UUID$compare_fn(key, nKey);
            switch (_v1) {
                case 0:
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn(nColor, nKey, nValue, $PedroHLC$elm_uuid_dict$UUID$Dict$insertHelp_fn(key, value, nLeft), nRight);
                case 1:
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(nColor, nKey, value, nLeft, nRight);
                default:
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn(nColor, nKey, nValue, nLeft, $PedroHLC$elm_uuid_dict$UUID$Dict$insertHelp_fn(key, value, nRight));
            }
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$insertHelp = F3($PedroHLC$elm_uuid_dict$UUID$Dict$insertHelp_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn = function (key, value, dict) {
        var _v0 = $PedroHLC$elm_uuid_dict$UUID$Dict$insertHelp_fn(key, value, dict);
        if ((!_v0.$) && (!_v0.a)) {
            var _v1 = _v0.a;
            var k = _v0.b;
            var v = _v0.c;
            var l = _v0.d;
            var r = _v0.e;
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, l, r);
        }
        else {
            var x = _v0;
            return x;
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$insert = F3($PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn);
    var $author$project$Maple$Data$Server$internalToModel_a0 = function (server) {
        return A2($PedroHLC$elm_uuid_dict$UUID$Dict$insert, server.bV, server);
    }, $author$project$Maple$Data$Server$internalToModel_a1 = $PedroHLC$elm_uuid_dict$UUID$Dict$empty, $author$project$Maple$Data$Server$internalToModel = A2($elm$core$List$foldr, $author$project$Maple$Data$Server$internalToModel_a0, $author$project$Maple$Data$Server$internalToModel_a1);
    var $author$project$Maple$Data$Server$toModel_a0 = $author$project$Maple$Data$Server$internalToModel, $author$project$Maple$Data$Server$toModel_a1 = $PaackEng$paack_remotedata$Remote$Recyclable$Ready, $author$project$Maple$Data$Server$toModel = A2($elm$core$Basics$composeR, $author$project$Maple$Data$Server$toModel_a0, $author$project$Maple$Data$Server$toModel_a1);
    var $author$project$Main$Model$fromStorage = function (maybeStorage) {
        if (!maybeStorage.$) {
            var decodedStorage = maybeStorage.a;
            var sidebarOpen = $elm$core$Maybe$withDefault_fn(true, decodedStorage.aN);
            var cart = $elm$core$Maybe$withDefault_fn($elm$core$Dict$empty, decodedStorage.aX);
            var _v1 = decodedStorage.A;
            if (!_v1.$) {
                var account = _v1.a;
                return _Utils_Tuple3(cart, function () {
                    var teams = $elm$core$Maybe$withDefault_fn($PaackEng$paack_remotedata$Remote$Recyclable$NeverAsked, $elm$core$Maybe$map_fn($PaackEng$paack_remotedata$Remote$Recyclable$Ready, decodedStorage.aa));
                    var servers = $elm$core$Maybe$withDefault_fn($PaackEng$paack_remotedata$Remote$Recyclable$NeverAsked, $elm$core$Maybe$map_fn($author$project$Maple$Data$Server$toModel, decodedStorage.O));
                    return $author$project$Main$Model$LoginSuccess($author$project$Main$Model$Session_fn(account, servers, teams));
                }(), sidebarOpen);
            }
            else {
                return _Utils_Tuple3(cart, $author$project$Main$Model$LoginEmail_fn("", false), sidebarOpen);
            }
        }
        else {
            return _Utils_Tuple3($elm$core$Dict$empty, $author$project$Main$Model$LoginEmail_fn("", false), true);
        }
    };
    var $author$project$Maple$Environment$Environment = $elm$core$Basics$identity;
    var $author$project$Maple$Environment$fromString = function (url) {
        return url;
    };
    var $author$project$Pages$Route$Kidnapping = { $: 6 };
    var $elm$url$Url$Parser$State_fn = function (visited, unvisited, params, frag, value) {
        return { G: frag, I: params, E: unvisited, az: value, P: visited };
    }, $elm$url$Url$Parser$State = F5($elm$url$Url$Parser$State_fn);
    var $elm$url$Url$Parser$getFirstMatch = function (states) {
        getFirstMatch: while (true) {
            if (!states.b) {
                return $elm$core$Maybe$Nothing;
            }
            else {
                var state = states.a;
                var rest = states.b;
                var _v1 = state.E;
                if (!_v1.b) {
                    return $elm$core$Maybe$Just(state.az);
                }
                else {
                    if ((_v1.a === "") && (!_v1.b.b)) {
                        return $elm$core$Maybe$Just(state.az);
                    }
                    else {
                        var $temp$states = rest;
                        states = $temp$states;
                        continue getFirstMatch;
                    }
                }
            }
        }
    };
    var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
        if (!segments.b) {
            return _List_Nil;
        }
        else {
            if ((segments.a === "") && (!segments.b.b)) {
                return _List_Nil;
            }
            else {
                var segment = segments.a;
                var rest = segments.b;
                return _List_Cons(segment, $elm$url$Url$Parser$removeFinalEmpty(rest));
            }
        }
    };
    var $elm$url$Url$Parser$preparePath = function (path) {
        var _v0 = $elm$core$String$split_fn("/", path);
        if (_v0.b && (_v0.a === "")) {
            var segments = _v0.b;
            return $elm$url$Url$Parser$removeFinalEmpty(segments);
        }
        else {
            var segments = _v0;
            return $elm$url$Url$Parser$removeFinalEmpty(segments);
        }
    };
    var $elm$url$Url$Parser$addToParametersHelp_fn = function (value, maybeList) {
        if (maybeList.$ === 1) {
            return $elm$core$Maybe$Just(_List_fromArray([value]));
        }
        else {
            var list = maybeList.a;
            return $elm$core$Maybe$Just(_List_Cons(value, list));
        }
    }, $elm$url$Url$Parser$addToParametersHelp = F2($elm$url$Url$Parser$addToParametersHelp_fn);
    var $elm$url$Url$percentDecode = _Url_percentDecode;
    var $elm$url$Url$Parser$addParam_fn = function (segment, dict) {
        var _v0 = $elm$core$String$split_fn("=", segment);
        if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
            var rawKey = _v0.a;
            var _v1 = _v0.b;
            var rawValue = _v1.a;
            var _v2 = $elm$url$Url$percentDecode(rawKey);
            if (_v2.$ === 1) {
                return dict;
            }
            else {
                var key = _v2.a;
                var _v3 = $elm$url$Url$percentDecode(rawValue);
                if (_v3.$ === 1) {
                    return dict;
                }
                else {
                    var value = _v3.a;
                    return $elm$core$Dict$update_fn(key, $elm$url$Url$Parser$addToParametersHelp(value), dict);
                }
            }
        }
        else {
            return dict;
        }
    }, $elm$url$Url$Parser$addParam = F2($elm$url$Url$Parser$addParam_fn);
    var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
        if (maybeQuery.$ === 1) {
            return $elm$core$Dict$empty;
        }
        else {
            var qry = maybeQuery.a;
            return $elm$core$List$foldr_fn($elm$url$Url$Parser$addParam, $elm$core$Dict$empty, $elm$core$String$split_fn("&", qry));
        }
    };
    var $elm$url$Url$Parser$parse_fn = function (_v0, url) {
        var parser = _v0;
        return $elm$url$Url$Parser$getFirstMatch(parser($elm$url$Url$Parser$State_fn(_List_Nil, $elm$url$Url$Parser$preparePath(url.b3), $elm$url$Url$Parser$prepareQuery(url.ca), url.bQ, $elm$core$Basics$identity)));
    }, $elm$url$Url$Parser$parse = F2($elm$url$Url$Parser$parse_fn);
    var $author$project$Pages$Route$GoingNotFound = { $: 5 };
    var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
    var $elm$url$Url$Parser$mapState_fn = function (func, _v0) {
        var visited = _v0.P;
        var unvisited = _v0.E;
        var params = _v0.I;
        var frag = _v0.G;
        var value = _v0.az;
        return $elm$url$Url$Parser$State_fn(visited, unvisited, params, frag, func(value));
    }, $elm$url$Url$Parser$mapState = F2($elm$url$Url$Parser$mapState_fn);
    var $elm$url$Url$Parser$map_fn = function (subValue, _v0) {
        var parseArg = _v0;
        return function (_v1) {
            var visited = _v1.P;
            var unvisited = _v1.E;
            var params = _v1.I;
            var frag = _v1.G;
            var value = _v1.az;
            return $elm$core$List$map_fn($elm$url$Url$Parser$mapState(value), parseArg($elm$url$Url$Parser$State_fn(visited, unvisited, params, frag, subValue)));
        };
    }, $elm$url$Url$Parser$map = F2($elm$url$Url$Parser$map_fn);
    var $elm$url$Url$Parser$oneOf = function (parsers) {
        return function (state) {
            return $elm$core$List$concatMap_fn(function (_v0) {
                var parser = _v0;
                return parser(state);
            }, parsers);
        };
    };
    var $elm$url$Url$Parser$s = function (str) {
        return function (_v0) {
            var visited = _v0.P;
            var unvisited = _v0.E;
            var params = _v0.I;
            var frag = _v0.G;
            var value = _v0.az;
            if (!unvisited.b) {
                return _List_Nil;
            }
            else {
                var next = unvisited.a;
                var rest = unvisited.b;
                return _Utils_eq(next, str) ? _List_fromArray([
                    $elm$url$Url$Parser$State_fn(_List_Cons(next, visited), rest, params, frag, value)
                ]) : _List_Nil;
            }
        };
    };
    var $elm$url$Url$Parser$slash_fn = function (_v0, _v1) {
        var parseBefore = _v0;
        var parseAfter = _v1;
        return function (state) {
            return $elm$core$List$concatMap_fn(parseAfter, parseBefore(state));
        };
    }, $elm$url$Url$Parser$slash = F2($elm$url$Url$Parser$slash_fn);
    var $elm$url$Url$Parser$custom_fn = function (tipe, stringToSomething) {
        return function (_v0) {
            var visited = _v0.P;
            var unvisited = _v0.E;
            var params = _v0.I;
            var frag = _v0.G;
            var value = _v0.az;
            if (!unvisited.b) {
                return _List_Nil;
            }
            else {
                var next = unvisited.a;
                var rest = unvisited.b;
                var _v2 = stringToSomething(next);
                if (!_v2.$) {
                    var nextValue = _v2.a;
                    return _List_fromArray([
                        $elm$url$Url$Parser$State_fn(_List_Cons(next, visited), rest, params, frag, value(nextValue))
                    ]);
                }
                else {
                    return _List_Nil;
                }
            }
        };
    }, $elm$url$Url$Parser$custom = F2($elm$url$Url$Parser$custom_fn);
    var $elm$url$Url$Parser$string = $elm$url$Url$Parser$custom_fn("STRING", $elm$core$Maybe$Just);
    var $author$project$Pages$Route$routeParser = $elm$url$Url$Parser$oneOf(_List_fromArray([
        $elm$url$Url$Parser$map_fn($author$project$Pages$Route$GoingDashboard, $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("assets"), $elm$url$Url$Parser$s("index.html"))),
        $elm$url$Url$Parser$map_fn($author$project$Pages$Route$GoingHome, $elm$url$Url$Parser$s("staging")),
        $elm$url$Url$Parser$map_fn($author$project$Pages$Route$GoingDashboard, $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("staging"), $elm$url$Url$Parser$s("dashboard"))),
        $elm$url$Url$Parser$map_fn($author$project$Pages$Route$GoingDashboardTeams, $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("staging"), $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("dashboard"), $elm$url$Url$Parser$s("teams")))),
        $elm$url$Url$Parser$map_fn($author$project$Pages$Route$GoingDashboardNewServer, $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("staging"), $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("dashboard"), $elm$url$Url$Parser$s("create")))),
        $elm$url$Url$Parser$map_fn(A2($elm$core$Basics$composeR, $elm$core$String$toUpper, $author$project$Pages$Route$GoingDashboardServer), $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("staging"), $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("dashboard"), $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("server"), $elm$url$Url$Parser$string)))),
        $elm$url$Url$Parser$map_fn(function (_v0) {
            return $author$project$Pages$Route$GoingNotFound;
        }, $elm$url$Url$Parser$slash_fn($elm$url$Url$Parser$s("staging"), $elm$url$Url$Parser$string))
    ]));
    var $author$project$Pages$Route$fromUrl = function (url) {
        return $elm$core$Maybe$withDefault_fn($author$project$Pages$Route$Kidnapping, $elm$url$Url$Parser$parse_fn($author$project$Pages$Route$routeParser, url));
    };
    var $author$project$Main$Model$DashNewServer = { $: 3 };
    var $author$project$Main$Model$DashOverview = { $: 0 };
    var $author$project$Main$Model$DashServer = function (a) {
        return { $: 2, a: a };
    };
    var $author$project$Main$Model$DashTeams = { $: 1 };
    var $author$project$Main$Msg$DashboardResult = function (a) {
        return { $: 3, a: a };
    };
    var $author$project$Main$Model$PageDashboard = function (a) {
        return { $: 3, a: a };
    };
    var $author$project$Main$Model$PageKidnapped = { $: 0 };
    var $author$project$Main$Model$PageNotFound = { $: 1 };
    var $author$project$Main$Model$PageWelcome = { $: 2 };
    var $author$project$Main$Msg$TeamsResult = function (a) {
        return { $: 23, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2($dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn);
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded_fn = function (constant, _v0) {
        var objectFields = _v0.a;
        var objectDecoder = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(objectFields, _Json_map2_fn($elm$core$Basics$apR, $elm$json$Json$Decode$succeed(constant), objectDecoder));
    }, $dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded = F2($dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded_fn);
    var $author$project$Maple$ScalarCodecs$codecCoin = { da: $elm$json$Json$Decode$string, F: $elm$json$Json$Encode$string };
    var $elm$json$Json$Encode$int = _Json_wrap;
    var $elm$time$Time$posixToMillis = function (_v0) {
        var millis = _v0;
        return millis;
    };
    var $author$project$Maple$ScalarCodecs$codecDateTime = {
        da: _Json_map1_fn($elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
        F: A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$json$Json$Encode$int)
    };
    var $author$project$Maple$ScalarCodecs$codecEmailAddress = { da: $elm$json$Json$Decode$string, F: $elm$json$Json$Encode$string };
    var $author$project$Maple$ScalarCodecs$codecTimeZone = {
        da: _Json_map1_fn($elm$time$Time$Name, $elm$json$Json$Decode$string),
        F: function (zone) {
            if (!zone.$) {
                var string = zone.a;
                return $elm$json$Json$Encode$string(string);
            }
            else {
                var _int = zone.a;
                return $elm$json$Json$Encode$int(_int);
            }
        }
    };
    var $author$project$Maple$ScalarCodecs$codecToken = { da: $elm$json$Json$Decode$string, F: $elm$json$Json$Encode$string };
    var $TSFoster$elm_uuid$UUID$toValue_a0 = $TSFoster$elm_uuid$UUID$toString, $TSFoster$elm_uuid$UUID$toValue_a1 = $elm$json$Json$Encode$string, $TSFoster$elm_uuid$UUID$toValue = A2($elm$core$Basics$composeR, $TSFoster$elm_uuid$UUID$toValue_a0, $TSFoster$elm_uuid$UUID$toValue_a1);
    var $author$project$Maple$ScalarCodecs$codecUuid = { da: $TSFoster$elm_uuid$UUID$jsonDecoder, F: $TSFoster$elm_uuid$UUID$toValue };
    var $author$project$Maple$Internals$Scalar$Codecs = $elm$core$Basics$identity;
    var $author$project$Maple$Internals$Scalar$defineCodecs = function (definitions) {
        return definitions;
    };
    var $author$project$Maple$ScalarCodecs$codecs = $author$project$Maple$Internals$Scalar$defineCodecs({ by: $author$project$Maple$ScalarCodecs$codecCoin, bz: $author$project$Maple$ScalarCodecs$codecDateTime, bA: $author$project$Maple$ScalarCodecs$codecEmailAddress, bB: $author$project$Maple$ScalarCodecs$codecTimeZone, c$: $author$project$Maple$ScalarCodecs$codecToken, C: $author$project$Maple$ScalarCodecs$codecUuid });
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf_fn = function (details, args) {
        return $dillonkearns$elm_graphql$Graphql$RawField$Leaf_fn(details, args);
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn = function (typeString, fieldName, args, decoder) {
        var newLeaf = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf_fn({ bO: fieldName, cz: typeString }, args);
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(_List_fromArray([newLeaf]), $elm$json$Json$Decode$oneOf(_List_fromArray([
            _Json_decodeField_fn(fieldName, decoder),
            _Json_decodeField_fn($dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf), decoder)
        ])));
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn);
    var $author$project$Maple$Internals$Scalar$unwrapCodecs = function (_v0) {
        var unwrappedCodecs = _v0;
        return unwrappedCodecs;
    };
    var $author$project$Maple$Internals$Object$Server$id = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Uuid", "id", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).C.da);
    var $author$project$Maple$Internals$Object$Server$nickname = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("(Maybe String)", "nickname", _List_Nil, $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(_List_Nil, $elm$json$Json$Decode$succeed(constructor));
    };
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn = function (_v0, _v1) {
        var selectionFields1 = _v0.a;
        var selectionDecoder1 = _v0.b;
        var selectionFields2 = _v1.a;
        var selectionDecoder2 = _v1.b;
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(_Utils_ap(selectionFields1, selectionFields2), _Json_map2_fn($elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
    }, $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2($dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn);
    var $author$project$Maple$Data$Server$selection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Server$nickname, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Server$id, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(function (id) {
        return A2($author$project$Maple$Data$Server$Server, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    })));
    var $author$project$Maple$Data$Server$basicSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded_fn($elm$core$Maybe$Nothing, $author$project$Maple$Data$Server$selection);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite_fn = function (fieldName, args, fields) {
        return $dillonkearns$elm_graphql$Graphql$RawField$Composite_fn(fieldName, args, fields);
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn = function (fieldName, args, _v0, decoderTransform) {
        var fields = _v0.a;
        var decoder = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite_fn(fieldName, args, fields)
        ]), $elm$json$Json$Decode$oneOf(_List_fromArray([
            _Json_decodeField_fn(fieldName, decoderTransform(decoder)),
            _Json_decodeField_fn($dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite_fn(fieldName, args, fields)), decoderTransform(decoder))
        ])));
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn);
    var $author$project$Maple$Internals$Object$Dashboard$servers = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("servers", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Ops$Dashboard$grabServers = function (serverSelection) {
        return $author$project$Maple$Internals$Object$Dashboard$servers(serverSelection);
    };
    var $author$project$Main$Msg$ServerResult = function (a) {
        return { $: 11, a: a };
    };
    var $author$project$Maple$Data$Server$ExtraData_fn = function (tier, games, managers) {
        return { dk: games, dG: managers, es: tier };
    }, $author$project$Maple$Data$Server$ExtraData = F3($author$project$Maple$Data$Server$ExtraData_fn);
    var $author$project$Maple$Internals$Object$Server$games = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("games", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Internals$Object$ServerManager$manager = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("manager", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Data$Team$OpContributor_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $author$project$Maple$Data$Team$OpContributor = F2($author$project$Maple$Data$Team$OpContributor_fn);
    var $author$project$Maple$Data$Team$OpTeam_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $author$project$Maple$Data$Team$OpTeam = F2($author$project$Maple$Data$Team$OpTeam_fn);
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet = F3($dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn = function (fragmentTypeName, _v0) {
        var fields = _v0.a;
        var decoder = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet_fn(fragmentTypeName, fields, decoder);
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment = F2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage_fn = function (typeSpecificSelections, typeName) {
        return $lukewestby$elm_string_interpolate$String$Interpolate$interpolate_fn("Unhandled type `{0}` in exhaustive fragment handling. The following types had handlers registered to handle them: [{1}]. This happens if you are parsing either a Union or Interface. Do you need to rerun the `@dillonkearns/elm-graphql` command line tool?", _List_fromArray([
            typeName,
            $elm$core$String$join_fn(", ", $elm$core$List$map_fn(function (_v0) {
                var fragmentType = _v0.a;
                var fields = _v0.b;
                var decoder = _v0.c;
                return fragmentType;
            }, typeSpecificSelections))
        ]));
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage = F2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection = function (typeSpecificSelections) {
        var selections = $elm$core$List$map_fn(function (_v1) {
            var typeName = _v1.a;
            var fields = _v1.b;
            var decoder = _v1.c;
            return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite_fn("...on " + typeName, _List_Nil, fields);
        }, typeSpecificSelections);
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(_List_Cons($dillonkearns$elm_graphql$Graphql$RawField$typename, selections), _Json_andThen_fn(function (typeName) {
            return $elm$core$Maybe$withDefault_fn($elm$json$Json$Decode$fail($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage_fn(typeSpecificSelections, typeName)), $elm$core$Dict$get_fn(typeName, $elm$core$Dict$fromList($elm$core$List$map_fn(function (_v0) {
                var thisTypeName = _v0.a;
                var fields = _v0.b;
                var decoder = _v0.c;
                return _Utils_Tuple2(thisTypeName, decoder);
            }, typeSpecificSelections))));
        }, _Json_decodeField_fn($dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName($dillonkearns$elm_graphql$Graphql$RawField$typename), $elm$json$Json$Decode$string)));
    };
    var $author$project$Maple$Internals$Interface$Manager$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Contributor", selections____.d1),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Team", selections____.d3)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn = function (mapFunction, _v0) {
        var selectionFields = _v0.a;
        var selectionDecoder = _v0.b;
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(selectionFields, _Json_map1_fn(mapFunction, selectionDecoder));
    }, $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2($dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn);
    var $author$project$Maple$Internals$Object$Contributor$email = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.EmailAddress", "email", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).bA.da);
    var $author$project$Maple$Internals$Object$Contributor$id = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Uuid", "id", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).C.da);
    var $author$project$Maple$Internals$Object$Contributor$nickname = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("(Maybe String)", "nickname", _List_Nil, $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
    var $author$project$Maple$Data$Team$memberSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Contributor$nickname, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Contributor$email, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Contributor$id, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(function (id) {
        return A2($author$project$Maple$Data$Team$Contributor, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    }))));
    var $author$project$Maple$Internals$Object$Team$id = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Uuid", "id", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).C.da);
    var $author$project$Maple$Internals$Object$Team$members = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("members", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Internals$Object$Team$name = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("(Maybe String)", "name", _List_Nil, $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
    var $author$project$Maple$Data$Team$teamSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Team$members($author$project$Maple$Data$Team$memberSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Team$name, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Team$id, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(function (id) {
        return A2($author$project$Maple$Data$Team$Team, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    }))));
    var $author$project$Maple$Data$Team$managerSelection = $author$project$Maple$Internals$Interface$Manager$fragments({
        d1: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($author$project$Maple$Data$Team$OpContributor, $author$project$Maple$Data$Team$memberSelection),
        d3: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($author$project$Maple$Data$Team$OpTeam, $author$project$Maple$Data$Team$teamSelection)
    });
    var $author$project$Maple$Internals$Object$ServerManager$perms = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("perms", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Data$Server$Permissions_fn = function (canAddGames, canDelete, canRename, canStartStop) {
        return { cT: canAddGames, cU: canDelete, cV: canRename, cW: canStartStop };
    }, $author$project$Maple$Data$Server$Permissions = F4($author$project$Maple$Data$Server$Permissions_fn);
    var $author$project$Maple$Internals$Object$ServerPermissionSet$canAddGames = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Bool", "canAddGames", _List_Nil, $elm$json$Json$Decode$bool);
    var $author$project$Maple$Internals$Object$ServerPermissionSet$canDelete = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Bool", "canDelete", _List_Nil, $elm$json$Json$Decode$bool);
    var $author$project$Maple$Internals$Object$ServerPermissionSet$canRename = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Bool", "canRename", _List_Nil, $elm$json$Json$Decode$bool);
    var $author$project$Maple$Internals$Object$ServerPermissionSet$canStartStop = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Bool", "canStartStop", _List_Nil, $elm$json$Json$Decode$bool);
    var $author$project$Maple$Data$Server$permsSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerPermissionSet$canStartStop, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerPermissionSet$canRename, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerPermissionSet$canDelete, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerPermissionSet$canAddGames, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Server$Permissions)))));
    var $author$project$Maple$Data$Server$managerSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerManager$perms($author$project$Maple$Data$Server$permsSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerManager$manager($author$project$Maple$Data$Team$managerSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(F2(function (manager, perms) {
        return manager(perms);
    }))));
    var $author$project$Maple$Internals$Object$Server$managers = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("managers", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Data$Game$Instance_fn = function (id, cuteId, game, nickname, players, accounts, logs) {
        return { cH: accounts, bE: cuteId, dj: game, bV: id, dF: logs, b1: nickname, d9: players };
    }, $author$project$Maple$Data$Game$Instance = F7($author$project$Maple$Data$Game$Instance_fn);
    var $author$project$Maple$Internals$Object$GameInstance$accounts = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Int", "accounts", _List_Nil, $elm$json$Json$Decode$int);
    var $author$project$Maple$Internals$Object$GameInstance$game = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("game", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Data$Game$Minecraft = function (a) {
        return { $: 0, a: a };
    };
    var $author$project$Maple$Data$Game$Terraria = function (a) {
        return { $: 1, a: a };
    };
    var $author$project$Maple$Internals$Union$Game$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Minecraft", selections____.d2),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Terraria", selections____.d4)
        ]));
    };
    var $author$project$Maple$Data$Game$gameSelection = $author$project$Maple$Internals$Union$Game$fragments({
        d2: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Game$Minecraft({})),
        d4: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Game$Terraria({}))
    });
    var $author$project$Maple$Internals$Object$GameInstance$id = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Uuid", "id", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).C.da);
    var $author$project$Maple$Internals$Object$GameInstance$logs = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("logs", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Internals$Object$GameInstance$nickname = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("(Maybe String)", "nickname", _List_Nil, $elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
    var $author$project$Maple$Internals$Object$GameInstance$players = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("players", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Data$Log$Logs = function (lines) {
        return { dD: lines };
    };
    var $author$project$Maple$Data$Log$Line = function (content) {
        return { c2: content };
    };
    var $author$project$Maple$Internals$Object$LogLine$content = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("String", "content", _List_Nil, $elm$json$Json$Decode$string);
    var $author$project$Maple$Data$Log$lineSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$LogLine$content, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Log$Line));
    var $author$project$Maple$Internals$Object$Logs$lines = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("lines", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Data$Log$selection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Logs$lines($author$project$Maple$Data$Log$lineSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Log$Logs));
    var $author$project$Maple$Data$Resource$Usage_fn = function (maxTotal, latest, sample) {
        return { dA: latest, dI: maxTotal, ei: sample };
    }, $author$project$Maple$Data$Resource$Usage = F3($author$project$Maple$Data$Resource$Usage_fn);
    var $elm$json$Json$Decode$float = _Json_decodeFloat;
    var $author$project$Maple$Internals$Object$ResourceUsage$latest = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Float", "latest", _List_Nil, $elm$json$Json$Decode$float);
    var $author$project$Maple$Internals$Object$ResourceUsage$maxTotal = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Float", "maxTotal", _List_Nil, $elm$json$Json$Decode$float);
    var $author$project$Maple$Internals$Object$ResourceUsage$sample = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("sample", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
    };
    var $author$project$Maple$Data$Resource$Sample_fn = function (size, median, maximun, minimun) {
        return { dJ: maximun, dK: median, dO: minimun, as: size };
    }, $author$project$Maple$Data$Resource$Sample = F4($author$project$Maple$Data$Resource$Sample_fn);
    var $author$project$Maple$Internals$Object$ResourceSample$maximun = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Float", "maximun", _List_Nil, $elm$json$Json$Decode$float);
    var $author$project$Maple$Internals$Object$ResourceSample$median = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Float", "median", _List_Nil, $elm$json$Json$Decode$float);
    var $author$project$Maple$Internals$Object$ResourceSample$minimun = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Float", "minimun", _List_Nil, $elm$json$Json$Decode$float);
    var $author$project$Maple$Internals$Object$ResourceSample$size = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Int", "size", _List_Nil, $elm$json$Json$Decode$int);
    var $author$project$Maple$Data$Resource$sampleSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceSample$minimun, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceSample$maximun, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceSample$median, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceSample$size, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Resource$Sample)))));
    var $author$project$Maple$Data$Resource$usageSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceUsage$sample($author$project$Maple$Data$Resource$sampleSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceUsage$latest, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ResourceUsage$maxTotal, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Resource$Usage))));
    var $author$project$Maple$Data$Game$selection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$GameInstance$logs($author$project$Maple$Data$Log$selection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$GameInstance$accounts, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$GameInstance$players($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$GameInstance$nickname, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$GameInstance$game($author$project$Maple$Data$Game$gameSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$GameInstance$id, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(function (id) {
        return A2($author$project$Maple$Data$Game$Instance, id, $elm$core$Basics$composeR_fn($author$project$Maple$Data$CuteId$gen_a0, $author$project$Maple$Data$CuteId$gen_a1, id));
    })))))));
    var $author$project$Maple$Internals$Object$Server$tier = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("tier", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Data$Server$Tier_fn = function (id, name, vCPUs, cpuLoad, memory, storage, io, bandwidth, egress) {
        return { cO: bandwidth, c6: cpuLoad, de: egress, bV: id, dy: io, dM: memory, dS: name, ct: storage, ez: vCPUs };
    }, $author$project$Maple$Data$Server$Tier = F9($author$project$Maple$Data$Server$Tier_fn);
    var $author$project$Maple$Internals$Object$ServerTier$bandwidth = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("bandwidth", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
    };
    var $author$project$Maple$Internals$Object$ServerTier$cpuLoad = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("cpuLoad", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Internals$Object$ServerTier$egress = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("egress", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
    };
    var $author$project$Maple$Internals$Object$ServerTier$id = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Uuid", "id", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).C.da);
    var $author$project$Maple$Internals$Object$ServerTier$io = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("io", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
    };
    var $author$project$Maple$Internals$Object$ServerTier$memory = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("memory", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Internals$Object$ServerTier$name = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("String", "name", _List_Nil, $elm$json$Json$Decode$string);
    var $author$project$Maple$Internals$Object$ServerTier$storage = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("storage", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Internals$Object$ServerTier$vCPUs = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Int", "vCPUs", _List_Nil, $elm$json$Json$Decode$int);
    var $author$project$Maple$Data$Server$tierSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$egress($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$bandwidth($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$io($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$storage($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$memory($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$cpuLoad($author$project$Maple$Data$Resource$usageSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$vCPUs, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$name, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$ServerTier$id, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Server$Tier))))))))));
    var $author$project$Maple$Data$Server$extraSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Server$managers($author$project$Maple$Data$Server$managerSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Server$games($author$project$Maple$Data$Game$selection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Server$tier($author$project$Maple$Data$Server$tierSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Maple$Data$Server$ExtraData))));
    var $dillonkearns$elm_graphql$Graphql$SelectionSet$map2_fn = function (combine, _v0, _v1) {
        var selectionFields1 = _v0.a;
        var selectionDecoder1 = _v0.b;
        var selectionFields2 = _v1.a;
        var selectionDecoder2 = _v1.b;
        return $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(_Utils_ap(selectionFields1, selectionFields2), _Json_map2_fn(combine, selectionDecoder1, selectionDecoder2));
    }, $dillonkearns$elm_graphql$Graphql$SelectionSet$map2 = F3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2_fn);
    var $author$project$Maple$Data$Server$fullSelection = $dillonkearns$elm_graphql$Graphql$SelectionSet$map2_fn(F2(function (base, extra) {
        return base(extra);
    }), $author$project$Maple$Data$Server$selection, $author$project$Maple$Data$Server$extraSelection);
    var $author$project$Effects$none = _List_Nil;
    var $author$project$Maple$Ops$Server$InvalidSessionError = 0;
    var $author$project$Maple$Internals$Query$PullServerRequiredArguments = function (input) {
        return { m: input };
    };
    var $author$project$Maple$Client$Query = function (a) {
        return { $: 0, a: a };
    };
    var $author$project$Maple$Ops$Server$ServerNotFound = 1;
    var $author$project$Maple$Internals$Union$ServerResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Server", selections____.be),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("ServerNotFoundError", selections____.bf),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidSessionError", selections____.a7)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object($elm$core$List$filterMap_fn(function (_v0) {
            var key = _v0.a;
            var value = _v0.b;
            return $elm$core$Maybe$andThen_fn(function (actualValue) {
                return $elm$core$Maybe$Just(_Utils_Tuple2(key, actualValue));
            }, value);
        }, maybeValues));
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
    };
    var $author$project$Maple$Internals$Scalar$unwrapEncoder_fn = function (getter, _v0) {
        var unwrappedCodecs = _v0;
        return A2($elm$core$Basics$composeR, getter(unwrappedCodecs).F, $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
    }, $author$project$Maple$Internals$Scalar$unwrapEncoder = F2($author$project$Maple$Internals$Scalar$unwrapEncoder_fn);
    var $author$project$Maple$Internals$InputObject$encodeServerInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("id", $elm$core$Maybe$Just($author$project$Maple$Internals$Scalar$unwrapEncoder_fn(function ($) {
                return $.C;
            }, $author$project$Maple$ScalarCodecs$codecs)(input____.bV)))
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument_fn);
    var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn = function (fieldName, raw, encode) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument_fn(fieldName, encode(raw));
    }, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn);
    var $author$project$Maple$Internals$Query$pullServer_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("pullServer", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodeServerInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Query$pullServer = F2($author$project$Maple$Internals$Query$pullServer_fn);
    var $author$project$Maple$Ops$Server$operation_fn = function (selection, params) {
        return $author$project$Maple$Client$Query($author$project$Maple$Internals$Query$pullServer_fn($author$project$Maple$Internals$Query$PullServerRequiredArguments(params), $author$project$Maple$Internals$Union$ServerResult$fragments({
            a7: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0)),
            be: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, selection),
            bf: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(1))
        })));
    }, $author$project$Maple$Ops$Server$operation = F2($author$project$Maple$Ops$Server$operation_fn);
    var $author$project$Maple$Client$UnauthorizedRequestConfig_fn = function (environment, toMsgFn, operation) {
        return { bJ: environment, aJ: operation, z: toMsgFn };
    }, $author$project$Maple$Client$UnauthorizedRequestConfig = F3($author$project$Maple$Client$UnauthorizedRequestConfig_fn);
    var $author$project$Maple$Data$Account$getToken = function (_v0) {
        var token = _v0.bq;
        return token;
    };
    var $author$project$Effects$GraphqlHttpMutation = function (a) {
        return { $: 3, a: a };
    };
    var $elm$core$Result$andThen_fn = function (callback, result) {
        if (!result.$) {
            var value = result.a;
            return callback(value);
        }
        else {
            var msg = result.a;
            return $elm$core$Result$Err(msg);
        }
    }, $elm$core$Result$andThen = F2($elm$core$Result$andThen_fn);
    var $author$project$Effects$graphqlDecode_fn = function (decoder, value) {
        return $elm$core$Result$mapError_fn(A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$BadPayload, $dillonkearns$elm_graphql$Graphql$Http$HttpError), _Json_run_fn(decoder, value));
    }, $author$project$Effects$graphqlDecode = F2($author$project$Effects$graphqlDecode_fn);
    var $author$project$Effects$flip_fn = function (applier, b, a) {
        return A2(applier, a, b);
    }, $author$project$Effects$flip_fn_unwrapped = function (applier, b, a) {
        return applier(a, b);
    }, $author$project$Effects$flip = F3($author$project$Effects$flip_fn);
    var $elm$core$Result$map_fn = function (func, ra) {
        if (!ra.$) {
            var a = ra.a;
            return $elm$core$Result$Ok(func(a));
        }
        else {
            var e = ra.a;
            return $elm$core$Result$Err(e);
        }
    }, $elm$core$Result$map = F2($elm$core$Result$map_fn);
    var $elm$core$Result$withDefault_fn = function (def, result) {
        if (!result.$) {
            var a = result.a;
            return a;
        }
        else {
            return def;
        }
    }, $elm$core$Result$withDefault = F2($elm$core$Result$withDefault_fn);
    var $author$project$Effects$graphqlFailure_fn = function (decoder, error) {
        if (!error.$) {
            if (!error.a.$) {
                var value = error.a.a;
                var list = error.b;
                return $author$project$Effects$flip_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, list, $elm$core$Result$withDefault_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(value), $elm$core$Result$map_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, $author$project$Effects$graphqlDecode_fn(decoder, value))));
            }
            else {
                var raw = error.a.a;
                var list = error.b;
                return $dillonkearns$elm_graphql$Graphql$Http$GraphqlError_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(raw), list);
            }
        }
        else {
            var err = error.a;
            return $dillonkearns$elm_graphql$Graphql$Http$HttpError(err);
        }
    }, $author$project$Effects$graphqlFailure = F2($author$project$Effects$graphqlFailure_fn);
    var $dillonkearns$elm_graphql$Graphql$Http$discardParsedErrorData = function (result) {
        if (!result.$) {
            var data = result.a;
            return $elm$core$Result$Ok(data);
        }
        else {
            if (result.a.$ === 1) {
                var httpError = result.a.a;
                return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
            }
            else {
                if (!result.a.a.$) {
                    var _v1 = result.a;
                    var parsed = _v1.a.a;
                    var errorList = _v1.b;
                    return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$GraphqlError_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData($elm$json$Json$Encode$null), errorList));
                }
                else {
                    var _v2 = result.a;
                    var value = _v2.a.a;
                    var errorList = _v2.b;
                    return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$GraphqlError_fn($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(value), errorList));
                }
            }
        }
    };
    var $PaackEng$paack_remotedata$Remote$Errors$Custom = function (a) {
        return { $: 0, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Response$Failure = function (a) {
        return { $: 0, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Response$Success = function (a) {
        return { $: 1, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Errors$Transport = function (a) {
        return { $: 1, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Response$fromResults = function (results) {
        if (results.$ === 1) {
            var transportError = results.a;
            return $PaackEng$paack_remotedata$Remote$Response$Failure($PaackEng$paack_remotedata$Remote$Errors$Transport(transportError));
        }
        else {
            if (results.a.$ === 1) {
                var customError = results.a.a;
                return $PaackEng$paack_remotedata$Remote$Response$Failure($PaackEng$paack_remotedata$Remote$Errors$Custom(customError));
            }
            else {
                var object = results.a.a;
                return $PaackEng$paack_remotedata$Remote$Response$Success(object);
            }
        }
    };
    var $PaackEng$paack_remotedata$Remote$Response$graphqlHttpToMsg_fn = function (message, input) {
        return message($PaackEng$paack_remotedata$Remote$Response$fromResults($dillonkearns$elm_graphql$Graphql$Http$discardParsedErrorData(input)));
    }, $PaackEng$paack_remotedata$Remote$Response$graphqlHttpToMsg = F2($PaackEng$paack_remotedata$Remote$Response$graphqlHttpToMsg_fn);
    var $author$project$Effects$graphqlMutation_fn = function (config, _v0) {
        var selector = _v0.a;
        var decoder = _v0.b;
        return $elm$core$List$singleton($author$project$Effects$GraphqlHttpMutation({
            x: config.x,
            N: $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(selector, $elm$json$Json$Decode$value),
            z: A2($elm$core$Basics$composeR, $elm$core$Result$mapError($author$project$Effects$graphqlFailure(decoder)), A2($elm$core$Basics$composeR, $elm$core$Result$andThen($author$project$Effects$graphqlDecode(decoder)), $PaackEng$paack_remotedata$Remote$Response$graphqlHttpToMsg(config.z))),
            o: config.o
        }));
    }, $author$project$Effects$graphqlMutation = F2($author$project$Effects$graphqlMutation_fn);
    var $author$project$Effects$GraphqlHttpQuery = function (a) {
        return { $: 4, a: a };
    };
    var $author$project$Effects$graphqlQuery_fn = function (config, _v0) {
        var selector = _v0.a;
        var decoder = _v0.b;
        return $elm$core$List$singleton($author$project$Effects$GraphqlHttpQuery({
            x: config.x,
            N: $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet_fn(selector, $elm$json$Json$Decode$value),
            z: A2($elm$core$Basics$composeR, $elm$core$Result$mapError($author$project$Effects$graphqlFailure(decoder)), A2($elm$core$Basics$composeR, $elm$core$Result$andThen($author$project$Effects$graphqlDecode(decoder)), $PaackEng$paack_remotedata$Remote$Response$graphqlHttpToMsg(config.z))),
            o: config.o
        }));
    }, $author$project$Effects$graphqlQuery = F2($author$project$Effects$graphqlQuery_fn);
    var $author$project$Maple$Environment$mapleUrl = function (_v0) {
        var url = _v0;
        return url;
    };
    var $author$project$Maple$Client$sendRequestCustom_fn = function (extraHeaders, config) {
        var requestConfig = {
            x: extraHeaders,
            z: config.z,
            o: $author$project$Maple$Environment$mapleUrl(config.bJ)
        };
        var _v0 = config.aJ;
        if (!_v0.$) {
            var query = _v0.a;
            return $author$project$Effects$graphqlQuery_fn(requestConfig, query);
        }
        else {
            var mutation = _v0.a;
            return $author$project$Effects$graphqlMutation_fn(requestConfig, mutation);
        }
    }, $author$project$Maple$Client$sendRequestCustom = F2($author$project$Maple$Client$sendRequestCustom_fn);
    var $author$project$Maple$Client$sendRequest = function (config) {
        return $author$project$Maple$Client$sendRequestCustom_fn(_List_fromArray([
            _Utils_Tuple2("Authorization", "Bearer " + $author$project$Maple$Data$Account$getToken(config.cA))
        ]), $author$project$Maple$Client$UnauthorizedRequestConfig_fn(config.bJ, config.z, config.aJ));
    };
    var $author$project$Maple$Ops$Server$pull_fn = function (selection, responseMsg, environment, params, account) {
        return $author$project$Maple$Client$sendRequest({
            bJ: environment,
            aJ: $author$project$Maple$Ops$Server$operation_fn(selection, params),
            z: responseMsg,
            cA: account
        });
    }, $author$project$Maple$Ops$Server$pull = F5($author$project$Maple$Ops$Server$pull_fn);
    var $author$project$Main$Model$loadServer_fn = function (env, session, cuteId) {
        var searchServer = function (_v4) {
            return $author$project$Effects$none;
        };
        var withServers = F2(function (_v3, servers) {
            var account = _v3.A;
            var _v2 = A2($author$project$Maple$Data$Server$slowGetByCute, cuteId, servers);
            if (!_v2.$) {
                var server = _v2.a;
                return $author$project$Maple$Ops$Server$pull_fn($author$project$Maple$Data$Server$fullSelection, $author$project$Main$Msg$ServerResult, env, { bV: server.bV }, account);
            }
            else {
                return searchServer(account);
            }
        });
        var _v0 = $author$project$Main$Model$getSession(session);
        if (!_v0.$) {
            var successSession = _v0.a;
            var _v1 = successSession.O;
            switch (_v1.$) {
                case 3:
                    var servers = _v1.a;
                    return A2(withServers, successSession, servers);
                case 4:
                    var servers = _v1.a;
                    return A2(withServers, successSession, servers);
                default:
                    return searchServer(successSession.A);
            }
        }
        else {
            return $author$project$Effects$none;
        }
    }, $author$project$Main$Model$loadServer = F3($author$project$Main$Model$loadServer_fn);
    var $author$project$Main$Model$notReloading = { L: false, aK: false };
    var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = { $: 1 };
    var $author$project$Maple$Ops$Dashboard$InvalidSessionError = 0;
    var $author$project$Maple$Internals$Query$PullDashboardRequiredArguments = function (input) {
        return { m: input };
    };
    var $author$project$Maple$Internals$Union$DashboardResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Dashboard", selections____.a5),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidSessionError", selections____.a7)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$bool(value));
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn = function (optionalValue, toValue) {
        switch (optionalValue.$) {
            case 0:
                var value = optionalValue.a;
                return $elm$core$Maybe$Just(toValue(value));
            case 1:
                return $elm$core$Maybe$Nothing;
            default:
                return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
        }
    }, $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn);
    var $author$project$Maple$Internals$InputObject$encodeDashboardInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("keep", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.e, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))
        ]));
    };
    var $author$project$Maple$Internals$Query$pullDashboard_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("pullDashboard", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodeDashboardInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Query$pullDashboard = F2($author$project$Maple$Internals$Query$pullDashboard_fn);
    var $author$project$Maple$Ops$Dashboard$operation = function (selection) {
        return $author$project$Maple$Client$Query($author$project$Maple$Internals$Query$pullDashboard_fn($author$project$Maple$Internals$Query$PullDashboardRequiredArguments({ e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent }), $author$project$Maple$Internals$Union$DashboardResult$fragments({
            a5: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, selection),
            a7: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0))
        })));
    };
    var $author$project$Maple$Ops$Dashboard$pull_fn = function (selection, responseMsg, environment, account) {
        return $author$project$Maple$Client$sendRequest({
            bJ: environment,
            aJ: $author$project$Maple$Ops$Dashboard$operation(selection),
            z: responseMsg,
            cA: account
        });
    }, $author$project$Maple$Ops$Dashboard$pull = F4($author$project$Maple$Ops$Dashboard$pull_fn);
    var $author$project$Maple$Ops$Team$InvalidSessionError = 0;
    var $author$project$Maple$Internals$Query$PullTeamsRequiredArguments = function (input) {
        return { m: input };
    };
    var $author$project$Maple$Internals$Union$TeamsResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Teams", selections____.bj),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidSessionError", selections____.a7)
        ]));
    };
    var $author$project$Maple$Internals$InputObject$encodeTeamsInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("keep", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.e, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))
        ]));
    };
    var $author$project$Maple$Internals$Query$pullTeams_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("pullTeams", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodeTeamsInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Query$pullTeams = F2($author$project$Maple$Internals$Query$pullTeams_fn);
    var $author$project$Maple$Internals$Object$Teams$teams = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("teams", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Ops$Team$pullOperation = function (selection) {
        return $author$project$Maple$Client$Query($author$project$Maple$Internals$Query$pullTeams_fn($author$project$Maple$Internals$Query$PullTeamsRequiredArguments({ e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent }), $author$project$Maple$Internals$Union$TeamsResult$fragments({
            a7: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0)),
            bj: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, $author$project$Maple$Internals$Object$Teams$teams(selection))
        })));
    };
    var $author$project$Maple$Ops$Team$pull_fn = function (selection, responseMsg, environment, account) {
        return $author$project$Maple$Client$sendRequest({
            bJ: environment,
            aJ: $author$project$Maple$Ops$Team$pullOperation(selection),
            z: responseMsg,
            cA: account
        });
    }, $author$project$Maple$Ops$Team$pull = F4($author$project$Maple$Ops$Team$pull_fn);
    var $author$project$Main$Model$viewingFromRoute_fn = function (env, session, route) {
        switch (route.$) {
            case 0:
                return _Utils_Tuple3($author$project$Main$Model$PageDashboard($author$project$Main$Model$DashOverview), function () {
                    var _v1 = $author$project$Main$Model$getSession(session);
                    if (!_v1.$) {
                        var account = _v1.a.A;
                        return $author$project$Maple$Ops$Dashboard$pull_fn($author$project$Maple$Ops$Dashboard$grabServers($author$project$Maple$Data$Server$basicSelection), $author$project$Main$Msg$DashboardResult, env, account);
                    }
                    else {
                        return $author$project$Effects$none;
                    }
                }(), _Utils_update($author$project$Main$Model$notReloading, { L: true }));
            case 1:
                return _Utils_Tuple3($author$project$Main$Model$PageDashboard($author$project$Main$Model$DashNewServer), $author$project$Effects$none, $author$project$Main$Model$notReloading);
            case 2:
                var cuteId = route.a;
                return _Utils_Tuple3($author$project$Main$Model$PageDashboard($author$project$Main$Model$DashServer(cuteId)), $author$project$Main$Model$loadServer_fn(env, session, cuteId), _Utils_update($author$project$Main$Model$notReloading, { L: true }));
            case 3:
                return _Utils_Tuple3($author$project$Main$Model$PageDashboard($author$project$Main$Model$DashTeams), function () {
                    var _v2 = $author$project$Main$Model$getSession(session);
                    if (!_v2.$) {
                        var account = _v2.a.A;
                        return $author$project$Maple$Ops$Team$pull_fn($author$project$Maple$Data$Team$teamSelection, $author$project$Main$Msg$TeamsResult, env, account);
                    }
                    else {
                        return $author$project$Effects$none;
                    }
                }(), _Utils_update($author$project$Main$Model$notReloading, { aK: true }));
            case 4:
                return _Utils_Tuple3($author$project$Main$Model$PageWelcome, $author$project$Effects$none, $author$project$Main$Model$notReloading);
            case 5:
                return _Utils_Tuple3($author$project$Main$Model$PageNotFound, $author$project$Effects$none, $author$project$Main$Model$notReloading);
            default:
                return _Utils_Tuple3($author$project$Main$Model$PageKidnapped, $author$project$Effects$none, $author$project$Main$Model$notReloading);
        }
    }, $author$project$Main$Model$viewingFromRoute = F3($author$project$Main$Model$viewingFromRoute_fn);
    var $author$project$Main$Model$init_fn = function (_v0, url) {
        var apiUrl = _v0.cL;
        var innerWidth = _v0.dw;
        var innerHeight = _v0.dv;
        var expendable = _v0.dh;
        var env = $author$project$Maple$Environment$fromString(apiUrl);
        var _v1 = $author$project$Main$Model$fromStorage($elm$core$Result$withDefault_fn($author$project$Main$Model$expendableDefault, _Json_run_fn($author$project$Main$Model$expendableDecoder, expendable)).ct);
        var cart = _v1.a;
        var session = _v1.b;
        var sidebarOpen = _v1.c;
        var _v2 = $author$project$Main$Model$viewingFromRoute_fn(env, session, $author$project$Pages$Route$fromUrl(url));
        var viewing = _v2.a;
        var viewingEffects = _v2.b;
        return _Utils_Tuple2({
            aX: cart,
            bJ: env,
            dv: innerHeight,
            dw: innerWidth,
            ar: session,
            aN: sidebarOpen,
            cw: $author$project$Main$Model$FormFilling(_Utils_Tuple2("", false)),
            cx: $PedroHLC$elm_uuid_dict$UUID$Dict$empty,
            cB: viewing
        }, viewingEffects);
    }, $author$project$Main$Model$init = F2($author$project$Main$Model$init_fn);
    var $elm$random$Random$initialSeed = function (x) {
        var _v0 = $elm$random$Random$next($elm$random$Random$Seed_fn(0, 1013904223));
        var state1 = _v0.a;
        var incr = _v0.b;
        var state2 = (state1 + x) >>> 0;
        return $elm$random$Random$next($elm$random$Random$Seed_fn(state2, incr));
    };
    var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
    var $author$project$Main$wrappedInit_fn = function (flags, url, key) {
        var firstSeeds = $TSFoster$elm_uuid$UUID$Seeds_fn($elm$random$Random$initialSeed(flags.cb), $elm$random$Random$initialSeed(flags.cc), $elm$random$Random$initialSeed(flags.cd), $elm$random$Random$initialSeed(flags.ce));
        var _v0 = $author$project$Main$Model$init_fn(flags, url);
        var appModel = _v0.a;
        var effects = _v0.b;
        var _v1 = $elm$core$List$foldl_fn(A2($author$project$Main$effectsApplier, key, appModel), _Utils_Tuple2(firstSeeds, $elm$core$Platform$Cmd$none), effects);
        var newSeeds = _v1.a;
        var cmds = _v1.b;
        return _Utils_Tuple2({ R: appModel, a0: key, aM: newSeeds }, cmds);
    }, $author$project$Main$wrappedInit = F3($author$project$Main$wrappedInit_fn);
    var $author$project$Main$Model$LoginOTP_fn = function (a, b, c) {
        return { $: 1, a: a, b: b, c: c };
    }, $author$project$Main$Model$LoginOTP = F3($author$project$Main$Model$LoginOTP_fn);
    var $author$project$Main$Msg$StorageResult = function (a) {
        return { $: 13, a: a };
    };
    var $elm$json$Json$Encode$dict_fn = function (toKey, toValue, dictionary) {
        return _Json_wrap($elm$core$Dict$foldl_fn_unwrapped(function (key, value, obj) {
            return _Json_addField_fn(toKey(key), toValue(value), obj);
        }, _Json_emptyObject(0), dictionary));
    }, $elm$json$Json$Encode$dict = F3($elm$json$Json$Encode$dict_fn);
    var $author$project$Effects$StorageInsert_fn = function (a, b, c) {
        return { $: 10, a: a, b: b, c: c };
    }, $author$project$Effects$StorageInsert = F3($author$project$Effects$StorageInsert_fn);
    var $author$project$Effects$storageInsert_fn = function (msg, key, value) {
        return _List_fromArray([
            $author$project$Effects$StorageInsert_fn(msg, key, value)
        ]);
    }, $author$project$Effects$storageInsert = F3($author$project$Effects$storageInsert_fn);
    var $author$project$Main$Update$cartSave_a0 = A2($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$int), $author$project$Main$Update$cartSave_a1 = A2($elm$core$Basics$composeR, $elm$json$Json$Encode$encode(0), A2($author$project$Effects$storageInsert, $author$project$Main$Msg$StorageResult, "c")), $author$project$Main$Update$cartSave = A2($elm$core$Basics$composeR, $author$project$Main$Update$cartSave_a0, $author$project$Main$Update$cartSave_a1);
    var $author$project$Main$Update$cartInsert_fn = function (game, playerAmount, model) {
        var newCart = $elm$core$Dict$insert_fn(game, playerAmount, model.aX);
        return _Utils_Tuple2(_Utils_update(model, { aX: newCart }), $elm$core$Basics$composeR_fn($author$project$Main$Update$cartSave_a0, $author$project$Main$Update$cartSave_a1, newCart));
    }, $author$project$Main$Update$cartInsert = F3($author$project$Main$Update$cartInsert_fn);
    var $author$project$Main$Update$cartRemove_fn = function (game, model) {
        var newCart = $elm$core$Dict$remove_fn(game, model.aX);
        return _Utils_Tuple2(_Utils_update(model, { aX: newCart }), $elm$core$Basics$composeR_fn($author$project$Main$Update$cartSave_a0, $author$project$Main$Update$cartSave_a1, newCart));
    }, $author$project$Main$Update$cartRemove = F2($author$project$Main$Update$cartRemove_fn);
    var $author$project$Maple$Data$Server$DashboardError = 0;
    var $PaackEng$paack_remotedata$Remote$Response$map_fn = function (applier, response) {
        if (!response.$) {
            var error = response.a;
            return $PaackEng$paack_remotedata$Remote$Response$Failure(error);
        }
        else {
            var object = response.a;
            return $PaackEng$paack_remotedata$Remote$Response$Success(applier(object));
        }
    }, $PaackEng$paack_remotedata$Remote$Response$map = F2($PaackEng$paack_remotedata$Remote$Response$map_fn);
    var $PaackEng$paack_remotedata$Remote$Response$mapCustomError_fn = function (applier, response) {
        if (response.$ === 1) {
            var object = response.a;
            return $PaackEng$paack_remotedata$Remote$Response$Success(object);
        }
        else {
            if (!response.a.$) {
                var customError = response.a.a;
                return $PaackEng$paack_remotedata$Remote$Response$Failure($PaackEng$paack_remotedata$Remote$Errors$Custom(applier(customError)));
            }
            else {
                var transportError = response.a.a;
                return $PaackEng$paack_remotedata$Remote$Response$Failure($PaackEng$paack_remotedata$Remote$Errors$Transport(transportError));
            }
        }
    }, $PaackEng$paack_remotedata$Remote$Response$mapCustomError = F2($PaackEng$paack_remotedata$Remote$Response$mapCustomError_fn);
    var $PaackEng$paack_remotedata$Remote$Recyclable$Failure = function (a) {
        return { $: 2, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage = function (a) {
        return { $: 1, a: a };
    };
    var $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn = function (a, b) {
        return { $: 4, a: a, b: b };
    }, $PaackEng$paack_remotedata$Remote$Recyclable$Recycling = F2($PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn);
    var $PaackEng$paack_remotedata$Remote$Recyclable$mergeResponse_fn = function (response, data) {
        if (response.$ === 1) {
            var object = response.a;
            return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(object);
        }
        else {
            var error = response.a;
            switch (data.$) {
                case 0:
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Failure(error);
                case 2:
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Failure(error);
                case 1:
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Failure(error);
                case 4:
                    var object = data.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(object, $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage(error));
                default:
                    var object = data.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(object, $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage(error));
            }
        }
    }, $PaackEng$paack_remotedata$Remote$Recyclable$mergeResponse = F2($PaackEng$paack_remotedata$Remote$Recyclable$mergeResponse_fn);
    var $author$project$Maple$Data$Server$mergeToModel_fn = function (mapErrors, response) {
        return $PaackEng$paack_remotedata$Remote$Recyclable$mergeResponse($PaackEng$paack_remotedata$Remote$Response$mapCustomError_fn(mapErrors, $PaackEng$paack_remotedata$Remote$Response$map_fn($author$project$Maple$Data$Server$internalToModel, response)));
    }, $author$project$Maple$Data$Server$mergeToModel = F2($author$project$Maple$Data$Server$mergeToModel_fn);
    var $author$project$Maple$Data$Server$itemToStorage = function (_v0) {
        var id = _v0.bV;
        var nickname = _v0.b1;
        return $elm$json$Json$Encode$object(_List_fromArray([
            _Utils_Tuple2("i", $elm$core$Basics$composeR_fn($TSFoster$elm_uuid$UUID$toValue_a0, $TSFoster$elm_uuid$UUID$toValue_a1, id)),
            _Utils_Tuple2("n", $elm$core$Maybe$withDefault_fn($elm$json$Json$Encode$null, $elm$core$Maybe$map_fn($elm$json$Json$Encode$string, nickname)))
        ]));
    };
    var $elm$json$Json$Encode$list_fn = function (func, entries) {
        return _Json_wrap($elm$core$List$foldl_fn(_Json_addEntry(func), _Json_emptyArray(0), entries));
    }, $elm$json$Json$Encode$list = F2($elm$json$Json$Encode$list_fn);
    var $author$project$Maple$Data$Server$toStorage_a0 = $elm$json$Json$Encode$list($author$project$Maple$Data$Server$itemToStorage), $author$project$Maple$Data$Server$toStorage_a1 = $elm$json$Json$Encode$encode(0), $author$project$Maple$Data$Server$toStorage = A2($elm$core$Basics$composeR, $author$project$Maple$Data$Server$toStorage_a0, $author$project$Maple$Data$Server$toStorage_a1);
    var $author$project$Main$Update$dashboardResult_fn = function (response, model) {
        var _v0 = model.ar;
        if (_v0.$ === 3) {
            var session = _v0.a;
            var newServers = A3($author$project$Maple$Data$Server$mergeToModel, function (_v2) {
                return 0;
            }, response, session.O);
            return _Utils_Tuple2(_Utils_update(model, {
                ar: $author$project$Main$Model$LoginSuccess(_Utils_update(session, { O: newServers }))
            }), function () {
                if (!response.$) {
                    return $author$project$Effects$none;
                }
                else {
                    var servers = response.a;
                    return $author$project$Effects$storageInsert_fn($author$project$Main$Msg$StorageResult, "s", $elm$core$Basics$composeR_fn($author$project$Maple$Data$Server$toStorage_a0, $author$project$Maple$Data$Server$toStorage_a1, servers));
                }
            }());
        }
        else {
            return _Utils_Tuple2(model, $author$project$Effects$none);
        }
    }, $author$project$Main$Update$dashboardResult = F2($author$project$Main$Update$dashboardResult_fn);
    var $author$project$Utils$Email$EmailAddress_fn = function (local, domain) {
        return { bG: domain, b_: local };
    }, $author$project$Utils$Email$EmailAddress = F2($author$project$Utils$Email$EmailAddress_fn);
    var $elm$parser$Parser$Advanced$Bad_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $elm$parser$Parser$Advanced$Bad = F2($elm$parser$Parser$Advanced$Bad_fn);
    var $elm$parser$Parser$Advanced$Good_fn = function (a, b, c) {
        return { $: 0, a: a, b: b, c: c };
    }, $elm$parser$Parser$Advanced$Good = F3($elm$parser$Parser$Advanced$Good_fn);
    var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
    var $elm$parser$Parser$Advanced$andThen_fn = function (callback, _v0) {
        var parseA = _v0;
        return function (s0) {
            var _v1 = parseA(s0);
            if (_v1.$ === 1) {
                var p = _v1.a;
                var x = _v1.b;
                return $elm$parser$Parser$Advanced$Bad_fn(p, x);
            }
            else {
                var p1 = _v1.a;
                var a = _v1.b;
                var s1 = _v1.c;
                var _v2 = callback(a);
                var parseB = _v2;
                var _v3 = parseB(s1);
                if (_v3.$ === 1) {
                    var p2 = _v3.a;
                    var x = _v3.b;
                    return $elm$parser$Parser$Advanced$Bad_fn(p1 || p2, x);
                }
                else {
                    var p2 = _v3.a;
                    var b = _v3.b;
                    var s2 = _v3.c;
                    return $elm$parser$Parser$Advanced$Good_fn(p1 || p2, b, s2);
                }
            }
        };
    }, $elm$parser$Parser$Advanced$andThen = F2($elm$parser$Parser$Advanced$andThen_fn);
    var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
    var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
    var $elm$parser$Parser$Advanced$chompWhileHelp_fn = function (isGood, offset, row, col, s0) {
        chompWhileHelp: while (true) {
            var newOffset = _Parser_isSubChar_fn(isGood, offset, s0.a);
            if (newOffset === -1) {
                return $elm$parser$Parser$Advanced$Good_fn(_Utils_cmp(s0.b, offset) < 0, 0, { bC: col, c: s0.c, d: s0.d, b: offset, cj: row, a: s0.a });
            }
            else {
                if (newOffset === -2) {
                    var $temp$isGood = isGood, $temp$offset = offset + 1, $temp$row = row + 1, $temp$col = 1, $temp$s0 = s0;
                    isGood = $temp$isGood;
                    offset = $temp$offset;
                    row = $temp$row;
                    col = $temp$col;
                    s0 = $temp$s0;
                    continue chompWhileHelp;
                }
                else {
                    var $temp$isGood = isGood, $temp$offset = newOffset, $temp$row = row, $temp$col = col + 1, $temp$s0 = s0;
                    isGood = $temp$isGood;
                    offset = $temp$offset;
                    row = $temp$row;
                    col = $temp$col;
                    s0 = $temp$s0;
                    continue chompWhileHelp;
                }
            }
        }
    }, $elm$parser$Parser$Advanced$chompWhileHelp = F5($elm$parser$Parser$Advanced$chompWhileHelp_fn);
    var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
        return function (s) {
            return $elm$parser$Parser$Advanced$chompWhileHelp_fn(isGood, s.b, s.cj, s.bC, s);
        };
    };
    var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
    var $elm$parser$Parser$Advanced$mapChompedString_fn = function (func, _v0) {
        var parse = _v0;
        return function (s0) {
            var _v1 = parse(s0);
            if (_v1.$ === 1) {
                var p = _v1.a;
                var x = _v1.b;
                return $elm$parser$Parser$Advanced$Bad_fn(p, x);
            }
            else {
                var p = _v1.a;
                var a = _v1.b;
                var s1 = _v1.c;
                return $elm$parser$Parser$Advanced$Good_fn(p, A2(func, _String_slice_fn(s0.b, s1.b, s0.a), a), s1);
            }
        };
    }, $elm$parser$Parser$Advanced$mapChompedString_fn_unwrapped = function (func, _v0) {
        var parse = _v0;
        return function (s0) {
            var _v1 = parse(s0);
            if (_v1.$ === 1) {
                var p = _v1.a;
                var x = _v1.b;
                return $elm$parser$Parser$Advanced$Bad_fn(p, x);
            }
            else {
                var p = _v1.a;
                var a = _v1.b;
                var s1 = _v1.c;
                return $elm$parser$Parser$Advanced$Good_fn(p, func(_String_slice_fn(s0.b, s1.b, s0.a), a), s1);
            }
        };
    }, $elm$parser$Parser$Advanced$mapChompedString = F2($elm$parser$Parser$Advanced$mapChompedString_fn);
    var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
        return $elm$parser$Parser$Advanced$mapChompedString_fn($elm$core$Basics$always, parser);
    };
    var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
    var $author$project$Utils$Email$invalidSection = function (s) {
        return $elm$core$String$isEmpty(s) || ($elm$core$String$length(s) > 62);
    };
    var $elm$parser$Parser$Problem = function (a) {
        return { $: 12, a: a };
    };
    var $elm$parser$Parser$Advanced$AddRight_fn = function (a, b) {
        return { $: 1, a: a, b: b };
    }, $elm$parser$Parser$Advanced$AddRight = F2($elm$parser$Parser$Advanced$AddRight_fn);
    var $elm$parser$Parser$Advanced$DeadEnd_fn = function (row, col, problem, contextStack) {
        return { bC: col, c4: contextStack, b6: problem, cj: row };
    }, $elm$parser$Parser$Advanced$DeadEnd = F4($elm$parser$Parser$Advanced$DeadEnd_fn);
    var $elm$parser$Parser$Advanced$Empty = { $: 0 };
    var $elm$parser$Parser$Advanced$fromState_fn = function (s, x) {
        return $elm$parser$Parser$Advanced$AddRight_fn($elm$parser$Parser$Advanced$Empty, $elm$parser$Parser$Advanced$DeadEnd_fn(s.cj, s.bC, x, s.c));
    }, $elm$parser$Parser$Advanced$fromState = F2($elm$parser$Parser$Advanced$fromState_fn);
    var $elm$parser$Parser$Advanced$problem = function (x) {
        return function (s) {
            return $elm$parser$Parser$Advanced$Bad_fn(false, $elm$parser$Parser$Advanced$fromState_fn(s, x));
        };
    };
    var $elm$parser$Parser$problem = function (msg) {
        return $elm$parser$Parser$Advanced$problem($elm$parser$Parser$Problem(msg));
    };
    var $elm$parser$Parser$Advanced$succeed = function (a) {
        return function (s) {
            return $elm$parser$Parser$Advanced$Good_fn(false, a, s);
        };
    };
    var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
    var $author$project$Utils$Email$domainPart = function () {
        var checkLen = function (s) {
            if ($elm$core$String$isEmpty(s)) {
                return $elm$parser$Parser$problem("domain is empty");
            }
            else {
                var sections = $elm$core$String$split_fn(".", s);
                return ($elm$core$List$length(sections) === 1) ? $elm$parser$Parser$problem("domain is not valid") : ($elm$core$List$any_fn($author$project$Utils$Email$invalidSection, sections) ? $elm$parser$Parser$problem("one or more domain's sections' are not 1-62 characters") : $elm$parser$Parser$succeed(s));
            }
        };
        return $elm$parser$Parser$Advanced$andThen_fn(checkLen, $elm$parser$Parser$getChompedString($elm$parser$Parser$chompWhile(function (c) {
            return $elm$core$Char$isAlphaNum(c) || ((c === "-") || (c === "."));
        })));
    }();
    var $elm$parser$Parser$ExpectingEnd = { $: 10 };
    var $elm$parser$Parser$Advanced$end = function (x) {
        return function (s) {
            return _Utils_eq($elm$core$String$length(s.a), s.b) ? $elm$parser$Parser$Advanced$Good_fn(false, 0, s) : $elm$parser$Parser$Advanced$Bad_fn(false, $elm$parser$Parser$Advanced$fromState_fn(s, x));
        };
    };
    var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
    var $elm$parser$Parser$Advanced$map2_fn = function (func, _v0, _v1) {
        var parseA = _v0;
        var parseB = _v1;
        return function (s0) {
            var _v2 = parseA(s0);
            if (_v2.$ === 1) {
                var p = _v2.a;
                var x = _v2.b;
                return $elm$parser$Parser$Advanced$Bad_fn(p, x);
            }
            else {
                var p1 = _v2.a;
                var a = _v2.b;
                var s1 = _v2.c;
                var _v3 = parseB(s1);
                if (_v3.$ === 1) {
                    var p2 = _v3.a;
                    var x = _v3.b;
                    return $elm$parser$Parser$Advanced$Bad_fn(p1 || p2, x);
                }
                else {
                    var p2 = _v3.a;
                    var b = _v3.b;
                    var s2 = _v3.c;
                    return $elm$parser$Parser$Advanced$Good_fn(p1 || p2, A2(func, a, b), s2);
                }
            }
        };
    }, $elm$parser$Parser$Advanced$map2_fn_unwrapped = function (func, _v0, _v1) {
        var parseA = _v0;
        var parseB = _v1;
        return function (s0) {
            var _v2 = parseA(s0);
            if (_v2.$ === 1) {
                var p = _v2.a;
                var x = _v2.b;
                return $elm$parser$Parser$Advanced$Bad_fn(p, x);
            }
            else {
                var p1 = _v2.a;
                var a = _v2.b;
                var s1 = _v2.c;
                var _v3 = parseB(s1);
                if (_v3.$ === 1) {
                    var p2 = _v3.a;
                    var x = _v3.b;
                    return $elm$parser$Parser$Advanced$Bad_fn(p1 || p2, x);
                }
                else {
                    var p2 = _v3.a;
                    var b = _v3.b;
                    var s2 = _v3.c;
                    return $elm$parser$Parser$Advanced$Good_fn(p1 || p2, func(a, b), s2);
                }
            }
        };
    }, $elm$parser$Parser$Advanced$map2 = F3($elm$parser$Parser$Advanced$map2_fn);
    var $elm$parser$Parser$Advanced$ignorer_fn = function (keepParser, ignoreParser) {
        return $elm$parser$Parser$Advanced$map2_fn($elm$core$Basics$always, keepParser, ignoreParser);
    }, $elm$parser$Parser$Advanced$ignorer = F2($elm$parser$Parser$Advanced$ignorer_fn);
    var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
    var $elm$parser$Parser$Advanced$keeper_fn = function (parseFunc, parseArg) {
        return $elm$parser$Parser$Advanced$map2_fn($elm$core$Basics$apL, parseFunc, parseArg);
    }, $elm$parser$Parser$Advanced$keeper = F2($elm$parser$Parser$Advanced$keeper_fn);
    var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
    var $author$project$Utils$Email$checkLocal = function (str) {
        var isLocalChar = function (c) {
            return $elm$core$Char$isAlphaNum(c) || ((c === ".") || ((c === "!") || ((c === "#") || ((c === "$") || ((c === "%") || ((c === "&") || ((c === "'") || ((c === "*") || ((c === "+") || ((c === "=") || ((c === "?") || ((c === "^") || ((c === "_") || ((c === "`") || ((c === "{") || ((c === "|") || ((c === "}") || ((c === "~") || (c === "-")))))))))))))))))));
        };
        return $elm$core$String$isEmpty(str) ? $elm$parser$Parser$problem("local part is empty") : (_String_foldl_fn_unwrapped(function (c, acc) {
            return acc && isLocalChar(c);
        }, true, str) ? $elm$parser$Parser$succeed(str) : $elm$parser$Parser$problem("local part contains invalid characters"));
    };
    var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
    var $elm$parser$Parser$Advanced$fromInfo_fn = function (row, col, x, context) {
        return $elm$parser$Parser$Advanced$AddRight_fn($elm$parser$Parser$Advanced$Empty, $elm$parser$Parser$Advanced$DeadEnd_fn(row, col, x, context));
    }, $elm$parser$Parser$Advanced$fromInfo = F4($elm$parser$Parser$Advanced$fromInfo_fn);
    var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
        var str = _v0.a;
        var expecting = _v0.b;
        return function (s) {
            var _v1 = _Parser_findSubString_fn(str, s.b, s.cj, s.bC, s.a);
            var newOffset = _v1.a;
            var newRow = _v1.b;
            var newCol = _v1.c;
            return newOffset === -1 ? $elm$parser$Parser$Advanced$Bad_fn(false, $elm$parser$Parser$Advanced$fromInfo_fn(newRow, newCol, expecting, s.c)) : $elm$parser$Parser$Advanced$Good_fn(_Utils_cmp(s.b, newOffset) < 0, 0, { bC: newCol, c: s.c, d: s.d, b: newOffset, cj: newRow, a: s.a });
        };
    };
    var $elm$parser$Parser$Expecting = function (a) {
        return { $: 0, a: a };
    };
    var $elm$parser$Parser$Advanced$Token_fn = function (a, b) {
        return { $: 0, a: a, b: b };
    }, $elm$parser$Parser$Advanced$Token = F2($elm$parser$Parser$Advanced$Token_fn);
    var $elm$parser$Parser$toToken = function (str) {
        return $elm$parser$Parser$Advanced$Token_fn(str, $elm$parser$Parser$Expecting(str));
    };
    var $elm$parser$Parser$chompUntil = function (str) {
        return $elm$parser$Parser$Advanced$chompUntil($elm$parser$Parser$toToken(str));
    };
    var $author$project$Utils$Email$localPart = $elm$parser$Parser$Advanced$andThen_fn($author$project$Utils$Email$checkLocal, $elm$parser$Parser$getChompedString($elm$parser$Parser$chompUntil("@")));
    var $elm$parser$Parser$ExpectingSymbol = function (a) {
        return { $: 8, a: a };
    };
    var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
    var $elm$parser$Parser$Advanced$token = function (_v0) {
        var str = _v0.a;
        var expecting = _v0.b;
        var progress = !$elm$core$String$isEmpty(str);
        return function (s) {
            var _v1 = _Parser_isSubString_fn(str, s.b, s.cj, s.bC, s.a);
            var newOffset = _v1.a;
            var newRow = _v1.b;
            var newCol = _v1.c;
            return newOffset === -1 ? $elm$parser$Parser$Advanced$Bad_fn(false, $elm$parser$Parser$Advanced$fromState_fn(s, expecting)) : $elm$parser$Parser$Advanced$Good_fn(progress, 0, { bC: newCol, c: s.c, d: s.d, b: newOffset, cj: newRow, a: s.a });
        };
    };
    var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
    var $elm$parser$Parser$symbol = function (str) {
        return $elm$parser$Parser$Advanced$symbol($elm$parser$Parser$Advanced$Token_fn(str, $elm$parser$Parser$ExpectingSymbol(str)));
    };
    var $author$project$Utils$Email$emailParser = $elm$parser$Parser$Advanced$keeper_fn($elm$parser$Parser$Advanced$keeper_fn($elm$parser$Parser$succeed($author$project$Utils$Email$EmailAddress), $elm$parser$Parser$Advanced$ignorer_fn($author$project$Utils$Email$localPart, $elm$parser$Parser$symbol("@"))), $elm$parser$Parser$Advanced$ignorer_fn($author$project$Utils$Email$domainPart, $elm$parser$Parser$end));
    var $elm$parser$Parser$DeadEnd_fn = function (row, col, problem) {
        return { bC: col, b6: problem, cj: row };
    }, $elm$parser$Parser$DeadEnd = F3($elm$parser$Parser$DeadEnd_fn);
    var $elm$parser$Parser$problemToDeadEnd = function (p) {
        return $elm$parser$Parser$DeadEnd_fn(p.cj, p.bC, p.b6);
    };
    var $elm$parser$Parser$Advanced$bagToList_fn = function (bag, list) {
        bagToList: while (true) {
            switch (bag.$) {
                case 0:
                    return list;
                case 1:
                    var bag1 = bag.a;
                    var x = bag.b;
                    var $temp$bag = bag1, $temp$list = _List_Cons(x, list);
                    bag = $temp$bag;
                    list = $temp$list;
                    continue bagToList;
                default:
                    var bag1 = bag.a;
                    var bag2 = bag.b;
                    var $temp$bag = bag1, $temp$list = $elm$parser$Parser$Advanced$bagToList_fn(bag2, list);
                    bag = $temp$bag;
                    list = $temp$list;
                    continue bagToList;
            }
        }
    }, $elm$parser$Parser$Advanced$bagToList = F2($elm$parser$Parser$Advanced$bagToList_fn);
    var $elm$parser$Parser$Advanced$run_fn = function (_v0, src) {
        var parse = _v0;
        var _v1 = parse({ bC: 1, c: _List_Nil, d: 1, b: 0, cj: 1, a: src });
        if (!_v1.$) {
            var value = _v1.b;
            return $elm$core$Result$Ok(value);
        }
        else {
            var bag = _v1.b;
            return $elm$core$Result$Err($elm$parser$Parser$Advanced$bagToList_fn(bag, _List_Nil));
        }
    }, $elm$parser$Parser$Advanced$run = F2($elm$parser$Parser$Advanced$run_fn);
    var $elm$parser$Parser$run_fn = function (parser, source) {
        var _v0 = $elm$parser$Parser$Advanced$run_fn(parser, source);
        if (!_v0.$) {
            var a = _v0.a;
            return $elm$core$Result$Ok(a);
        }
        else {
            var problems = _v0.a;
            return $elm$core$Result$Err($elm$core$List$map_fn($elm$parser$Parser$problemToDeadEnd, problems));
        }
    }, $elm$parser$Parser$run = F2($elm$parser$Parser$run_fn);
    var $author$project$Utils$Email$isValid = function (s) {
        return $elm$core$Result$withDefault_fn(false, $elm$core$Result$map_fn($elm$core$Basics$always(true), $elm$parser$Parser$run_fn($author$project$Utils$Email$emailParser, s)));
    };
    var $elm$core$String$trim = _String_trim;
    var $author$project$Main$Update$isValidName = function (newValue) {
        return ($elm$core$String$length(newValue) > 2) && _Utils_eq($elm$core$String$trim(newValue), newValue);
    };
    var $author$project$Main$Update$isValidOTP = function (value) {
        var chars = $elm$core$String$toList(value);
        return ($elm$core$List$length(chars) === 8) && $elm$core$List$all_fn($elm$core$Char$isAlphaNum, chars);
    };
    var $author$project$Main$Msg$LoginResult_fn = function (a, b) {
        return { $: 8, a: a, b: b };
    }, $author$project$Main$Msg$LoginResult = F2($author$project$Main$Msg$LoginResult_fn);
    var $author$project$Main$Model$LoginSending = { $: 4 };
    var $author$project$Maple$Internals$Object$LoggedIn$account = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("account", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Internals$Object$Account$email = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("String", "email", _List_Nil, $elm$json$Json$Decode$string);
    var $author$project$Maple$Internals$Object$Account$id = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Uuid", "id", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).C.da);
    var $author$project$Maple$Internals$Object$Account$nickname = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("String", "nickname", _List_Nil, $elm$json$Json$Decode$string);
    var $author$project$Maple$Data$Account$selection = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Account$nickname, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Account$email, $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$Account$id, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(F4(function (id, email, nickname, token) {
        return $author$project$Maple$Data$Account$Account_fn(token, id, email, nickname);
    })))));
    var $author$project$Maple$Internals$Object$LoggedIn$servers = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("servers", _List_Nil, object____, A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
    };
    var $author$project$Maple$Internals$Object$LoggedIn$token = $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField_fn("Maple.ScalarCodecs.Token", "token", _List_Nil, $author$project$Maple$Internals$Scalar$unwrapCodecs($author$project$Maple$ScalarCodecs$codecs).c$.da);
    var $author$project$Main$Update$accountSet = $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$LoggedIn$servers($author$project$Maple$Data$Server$basicSelection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$LoggedIn$account($author$project$Maple$Data$Account$selection), $dillonkearns$elm_graphql$Graphql$SelectionSet$with_fn($author$project$Maple$Internals$Object$LoggedIn$token, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(F3(function (token, account, servers) {
        return _Utils_Tuple2(account(token), servers);
    })))));
    var $author$project$Maple$Ops$Auth$InvalidCredentialsError = 3;
    var $author$project$Maple$Client$Mutation = function (a) {
        return { $: 1, a: a };
    };
    var $author$project$Maple$Ops$Auth$Require2FA = 1;
    var $author$project$Maple$Ops$Auth$RequireReset = 2;
    var $author$project$Maple$Ops$Auth$RequireSignUp = 0;
    var $author$project$Maple$Internals$Union$AuthResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("LoggedIn", selections____.a9),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("RequireSignUp", selections____.bd),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("Require2FA", selections____.bb),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("RequireReset", selections____.bc),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidCredentialsError", selections____.a6)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$string(value));
    };
    var $author$project$Maple$Internals$InputObject$encodeAuthInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("code", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.c_, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
            _Utils_Tuple2("email", $elm$core$Maybe$Just($author$project$Maple$Internals$Scalar$unwrapEncoder_fn(function ($) {
                return $.bA;
            }, $author$project$Maple$ScalarCodecs$codecs)(input____.bH))),
            _Utils_Tuple2("password", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.d8, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
        ]));
    };
    var $author$project$Maple$Internals$Mutation$pushAuth_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("pushAuth", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodeAuthInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Mutation$pushAuth = F2($author$project$Maple$Internals$Mutation$pushAuth_fn);
    var $author$project$Maple$Internals$Mutation$PushAuthRequiredArguments = function (input) {
        return { m: input };
    };
    var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Null = { $: 2 };
    var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
        return { $: 0, a: a };
    };
    var $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybeWithNull = function (maybeValue) {
        if (!maybeValue.$) {
            var value = maybeValue.a;
            return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
        }
        else {
            return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Null;
        }
    };
    var $author$project$Maple$Ops$Auth$pushInput = function (params) {
        return $author$project$Maple$Internals$Mutation$PushAuthRequiredArguments({
            c_: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybeWithNull(params.c_),
            bH: params.bH,
            d8: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybeWithNull(params.d8)
        });
    };
    var $author$project$Maple$Ops$Auth$pushOperation_fn = function (selection, params) {
        return $author$project$Maple$Client$Mutation($author$project$Maple$Internals$Mutation$pushAuth_fn($author$project$Maple$Ops$Auth$pushInput(params), $author$project$Maple$Internals$Union$AuthResult$fragments({
            a6: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(3)),
            a9: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, selection),
            bb: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(1)),
            bc: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(2)),
            bd: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0))
        })));
    }, $author$project$Maple$Ops$Auth$pushOperation = F2($author$project$Maple$Ops$Auth$pushOperation_fn);
    var $author$project$Maple$Client$sendUnauthorizedRequest = function (config) {
        return $author$project$Maple$Client$sendRequestCustom_fn(_List_Nil, config);
    };
    var $author$project$Maple$Ops$Auth$push_fn = function (selection, responseMsg, environment, params) {
        return $author$project$Maple$Client$sendUnauthorizedRequest({
            bJ: environment,
            aJ: $author$project$Maple$Ops$Auth$pushOperation_fn(selection, params),
            z: responseMsg(params.bH)
        });
    }, $author$project$Maple$Ops$Auth$push = F4($author$project$Maple$Ops$Auth$push_fn);
    var $author$project$Main$Update$login_fn = function (email, code, model) {
        return _Utils_Tuple2(_Utils_update(model, { ar: $author$project$Main$Model$LoginSending }), $author$project$Maple$Ops$Auth$push_fn($author$project$Main$Update$accountSet, $author$project$Main$Msg$LoginResult, model.bJ, { c_: code, bH: email, d8: $elm$core$Maybe$Nothing }));
    }, $author$project$Main$Update$login = F3($author$project$Main$Update$login_fn);
    var $author$project$Main$Model$LoginFailed = function (a) {
        return { $: 2, a: a };
    };
    var $author$project$Effects$batch = $elm$core$List$concat;
    var $author$project$Effects$StorageRemove_fn = function (a, b) {
        return { $: 11, a: a, b: b };
    }, $author$project$Effects$StorageRemove = F2($author$project$Effects$StorageRemove_fn);
    var $author$project$Effects$storageRemove_fn = function (msg, key) {
        return _List_fromArray([
            $author$project$Effects$StorageRemove_fn(msg, key)
        ]);
    }, $author$project$Effects$storageRemove = F2($author$project$Effects$storageRemove_fn);
    var $author$project$Maple$Data$Account$toStorage = function (_v0) {
        var token = _v0.bq;
        var id = _v0.bV;
        var email = _v0.bH;
        var nickname = _v0.b1;
        return _Json_encode_fn(0, $elm$json$Json$Encode$object(_List_fromArray([
            _Utils_Tuple2("t", $elm$json$Json$Encode$string(token)),
            _Utils_Tuple2("i", $elm$core$Basics$composeR_fn($TSFoster$elm_uuid$UUID$toValue_a0, $TSFoster$elm_uuid$UUID$toValue_a1, id)),
            _Utils_Tuple2("e", $elm$json$Json$Encode$string(email)),
            _Utils_Tuple2("n", $elm$json$Json$Encode$string(nickname))
        ])));
    };
    var $author$project$Main$Update$loginResult_fn = function (email, response, model) {
        var _v0 = function () {
            _v1$3: while (true) {
                if (!response.$) {
                    if (!response.a.$) {
                        switch (response.a.a) {
                            case 0:
                                var _v2 = response.a.a;
                                return _Utils_Tuple2($author$project$Main$Model$LoginOTP_fn(email, "", false), $elm$core$Result$Err(0));
                            case 1:
                                var _v3 = response.a.a;
                                return _Utils_Tuple2($author$project$Main$Model$LoginOTP_fn(email, "", false), $elm$core$Result$Err(0));
                            case 2:
                                var _v4 = response.a.a;
                                return _Utils_Tuple2($author$project$Main$Model$LoginOTP_fn(email, "", false), $elm$core$Result$Err(0));
                            default:
                                break _v1$3;
                        }
                    }
                    else {
                        break _v1$3;
                    }
                }
                else {
                    var data = response.a;
                    return _Utils_Tuple2($author$project$Main$Model$LoginSuccess({
                        A: data.a,
                        O: $elm$core$Basics$composeR_fn($author$project$Maple$Data$Server$toModel_a0, $author$project$Maple$Data$Server$toModel_a1, data.b),
                        aa: $PaackEng$paack_remotedata$Remote$Recyclable$NeverAsked
                    }), $elm$core$Result$Ok(data));
                }
            }
            var fullError = response.a;
            return _Utils_Tuple2($author$project$Main$Model$LoginFailed(fullError), $elm$core$Result$Err(0));
        }();
        var newSession = _v0.a;
        var effectsData = _v0.b;
        return _Utils_Tuple2(_Utils_update(model, { ar: newSession }), function () {
            if (!effectsData.$) {
                var _v6 = effectsData.a;
                var account = _v6.a;
                var servers = _v6.b;
                return $author$project$Effects$batch(_List_fromArray([
                    $author$project$Effects$storageInsert_fn($author$project$Main$Msg$StorageResult, "a", $author$project$Maple$Data$Account$toStorage(account)),
                    $author$project$Effects$storageInsert_fn($author$project$Main$Msg$StorageResult, "s", $elm$core$Basics$composeR_fn($author$project$Maple$Data$Server$toStorage_a0, $author$project$Maple$Data$Server$toStorage_a1, servers))
                ]));
            }
            else {
                return $author$project$Effects$batch(_List_fromArray([
                    $author$project$Effects$storageRemove_fn($author$project$Main$Msg$StorageResult, "a"),
                    $author$project$Effects$storageRemove_fn($author$project$Main$Msg$StorageResult, "s")
                ]));
            }
        }());
    }, $author$project$Main$Update$loginResult = F3($author$project$Main$Update$loginResult_fn);
    var $author$project$Main$Msg$LogoutResult = function (a) {
        return { $: 10, a: a };
    };
    var $author$project$Main$Model$PageLeaving = function (a) {
        return { $: 4, a: a };
    };
    var $author$project$Main$Model$getAccount_a0 = $author$project$Main$Model$getSession, $author$project$Main$Model$getAccount_a1 = $elm$core$Maybe$map(function ($) {
        return $.A;
    }), $author$project$Main$Model$getAccount = A2($elm$core$Basics$composeR, $author$project$Main$Model$getAccount_a0, $author$project$Main$Model$getAccount_a1);
    var $author$project$Maple$Internals$Mutation$PurgeAuthRequiredArguments = function (input) {
        return { m: input };
    };
    var $author$project$Maple$Internals$Union$PurgeAuthResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("PurgeAuthOk", selections____.ba),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidSessionError", selections____.a7)
        ]));
    };
    var $author$project$Maple$Internals$InputObject$encodePurgeAuthInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("keep", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.e, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))
        ]));
    };
    var $author$project$Maple$Internals$Mutation$purgeAuth_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("purgeAuth", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodePurgeAuthInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Mutation$purgeAuth = F2($author$project$Maple$Internals$Mutation$purgeAuth_fn);
    var $author$project$Maple$Ops$Auth$Purge$purgeOperation = $author$project$Maple$Client$Mutation($author$project$Maple$Internals$Mutation$purgeAuth_fn($author$project$Maple$Internals$Mutation$PurgeAuthRequiredArguments({ e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent }), $author$project$Maple$Internals$Union$PurgeAuthResult$fragments({
        a7: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0)),
        ba: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(0))
    })));
    var $author$project$Maple$Ops$Auth$Purge$purge_fn = function (responseMsg, environment, account) {
        return $author$project$Maple$Client$sendRequest({ bJ: environment, aJ: $author$project$Maple$Ops$Auth$Purge$purgeOperation, z: responseMsg, cA: account });
    }, $author$project$Maple$Ops$Auth$Purge$purge = F3($author$project$Maple$Ops$Auth$Purge$purge_fn);
    var $author$project$Main$Update$logout = function (model) {
        var _v0 = $elm$core$Basics$composeR_fn($author$project$Main$Model$getAccount_a0, $author$project$Main$Model$getAccount_a1, model.ar);
        if (!_v0.$) {
            var account = _v0.a;
            return _Utils_Tuple2(_Utils_update(model, {
                cB: (!_Utils_eq(model.cB, $author$project$Main$Model$PageWelcome)) ? $author$project$Main$Model$PageLeaving(false) : model.cB
            }), $author$project$Maple$Ops$Auth$Purge$purge_fn($author$project$Main$Msg$LogoutResult, model.bJ, account));
        }
        else {
            return _Utils_Tuple2(_Utils_update(model, {
                ar: $author$project$Main$Model$LoginEmail_fn("", false),
                cB: $author$project$Main$Model$PageWelcome
            }), $author$project$Effects$none);
        }
    };
    var $author$project$Pages$Route$toUrl = function (route) {
        switch (route.$) {
            case 0:
                return "/staging/dashboard";
            case 1:
                return "/staging/dashboard/create";
            case 2:
                var name = route.a;
                return "/staging/dashboard/server/" + $elm$url$Url$percentEncode(name);
            case 3:
                return "/staging/dashboard/teams";
            case 4:
                return "/staging";
            case 5:
                return "/staging/404.html";
            default:
                return "/";
        }
    };
    var $author$project$Effects$UrlPush = function (a) {
        return { $: 14, a: a };
    };
    var $author$project$Effects$urlPush = function (url) {
        return _List_fromArray([
            $author$project$Effects$UrlPush(url)
        ]);
    };
    var $author$project$Main$Update$pageChange_fn = function (route, model) {
        return _Utils_Tuple2(model, $author$project$Effects$urlPush($author$project$Pages$Route$toUrl(route)));
    }, $author$project$Main$Update$pageChange = F2($author$project$Main$Update$pageChange_fn);
    var $author$project$Main$Update$logoutResult_fn = function (response, model) {
        if (!response.$) {
            return _Utils_Tuple2(_Utils_update(model, {
                cB: (!_Utils_eq($elm$core$Basics$composeR_fn($author$project$Main$Model$getAccount_a0, $author$project$Main$Model$getAccount_a1, model.ar), $elm$core$Maybe$Nothing)) ? $author$project$Main$Model$PageLeaving(true) : model.cB
            }), $author$project$Effects$none);
        }
        else {
            return $elm$core$Tuple$mapSecond_fn(function (effect) {
                return $author$project$Effects$batch(_List_fromArray([
                    $author$project$Effects$storageRemove_fn($author$project$Main$Msg$StorageResult, "a"),
                    $author$project$Effects$storageRemove_fn($author$project$Main$Msg$StorageResult, "s"),
                    effect
                ]));
            }, (_Utils_eq(model.cB, $author$project$Main$Model$PageWelcome) ? function (m) {
                return _Utils_Tuple2(m, $author$project$Effects$none);
            } : $author$project$Main$Update$pageChange($author$project$Pages$Route$GoingHome))(_Utils_update(model, {
                ar: $author$project$Main$Model$LoginEmail_fn("", false)
            })));
        }
    }, $author$project$Main$Update$logoutResult = F2($author$project$Main$Update$logoutResult_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$getMin = function (dict) {
        getMin: while (true) {
            if ((!dict.$) && (!dict.d.$)) {
                var left = dict.d;
                var $temp$dict = left;
                dict = $temp$dict;
                continue getMin;
            }
            else {
                return dict;
            }
        }
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$moveRedLeft = function (dict) {
        if (((!dict.$) && (!dict.d.$)) && (!dict.e.$)) {
            if ((!dict.e.d.$) && (!dict.e.d.a)) {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v1 = dict.d;
                var lClr = _v1.a;
                var lK = _v1.b;
                var lV = _v1.c;
                var lLeft = _v1.d;
                var lRight = _v1.e;
                var _v2 = dict.e;
                var rClr = _v2.a;
                var rK = _v2.b;
                var rV = _v2.c;
                var rLeft = _v2.d;
                var _v3 = rLeft.a;
                var rlK = rLeft.b;
                var rlV = rLeft.c;
                var rlL = rLeft.d;
                var rlR = rLeft.e;
                var rRight = _v2.e;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, rlK, rlV, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), rlL), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, rK, rV, rlR, rRight));
            }
            else {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v4 = dict.d;
                var lClr = _v4.a;
                var lK = _v4.b;
                var lV = _v4.c;
                var lLeft = _v4.d;
                var lRight = _v4.e;
                var _v5 = dict.e;
                var rClr = _v5.a;
                var rK = _v5.b;
                var rV = _v5.c;
                var rLeft = _v5.d;
                var rRight = _v5.e;
                if (clr === 1) {
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
                else {
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
            }
        }
        else {
            return dict;
        }
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$moveRedRight = function (dict) {
        if (((!dict.$) && (!dict.d.$)) && (!dict.e.$)) {
            if ((!dict.d.d.$) && (!dict.d.d.a)) {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v1 = dict.d;
                var lClr = _v1.a;
                var lK = _v1.b;
                var lV = _v1.c;
                var _v2 = _v1.d;
                var _v3 = _v2.a;
                var llK = _v2.b;
                var llV = _v2.c;
                var llLeft = _v2.d;
                var llRight = _v2.e;
                var lRight = _v1.e;
                var _v4 = dict.e;
                var rClr = _v4.a;
                var rK = _v4.b;
                var rV = _v4.c;
                var rLeft = _v4.d;
                var rRight = _v4.e;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, llK, llV, llLeft, llRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, lRight, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight)));
            }
            else {
                var clr = dict.a;
                var k = dict.b;
                var v = dict.c;
                var _v5 = dict.d;
                var lClr = _v5.a;
                var lK = _v5.b;
                var lV = _v5.c;
                var lLeft = _v5.d;
                var lRight = _v5.e;
                var _v6 = dict.e;
                var rClr = _v6.a;
                var rK = _v6.b;
                var rV = _v6.c;
                var rLeft = _v6.d;
                var rRight = _v6.e;
                if (clr === 1) {
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
                else {
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, lK, lV, lLeft, lRight), $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, rK, rV, rLeft, rRight));
                }
            }
        }
        else {
            return dict;
        }
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpPrepEQGT_fn = function (targetKey, dict, color, key, value, left, right) {
        if ((!left.$) && (!left.a)) {
            var _v1 = left.a;
            var lK = left.b;
            var lV = left.c;
            var lLeft = left.d;
            var lRight = left.e;
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, lK, lV, lLeft, $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(0, key, value, lRight, right));
        }
        else {
            _v2$2: while (true) {
                if ((!right.$) && (right.a === 1)) {
                    if (!right.d.$) {
                        if (right.d.a === 1) {
                            var _v3 = right.a;
                            var _v4 = right.d;
                            var _v5 = _v4.a;
                            return $PedroHLC$elm_uuid_dict$UUID$Dict$moveRedRight(dict);
                        }
                        else {
                            break _v2$2;
                        }
                    }
                    else {
                        var _v6 = right.a;
                        var _v7 = right.d;
                        return $PedroHLC$elm_uuid_dict$UUID$Dict$moveRedRight(dict);
                    }
                }
                else {
                    break _v2$2;
                }
            }
            return dict;
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpPrepEQGT = F7($PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpPrepEQGT_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$removeMin = function (dict) {
        if ((!dict.$) && (!dict.d.$)) {
            var color = dict.a;
            var key = dict.b;
            var value = dict.c;
            var left = dict.d;
            var lColor = left.a;
            var lLeft = left.d;
            var right = dict.e;
            if (lColor === 1) {
                if ((!lLeft.$) && (!lLeft.a)) {
                    var _v3 = lLeft.a;
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$removeMin(left), right);
                }
                else {
                    var _v4 = $PedroHLC$elm_uuid_dict$UUID$Dict$moveRedLeft(dict);
                    if (!_v4.$) {
                        var nColor = _v4.a;
                        var nKey = _v4.b;
                        var nValue = _v4.c;
                        var nLeft = _v4.d;
                        var nRight = _v4.e;
                        return $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn(nColor, nKey, nValue, $PedroHLC$elm_uuid_dict$UUID$Dict$removeMin(nLeft), nRight);
                    }
                    else {
                        return $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
                    }
                }
            }
            else {
                return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$removeMin(left), right);
            }
        }
        else {
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
        }
    };
    var $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn = function (targetKey, dict) {
        if (dict.$ === -2) {
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
        }
        else {
            var color = dict.a;
            var key = dict.b;
            var value = dict.c;
            var left = dict.d;
            var right = dict.e;
            if (!$TSFoster$elm_uuid$UUID$compare_fn(targetKey, key)) {
                if ((!left.$) && (left.a === 1)) {
                    var _v4 = left.a;
                    var lLeft = left.d;
                    if ((!lLeft.$) && (!lLeft.a)) {
                        var _v6 = lLeft.a;
                        return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn(targetKey, left), right);
                    }
                    else {
                        var _v7 = $PedroHLC$elm_uuid_dict$UUID$Dict$moveRedLeft(dict);
                        if (!_v7.$) {
                            var nColor = _v7.a;
                            var nKey = _v7.b;
                            var nValue = _v7.c;
                            var nLeft = _v7.d;
                            var nRight = _v7.e;
                            return $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn(nColor, nKey, nValue, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn(targetKey, nLeft), nRight);
                        }
                        else {
                            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
                        }
                    }
                }
                else {
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(color, key, value, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn(targetKey, left), right);
                }
            }
            else {
                return $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpEQGT_fn(targetKey, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpPrepEQGT_fn(targetKey, dict, color, key, value, left, right));
            }
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp = F2($PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpEQGT_fn = function (targetKey, dict) {
        if (!dict.$) {
            var color = dict.a;
            var key = dict.b;
            var value = dict.c;
            var left = dict.d;
            var right = dict.e;
            if (_Utils_eq(targetKey, key)) {
                var _v1 = $PedroHLC$elm_uuid_dict$UUID$Dict$getMin(right);
                if (!_v1.$) {
                    var minKey = _v1.b;
                    var minValue = _v1.c;
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn(color, minKey, minValue, left, $PedroHLC$elm_uuid_dict$UUID$Dict$removeMin(right));
                }
                else {
                    return $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
                }
            }
            else {
                return $PedroHLC$elm_uuid_dict$UUID$Dict$balance_fn(color, key, value, left, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn(targetKey, right));
            }
        }
        else {
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBEmpty_elm_builtin;
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpEQGT = F2($PedroHLC$elm_uuid_dict$UUID$Dict$removeHelpEQGT_fn);
    var $PedroHLC$elm_uuid_dict$UUID$Dict$remove_fn = function (key, dict) {
        var _v0 = $PedroHLC$elm_uuid_dict$UUID$Dict$removeHelp_fn(key, dict);
        if ((!_v0.$) && (!_v0.a)) {
            var _v1 = _v0.a;
            var k = _v0.b;
            var v = _v0.c;
            var l = _v0.d;
            var r = _v0.e;
            return $PedroHLC$elm_uuid_dict$UUID$Dict$RBNode_elm_builtin_fn(1, k, v, l, r);
        }
        else {
            var x = _v0;
            return x;
        }
    }, $PedroHLC$elm_uuid_dict$UUID$Dict$remove = F2($PedroHLC$elm_uuid_dict$UUID$Dict$remove_fn);
    var $author$project$Maple$Data$Server$SingleServerError = 1;
    var $author$project$Maple$Data$Server$extraMap_fn = function (mapper, _v0) {
        var id = _v0.bV;
        var cuteId = _v0.bE;
        var nickname = _v0.b1;
        var extra = _v0.bM;
        return $author$project$Maple$Data$Server$Server_fn(id, cuteId, nickname, mapper(extra));
    }, $author$project$Maple$Data$Server$extraMap = F2($author$project$Maple$Data$Server$extraMap_fn);
    var $author$project$Maple$Data$Server$fromFull_a0 = $elm$core$Maybe$Just, $author$project$Maple$Data$Server$fromFull = $author$project$Maple$Data$Server$extraMap($author$project$Maple$Data$Server$fromFull_a0);
    var $author$project$Maple$Data$Server$insertToModelMerger_fn = function (maybeServer, maybeServers) {
        var _v0 = _Utils_Tuple2(maybeServer, maybeServers);
        if (!_v0.a.$) {
            if (!_v0.b.$) {
                var server = _v0.a.a;
                var servers = _v0.b.a;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn(server.bV, server, servers);
            }
            else {
                var server = _v0.a.a;
                var _v1 = _v0.b;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn(server.bV, server, $PedroHLC$elm_uuid_dict$UUID$Dict$empty);
            }
        }
        else {
            if (!_v0.b.$) {
                var _v2 = _v0.a;
                var servers = _v0.b.a;
                return servers;
            }
            else {
                var _v3 = _v0.a;
                var _v4 = _v0.b;
                return $PedroHLC$elm_uuid_dict$UUID$Dict$empty;
            }
        }
    }, $author$project$Maple$Data$Server$insertToModelMerger = F2($author$project$Maple$Data$Server$insertToModelMerger_fn);
    var $author$project$Utils$Recyclable$mergeResponse2_fn = function (map2, response, data) {
        var _v0 = _Utils_Tuple2(response, data);
        if (!_v0.a.$) {
            switch (_v0.b.$) {
                case 3:
                    var error = _v0.a.a;
                    var objB = _v0.b.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(A2(map2, $elm$core$Maybe$Nothing, $elm$core$Maybe$Just(objB)), $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage(error));
                case 4:
                    var error = _v0.a.a;
                    var _v1 = _v0.b;
                    var objB = _v1.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(A2(map2, $elm$core$Maybe$Nothing, $elm$core$Maybe$Just(objB)), $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage(error));
                default:
                    var error = _v0.a.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Failure(error);
            }
        }
        else {
            switch (_v0.b.$) {
                case 3:
                    var objA = _v0.a.a;
                    var objB = _v0.b.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(A2(map2, $elm$core$Maybe$Just(objA), $elm$core$Maybe$Just(objB)));
                case 4:
                    var objA = _v0.a.a;
                    var _v2 = _v0.b;
                    var objB = _v2.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(A2(map2, $elm$core$Maybe$Just(objA), $elm$core$Maybe$Just(objB)));
                default:
                    var objA = _v0.a.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(A2(map2, $elm$core$Maybe$Just(objA), $elm$core$Maybe$Nothing));
            }
        }
    }, $author$project$Utils$Recyclable$mergeResponse2_fn_unwrapped = function (map2, response, data) {
        var _v0 = _Utils_Tuple2(response, data);
        if (!_v0.a.$) {
            switch (_v0.b.$) {
                case 3:
                    var error = _v0.a.a;
                    var objB = _v0.b.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(map2($elm$core$Maybe$Nothing, $elm$core$Maybe$Just(objB)), $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage(error));
                case 4:
                    var error = _v0.a.a;
                    var _v1 = _v0.b;
                    var objB = _v1.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(map2($elm$core$Maybe$Nothing, $elm$core$Maybe$Just(objB)), $PaackEng$paack_remotedata$Remote$Recyclable$FailureStage(error));
                default:
                    var error = _v0.a.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Failure(error);
            }
        }
        else {
            switch (_v0.b.$) {
                case 3:
                    var objA = _v0.a.a;
                    var objB = _v0.b.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(map2($elm$core$Maybe$Just(objA), $elm$core$Maybe$Just(objB)));
                case 4:
                    var objA = _v0.a.a;
                    var _v2 = _v0.b;
                    var objB = _v2.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(map2($elm$core$Maybe$Just(objA), $elm$core$Maybe$Just(objB)));
                default:
                    var objA = _v0.a.a;
                    return $PaackEng$paack_remotedata$Remote$Recyclable$Ready(map2($elm$core$Maybe$Just(objA), $elm$core$Maybe$Nothing));
            }
        }
    }, $author$project$Utils$Recyclable$mergeResponse2 = F3($author$project$Utils$Recyclable$mergeResponse2_fn);
    var $author$project$Maple$Data$Server$insertToModel_fn = function (mapErrors, response) {
        return A2($author$project$Utils$Recyclable$mergeResponse2, $author$project$Maple$Data$Server$insertToModelMerger, $PaackEng$paack_remotedata$Remote$Response$mapCustomError_fn(mapErrors, response));
    }, $author$project$Maple$Data$Server$insertToModel = F2($author$project$Maple$Data$Server$insertToModel_fn);
    var $author$project$Main$Update$serverResult_fn = function (response, model) {
        return _Utils_Tuple2(function () {
            var _v0 = model.ar;
            if (_v0.$ === 3) {
                var session = _v0.a;
                var newServers = A3($author$project$Maple$Data$Server$insertToModel, function (_v1) {
                    return 1;
                }, $PaackEng$paack_remotedata$Remote$Response$map_fn($author$project$Maple$Data$Server$fromFull, response), session.O);
                return _Utils_update(model, {
                    ar: $author$project$Main$Model$LoginSuccess(_Utils_update(session, { O: newServers }))
                });
            }
            else {
                return model;
            }
        }(), $author$project$Effects$none);
    }, $author$project$Main$Update$serverResult = F2($author$project$Main$Update$serverResult_fn);
    var $author$project$Main$Update$sidebarSetOpen_fn = function (newState, model) {
        return _Utils_Tuple2(_Utils_update(model, { aN: newState }), $author$project$Effects$storageInsert_fn($author$project$Main$Msg$StorageResult, "o", _Json_encode_fn(0, $elm$json$Json$Encode$bool(newState))));
    }, $author$project$Main$Update$sidebarSetOpen = F2($author$project$Main$Update$sidebarSetOpen_fn);
    var $elm$core$Result$toMaybe = function (result) {
        if (!result.$) {
            var v = result.a;
            return $elm$core$Maybe$Just(v);
        }
        else {
            return $elm$core$Maybe$Nothing;
        }
    };
    var $author$project$Main$Update$storageUpdated_fn = function (newStorage, model) {
        var _v0 = $author$project$Main$Model$fromStorage($elm$core$Result$toMaybe(_Json_run_fn($author$project$Main$Model$knownStorageDecoder, newStorage)));
        var cart = _v0.a;
        var session = _v0.b;
        return _Utils_Tuple2(_Utils_update(model, { aX: cart, ar: session }), $author$project$Effects$none);
    }, $author$project$Main$Update$storageUpdated = F2($author$project$Main$Update$storageUpdated_fn);
    var $author$project$Main$Model$FormSending = { $: 3 };
    var $author$project$Main$Msg$TeamCreateResult_fn = function (a, b) {
        return { $: 18, a: a, b: b };
    }, $author$project$Main$Msg$TeamCreateResult = F2($author$project$Main$Msg$TeamCreateResult_fn);
    var $author$project$Maple$Ops$Team$Create$InvalidSessionError = 2;
    var $author$project$Maple$Ops$Team$Create$InvalidTeamNameError = 1;
    var $author$project$Maple$Internals$Mutation$PushTeamRequiredArguments = function (input) {
        return { m: input };
    };
    var $author$project$Maple$Ops$Team$Create$TeamNotFoundError = 0;
    var $author$project$Maple$Internals$Union$SetTeamResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("SetTeamOk", selections____.bh),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("TeamNotFoundError", selections____.bi),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidTeamNameError", selections____.a8),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidSessionError", selections____.a7)
        ]));
    };
    var $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe = function (maybeValue) {
        if (!maybeValue.$) {
            var value = maybeValue.a;
            return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
        }
        else {
            return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
        }
    };
    var $author$project$Maple$Internals$InputObject$encodeSetTeamInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("id", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.bV, $author$project$Maple$Internals$Scalar$unwrapEncoder_fn(function ($) {
                return $.C;
            }, $author$project$Maple$ScalarCodecs$codecs))),
            _Utils_Tuple2("nickname", $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional_fn(input____.b1, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
        ]));
    };
    var $author$project$Maple$Internals$Mutation$pushTeam_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("pushTeam", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodeSetTeamInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Mutation$pushTeam = F2($author$project$Maple$Internals$Mutation$pushTeam_fn);
    var $author$project$Maple$Internals$Object$SetTeamOk$team = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("team", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Ops$Team$Create$operation_fn = function (selection, params) {
        return $author$project$Maple$Client$Mutation($author$project$Maple$Internals$Mutation$pushTeam_fn($author$project$Maple$Internals$Mutation$PushTeamRequiredArguments({
            bV: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(params.bV),
            b1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(params.dS)
        }), $author$project$Maple$Internals$Union$SetTeamResult$fragments({
            a7: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(2)),
            a8: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(1)),
            bh: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, $author$project$Maple$Internals$Object$SetTeamOk$team(selection)),
            bi: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0))
        })));
    }, $author$project$Maple$Ops$Team$Create$operation = F2($author$project$Maple$Ops$Team$Create$operation_fn);
    var $author$project$Maple$Ops$Team$Create$push_fn = function (selection, responseMsg, environment, params, account) {
        return $author$project$Maple$Client$sendRequest({
            bJ: environment,
            aJ: $author$project$Maple$Ops$Team$Create$operation_fn(selection, params),
            z: responseMsg,
            cA: account
        });
    }, $author$project$Maple$Ops$Team$Create$push = F5($author$project$Maple$Ops$Team$Create$push_fn);
    var $author$project$Main$Update$teamCreate_fn = function (value, model) {
        var _v0 = model.ar;
        if (_v0.$ === 3) {
            var session = _v0.a;
            return _Utils_Tuple2(_Utils_update(model, { cw: $author$project$Main$Model$FormSending }), $author$project$Maple$Ops$Team$Create$push_fn($author$project$Maple$Data$Team$teamSelection, $author$project$Main$Msg$TeamCreateResult(value), model.bJ, {
                bV: $elm$core$Maybe$Nothing,
                dS: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
            }, session.A));
        }
        else {
            return _Utils_Tuple2(model, $author$project$Effects$none);
        }
    }, $author$project$Main$Update$teamCreate = F2($author$project$Main$Update$teamCreate_fn);
    var $author$project$Main$Model$FormFailed = function (a) {
        return { $: 1, a: a };
    };
    var $author$project$Main$Model$FormSuccess = { $: 2 };
    var $PaackEng$paack_remotedata$Remote$Recyclable$Loading = { $: 1 };
    var $PaackEng$paack_remotedata$Remote$Recyclable$LoadingStage = { $: 0 };
    var $PaackEng$paack_remotedata$Remote$Recyclable$toLoading = function (data) {
        switch (data.$) {
            case 0:
                return $PaackEng$paack_remotedata$Remote$Recyclable$Loading;
            case 1:
                return $PaackEng$paack_remotedata$Remote$Recyclable$Loading;
            case 2:
                return $PaackEng$paack_remotedata$Remote$Recyclable$Loading;
            case 4:
                var object = data.a;
                return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(object, $PaackEng$paack_remotedata$Remote$Recyclable$LoadingStage);
            default:
                var object = data.a;
                return $PaackEng$paack_remotedata$Remote$Recyclable$Recycling_fn(object, $PaackEng$paack_remotedata$Remote$Recyclable$LoadingStage);
        }
    };
    var $author$project$Main$Model$refresh_fn = function (what, model) {
        return (what.L || what.aK) ? _Utils_update(model, {
            ar: function () {
                var _v0 = model.ar;
                if (_v0.$ === 3) {
                    var session = _v0.a;
                    return $author$project$Main$Model$LoginSuccess(_Utils_update(session, {
                        O: what.L ? $PaackEng$paack_remotedata$Remote$Recyclable$toLoading(session.O) : session.O,
                        aa: what.L ? $PaackEng$paack_remotedata$Remote$Recyclable$toLoading(session.aa) : session.aa
                    }));
                }
                else {
                    return model.ar;
                }
            }()
        }) : model;
    }, $author$project$Main$Model$refresh = F2($author$project$Main$Model$refresh_fn);
    var $author$project$Main$Update$teamPull = function (model) {
        var _v0 = $author$project$Main$Model$getSession(model.ar);
        if (!_v0.$) {
            var account = _v0.a.A;
            return $author$project$Maple$Ops$Team$pull_fn($author$project$Maple$Data$Team$teamSelection, $author$project$Main$Msg$TeamsResult, model.bJ, account);
        }
        else {
            return $author$project$Effects$none;
        }
    };
    var $author$project$Main$Update$teamCreateResult_fn = function (response, model) {
        var _v0 = function () {
            if (!response.$) {
                var error = response.a;
                return _Utils_Tuple2($author$project$Main$Model$FormFailed(error), false);
            }
            else {
                return _Utils_Tuple2($author$project$Main$Model$FormSuccess, true);
            }
        }();
        var newForm = _v0.a;
        var reload = _v0.b;
        var newModel = _Utils_update(model, { cw: newForm });
        return reload ? _Utils_Tuple2($author$project$Main$Model$refresh_fn(_Utils_update($author$project$Main$Model$notReloading, { aK: true }), newModel), $author$project$Main$Update$teamPull(newModel)) : _Utils_Tuple2(newModel, $author$project$Effects$none);
    }, $author$project$Main$Update$teamCreateResult = F2($author$project$Main$Update$teamCreateResult_fn);
    var $author$project$Main$Msg$TeamInvitationResult_fn = function (a, b, c) {
        return { $: 22, a: a, b: b, c: c };
    }, $author$project$Main$Msg$TeamInvitationResult = F3($author$project$Main$Msg$TeamInvitationResult_fn);
    var $author$project$Maple$Ops$Team$PushStranger$ContributorNotFoundError = 1;
    var $author$project$Maple$Ops$Team$PushStranger$InvalidSessionError = 2;
    var $author$project$Maple$Internals$Mutation$PushTeamStrangerMemberRequiredArguments = function (input) {
        return { m: input };
    };
    var $author$project$Maple$Ops$Team$PushStranger$TeamNotFoundError = 0;
    var $author$project$Maple$Internals$Union$SetTeamMemberResult$fragments = function (selections____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(_List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("SetTeamMemberOk", selections____.bg),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("TeamNotFoundError", selections____.bi),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("ContributorNotFoundError", selections____.a4),
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment_fn("InvalidSessionError", selections____.a7)
        ]));
    };
    var $author$project$Maple$Internals$InputObject$encodeSetTeamStrangerMemberInput = function (input____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(_List_fromArray([
            _Utils_Tuple2("contributorEmail", $elm$core$Maybe$Just($author$project$Maple$Internals$Scalar$unwrapEncoder_fn(function ($) {
                return $.bA;
            }, $author$project$Maple$ScalarCodecs$codecs)(input____.ah))),
            _Utils_Tuple2("teamID", $elm$core$Maybe$Just($author$project$Maple$Internals$Scalar$unwrapEncoder_fn(function ($) {
                return $.C;
            }, $author$project$Maple$ScalarCodecs$codecs)(input____.w)))
        ]));
    };
    var $author$project$Maple$Internals$Mutation$pushTeamStrangerMember_fn = function (requiredArgs____, object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("pushTeamStrangerMember", _List_fromArray([
            $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required_fn("input", requiredArgs____.m, $author$project$Maple$Internals$InputObject$encodeSetTeamStrangerMemberInput)
        ]), object____, $elm$core$Basics$identity);
    }, $author$project$Maple$Internals$Mutation$pushTeamStrangerMember = F2($author$project$Maple$Internals$Mutation$pushTeamStrangerMember_fn);
    var $author$project$Maple$Internals$Object$SetTeamMemberOk$team = function (object____) {
        return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField_fn("team", _List_Nil, object____, $elm$core$Basics$identity);
    };
    var $author$project$Maple$Ops$Team$PushStranger$operation_fn = function (selection, params) {
        return $author$project$Maple$Client$Mutation($author$project$Maple$Internals$Mutation$pushTeamStrangerMember_fn($author$project$Maple$Internals$Mutation$PushTeamStrangerMemberRequiredArguments({ ah: params.bH, w: params.eq }), $author$project$Maple$Internals$Union$SetTeamMemberResult$fragments({
            a4: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(1)),
            a7: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(2)),
            bg: $dillonkearns$elm_graphql$Graphql$SelectionSet$map_fn($elm$core$Result$Ok, $author$project$Maple$Internals$Object$SetTeamMemberOk$team(selection)),
            bi: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Result$Err(0))
        })));
    }, $author$project$Maple$Ops$Team$PushStranger$operation = F2($author$project$Maple$Ops$Team$PushStranger$operation_fn);
    var $author$project$Maple$Ops$Team$PushStranger$push_fn = function (selection, responseMsg, environment, params, account) {
        return $author$project$Maple$Client$sendRequest({
            bJ: environment,
            aJ: $author$project$Maple$Ops$Team$PushStranger$operation_fn(selection, params),
            z: responseMsg,
            cA: account
        });
    }, $author$project$Maple$Ops$Team$PushStranger$push = F5($author$project$Maple$Ops$Team$PushStranger$push_fn);
    var $author$project$Main$Update$teamInvitation_fn = function (teamId, email, model) {
        var _v0 = model.ar;
        if (_v0.$ === 3) {
            var session = _v0.a;
            var newInvitingToTeam = $PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn(teamId, $author$project$Main$Model$FormSending, model.cx);
            return _Utils_Tuple2(_Utils_update(model, { cx: newInvitingToTeam }), $author$project$Maple$Ops$Team$PushStranger$push_fn($author$project$Maple$Data$Team$teamSelection, A2($author$project$Main$Msg$TeamInvitationResult, teamId, email), model.bJ, { bH: email, eq: teamId }, session.A));
        }
        else {
            return _Utils_Tuple2(model, $author$project$Effects$none);
        }
    }, $author$project$Main$Update$teamInvitation = F3($author$project$Main$Update$teamInvitation_fn);
    var $author$project$Main$Update$teamInvitationResult_fn = function (teamId, response, model) {
        var _v0 = function () {
            if (!response.$) {
                var error = response.a;
                return _Utils_Tuple2($author$project$Main$Model$FormFailed(error), false);
            }
            else {
                return _Utils_Tuple2($author$project$Main$Model$FormSuccess, true);
            }
        }();
        var newForm = _v0.a;
        var reload = _v0.b;
        var newInvitingToTeam = $PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn(teamId, newForm, model.cx);
        var newModel = _Utils_update(model, { cx: newInvitingToTeam });
        return reload ? _Utils_Tuple2($author$project$Main$Model$refresh_fn(_Utils_update($author$project$Main$Model$notReloading, { aK: true }), newModel), $author$project$Main$Update$teamPull(newModel)) : _Utils_Tuple2(newModel, $author$project$Effects$none);
    }, $author$project$Main$Update$teamInvitationResult = F3($author$project$Main$Update$teamInvitationResult_fn);
    var $author$project$Maple$Data$Team$InvalidSessionError = 0;
    var $author$project$Main$Update$teamsResult_fn = function (response, model) {
        return _Utils_Tuple2(function () {
            var _v0 = model.ar;
            if (_v0.$ === 3) {
                var session = _v0.a;
                var newTeams = $PaackEng$paack_remotedata$Remote$Recyclable$mergeResponse_fn($PaackEng$paack_remotedata$Remote$Response$mapCustomError_fn(function (_v1) {
                    return 0;
                }, response), session.aa);
                return _Utils_update(model, {
                    ar: $author$project$Main$Model$LoginSuccess(_Utils_update(session, { aa: newTeams }))
                });
            }
            else {
                return model;
            }
        }(), $author$project$Effects$none);
    }, $author$project$Main$Update$teamsResult = F2($author$project$Main$Update$teamsResult_fn);
    var $author$project$Main$Update$urlChanged_fn = function (newUrl, model) {
        var _v0 = $author$project$Main$Model$viewingFromRoute_fn(model.bJ, model.ar, $author$project$Pages$Route$fromUrl(newUrl));
        var viewing = _v0.a;
        var viewingEffects = _v0.b;
        var whatIsRefreshing = _v0.c;
        return _Utils_Tuple2($author$project$Main$Model$refresh_fn(whatIsRefreshing, _Utils_update(model, { cB: viewing })), viewingEffects);
    }, $author$project$Main$Update$urlChanged = F2($author$project$Main$Update$urlChanged_fn);
    var $author$project$Main$Update$update_fn = function (msg, model) {
        var noOp = _Utils_Tuple2(model, $author$project$Effects$none);
        switch (msg.$) {
            case 0:
                var newWidth = msg.a;
                var newHeight = msg.b;
                return _Utils_Tuple2(_Utils_update(model, { dv: newHeight, dw: newWidth }), $author$project$Effects$none);
            case 1:
                var game = msg.a;
                var playerAmount = msg.b;
                return $author$project$Main$Update$cartInsert_fn(game, playerAmount, model);
            case 2:
                var game = msg.a;
                return $author$project$Main$Update$cartRemove_fn(game, model);
            case 3:
                var response = msg.a;
                return $author$project$Main$Update$dashboardResult_fn(response, model);
            case 4:
                var route = msg.a;
                return $author$project$Main$Update$pageChange_fn(route, model);
            case 5:
                var email = msg.a;
                var code = msg.b;
                return $author$project$Main$Update$login_fn(email, code, model);
            case 6:
                var value = msg.a;
                return _Utils_Tuple2(_Utils_update(model, {
                    ar: $author$project$Main$Model$LoginEmail_fn(value, $author$project$Utils$Email$isValid(value))
                }), $author$project$Effects$none);
            case 7:
                var email = msg.a;
                var value = msg.b;
                return _Utils_Tuple2(_Utils_update(model, {
                    ar: $author$project$Main$Model$LoginOTP_fn(email, value, $author$project$Main$Update$isValidOTP(value))
                }), $author$project$Effects$none);
            case 8:
                var email = msg.a;
                var response = msg.b;
                return $author$project$Main$Update$loginResult_fn(email, response, model);
            case 9:
                return $author$project$Main$Update$logout(model);
            case 10:
                var response = msg.a;
                return $author$project$Main$Update$logoutResult_fn(response, model);
            case 11:
                var response = msg.a;
                return $author$project$Main$Update$serverResult_fn(response, model);
            case 12:
                var newState = msg.a;
                return $author$project$Main$Update$sidebarSetOpen_fn(newState, model);
            case 13:
                return noOp;
            case 14:
                var newStorage = msg.a;
                return $author$project$Main$Update$storageUpdated_fn(newStorage, model);
            case 15:
                var value = msg.a;
                return $author$project$Main$Update$teamCreate_fn(value, model);
            case 16:
                var newValue = msg.a;
                return _Utils_Tuple2(_Utils_update(model, {
                    cw: $author$project$Main$Model$FormFilling(_Utils_Tuple2(newValue, $author$project$Main$Update$isValidName(newValue)))
                }), $author$project$Effects$none);
            case 17:
                return _Utils_Tuple2(_Utils_update(model, {
                    cw: $author$project$Main$Model$FormFilling(_Utils_Tuple2("", false))
                }), $author$project$Effects$none);
            case 18:
                var response = msg.b;
                return $author$project$Main$Update$teamCreateResult_fn(response, model);
            case 19:
                var teamId = msg.a;
                var email = msg.b;
                return $author$project$Main$Update$teamInvitation_fn(teamId, email, model);
            case 20:
                var teamId = msg.a;
                var value = msg.b;
                return _Utils_Tuple2(_Utils_update(model, {
                    cx: $PedroHLC$elm_uuid_dict$UUID$Dict$insert_fn(teamId, $author$project$Main$Model$FormFilling(_Utils_Tuple2(value, $author$project$Utils$Email$isValid(value))), model.cx)
                }), $author$project$Effects$none);
            case 21:
                var teamId = msg.a;
                return _Utils_Tuple2(_Utils_update(model, {
                    cx: $PedroHLC$elm_uuid_dict$UUID$Dict$remove_fn(teamId, model.cx)
                }), $author$project$Effects$none);
            case 22:
                var teamId = msg.a;
                var response = msg.c;
                return $author$project$Main$Update$teamInvitationResult_fn(teamId, response, model);
            case 23:
                var response = msg.a;
                return $author$project$Main$Update$teamsResult_fn(response, model);
            case 24:
                var newUrl = msg.a;
                return $author$project$Main$Update$urlChanged_fn(newUrl, model);
            default:
                if (!msg.a.$) {
                    var newUrl = msg.a.a;
                    return $author$project$Main$Update$urlChanged_fn(newUrl, model);
                }
                else {
                    return noOp;
                }
        }
    }, $author$project$Main$Update$update = F2($author$project$Main$Update$update_fn);
    var $author$project$Main$wrappedUpdate_fn = function (msg, wrapped) {
        var _v0 = $author$project$Main$Update$update_fn(msg, wrapped.R);
        var appModel = _v0.a;
        var effects = _v0.b;
        var _v1 = $elm$core$List$foldl_fn(A2($author$project$Main$effectsApplier, wrapped.a0, appModel), _Utils_Tuple2(wrapped.aM, $elm$core$Platform$Cmd$none), effects);
        var newSeeds = _v1.a;
        var cmds = _v1.b;
        return _Utils_Tuple2(_Utils_update(wrapped, { R: appModel, aM: newSeeds }), cmds);
    }, $author$project$Main$wrappedUpdate = F2($author$project$Main$wrappedUpdate_fn);
    var $author$project$Main$main = $elm$browser$Browser$application({
        du: $author$project$Main$wrappedInit,
        d5: $author$project$Main$Msg$UrlChanged,
        d6: $author$project$Main$Msg$UrlRequested,
        eo: A2($elm$core$Basics$composeR, function ($) {
            return $.R;
        }, $author$project$Main$Subscriptions$subscriptions),
        ey: $author$project$Main$wrappedUpdate,
        eB: A2($elm$core$Basics$composeR, function ($) {
            return $.R;
        }, $author$project$Main$View$document)
    });
    _Platform_export({ "Main": { "init": $author$project$Main$main(_Json_andThen_fn(function (randomSeed4) {
                return _Json_andThen_fn(function (randomSeed3) {
                    return _Json_andThen_fn(function (randomSeed2) {
                        return _Json_andThen_fn(function (randomSeed1) {
                            return _Json_andThen_fn(function (innerWidth) {
                                return _Json_andThen_fn(function (innerHeight) {
                                    return _Json_andThen_fn(function (expendable) {
                                        return _Json_andThen_fn(function (apiUrl) {
                                            return $elm$json$Json$Decode$succeed({ cL: apiUrl, dh: expendable, dv: innerHeight, dw: innerWidth, cb: randomSeed1, cc: randomSeed2, cd: randomSeed3, ce: randomSeed4 });
                                        }, _Json_decodeField_fn("apiUrl", $elm$json$Json$Decode$string));
                                    }, _Json_decodeField_fn("expendable", $elm$json$Json$Decode$value));
                                }, _Json_decodeField_fn("innerHeight", $elm$json$Json$Decode$int));
                            }, _Json_decodeField_fn("innerWidth", $elm$json$Json$Decode$int));
                        }, _Json_decodeField_fn("randomSeed1", $elm$json$Json$Decode$int));
                    }, _Json_decodeField_fn("randomSeed2", $elm$json$Json$Decode$int));
                }, _Json_decodeField_fn("randomSeed3", $elm$json$Json$Decode$int));
            }, _Json_decodeField_fn("randomSeed4", $elm$json$Json$Decode$int)))(0) } });
}(this));
