import "./sprite";
import { Elm } from "../../src/Main.elm";
import * as TaskPort from "../../vendor/node_modules/elm-taskport";
import * as LocalStorage from "../../vendor/node_modules/elm-localstorage";
import indexStyle from "../index.css";

const seeds = Array.from(crypto.getRandomValues(new Uint32Array(4)));
const environment =
  typeof env_APP_ENV != "undefined" ? env_APP_ENV : "development";
const appConfig = {
  flags: {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    randomSeed1: seeds[0],
    randomSeed2: seeds[1],
    randomSeed3: seeds[2],
    randomSeed4: seeds[3],
    apiUrl:
      environment === "production"
        ? "https://api.gpl.sh/v1"
        : environment === "testing"
          ? "https://lab.pedrohlc.com/shelter"
          : "http://localhost:8080/graphql",
    expendable: {
      storage: localStorage,
      language:
        window.navigator.userLanguage || window.navigator.language || null,
      timeNow: Date.now(),
    },
  },
};

const app = Elm.Main.init(appConfig);

TaskPort.install();
LocalStorage.install(TaskPort);

window.addEventListener("storage", async (event) => {
  if (event.storageArea === localStorage) {
    (await app).ports.storageChanged.send(localStorage);
  }
});
